/**
 * @see App\Models\Contact\Enum\DoctorProfession
 * @type {{urologist: string, internist: string, other: string, diabetologist: string, dermatologist: string, surgeon: string, radiologist: string, pulmonologist: string, dentist: string, pediatrician: string, doctor: string, naturopaths: string, psychiatrist: string, neurologist: string, orthopedist: string, rheumatologist: string}}
 */
export const doctorProfession = {
  doctor: "Allgemeinarzt",
  other: "Anderer Facharzt",
  surgeon: "Chirurg",
  dermatologist: "Dermatologe",
  diabetologist: "Diabetologe",
  naturopaths: "Heilpraktiker",
  internist: "Internist",
  pediatrician: "Kinderarzt",
  cardiologist: "Kardiologe",
  neurologist: "Neurologe",
  orthopedist: "Orthopäde",
  psychiatrist: "Psychiater",
  pulmonologist: "Pulmologe",
  radiologist: "Radiologe",
  rheumatologist: "Rheumatologe",
  urologist: "Urologe",
  dentist: "Zahnarzt",
};

/**
 * @see App\Models\Assignment\Enum\AssignmentRole
 * @type {{carer: string, generalPractitioner: string, nursingService: string, hospice: string, nursingHome: string, spouse: string, relative: string}}
 */
export const assignmentRole = {
  generalPractitioner: "Hausarzt",
  nursingHome: "Pflegeheim",
  nursingService: "Pflegedienst",
  hospice: "Hospiz",
  carer: "Betreuer",
  // spouse: "Ehepartner", => todo: store as PatientAttribute!
  // relative: "Angehöriger", => todo: store as PatientAttribute!
};
