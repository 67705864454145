<template>
  <div :class="{'flex flex-wrap space-x-1.5': inline}">
    <h6 class="text-gray-600" :class="{'font-semibold': inline, 'text-xs uppercase': !inline}">
      Eingeschränkte Mobilität{{ inline ? ":" : "" }}
    </h6>
    <ul :class="{'mt-0.5 text-sm': !inline}">
      <template v-for="(label, key) in mobilityRestriction" :key="key">
        <li v-if="attribute?.payload[key]">{{ label }}</li>
      </template>
    </ul>
  </div>
</template>

<script>
  import {mobilityRestriction} from "@pages/Patients/Components/PatientAttributes/Enums.js";

  export default {
    name: "ComponentPatientSidebarMobilityAttribute",

    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      return {mobilityRestriction};
    },
  };
</script>
