<template>
  <section
    :class="{
      '[.print-doc_&]:print:hidden': !printDoc,
      '[.print-patient_&]:print:hidden': !printPatient,
      'bg-yellow-50': highlightSection,
    }"
  >
    <h2 class="text-2xl text-gray-500 text-center cursor-default">Übersicht</h2>
    <div class="grid justify-end print:hidden">
      <div class="text-sm text-right" @mouseover="highlightSection = true" @mouseout="highlightSection = false">
        <div>Übersicht übernehmen in PDF</div>
        <div class="flex space-x-4 justify-end">
          <component-checkbox v-model="printDoc" data-test="overview-checkbox-printDoc">an Arzt</component-checkbox>
          <component-checkbox v-model="printPatient" data-test="overview-checkbox-printPatient">
            an Patient
          </component-checkbox>
        </div>
      </div>
    </div>
    <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4 print:break-before-avoid-page">
      <pages-record-result-overview-greenlights :checks="greenlights" />

      <div class="space-y-4">
        <pages-record-result-overview-redlights
          v-if="symptomaticRedlights.length"
          anchor-prefix="symptomatic"
          title="Aktuelle Probleme / Symptome"
          :checks="symptomaticRedlights"
        />
        <pages-record-result-overview-redlights
          v-if="prognosticRedlights.length"
          anchor-prefix="prognostic"
          title="Potentielle Risiken"
          :checks="prognosticRedlights"
        />
        <pages-record-result-overview-redlights
          v-if="nonComplianceRedlights.length"
          anchor-prefix="non_compliance"
          title="Adhärenz"
          :checks="nonComplianceRedlights"
        />
        <pages-record-result-overview-redlights
          v-if="preventionRedlights.length"
          anchor-prefix="prevention"
          title="Prävention"
          :checks="preventionRedlights"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import {computed, ref} from "vue";
  import PagesRecordResultOverviewGreenlights from "@pages/Records/Components/Result/Overview/Greenlights.vue";
  import PagesRecordResultOverviewRedlights from "@pages/Records/Components/Result/Overview/Redlights.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "PagesRecordResultOverview",
    components: {
      ComponentCheckbox,
      PagesRecordResultOverviewRedlights,
      PagesRecordResultOverviewGreenlights,
    },
    props: {
      greenlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
      symptomaticRedlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
      prognosticRedlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
      nonComplianceRedlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
      preventionRedlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    setup() {
      const printDoc = ref(true);
      const printPatient = ref(true);
      const highlightSection = ref(false);

      return {highlightSection, printDoc, printPatient};
    },
  };
</script>
