<template>
  <patientinput-component-step
    title="Labor-/Vitalwerte"
    optional
    :forward-button-enabled="allFieldValid"
    :test-id="testId + '-labor-data'"
  >
    <component-input
      v-model="form.laborvalues.size"
      type="text"
      label="Körpergröße"
      large
      :validation="errors.size"
      autocomplete="off"
      :test-id="testId + '-labor-data-step-size'"
    >
      <template #suffix>cm</template>
    </component-input>

    <component-input
      v-model="form.laborvalues.weight"
      type="text"
      label="Körpergewicht"
      large
      :validation="errors.weight"
      autocomplete="off"
      :test-id="testId + '-labor-data-step-weight'"
    >
      <template #suffix>kg</template>
    </component-input>

    <component-input
      v-model="form.laborvalues.bmi"
      type="text"
      label="Body-Mass-Index (BMI)"
      large
      :validation="errors.bmi"
      autocomplete="off"
      :test-id="testId + '-labor-data-step-bmi'"
    >
      <template #suffix>
        kg/m
        <sup>2</sup>
      </template>
    </component-input>

    <component-input
      v-model="form.laborvalues.bloodpressure"
      type="text"
      label="Blutdruck"
      placeholder="systolisch / diastolisch"
      large
      :validation="errors.bloodpressure"
      autocomplete="off"
      :test-id="testId + '-labor-data-step-blood-pressure'"
    >
      <template #suffix>mmHg</template>
    </component-input>

    <component-input
      v-model="form.laborvalues.pulse"
      type="text"
      label="Puls"
      large
      :validation="errors.pulse"
      autocomplete="off"
      :test-id="testId + '-labor-data-step-pulse'"
    >
      <template #suffix>Schläge/Minute</template>
    </component-input>
  </patientinput-component-step>
</template>

<script>
  import {computed, onMounted, reactive, watch} from "vue";
  import {debounce} from "lodash";

  import ComponentInput from "@components/Inputs/Input.vue";

  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepLabordata",

    components: {PatientinputComponentStep, ComponentInput},

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const errors = reactive({
        size: null,
        weight: null,
        bmi: null,
        bloodpressure: null,
        pulse: null,
      });

      const allFieldValid = computed(() => {
        return Object.values(errors).filter((i) => i !== null).length === 0;
      });

      watch(
        () => props.form.laborvalues.size,
        debounce((newValue) => {
          if (!checkNumericValue(newValue, "size")) {
            // alert non-numeric value
          } else if (newValue && parseFloat(newValue?.replace(",", ".")) < 5) {
            // alert that user knows size is in cm
            errors.size = "Bitte geben Sie die Körpergröße in Zentimetern an.";
          } else {
            errors.size = null;
          }
          calculateBmi();
        }, 1000),
      );

      watch(
        () => props.form.laborvalues.weight,
        debounce((newValue) => {
          checkNumericValue(newValue, "weight");
          calculateBmi();
        }, 1000),
      );

      watch(
        () => props.form.laborvalues.bmi,
        debounce((v) => checkNumericValue(v, "bmi"), 1000),
      );

      watch(
        () => props.form.laborvalues.bloodpressure,
        (newValue) => {
          // Add Slash to Bloodpressure

          // Skip if is empty or valid value
          if (!newValue || newValue.length === 0 || newValue.match(/^[0-9]{2,3} \/ [0-9]{2,3}$/)) return;

          if ((newValue.length === 2 && parseInt(newValue.substring(0, 1)) > 3) || newValue.length === 3) {
            let lastChar = newValue.substring(newValue.length - 1, newValue.length);

            if (lastChar.match(/[0-9]/)) {
              props.form.laborvalues.bloodpressure = newValue + " / ";
            } else {
              props.form.laborvalues.bloodpressure = newValue.substring(0, 2) + " / ";
            }
          } else if (newValue.length > 3) {
            let matches = newValue.match(/([0-9]{2,3})[^0-9]+([0-9]{0,2})/);

            if (matches) {
              props.form.laborvalues.bloodpressure = matches[1] + " / " + matches[2] ?? "";
            }
          }
        },
      );

      watch(
        () => props.form.laborvalues.bloodpressure,
        debounce((newValue) => {
          if (!newValue || newValue.length === 0) return (errors.bloodpressure = null);

          if (!newValue.match(/^[0-9]{2,3} \/ [0-9]{2,3}$/)) {
            errors.bloodpressure = 'Bitte geben Sie den Blutdruck im Format "systolisch / diastolisch" an.';
          } else {
            errors.bloodpressure = null;
          }
        }, 1000),
      );

      watch(
        () => props.form.laborvalues.pulse,
        debounce((v) => checkNumericValue(v, "pulse"), 1000),
      );

      onMounted(() => {
        calculateBmi();
      });

      const isNumeric = (str) => {
        if (typeof str === "number") return true;
        else if (typeof str !== "string") return false;
        return /^[\d,. ]+$/.test(str);
      };

      const calculateBmi = () => {
        if (
          props.form.laborvalues.size &&
          isNumeric(props.form.laborvalues.size) &&
          props.form.laborvalues.weight &&
          isNumeric(props.form.laborvalues.weight) &&
          errors["size"] === null &&
          errors["weight"] === null
        ) {
          const size =
            (typeof props.form.laborvalues.size === "number"
              ? props.form.laborvalues.size
              : parseFloat(props.form.laborvalues.size.replace(",", "."))) / 100;

          const weight =
            typeof props.form.laborvalues.weight === "number"
              ? props.form.laborvalues.weight
              : parseFloat(props.form.laborvalues.weight.replace(",", "."));

          const calculatedBmi = weight / Math.pow(size, 2);

          props.form.laborvalues.bmi = calculatedBmi.toFixed(0);
        } else if (
          props.form.laborvalues.size &&
          props.form.laborvalues.weight &&
          (errors["size"] !== null || errors["weight"] !== null)
        ) {
          // reset bmi if both, size and weight, are set but at least one of them is invalid
          props.form.laborvalues.bmi = null;
        }
      };

      const checkNumericValue = (newValue, key) => {
        if (newValue && !isNumeric(newValue)) {
          errors[key] = "Bitte geben Sie eine Zahl ein.";
          return false;
        } else {
          errors[key] = null;
          return true;
        }
      };

      return {
        /** const */
        errors,

        /** computed */
        allFieldValid,
      };
    },
  };
</script>
