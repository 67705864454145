<template>
  <component-dialog ref="refDialog" :dialog-title="dialogTitle" dialog-width="large" :test-id="testId + '-share'">
    <template #content>
      <div class="flex flex-col gap-3">
        <p>
          <span v-if="isCarer">Pflegefachkräfte können die digitale Checkliste</span>
          <span v-else>Ihre Patienten können den digitalen Patientenfragebogen</span>
          entweder über den untenstehenden QR-Code oder Link aufrufen.
        </p>

        <div>
          <p>
            Den QR-Code oder Link können Sie
            <span v-if="isCarer">den Pflegefachkräften</span>
            <span v-else>den Patienten</span>
            ganz nach Ihren eigenen Wünschen zur Verfügung stellen, z. B.
          </p>

          <ul class="list-disc ml-5">
            <li>in automatischen E-Mails Ihrer Terminvereinbarungssoftware,</li>
            <li>auf einem Terminkärtchen,</li>
            <li>auf Ihrer Website,</li>
            <li>auf einem Flyer,</li>
            <li>auf einer Apothekenvisitenkarten etc.</li>
          </ul>
        </div>

        <p>
          <span v-if="clientCompany">
            <span v-if="isCarer">Der Pflegefachkraft</span>

            <span v-else>Dem Patienten</span>
            wird der Name Ihrer Apotheke ({{ clientCompany }}) angezeigt. Diesen können Sie in der

            <a :href="profileEditLink" target="_blank" :data-test="testId + '-share-dialog-profile-edit-link'">
              pharma4u-Benutzerverwaltung
            </a>
            ändern.
          </span>

          <span v-else>
            Bitte hinterlegen Sie vorab Ihren Apothekennamen in der
            <a :href="profileEditLink" target="_blank" :data-test="testId + '-share-dialog-profile-edit-link'">
              pharma4u-Benutzerverwaltung.
            </a>
          </span>
        </p>

        <p>
          Bitte beachten: Ihre Apotheke ist im Sinne der DSGVO verantwortlich für die Datenverarbeitung. Wir empfehlen,
          Ihrem Patienten Ihre eigene Datenschutzerklärung z.B. per Link zugänglich zu machen. Eine allgemeingültige
          Vorlage können Sie sich

          <a
            href="/static/Mustereinwilligungserklärung-Medikationsanalyse.docx"
            :data-test="testId + '-share-dialog-download-declaration-of-consent-link'"
          >
            hier herunterladen
          </a>
          (Word-Datei im docx Format).
        </p>

        <div class="flex flex-row flex-wrap justify-center items-center gap-5">
          <div class="flex flex-col items-center">
            <div class="w-32">
              <vue-qrcode
                :value="shareLink"
                type="image/jpeg"
                scale="12"
                margin="2"
                :data-test="testId + '-share-dialog-qr-code'"
                @change="qrCodeChanged"
              />
            </div>

            <a
              :href="qrCodeDataUrl"
              target="_blank"
              :download="downloadFileName"
              :data-test="testId + '-share-dialog-download-qr-code-data'"
            >
              <component-button class="p4umc-primary" label="QR-Code herunterladen" />
            </a>
          </div>

          <div class="flex flex-col items-center">
            <p class="font-mono mb-2">{{ shareLink }}</p>

            <component-button
              label="Link kopieren"
              :class="{'p4umc-primary': !copied, 'p4umc-accent': copied}"
              :data-test="testId + '-share-dialog-copy-link-to-clipboard'"
              @click="copyToClipboard()"
            >
              <component-icon v-if="copied" class="ms-1 font-bold">check</component-icon>
            </component-button>
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <component-button label="Schließen" :data-test="testId + '-share-dialog-close'" @click="close()" />

      <a :href="shareLink" target="_blank" :data-test="testId + '-share-dialog-preview-link'">
        <component-button label="Vorschau" />
      </a>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";
  import VueQrcode from "vue-qrcode";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "PatientinputClientComponentShareDialog",

    components: {ComponentIcon, VueQrcode, ComponentButton, ComponentDialog},

    setup() {
      const page = usePage();

      const refDialog = ref(null);

      const qrCodeDataUrl = ref(null);
      const copied = ref(false);
      const isCarer = ref(false);

      const shareLink = computed(() => (isCarer.value ? page.props.shareLinkCarer : page.props.shareLinkPatient));
      const clientCompany = computed(() => page.props.clientCompany);
      const profileEditLink = computed(() => page.props.links.p4uProfile);

      const dialogTitle = computed(() => {
        return isCarer.value ? "Checkliste für Pflegefachkräfte" : "Patientenfragebogen";
      });

      const downloadFileName = computed(() => {
        return isCarer.value ? "QrCodePflegeCheckliste.jpg" : "QrCodePatientenfragebogen";
      });

      const open = (carer) => {
        isCarer.value = carer;
        refDialog.value.open();
      };
      const close = () => refDialog.value.close();

      const qrCodeChanged = (dataUrl) => {
        qrCodeDataUrl.value = dataUrl;
      };

      const copyToClipboard = () => {
        try {
          navigator.clipboard.writeText(shareLink.value);
          copied.value = true;
          setTimeout(() => (copied.value = false), 1000);
        } catch (e) {}
      };

      return {
        /** ref */
        refDialog,

        /** const */
        qrCodeDataUrl,
        copied,
        isCarer,

        /** computed */
        shareLink,
        clientCompany,
        profileEditLink,
        dialogTitle,
        downloadFileName,

        /** function */
        open,
        close,
        qrCodeChanged,
        copyToClipboard,
      };
    },
  };
</script>
