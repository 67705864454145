<template>
  <div class="hidden print:block" :data-test="testId + '-print-recovery-code-content'">
    <p>Ihr Recovery-Code lautet:</p>

    <code class="recovery" :data-test="testId + '-print-recovery-code'">
      {{ recoveryCode }}
    </code>

    <p>
      Notieren Sie sich Ihr Passwort und hinterlegen Sie es zusammen mit diesem Recovery-Code an einem sicheren Ort. Bei
      Verlust Ihres Datenschutzpassworts sind die personenbezogenen Daten Ihrer Fälle irreversibel verloren. Auch
      Mitarbeiter von pharma4u (MediCheck) haben keine technische Möglichkeit, Ihre lokal verschlüsselten Daten zu lesen
      oder wiederherzustellen.
    </p>

    <p>
      Raum für Ihre Notizen zu Ihrem Passwort:
      <br />
      <br />
      __________________________________________________________________________
      <br />
      <br />
      __________________________________________________________________________
      <br />
      <br />
      __________________________________________________________________________
    </p>
  </div>
</template>

<script>
  export default {
    name: "LayoutPrintRecoveryCode",

    props: {
      recoveryCode: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="css" scoped>
  @media print {
    .recovery {
      display: block;
      max-width: 80%;
      font-size: 1.4em;
      word-break: break-all;
    }

    .crypt-dialog {
      width: 100%;
      height: 100%;
    }
  }
</style>
