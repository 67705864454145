import {isEmpty, isEqual} from "lodash";

export function complianceCheck(form, pageData, callback) {
  let result = true;

  if (hasDifferentDosage(form, pageData)) {
    result = false;
  }

  if (hasDifferentMeal(form, pageData)) {
    result = false;
  }

  if (hasDifferentIndications(form, pageData)) {
    result = false;
  }

  if (hasDifferentUsage(form, pageData)) {
    result = false;
  }

  if (hasSatisfied(form)) {
    result = false;
  }

  if (hasSideEffects(form)) {
    result = false;
  }

  if (typeof callback === "function") {
    callback(result);
  }
}

/**
 * @private
 * @param {Object} form
 * @param {Object} pageData
 * @return {boolean}
 */
function hasDifferentDosage(form, pageData) {
  if (form.deviantmedication === null) return false;

  const patientDosage = form.deviantmedication.dosage;
  const recordDosage = pageData.dosage;

  if (isDosageSet(patientDosage)) {
    return (
      recordDosage.morning !== patientDosage.morning ||
      recordDosage.noon !== patientDosage.noon ||
      recordDosage.evening !== patientDosage.evening ||
      recordDosage.night !== patientDosage.night
    );
  }
}

/**
 * @private
 * @param {Object} dosage
 * @return {boolean}
 */
function isDosageSet(dosage) {
  return (
    isDosageNotEmpty(dosage.morning) ||
    isDosageNotEmpty(dosage.noon) ||
    isDosageNotEmpty(dosage.evening) ||
    isDosageNotEmpty(dosage.night) ||
    dosage.dosagealternative !== null
  );
}

/**
 * @private
 * @param {number|string} dosageField
 * @return {boolean}
 */
function isDosageNotEmpty(dosageField) {
  return dosageField !== "" || dosageField !== 0;
}

/**
 * @private
 * @param {Object} form
 * @param {Object} pageData
 * @return {boolean}
 */
function hasDifferentMeal(form, pageData) {
  if (pageData.dosage.meal === "" || pageData.dosage.meal === null) {
    return false;
  }

  // Check if the main mail info is on independent
  if (pageData.dosage.meal === "A22") {
    return false;
  }

  return pageData.dosage.meal !== form.deviantmedication.dosage.meal;
}

/**
 * @private
 * @param {Object} form
 * @param {Object} pageData
 * @return {boolean}
 */
function hasDifferentIndications(form, pageData) {
  if (form.deviantmedication === null) return false;

  const patientIndications = form.deviantmedication.indications;
  const recordIndications = pageData.indications;

  if (isEmpty(patientIndications)) {
    return false;
  }

  return isEqual(recordIndications, patientIndications) === false;
}

/**
 * @private
 * @param {Object} form
 * @param {Object} pageData
 * @return {boolean}
 */
function hasDifferentUsage(form, pageData) {
  if (pageData.usage === "" || pageData.usage === null) {
    return false;
  }

  return parseInt(pageData.usage) !== parseInt(form.usage);
}

/**
 * @private
 * @param {Object} form
 * @return {boolean}
 */
function hasSatisfied(form) {
  return (
    form.additionalinformation.satisfaction === "uaw" ||
    form.additionalinformation.satisfaction === "effectiveness" ||
    form.additionalinformation.satisfaction === "handling"
  );
}

/**
 * @private
 * @param {Object} form
 * @return {boolean}
 */
function hasSideEffects(form) {
  return form.additionalinformation.sideeffects.length > 0;
}
