<template>
  <div :class="{'relative flex items-center justify-between w-[100px] z-0': !showEasyComOnlyButton}">
    <!-- background lines: start -->
    <div
      v-if="!showEasyComOnlyButton"
      class="absolute top-1/2 w-1/2 h-[3px]"
      :class="{
        'bg-gray-300': forceDisabled || is('default') || is('formIsEmpty') || is('formIsFilled'),
        'bg-mcred': !forceDisabled && (is('resultHasBeenCreated') || is('resultHasBeenCommunicated')),
      }"
    />

    <div
      v-if="!showEasyComOnlyButton"
      class="absolute right-0 top-1/2 w-1/2 h-[3px] z-0"
      :class="{
        'bg-gray-300':
          forceDisabled || is('default') || is('formIsEmpty') || is('formIsFilled') || is('resultHasBeenCreated'),
        'bg-mcred': !forceDisabled && (is('resultHasBeenCommunicated') || record?.closed),
      }"
    />
    <!-- background lines: end -->

    <!-- easy in button -->
    <component-tooltip v-if="!showEasyComOnlyButton" :tooltip="easyInTooltip" :force-hidden="forceDisabled">
      <component-icon-button
        icon="record_voice_over"
        class="z-10 relative text-base"
        :class="{
          'cursor-not-allowed': forceDisabled,
          'text-gray-500 bg-gray-200 border-gray-200 hover:bg-gray-300 hover:border-gray-300': is('default'),
          'p4umc-primary':
            (is('formIsEmpty') ||
              is('formIsFilled') ||
              is('resultHasBeenCreated') ||
              is('resultHasBeenCommunicated')) &&
            !forceDisabled,
        }"
        :disabled="forceDisabled || record.edit_url === null"
        :test-id="testId + '-record-progress-easy-in'"
        @click="easyIn"
      />
    </component-tooltip>

    <!-- easy out button -->
    <component-tooltip v-if="!showEasyComOnlyButton" :tooltip="easyOutTooltip" :force-hidden="forceDisabled">
      <component-icon-button
        class="z-10 relative text-base"
        :class="{
          'cursor-not-allowed': forceDisabled,
          'text-gray-500 bg-gray-200 border-gray-200 hover:bg-gray-300 hover:border-gray-300':
            is('default') || is('formIsEmpty') || is('formIsFilled'),
          'p4umc-primary': (is('resultHasBeenCreated') || is('resultHasBeenCommunicated')) && !forceDisabled,
        }"
        :disabled="forceDisabled || record.show_url === null"
        :test-id="testId + '-record-progress-easy-out'"
        @click="easyOut"
      >
        <svg class="fill-current size-4" viewBox="0 0 24 24">
          <path
            d="M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M11,18H13V15.87C14.73,15.43 16,13.86 16,12A4,4 0 0,0 12,8A4,4 0 0,0 8,12C8,13.86 9.27,15.43 11,15.87V18Z"
          />
        </svg>
      </component-icon-button>
    </component-tooltip>

    <!-- easy com button -->
    <component-tooltip :tooltip="easyComTooltip" :force-hidden="forceDisabled">
      <component-icon-button
        icon="sentiment_satisfied_alt"
        class="z-10 relative"
        :class="{
          'text-base': !showEasyComOnlyButton,
          'text-3xl': showEasyComOnlyButton,
          'cursor-not-allowed': forceDisabled,
          'text-gray-500 bg-gray-200 border-gray-200 hover:bg-gray-200 hover:border-gray-300':
            is('default') || is('formIsEmpty') || is('formIsFilled'),
          'border-2 border-mcred hover:border-mcred': is('resultHasBeenCommunicated'),
          'p4umc-primary': record?.closed && !forceDisabled,
        }"
        :disabled="forceDisabled || record.show_url === null"
        :test-id="testId + '-record-progress-easy-com'"
        @click="easyCom"
      />
    </component-tooltip>
  </div>

  <component-record-easy-com-dialog ref="refRecordEasyComDialog" :record="record" />

  <component-dialog
    ref="refHowToCaseCloseDialog"
    :dialog-title="
      record.has_pos_documents?.doctor_pdf || record.has_pos_documents?.patient_pdf
        ? ''
        : 'Easy com - Kommunikation und Dokumentation'
    "
    dialog-width="medium"
    dialog-type="information"
    :test-id="testId + '-record-progress-how-to-case-close'"
  >
    <template #content>
      <template v-if="record.has_pos_documents?.doctor_pdf || record.has_pos_documents?.patient_pdf">
        <h2 class="flex space-x-2 w-full justify-center text-mcgreen">
          <component-icon class="text-xl mt-1">check</component-icon>
          <span class="text-xl">Dokumente erfolgreich gespeichert</span>
        </h2>

        <p class="mt-2 text-center">Folgende PDF-Dokumente wurden bereits in der Warenwirtschaft gespeichert:</p>

        <ul class="mt-2 list-disc w-32 mx-auto">
          <li v-if="record.has_pos_documents?.doctor_pdf">Arzt-PDF</li>
          <li v-if="record.has_pos_documents?.patient_pdf">Patient-PDF</li>
          <li v-if="record.has_pos_documents?.full_pdf">Archiv-PDF</li>
        </ul>

        <hr class="w-full h-[1px] p-0 mt-4 bg-gray-500" />

        <p class="mt-4">Möchten Sie PDF-Dokumente in der Warenwirtschaft aktualisieren?</p>

        <p class="mt-4">
          Öffnen Sie das Analyseergebnis und klicken Sie unten auf „Dokumente gespeichert“, um diese erneut in der
          Warenwirtschaft zu speichern.
        </p>
      </template>

      <template v-else>
        <p>Möchten Sie PDF-Dokumente in der Warenwirtschaft speichern?</p>

        <p class="mt-4">
          Öffnen Sie das Analyseergebnis und klicken Sie unten auf „Weiter“, um Dokumente in der Warenwirtschaft zu
          speichern.
        </p>
      </template>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref} from "vue";
  import {router} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentRecordEasyComDialog from "@components/Dialogs/RecordEasyComDialog.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "ComponentRecordProgress",

    components: {
      ComponentDialog,
      ComponentIcon,
      ComponentIconButton,
      ComponentRecordEasyComDialog,
      ComponentTooltip,
    },

    props: {
      record: {
        type: Object,
        default: () => {},
      },
      forceDisabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Only show the single easy-com button
       */
      showEasyComOnlyButton: {
        type: Boolean,
        default: false,
      },
      /**
       * Clicks on easy-com button must be handled
       * differently when on result page
       */
      isOnResultPage: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["open:recordCaseCloseDialog"],

    setup(props, {emit}) {
      const refRecordEasyComDialog = ref(null);
      const refHowToCaseCloseDialog = ref(null);

      const easyInTooltip = computed(() => {
        return is("formIsEmpty") ? "„Easy in“ Eingabemaske; in Bearbeitung" : "„Easy in“ Eingabemaske; bearbeitet";
      });

      const easyOutTooltip = computed(() => {
        if (props.record.show_url === null) {
          return "„Easy out“; noch nicht analysiert";
        }

        if (is("resultHasBeenCreated")) {
          return "„Easy out“ Ausgabeergebnis; in Bearbeitung...";
        }
        if (is("resultHasBeenCommunicated")) {
          return "„Easy out“ Ausgabeergebnis; bearbeitet";
        }

        return "„Easy out“ Ausgabeergebnis";
      });

      const easyComTooltip = computed(() => {
        if (props.record.show_url === null) {
          return "„Easy com“; noch nicht analysiert";
        }

        if (is("resultHasBeenCommunicated") && props.record.status !== "completed") {
          return "„Easy com“ Kommunikation und Dokumentation; in Bearbeitung...";
        }
        if (props.record.status === "completed") {
          return "„Easy com“ Kommunikation und Dokumentation; bearbeitet";
        }
        return "„Easy com“ Kommunikation und Dokumentation";
      });

      function is(progress) {
        return props.record?.progress === progress;
      }

      function easyIn() {
        if (props.record.edit_url !== null) {
          router.visit(props.record.edit_url);
        }
      }

      function easyOut() {
        if (props.record.show_url !== null) {
          router.visit(props.record.show_url);
        }
      }

      function easyCom() {
        if (props.record.is_pos_record === false) {
          refRecordEasyComDialog.value.open(props.record);
        } else {
          // it's a b̶o̶y̶ pos record...

          if (props.showEasyComOnlyButton || props.isOnResultPage) {
            // when this RecordProgress button has been clicked at records.show page,
            // we emit an open event to open the real "RecordCaseCloseDialog"
            emit("open:recordCaseCloseDialog");
          } else {
            // otherwise this button has been clicked elsewhere... we just
            // show the information, that closing a pos case is only possible
            // when then records.show page is open
            refHowToCaseCloseDialog.value.open();
          }
        }
      }

      return {
        /** ref */
        refRecordEasyComDialog,
        refHowToCaseCloseDialog,

        /** computed */
        easyInTooltip,
        easyOutTooltip,
        easyComTooltip,

        /** function */
        is,
        easyIn,
        easyOut,
        easyCom,
      };
    },
  };
</script>
