<template>
  <div class="grid grid-cols-12 font-normal items-end text-sm gap-x-6 text-gray-700 pb-2">
    <div
      class="col-span-4 ml-2 flex items-center"
      :class="{'ml-5': isEditable, 'cursor-pointer': isEditable}"
      @click="sortByName"
    >
      <template v-if="isEditable">
        <template v-if="sortDirection === 'asc'">
          <component-icon>expand_more</component-icon>
        </template>

        <template v-else-if="sortDirection === 'desc'">
          <component-icon>expand_less</component-icon>
        </template>

        <template v-else>
          <component-icon>unfold_more</component-icon>
        </template>
      </template>

      Problem / Symptom
    </div>
    <div class="col-span-2">Seit (Datum; opt.)</div>
    <div class="col-span-2">Vermutung</div>
    <div class="col-span-3">Warum? / Was?</div>
    <div class="col-span-1" />
  </div>

  <ul ref="parent">
    <template v-for="symptom in symptoms" :key="symptom.row">
      <template v-if="isLoading">
        <symptom-skeleton-row />
      </template>

      <template v-else>
        <symptom-table-row :is-editable="isEditable" :symptom="symptom" :is-dragging="isDragging" />
      </template>
    </template>
  </ul>
</template>

<script>
  import {computed, ref, watch} from "vue";
  import {usePage, router} from "@inertiajs/vue3";
  import {useDragAndDrop} from "@formkit/drag-and-drop/vue";
  import {cloneDeep, isEqual} from "lodash";
  import {dropOrSwap, sort} from "@formkit/drag-and-drop";

  import {sortList} from "@utils/Helpers/ListSorter.js";

  import ComponentIcon from "@components/Icons/Icon.vue";

  import SymptomTableRow from "@pages/Records/Components/Sections/SymptomBar/Components/Row.vue";
  import SymptomSkeletonRow from "@pages/Records/Components/Sections/SymptomBar/Components/SkeletonRow.vue";

  export default {
    name: "SymptomTable",

    components: {ComponentIcon, SymptomSkeletonRow, SymptomTableRow},

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();
      const isDragging = ref(false);

      const config = {
        dragHandle: "[drag_handle]",
        synthDropZoneClass: "ring-2 ring-mcred-300",
        plugins: [
          dropOrSwap({
            shouldSwap: false,
          }),
        ],
        onDragstart: (data) => {
          isDragging.value = true;
        },
        onDragend: (data) => {
          isDragging.value = false;
        },
      };

      const [parent, symptoms] = useDragAndDrop(props.recordData.symptoms, config);

      const sortDirection = computed(() => {
        if (symptoms.value.length > 0) {
          const copyOfSymptoms = cloneDeep(symptoms.value);

          const symptomIds = copyOfSymptoms.map((symptom) => symptom.id);
          const ascSortedObject = sortList(copyOfSymptoms, "symptom");

          if (isEqual(symptomIds, ascSortedObject.sortedIds)) {
            return "asc";
          }

          if (isEqual(symptomIds, ascSortedObject.sortedIds.reverse())) {
            return "desc";
          }
        }

        return "";
      });

      const newSortDirection = computed(() => {
        if (sortDirection.value === "" || sortDirection.value === "desc") {
          return "asc";
        }

        if (sortDirection.value === "asc") {
          return "desc";
        }

        return "";
      });

      watch(
        () => props.recordData.symptoms,
        () => {
          symptoms.value = props.recordData.symptoms;
        },
        {deep: true},
      );

      watch(
        () => symptoms.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = oldValue.map((symptom) => symptom.id);
            const newSortOrder = value.map((symptom) => symptom.id);

            if (!isEqual(oldSortOrder, newSortOrder)) {
              updateOrder(newSortOrder);
            }
          }
        },
      );

      const updateOrder = (newSortOrder) => {
        router.post(
          route("sort.store", {
            patient: page.props.patient.id,
            record: page.props.record.id,
            relation: "symptoms",
          }),
          {
            ids: newSortOrder,
          },
          {
            preserveScroll: true,
            only: ["record", "flash"],
            onFinish: () => {
              isDragging.value = false;
            },
          },
        );
      };

      const sortByName = () => {
        if (!props.isEditable) return;

        const sortedObject = sortList(symptoms.value, "symptom", newSortDirection.value);

        symptoms.value = sortedObject.sortedArray;
        updateOrder(sortedObject.sortedIds);
      };

      return {
        /** ref */
        parent,

        /** const */
        symptoms,
        isDragging,

        /** computed */
        sortDirection,

        /** function */
        sortByName,
      };
    },
    methods: {sort},
  };
</script>
