<template>
  <div ref="dropdown" class="relative" :data-test="testId + '-dropdown'">
    <div
      class="cursor-pointer headline-container"
      :class="{'text-gray-900': isOpen}"
      :data-test="testId + '-dropdown-trigger'"
      @click="toggleDropdown"
    >
      <slot name="headline" />
    </div>

    <div
      v-if="isOpen"
      :class="alignment === 'left' ? 'left-0' : 'right-0'"
      class="absolute flex-col space-y-2 mt-2 p-4 w-screen max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      :data-test="testId + '-dropdown-options'"
      @click="toggleDropdown"
    >
      <slot name="options" />
    </div>
  </div>
</template>

<script>
  import {onMounted, onUnmounted, ref} from "vue";

  export default {
    name: "AuthHeaderDropdown",

    props: {
      alignment: {
        type: String,
        default: "left",
        validator: (value) => ["left", "right"].includes(value),
      },
      label: {
        type: String,
        default: null,
      },
      icon: {
        type: String,
        default: null,
      },
      href: {
        type: String,
        default: null,
      },
    },

    setup() {
      const isOpen = ref(false);
      const dropdown = ref(null);

      onMounted(() => {
        window.addEventListener("click", closeDropdownOnOutsideClick);
      });

      onUnmounted(() => {
        window.addEventListener("click", closeDropdownOnOutsideClick);
      });

      const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
      };

      const handleDropdownClick = (event) => {
        event.stopPropagation();
      };

      const closeDropdownOnOutsideClick = (event) => {
        if (isOpen.value && dropdown.value && !dropdown.value.contains(event.target)) isOpen.value = false;
      };

      return {isOpen, dropdown, toggleDropdown, handleDropdownClick};
    },
  };
</script>
