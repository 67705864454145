<template>
  <component-dialog ref="refDiseaseConfirmDialog" dialog-title="Erkrankungen korrekt?" :is-loading="isLoading">
    <template #content>
      Eingetragene Erkrankungen werden im Analysevorgang berücksichtigt
      <br />
      <br />
      (z. B. ob Probleme/Symptome des Patienten auch von der Erkrankung kommen können, ob die Therapie in Bezug auf die
      Indikationen leitlinienkonform ist bzw. ob gemäß Leitlinie ein Overuse oder Underuse vorliegt).

      <div class="mt-5">
        <component-checkbox v-model="dontShowAgain">Nicht mehr anzeigen</component-checkbox>
      </div>
    </template>

    <template #actions>
      <component-button class="p4umc-primary" label="ok" @click="updateDiseaseWarning" />
      <component-button label="Abbrechen" @click="close" />
    </template>
  </component-dialog>
</template>

<script>
  import {ref} from "vue";
  import {router} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "DiseasesConfirmDialog",

    components: {ComponentButton, ComponentCheckbox, ComponentDialog},

    props: {
      currentUserId: {
        type: String,
        required: true,
      },
    },
    emits: ["confirmed"],

    setup(props, {emit}) {
      const refDiseaseConfirmDialog = ref(null);

      const isLoading = ref(false);
      const dontShowAgain = ref(false);

      const open = () => {
        refDiseaseConfirmDialog.value.open();
      };

      const close = () => {
        if (refDiseaseConfirmDialog.value) refDiseaseConfirmDialog.value.close();
      };

      const updateDiseaseWarning = () => {
        if (dontShowAgain.value) {
          isLoading.value = true;

          router.post(
            route("settings.store", {user: props.currentUserId}),
            {
              diseaseWarning: !dontShowAgain.value,
            },
            {
              preserveScroll: true,
              onFinish: () => {
                isLoading.value = false;
                close();
                emit("confirmed");
              },
            },
          );
        } else {
          close();
          emit("confirmed");
        }
      };

      return {refDiseaseConfirmDialog, isLoading, dontShowAgain, open, close, updateDiseaseWarning};
    },
  };
</script>
