<template>
  <div>
    <div class="overflow-hidden rounded-md border border-gray-300 bg-white">
      <ul role="list" class="divide-y divide-gray-300">
        <slot />
      </ul>
    </div>
  </div>
</template>

<script>
  import {onBeforeMount, onBeforeUpdate, onMounted, onUpdated, provide, reactive} from "vue";

  export default {
    name: "ComponentAccordion",

    setup(props, {slots}) {
      const state = reactive({
        items: [],
        selectedIndex: null,
      });

      const setItems = () => {
        if (slots.default) {
          const tabs = [];

          slots.default().forEach((slotItem) => {
            if (slotItem.type.name === "ComponentAccordionItem") {
              tabs.push(slotItem);
            } else if (slotItem.children?.length > 0) {
              slotItem.children.forEach((child) => {
                if (child.type.name === "ComponentAccordionItem") {
                  tabs.push(child);
                }
              });
            }
          });

          state.items = tabs;
        }
      };

      const selectItem = (index) => {
        state.selectedIndex = index;
      };

      onBeforeMount(() => setItems());
      onMounted(() => {
        const initOpenedIndex = state.items.findIndex((item) => Object.keys(item.props).includes("init-opened"));
        selectItem(initOpenedIndex >= 0 ? initOpenedIndex : null);
      });
      onBeforeUpdate(() => setItems());
      onUpdated(() => selectItem(state.selectedIndex ?? null));

      provide("accordionProvider", state);
    },
  };
</script>

<style scoped></style>
