<template>
  <div v-if="patient.patient_attributes.length" class="py-2 sm:grid sm:grid-cols-4 sm:gap-4">
    <dt class="font-semibold text-gray-900">Individuelle Besonderheiten</dt>

    <dd class="mt-1 text-gray-700 sm:col-span-3 sm:mt-0">
      <ul>
        <template v-for="attribute in patient.patient_attributes" :key="attribute.id">
          <template v-if="attribute.type !== 'patientAddress'">
            <li>
              <component
                :is="getComponentName(attribute.type)"
                :attribute="attribute"
                :patient="patient"
                :inline="true"
              />
            </li>
          </template>
        </template>
      </ul>
    </dd>
  </div>
</template>

<script>
  import ComponentPatientSidebarDegreeOfCareAttribute from "@components/Sidebars/PatientSidebar/DegreeOfCareAttribute.vue";
  import ComponentPatientSidebarMobilityAttribute from "@components/Sidebars/PatientSidebar/MobilityAttribute.vue";
  import ComponentPatientSidebarNursingHomeAttribute from "@components/Sidebars/PatientSidebar/NursingHomeAttribute.vue";
  import ComponentPatientSidebarPatientAddressAttribute from "@components/Sidebars/PatientSidebar/PatientAddressAttribute.vue";
  import ComponentPatientSidebarTubePatientAttribute from "@components/Sidebars/PatientSidebar/TubePatientAttribute.vue";
  import ComponentPatientSidebarSimpleAttribute from "@components/Sidebars/PatientSidebar/SimpleAttribute.vue";

  export default {
    name: "PagesRecordResultPatientDataPatientAttributes",

    components: {
      ComponentPatientSidebarDegreeOfCareAttribute,
      ComponentPatientSidebarMobilityAttribute,
      ComponentPatientSidebarNursingHomeAttribute,
      ComponentPatientSidebarPatientAddressAttribute,
      ComponentPatientSidebarTubePatientAttribute,
      ComponentPatientSidebarSimpleAttribute,
    },
    props: {
      patient: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      function getComponentName(type) {
        switch (type) {
          case "degreeOfCare":
            return "component-patient-sidebar-degree-of-care-attribute";
          case "mobility":
            return "component-patient-sidebar-mobility-attribute";
          case "nursingHome":
            return "component-patient-sidebar-nursing-home-attribute";
          case "patientAddress":
            return props.patient.is_fake ? false : "component-patient-sidebar-patient-address-attribute";
          case "tubePatient":
            return "component-patient-sidebar-tube-patient-attribute";
          case "insulinPump":
          case "palliativePatient":
          case "port":
          case "stoma":
            return "component-patient-sidebar-simple-attribute";
        }
      }

      return {
        getComponentName,
      };
    },
  };
</script>
