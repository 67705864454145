export default {
  /** @return {Promise<WindowProxy>} */
  open(url, target = null, features = null) {
    return new Promise((resolve, reject) => {
      try {
        const myWindow = window.open(url, target, features);

        if (!myWindow || myWindow.toString() !== "[object Window]") {
          throw new Error();
        }

        resolve(myWindow);
      } catch (e) {
        reject();
      }
    });
  },
};
