<template>
  <div class="inline-block">
    <component-tooltip
      v-if="guidelines.length"
      tooltip="Diese Erkrankung wurde automatisiert auf leitlinienkonforme Therapie geprüft"
    >
      <button
        class="rounded-full bg-gray-200 hover:bg-gray-300 w-7 h-7 text-sm font-semibold"
        @click="refDialog.open()"
      >
        LL
      </button>
    </component-tooltip>
    <component-dialog ref="refDialog" dialog-width="medium" dialog-title="Leitlinien" dialog-type="information">
      <template #content>
        Diese Erkrankung wurde automatisiert auf leitlinienkonforme Therapie geprüft.

        <ul class="list-disc ml-4 mt-2 space-y-4">
          <li v-for="(guideline, index) in guidelines" :key="index" class="group leading-tight">
            <a :href="guideline.url" target="_blank" class="group-hover:underline">{{ guideline.name }}</a>
            <div class="truncate">
              <a class="text-xs text-gray-600 group-hover:underline" :href="guideline.url">{{ guideline.url }}</a>
            </div>
          </li>
        </ul>
      </template>
    </component-dialog>
  </div>
</template>
<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {ref} from "vue";

  export default {
    name: "ComponentGuidelineButton",
    components: {ComponentDialog, ComponentTooltip},
    props: {
      guidelines: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    setup() {
      const refDialog = ref(null);

      return {refDialog};
    },
  };
</script>
