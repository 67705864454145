import colors from "tailwindcss/colors";
import forms from "@tailwindcss/forms";

export const mcConfigDeepMerge = (target, source) => {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mcConfigDeepMerge(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
};

export default {
  content: [
    './vendor/medicheck/medicheckbladecomponents/resources/views/**/*.blade.php',
    './vendor/medicheck/medicheckbladecomponents/src/View/**/*.php',
    './vendor/medicheck/medicheckconfiguration/resources/views/**/*.blade.php',
    "./resources/**/*.blade.php",
    "./resources/**/*.js",
    "./resources/**/*.vue"
  ],
  darkMode: 'class',
  theme: {
    colors: {
      /* basics */
      transparent: "transparent",
      current: "currentColor",
      black: colors.black,
      white: colors.white,
      gray: colors.neutral,
      red: colors.red,
      green: colors.green,
      blue: colors.sky,
      lime: colors.lime,
      orange: colors.orange,
      yellow: colors.yellow,

      /* medicheck colors */
      risk: {
        absolute: "#AA0000",
        "very-high": "#E10000",
        high: "#FFA500",
        medium: "#FFE000",
        low: "#AAAAAA",
      },

      /* abdata colors */
      abdata: {
        darkred: "#C80000",
        red: "#FF0000",
        orange: "#FF8800",
        yellow: "#FADC0A",
        brown: "#6C3B2A",
        blue: "#0000FF",
        gray: "#888888",
      },

      /* mcred */
      mcred: {
        50: "#fbf4f4",
        100: "#fadede", // === mcredhoverlight
        200: "#edc6c6",
        300: "#e2a4a4",
        400: "#cd6060",
        450: "#c62828", // === mcredhover
        500: "#b71c1c", // === mcred
        DEFAULT: "#b71c1c", // so you can still use "mcred"
        600: "#a51919",
        700: "#891515",
        800: "#6e1111",
        900: "#5a0e0e",
      },

      /* mcgreen */
      mcgreen: {
        50: "#f7f9f5",
        100: "#deebd3", // === mcgreenhoverlight
        200: "#d5e2cb",
        300: "#bbd1ac",
        400: "#88ae6d",
        450: "#689f38", // === mcgreenhover
        500: "#558b2f", // === mcgreen
        DEFAULT: "#558b2f", // so you can still use "mcgreen"
        600: "#4d7d2a",
        700: "#406823",
        800: "#33531c",
        900: "#2a4417",
      },

      /* mcblue */
      mcblue: {
        50: "#f3f5f7",
        100: "#d0e0f2", // === mcbluehoverlight
        200: "#c4ccd5",
        300: "#a1adbb",
        400: "#5b7089",
        450: "#16375f", // === mcbluehover
        500: "#143256", // === mcblue
        DEFAULT: "#143256", // === so you can still use "mcblue"
        600: "#122d4d",
        700: "#0f2641",
        800: "#0c1e34",
        900: "#0a192a",
      },
    },
    extend: {
      animation: {
        "pulse-slow": "pulse 1s cubic-bezier(0.2, 0.9, 0.6, 2) infinite",
      },
      minWidth: {
        24: "6rem" /* 96px */,
        36: "9rem" /* 144px */,
        40: "10rem" /* 160px */,
        48: "12rem" /* 192px */,
        52: "13rem" /* 208px */,
        128: "32rem" /* 512px */,
      },
      width: {
        164: "41rem" /* 656px, nearly the old "medium" dialog */,
        200: "50rem" /* 800px, nearly like the "large" dialog */,
        256: "64rem" /* 1024px, new large dialog */,
      },
      borderWidth: {
        1: "1px",
      },
    },
    safelist: [
      "space-y-3",
      "text-xl",
      "text-lg",
      "font-bold",
      "mt-6",
      "text-red-600",
      "hover:underline",
      "decoration-2",
      "decoration-mcred",
      "decoration-orange-500",
      "decoration-yellow-500",
      "decoration-gray-500",
      "decoration-mcgreen",
    ],
  },
  plugins: [
    forms({
      strategy: "base", // only generate global styles
    }),
    // custom plugin "list-dash" class
    function({ addUtilities }) {
      const newUtilities = {
        'ul.list-dash': {
          listStyleType: 'none',
          'li::before': {
            content: "'\u2011\u00A0'", // "- "
            lineHeight: '1.3em',
          },
        },
      }
      addUtilities(newUtilities, ['responsive'])
    },
  ],
};
