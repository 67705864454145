export default {
  id: null,
  compliance: true,
  decreed: null,
  discontinued: false,
  discontinued_at: null,
  discontinued_by: null,
  requirements: null,
  satisfaction: null,
  satisfactioncomment: null,
  sideeffects: [],
  since: null,
  source: [],
  to: null,
};
