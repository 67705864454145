<template>
  <div>
    <div
      class="accordion-header bg-[linear-gradient(0deg,#fff,#f0f0f0)] flex w-full py-3 text-base text-gray-800 text-left bg-white border-gray-200 cursor-pointer"
      :class="{'border-b': !showDosageData}"
      @click.prevent="showDosageData = !showDosageData"
    >
      <div class="flex items-center font-medium">
        <component-icon v-if="showDosageData" class="text-gray-900 text-xl">arrow_drop_up</component-icon>
        <component-icon v-else class="text-gray-900 text-xl">arrow_drop_down</component-icon>

        Dosierungsangaben
      </div>
    </div>

    <div v-show="showDosageData" class="grid grid-cols-12 gap-x-2 gap-y-4">
      <!-- alternative dosage -->
      <div class="col-start-1 col-span-12 md:col-span-3 lg:col-span-2 h-9 flex items-center">
        <component-tooltip placement="top" tooltip="Bitte zuerst eine Einheit auswählen" :force-hidden="hasUnit">
          <component-checkbox v-model="isAlternativeDosage" :disabled="!isEditable || !hasUnit" color="accent">
            Sonderdosierung
          </component-checkbox>
        </component-tooltip>
      </div>

      <div class="-mt-4 md:mt-0 col-span-12 md:col-span-9 lg:col-span-10">
        <medication-component-detail-dialog-alternative-dosage-data
          v-show="isAlternativeDosage"
          ref="refAlternativeDosage"
          class="ml-7"
          :medication-form="medicationForm.value"
          :is-editable="isEditable"
          @is-invalid="handleInvalidAlternativeDosageEvent"
          @is-valid="handleValidAlternativeDosageEvent"
          @alternative-dosage-ids-changed="handleAlternativeDosageIdsChanged"
          @change="handleAlternativeDosageTypeChange"
          @init-type="handleAlternativeDosageTypeChange"
          @dosage-change="handleDosageChange"
        />
      </div>

      <!-- discontinued -->
      <div class="col-start-1 col-span-12 md:col-span-3 lg:col-span-2 h-9 flex space-x-1 items-center">
        <component-checkbox v-model="discontinued" :disabled="!isEditable" color="accent" @change="handleDiscontinued">
          abgesetzt
        </component-checkbox>

        <component-info-icon placement="right-start">
          <template #content>
            <h1 class="font-bold">Abgesetzt durch...</h1>

            <h1 class="mt-2 font-bold">„Arzt“</h1>
            <ul class="ml-4 list-disc">
              <li class="ml-4">
                FAM wird
                <strong>nicht</strong>
                in Analyse berücksichtigt
              </li>
              <li class="ml-4">
                erscheint
                <strong>nicht</strong>
                im BMP, da durch Arzt abgesetzt
              </li>
            </ul>

            <h1 class="mt-2 font-bold">
              „Arzt; Patient weicht ab“
              <span class="font-normal">(unter Patientenangaben bitte eintragen)</span>
            </h1>
            <ul class="ml-4 list-disc">
              <li class="ml-4">FAM wird in Analyse berücksichtigt</li>
              <li class="ml-4">erscheint als „abgesetzt, Patient weicht ab“ in Medikationsübersicht</li>
              <li class="ml-4">
                erscheint
                <strong>nicht</strong>
                im BMP, da durch Arzt abgesetzt
              </li>
            </ul>

            <h1 class="mt-2 font-bold">„Patient“</h1>
            <ul class="ml-4 list-disc">
              <li class="ml-4">FAM wird in Analyse berücksichtigt</li>
              <li class="ml-4">erscheint als „abgesetzt durch Patient“ in Medikationsübersicht</li>
              <li class="ml-4">erscheint im BMP, da ärztlich verordnet</li>
            </ul>

            <h1 class="mt-2 font-bold">
              keine Angabe:
              <span class="font-normal">siehe „Patient“</span>
            </h1>
          </template>
        </component-info-icon>
      </div>

      <div
        v-if="discontinued"
        class="-mt-4 md:mt-0 col-span-12 md:col-span-9 lg:col-span-10 flex text-sm items-baseline space-x-2"
      >
        <div>durch</div>

        <div class="w-48">
          <component-select
            id="discontinuedBy"
            ref="requirements"
            name="discontinuedBy"
            :model-value="additionalInformationForm.discontinued_by"
            :disabled="disableDiscontinuedFields || !isEditable"
            :nullable="true"
            placeholder-text=""
            @change="handleDiscontinuedBy"
          >
            <option value="doctor">Arzt</option>
            <option value="doctor-patient-deviates">Arzt; Patient weicht ab</option>
            <option value="patient">Patient</option>
          </component-select>
        </div>

        <div>am</div>

        <div class="flex">
          <component-input
            v-model="additionalInformationForm.discontinued_at"
            :disabled="!isEditable"
            type="date"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
  import {cloneDeep} from "lodash";

  import additionalInformationModel from "@models/additionalInformationModel.js";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import {checkMedicationDosageDetailDialog} from "@pages/Records/Components/Sections/MedicationBar/Utils/medicationConsistencyCheck.js";
  import MedicationComponentDetailDialogAlternativeDosageData from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/Components/AlternativeDosageData.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  export default {
    name: "MedicationComponentDetailDialogDosageData",

    components: {
      ComponentInfoIcon,
      ComponentTooltip,
      ComponentCheckbox,
      ComponentIcon,
      ComponentInput,
      ComponentSelect,
      MedicationComponentDetailDialogAlternativeDosageData,
    },

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      medicationForm: {
        type: Object,
        required: true,
      },
    },

    emits: ["changeForm", "valid", "invalid"],

    setup(props, {emit}) {
      const alternativeDosageId = "alternativeDosageValidatorId";

      const refAlternativeDosage = ref(null);

      const showDosageData = ref(true);
      const discontinued = ref(false);
      const isAlternativeDosage = ref(false);
      const errors = ref([]);
      const invalidDosages = ref([]);

      const additionalInformationForm = ref(null);

      const disableDiscontinuedFields = computed(() => !discontinued.value);

      const hasUnit = computed(() => !!props.medicationForm.value.dosage?.selected_unit);

      watch(
        () => discontinued.value,
        (newValue) => {
          additionalInformationForm.value.discontinued = newValue;

          if (newValue === false) {
            additionalInformationForm.value.discontinued_by = null;
            additionalInformationForm.value.discontinued_at = null;
          }

          emit("changeForm", {key: "additionalinformation", newValue: additionalInformationForm});
        },
      );

      watch(
        () => isAlternativeDosage.value,
        (newValue) => {
          if (newValue === false) {
            emit("valid", alternativeDosageId);
            refAlternativeDosage.value.restoreDefaultDosage();
          } else {
            if (refAlternativeDosage.value) {
              refAlternativeDosage.value.clearDefaultDosage();
            }
          }
        },
      );

      onBeforeMount(() => {
        additionalInformationForm.value = cloneDeep(additionalInformationModel);

        Object.assign(additionalInformationForm.value, props.medicationForm.value.additionalinformation);

        if (
          props.medicationForm.value.dosage &&
          props.medicationForm.value.dosage.dosagealternative !== null &&
          props.medicationForm.value.dosage.dosagealternative.id !== null
        ) {
          isAlternativeDosage.value = true;
        }

        discontinued.value = props.medicationForm.value.additionalinformation?.discontinued ?? false;

        showDosageData.value = checkMedicationDosageDetailDialog(props.medicationForm.value);
      });

      onBeforeUnmount(() => {
        clearErrors();
      });

      const handleDiscontinued = (value) => {
        additionalInformationForm.value.discontinued = value;

        // set compliance to false whenever the discontinued checkbox is
        // activated because the default discontinued_by (empty selection)
        // should trigger the non-compliance check
        if (additionalInformationForm.value.discontinued_by !== "doctor") {
          additionalInformationForm.value.compliance = false;
        }

        emit("changeForm", {key: "additionalinformation", newValue: additionalInformationForm.value});
      };

      const handleDiscontinuedBy = (value) => {
        additionalInformationForm.value.discontinued_by = value;

        if (value !== "doctor" && value !== null) {
          additionalInformationForm.value.compliance = false;
        } else {
          // when discontinued_by "doctor", the compliance is set
          // to true again. note: this might cause problems if
          // other attributes of this medication have triggered
          // a non-compliance as well; those triggers would be
          // reversed when selecting discontinued by doctor afterwards)
          additionalInformationForm.value.compliance = true;
        }

        emit("changeForm", {key: "additionalinformation", newValue: additionalInformationForm.value});
      };

      const handleDiscontinuedAt = (value) => {
        additionalInformationForm.value.discontinued_at = value;

        emit("changeForm", {key: "additionalinformation", newValue: additionalInformationForm.value});
      };

      const checkErrors = () => {
        if (invalidDosages.value.length > 0 || errors.value.length > 0) {
          emit("invalid", alternativeDosageId);
        } else {
          emit("valid", alternativeDosageId);
        }
      };

      const handleAlternativeDosageIdsChanged = () => {
        invalidDosages.value = [];
        checkErrors();
      };

      const handleValidAlternativeDosageEvent = (dosageId) => {
        emit("valid", dosageId);
        checkErrors();
      };

      const handleInvalidAlternativeDosageEvent = (dosageId) => {
        emit("invalid", dosageId);
        checkErrors();
      };

      const handleAlternativeDosageTypeChange = (newType) => {
        const errorId = "alternativeDosageInvalidType";

        if (newType === null || newType === "") {
          if (errors.value.indexOf(errorId) === -1) {
            errors.value.push(errorId);
          }
        } else {
          errors.value = errors.value.filter((id) => {
            return id !== errorId;
          });
        }

        checkErrors();
      };

      const clearErrors = () => {
        invalidDosages.value = [];
        errors.value = [];
        checkErrors();
      };

      const handleDosageChange = (newDosage) => {
        emit("changeForm", {key: "dosage", newValue: newDosage});
      };

      return {
        refAlternativeDosage,
        showDosageData,
        discontinued,
        isAlternativeDosage,
        additionalInformationForm,
        disableDiscontinuedFields,
        hasUnit,
        handleDiscontinued,
        handleDiscontinuedBy,
        handleDiscontinuedAt,
        handleAlternativeDosageIdsChanged,
        handleValidAlternativeDosageEvent,
        handleInvalidAlternativeDosageEvent,
        handleAlternativeDosageTypeChange,
        handleDosageChange,
      };
    },
  };
</script>
