<template>
  <div class="flex items-center">
    <button
      type="button"
      :class="{'bg-mcred': !currentValue, 'bg-mcgreen': currentValue}"
      role="switch"
      aria-checked="false"
      aria-labelledby="annual-billing-label"
      :disabled="disabled"
      @click="handleChange"
    >
      <span
        aria-hidden="true"
        class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        :class="{'translate-x-5': !currentValue, 'translate-x-0': currentValue}"
      />
    </button>

    <span class="ml-2" :class="{'text-gray-500': disabled}">{{ label }}</span>
  </div>
</template>

<script>
  import {onMounted, ref, watch} from "vue";

  export default {
    name: "ComponentSwitch",

    props: {
      label: {
        type: String,
        default: "",
      },
      modelValue: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["input", "changed", "update:modelValue"],

    setup(props, {emit}) {
      const currentValue = ref(null);

      watch(
        () => props.modelValue,
        (newValue) => {
          currentValue.value = newValue;
        },
      );

      onMounted(() => {
        currentValue.value = props.modelValue;
      });

      const handleChange = () => {
        currentValue.value = !currentValue.value;

        emit("update:modelValue", currentValue.value);
        emit("input", currentValue.value);
        emit("changed", {label: props.label, value: currentValue.value});
      };

      return {currentValue, handleChange};
    },
  };
</script>
