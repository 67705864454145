<template>
  <patientinput-component-autocomplete
    placeholder="Bitte Symptom eingeben"
    :fetch-method="doSearch"
    :test-id="testId + '-additional-symptoms'"
    @selected="symptomSelected"
  >
    <template #autocomplete-item="{item}">
      <div class="min-h-7 flex flex-col justify-center">
        <span>{{ item.Name }}</span>

        <div v-if="form.symptoms.mivs.some((miv) => miv === item.Key_MIV)" class="text-sm text-mcred-500">
          Problem/Symptom wurde bereits hinterlegt
        </div>
      </div>
    </template>

    <template #after-search>
      <div v-if="lastImportedSymptom && symptomImportedTo" class="mt-3 text-sm text-mcgreen">
        „{{ lastImportedSymptom.Name }}“ wurde erfolgreich unter „{{ symptomImportedTo }}“ gespeichert
      </div>
    </template>
  </patientinput-component-autocomplete>

  <component-accordion v-if="form.symptoms.list.length > 0">
    <component-accordion-item title="Weitere Probleme/Symptome" :test-id="testId + '-additional-symptoms'">
      <template #title>
        <sup>
          <component-category-badge
            color="info"
            :label="form.symptoms.list.length.toString()"
            :test-id="testId + '-additional-symptoms'"
          />
        </sup>
      </template>

      <div class="flex flex-col gap-3">
        <div v-for="(symptom, index) in form.symptoms.list" :key="index" class="flex justify-between items-center">
          <patientinput-non-unselectable-checkbox
            color="primary"
            large
            :test-id="testId + '-additional-symptoms-delete'"
            @click="confirmDelete(symptom)"
          >
            <span>{{ symptom.Name }}</span>
          </patientinput-non-unselectable-checkbox>

          <div>
            <component-icon-button
              icon="delete"
              class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
              :test-id="testId + '-additional-symptoms-confirm-delete'"
              @click="confirmDelete(symptom)"
            />
          </div>
        </div>
      </div>
    </component-accordion-item>
  </component-accordion>

  <component-dialog
    ref="refConfirmDeleteDialog"
    dialog-title="Symptom löschen?"
    primary-label="Ja, Löschen"
    :test-id="testId + '-additional-symptoms-confirm-delete'"
    @primary-button-clicked="deleteConfirmed"
  >
    <template #content>Sind Sie sicher, dass Sie „{{ symptomToDelete.Name }}“ löschen möchten?</template>
  </component-dialog>
</template>

<script>
  import {ref} from "vue";

  import {coreRouter} from "@utils/coreRouter/coreRouter.js";
  import {symptoms} from "@pages/Patientinput/Enums.js";

  import PatientinputComponentAutocomplete from "@pages/Patientinput/Components/Autocomplete.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentAccordion from "@components/Accordion/Accordion.vue";
  import ComponentAccordionItem from "@components/Accordion/AccordionItem.vue";
  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import PatientinputNonUnselectableCheckbox from "@pages/Patientinput/Components/NonUnselectableCheckbox.vue";

  export default {
    name: "PatientinputAdditionalSymptoms",

    components: {
      PatientinputNonUnselectableCheckbox,
      ComponentCategoryBadge,
      ComponentAccordionItem,
      ComponentAccordion,
      ComponentDialog,
      ComponentIconButton,
      PatientinputComponentAutocomplete,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const refConfirmDeleteDialog = ref(null);

      const lastImportedSymptom = ref(null);
      const symptomImportedTo = ref(null);
      const symptomToDelete = ref(null);

      const fixedSymptomMivs = symptoms.map((group) => group.items.map((item) => item.miv)).flat(2);

      const doSearch = (searchTerm) => {
        symptomImportedTo.value = null;
        lastImportedSymptom.value = null;

        return new Promise((resolve) => {
          coreRouter.searchSymptomsForPatientinput(searchTerm, props.form.masterdata.patient.gender, {
            onSuccess: (response) => {
              resolve(response.data);
            },
            onError: () => {
              resolve(false);
            },
          });
        });
      };

      const symptomSelected = (symptom) => {
        if (!props.form.symptoms.mivs.includes(symptom.Key_MIV)) {
          props.form.symptoms.mivs.push(symptom.Key_MIV);

          lastImportedSymptom.value = symptom;

          if (fixedSymptomMivs.includes(symptom.Key_MIV)) {
            symptomImportedTo.value = symptoms.find((group) =>
              group.items.some(
                (item) =>
                  symptom.Key_MIV === item.miv || (Array.isArray(item.miv) && item.miv.includes(symptom.Key_MIV)),
              ),
            ).title;
          } else {
            props.form.symptoms.list.push(symptom);
            symptomImportedTo.value = "Hinzugefügt";
          }

          setTimeout(() => {
            symptomImportedTo.value = null;
            lastImportedSymptom.value = null;
          }, 5000);
        }
      };

      const confirmDelete = (disease) => {
        symptomToDelete.value = disease;
        refConfirmDeleteDialog.value.open();
      };

      const deleteConfirmed = () => {
        props.form.symptoms.mivs = props.form.symptoms.mivs.filter((miv) => miv !== symptomToDelete.value.Key_MIV);
        props.form.symptoms.list = props.form.symptoms.list.filter(
          ({Key_MIV}) => Key_MIV !== symptomToDelete.value.Key_MIV,
        );
        symptomToDelete.value = null;
      };

      return {
        /** ref */
        refConfirmDeleteDialog,

        /** const */
        lastImportedSymptom,
        symptomImportedTo,
        symptomToDelete,

        /** function */
        doSearch,
        symptomSelected,
        confirmDelete,
        deleteConfirmed,
      };
    },
  };
</script>
