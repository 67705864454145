<template>
  <patientinput-component-step
    :title="form.meta.is_patient ? 'Los geht\'s' : 'Stammdaten'"
    :back-button="form.medication.bmp.length === 0"
    :forward-button-enabled="allFieldsValid"
    :test-id="testId + '-master-data'"
  >
    <template v-if="form.meta.is_carer">
      <patientinput-component-masterdata-carer-fields :form="form" :test-id="testId + '-master-data-step'" />
      <h2 class="font-bold text-xl">Angaben zum Patienten / zur Patientin</h2>
    </template>

    <component-input
      v-model="form.masterdata.patient.firstname"
      type="text"
      autocomplete="given-name"
      label="Vorname"
      large
      :test-id="testId + '-master-data-step-firstname'"
    />

    <component-input
      v-model="form.masterdata.patient.lastname"
      type="text"
      autocomplete="family-name"
      label="Nachname"
      large
      :test-id="testId + '-master-data-step-lastname'"
    />

    <component-input
      v-model="form.masterdata.patient.birthdate"
      type="date"
      autocomplete="bday"
      label="Geburtsdatum"
      :validation="birthdateValidationMessage"
      large
      :test-id="testId + '-master-data-step-birthdate'"
    />

    <component-select
      v-model="form.masterdata.patient.gender"
      label="Geschlecht"
      :options="patientGender"
      large
      :test-id="testId + '-master-data-step-gender'"
    />

    <component-input
      v-model="form.masterdata.patient.insurancenumber"
      type="text"
      autocomplete="false"
      label="Versichertennummer (optional)"
      large
      :test-id="testId + '-master-data-step-insurance-number'"
    />

    <component-checkbox
      v-if="form.meta.is_carer"
      v-model="form.circumstances.attributes['palliativePatient']"
      large
      color="primary"
      :test-id="testId + '-master-data-step-is-carer'"
    >
      Der Patient ist ein Palliativpatient
    </component-checkbox>
  </patientinput-component-step>
</template>

<script>
  import {computed, onMounted, ref, watch} from "vue";
  import {debounce} from "lodash";

  import {patientGender} from "@pages/Patients/Enums/Enums.js";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";

  import PatientinputComponentMasterdataCarerFields from "@pages/Patientinput/Components/MasterdataCarerFields.vue";
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepMasterdata",

    components: {
      ComponentCheckbox,
      ComponentInput,
      ComponentSelect,
      PatientinputComponentMasterdataCarerFields,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const validBirthdate = ref(false);
      const birthdateValidationMessage = ref(null);

      const allFieldsValid = computed(() => {
        return !!(
          props.form.masterdata.patient.firstname &&
          props.form.masterdata.patient.lastname &&
          validBirthdate.value &&
          props.form.masterdata.patient.gender
        );
      });

      watch(
        () => props.form.masterdata.patient.birthdate,
        debounce((newValue) => {
          validateBirthdate();
        }, 1000),
        {immediate: true},
      );

      onMounted(() => {
        validateBirthdate();
      });

      function validateBirthdate() {
        if (!props.form.masterdata.patient.birthdate) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = null; // not set should not show validation message
          return;
        }

        const value = props.form.masterdata.patient.birthdate;
        let year = null;
        let month = null;
        let day = null;

        if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
          // YYYY-MM-DD
          year = value.split("-")[0] ?? null;
          month = value.split("-")[1] ?? null;
          day = value.split("-")[2] ?? null;
        } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
          year = value.split(".")[2] ?? null;
          month = value.split(".")[1] ?? null;
          day = value.split(".")[0] ?? null;
        } else {
          // invalid like "11980-04-30"
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Bitte geben Sie ein sinnvolles Geburtsdatum ein";
          return;
        }

        // date is not set at all
        if (!year) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = null; // not set should not show validation message
          return;
        }

        // do not allow birthdate < 1900
        if (parseInt(year) < 1900) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Bitte geben Sie ein sinnvolles Geburtsdatum ein";
          return;
        }

        const inputDate = new Date(year, month - 1, day); // month is based on zero
        const now = new Date();
        inputDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        // do not allow future dates
        if (inputDate > now) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Das Geburtsdatum darf nicht in der Zukunft liegen";
          return;
        }

        validBirthdate.value = true;
        birthdateValidationMessage.value = null;
      }

      return {
        /** enum */
        patientGender,

        /** const */
        birthdateValidationMessage,

        /** computed */
        allFieldsValid,
      };
    },
  };
</script>
