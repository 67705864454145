<template>
  <component-dialog ref="dosageDialog">
    <template #title>
      <span v-if="type === 'info'">Hinweis zur Dosierungsangabe</span>
      <span v-else>Dosierungsangabe ungültig</span>
    </template>

    <template v-if="type === 'info'" #content>
      <p>Die Dosierung "{{ currentValue }}" kann auch als "{{ recommendedValue }}" eingegeben werden.</p>
    </template>

    <template v-else #content>
      <p>
        Die angegebene

        <span v-if="!dosageTime">Dosierung</span>
        <span v-else>{{ dosageTime }}-Dosierung</span>

        <span v-if="currentValue">("{{ currentValue }}")</span>
        widerspricht der Spezifikation für einen bundeseinheitlichen Medikationsplan.
      </p>

      <p v-if="errorCode === 'valueNotSet'">Es muss ein Wert gesetzt sein.</p>

      <p v-if="errorCode === 'fractionError'">Es dürfen nur die Brüche ½, ⅓, ¼, ⅔, ¾ und ⅛ eingegeben werden.</p>

      <p v-else-if="errorCode === 'valueToLong'">Die Dosierung darf maximal 4 Zeichen lang sein.</p>

      <p v-else-if="errorCode === 'invalidChars'">
        Es dürfen nur Zahlen, ein Komma oder ein Schrägstrich eingegeben werden.
      </p>

      <p v-if="recommendedValue">
        <strong>Stattdessen {{ recommendedValue }} übernehmen?</strong>
      </p>
    </template>

    <template #actions>
      <component-button
        v-if="recommendedValue"
        class="p4umc-primary"
        label="Übernehmen"
        @click="primaryButtonClicked" />

      <component-button v-if="recommendedValue" label="Weiter bearbeiten" @click="cancelButtonClicked" />

      <component-button
        v-if="!recommendedValue"
        class="p4umc-primary"
        label="Eingabe bearbeiten"
        @click="cancelButtonClicked" />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, onMounted, ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "MedicationComponentDosageDialog",

    components: {ComponentButton, ComponentDialog},

    setup() {
      const dosageDialog = ref(null);

      const id = ref(null);
      const type = ref(null);
      const errorCode = ref(null);
      const currentValue = ref(null);
      const recommendedValue = ref(null);
      const customMessage = ref(null);
      const callback = ref(null);

      const dosageTime = computed(() => {
        if (id.value.toString().toLowerCase().includes("morning")) {
          return "Morgen";
        } else if (id.value.toString().toLowerCase().includes("noon")) {
          return "Mittag";
        } else if (id.value.toString().toLowerCase().includes("evening")) {
          return "Abend";
        } else if (id.value.toString().toLowerCase().includes("night")) {
          return "Nacht";
        }

        return null;
      });

      onMounted(() => {});

      const open = () => {
        dosageDialog.value.open();
      };

      return {dosageDialog, type, errorCode, currentValue, recommendedValue, dosageTime, open};
    },
  };
</script>
