import {cloneDeep} from "lodash";
import dosageModel from "@models/dosageModel.js";

export default {
  id: null,
  dosage: cloneDeep(dosageModel),
  indication_unknown: 0,
  indications: [],
  usage: null,
};
