<template>
  <template v-if="isOpen">
    <section
      class="relative z-10 ease-in-out duration-500"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
      :data-open="isOpen ? 'true' : 'false'"
    >
      <div class="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
            :data-test="testId + '-notifications-slide-over'"
          >
            <div class="pointer-events-auto w-screen max-w-md">
              <div class="overscroll-contain flex h-full flex-col overflow-y-auto bg-white py-6 shadow-xl">
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h2 id="slide-over-title" class="text-2xl font-semibold leading-6 text-gray-900">
                      Benachrichtigungen
                    </h2>

                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        :data-test="testId + '-notifications-slide-over-close-button'"
                        @click="isOpen = false"
                      >
                        <span class="absolute -inset-2.5" />
                        <span class="sr-only">Close panel</span>
                        <component-icon class="text-2xl">close</component-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="relative w-full mt-6 flex-1">
                  <div v-if="isLoading" class="flex flex-col items-center">
                    <component-spinner class="size-12 mt-8" />
                  </div>

                  <ol
                    v-else-if="notifications.data.length"
                    role="list"
                    class="flex-1 divide-y divide-gray-300 overscroll-contain"
                  >
                    <li
                      v-for="(notification, index) in notifications.data"
                      :key="notification.id"
                      class="p-4 hover:bg-gray-100 space-x-2"
                    >
                      <div class="flex">
                        <component-tooltip tooltip="Als gelesen markieren und ausblenden">
                          <button
                            class="group h-8 w-8 rounded-full bg-gray-400 hover:bg-mcgreen-400 flex items-center justify-center"
                            :data-test="testId + '-notifications-slide-over-notification-delete-button-' + index"
                            @click="deleteNotification(notification.id)"
                          >
                            <component-icon class="group-hover:hidden text-white text-xl">
                              {{ notification.icon ?? "mail_outline" }}
                            </component-icon>

                            <component-icon class="hidden group-hover:block text-white text-xl">check</component-icon>
                          </button>
                        </component-tooltip>

                        <component
                          :is="notification.url ? 'inertia-link' : 'div'"
                          class="flex-1 text-gray-900 hover:no-underline ml-2"
                          :href="notification.url ?? false"
                          :data-test="testId + '-notifications-slide-over-notification-link-' + index"
                          @click="isOpen = !notification.url"
                        >
                          <div class="flex justify-between items-baseline space-x-4">
                            <div
                              v-if="notification.label"
                              class="text-base font-semibold"
                              :class="{'text-mcred hover:underline': notification.url}"
                            >
                              {{ notification.label }}
                            </div>

                            <div v-if="notification.created_at" class="text-right text-sm text-gray-500">
                              {{ notification.created_at }}
                            </div>
                          </div>

                          <div v-if="notification.text" class="text-sm">{{ notification.text }}</div>
                        </component>
                      </div>

                      <div
                        class="text-xs underline text-mcred-500 text-right mt-1 cursor-pointer"
                        :data-test="testId + '-notifications-slide-over-notification-delete-text-' + index"
                        @click="deleteNotification(notification.id)"
                      >
                        als gelesen markieren
                      </div>
                    </li>
                  </ol>

                  <component-empty-state v-else :test-id="testId + '-notifications-slide-over'">
                    Sie haben zur Zeit keine Benachrichtigungen.
                  </component-empty-state>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
</template>

<script>
  import {ref} from "vue";
  import {Link as InertiaLink, router, useForm} from "@inertiajs/vue3";

  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "AuthHeaderNotificationsSlideover",

    components: {ComponentTooltip, ComponentEmptyState, ComponentSpinner, ComponentIcon, InertiaLink},

    expose: ["open"],

    setup() {
      const isOpen = ref(false);
      const isLoading = ref(true);

      const notifications = ref([]);
      const form = useForm({});

      function open() {
        isLoading.value = true;

        router.reload({
          only: ["notifications"],
          onSuccess: (page) => {
            isLoading.value = false;
            notifications.value = page.props.notifications;
          },
        });

        isOpen.value = true;
      }

      function deleteNotification(id) {
        form.delete(route("notifications.destroy", {id: id}), {
          preserveScroll: true,
          onSuccess: () => {
            // just remove item from list; do not reload from server
            notifications.value.data = notifications.value.data.filter((notification) => notification.id !== id);
          },
        });
      }

      return {isOpen, isLoading, notifications, open, deleteNotification};
    },
  };
</script>
