<template>
  <component-dialog
    ref="refDialog"
    :dialog-title="dialogTitle"
    :dialog-width="dialogWidth"
    :test-id="testId + '-import'"
  >
    <template #content>
      <template v-if="status === 'loading'">
        <div class="h-20 flex justify-center items-center">
          <component-spinner class="w-10 h-10" :test-id="testId + '-import-dialog'" />
        </div>
      </template>

      <template v-else-if="status === 'hasExistingPatients'">
        <component-existing-patient-select
          v-slot="{hasExact}"
          :existing-patients="existingPatients"
          :current-patient="transfer.data.masterdata.patient"
          :test-id="testId + '-import-dialog'"
          @existing-patient-selected="selectExistingPatient"
        >
          <component-alert type="warning" :test-id="testId + '-import-dialog-existing-patient-select'">
            <template v-if="hasExact">
              Sie haben bereits einen Patient mit dem gleichen Namen. Möchten Sie die Daten hierhin importieren?
            </template>

            <template v-else>
              Sie haben bereits Patienten mit ähnlichen Stammdaten. Möchten Sie die Daten zu einem bestehenden Patienten
              importieren?
            </template>
          </component-alert>
        </component-existing-patient-select>
      </template>

      <template v-if="status === 'checkNursingHomeAttribute'">
        <component-alert type="warning" :test-id="testId + '-import-dialog-check-nursing-home-attribute'">
          Der zu importierende Patient ist ein Heim-Patient. Diese individuelle Besonderheit ist bereits beim Patienten
          hinterlegt. Bitte vergleichen Sie die Angaben und entscheiden Sie, ob Sie die bisherigen Daten beibehalten
          möchten oder nicht.
        </component-alert>

        <patientinput-client-component-import-dialog-nursing-home-comparison
          :existing-patient="selectedExistingPatient"
          :import-patient-nursing-home-data="nursingHomeData"
          :test-id="testId + '-import-dialog'"
          @perform-input="performImport"
        />
      </template>

      <template v-else-if="status === 'error'">
        <component-alert type="error" :test-id="testId + '-import-dialog-status-error'">
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie den Import erneut.
        </component-alert>
      </template>
    </template>

    <template #actions>
      <component-button
        v-if="status === 'hasExistingPatients'"
        class="p4umc-primary"
        label="Als neuen Patienten importieren"
        :test-id="testId + '-import-dialog-perform-import'"
        @click="performImport"
      />

      <component-button label="Abbrechen" :test-id="testId + '-import-dialog-close'" @click="close" />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, reactive, ref, toRefs} from "vue";
  import {router} from "@inertiajs/vue3";
  import {cloneDeep} from "lodash";

  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";
  import resetState from "@utils/Helpers/ResetState.js";
  import {pdfRouter} from "@utils/pdfRouter/pdfRouter.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentExistingPatientSelect from "@components/ExistingPatientSelect/ExistingPatientSelect.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  import {circumstancesCarer, wishesAims} from "@pages/Patientinput/Enums.js";

  import PatientinputClientComponentImportDialogNursingHomeComparison from "@pages/PatientinputClient/Components/ImportDialogNursingHomeComparison.vue";

  export default {
    name: "PatientinputClientComponentImportDialog",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentDialog,
      ComponentExistingPatientSelect,
      ComponentSpinner,
      PatientinputClientComponentImportDialogNursingHomeComparison,
    },

    setup() {
      const privacy = inject("$privacy");
      const axios = inject("$axios");

      const refDialog = ref(null);

      let abortController = null;

      const initialState = {
        status: "loading",
        transfer: null,
        existingPatients: [],
        selectedExistingPatient: null,
        nursingHomeData: null,
        dialogWidth: "small",
      };

      const state = reactive({...initialState});

      const dialogTitle = computed(() => {
        return state.transfer?.meta.is_carer ? "Pflegecheckliste importieren" : "Patientenfragebogen importieren";
      });

      const reset = () => {
        Object.entries(initialState).forEach(([key, value]) => {
          state[key] = resetState(value);
        });
      };

      const open = (transfer) => {
        if (abortController) {
          abortController.abort();
        }

        reset();

        state.transfer = cloneDeep(transfer);

        privacy
          .encryptPatient(state.transfer.data.masterdata.patient)
          .then((encryptedPatient) => {
            abortController = new AbortController();
            return axios.post(route("api.patients.search"), encryptedPatient, {
              signal: abortController.signal,
            });
          })
          .then((encryptedExistingPatients) => {
            return Promise.all(
              encryptedExistingPatients.data.data.map((existingPatient) => {
                return privacy.decryptPatient(existingPatient);
              }),
            );
          })
          .then((decryptedExistingPatients) => {
            state.existingPatients = filterExistingPatients(
              state.transfer.data.masterdata.patient,
              decryptedExistingPatients,
              ["birthdate"],
            );

            if (state.existingPatients.length) {
              state.status = "hasExistingPatients";
              state.dialogWidth = "large";
            } else {
              performImport();
            }
          })
          .catch(() => (state.status = "error"));

        refDialog.value.open();
      };

      const close = () => {
        refDialog.value?.close();
        abortController?.abort();
        pdfRouter.cancelDom?.abort();
        router.cancel();
      };

      const selectExistingPatient = (existingPatient) => {
        const nursingHomeAttribute = existingPatient.patient_attributes.findLast((attr) => attr.type === "nursingHome");

        if (
          state.transfer.meta.is_carer &&
          nursingHomeAttribute &&
          Object.values(nursingHomeAttribute.payload).some((data) => !!data)
        ) {
          state.status = "checkNursingHomeAttribute";
          state.dialogWidth = "medium";
          state.selectedExistingPatient = existingPatient;
          state.nursingHomeData = state.transfer.data.masterdata;
        } else {
          performImport(existingPatient);
        }
      };

      const performImport = async (existingPatient = null, overwriteNursingHomeAttribute = true) => {
        state.status = "loading";
        state.dialogWidth = "small";

        const data = {...state.transfer.data, existing_patient_id: existingPatient?.id};

        data.masterdata.patient = await privacy.encryptPatient(data.masterdata.patient);

        data.masterdata.overwriteNursingHomeAttribute = overwriteNursingHomeAttribute;

        const masterDataAttributes = [
          "doc_name",
          "nursing_home_carer",
          "nursing_home_name",
          "nursing_home_street",
          "nursing_home_zip",
          "nursing_home_city",
          "nursing_home_ward",
          "nursing_home_room",
        ];

        for (const key of masterDataAttributes) {
          if (data.masterdata[key]) {
            data.masterdata[key] = await privacy.encryptValue(data.masterdata[key]);
          }
        }

        if (state.transfer.meta.is_carer) {
          const circumstanceAttributes = [];

          for (const key of ["bitesMedicines", "refusesMedications", "swallowingDifficulties"]) {
            if (data.circumstances?.attributes && data.circumstances?.attributes[key]) {
              const label = circumstancesCarer.find((attribute) => attribute.key === key).label;

              circumstanceAttributes.push(label);
            }
          }

          if (circumstanceAttributes.length > 0) {
            data.info = await privacy.encryptValue("- " + circumstanceAttributes.join("\n- "));
          }
        } else {
          const info = [];

          if (data.wishes_aims?.length > 0) {
            info.push(
              "Wünsche und Ziele des Patienten:\n- " + data.wishes_aims.map((key) => wishesAims[key]).join("\n- "),
            );
          }

          if (data.additional) {
            info.push("Weitere Informationen:\n" + data.additional);
          }

          if (info.length > 0) {
            data.info = await privacy.encryptValue(info.join("\n\n"));
          }
        }

        // Decode BMP

        if (data.medication?.bmp?.length > 0) {
          const decodedBmp = await new Promise((resolve, reject) => {
            pdfRouter.decodeXml(
              {pages: data.medication.bmp},
              {
                onSuccess: (response) => {
                  resolve(response.data);
                },
                onError: () => {
                  state.status = "error";
                  reject();
                },
              },
            );
          });

          delete data.medication.bmp;

          data.medication.bmp_pzns = decodedBmp.record.medications;
          data.medication.failed = decodedBmp.failed ?? [];
        }

        // Save

        router.put(route("patientinput-client.update", {transfer: state.transfer.id}), data, {
          onSuccess: close,
          onError: () => (state.status = "error"),
        });
      };

      return {
        /** ref */
        refDialog,

        /** const */
        ...toRefs(state),

        /** computed */
        dialogTitle,

        /** function */
        open,
        close,
        selectExistingPatient,
        performImport,
      };
    },
  };
</script>
