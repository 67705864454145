const deepReset = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Base case: return primitive values or null unchanged
  }

  if (Array.isArray(obj)) {
    return obj.map(deepReset); // Recursively copy array elements
  }

  // Recursively copy object properties
  const copiedObj = {};
  for (const key in obj) {
    copiedObj[key] = deepReset(obj[key]);
  }
  return copiedObj;
};

export default deepReset;
