<template>
  <section class="bg-white shadow-md" :data-test="testId + '-card'">
    <div v-if="title.length > 0">
      <div class="flex items-center px-4 pt-4 space-x-2">
        <h5 class="text-2xl">{{ title }}</h5>
        <div v-if="$slots['info-i-icon']" class="mt-0.5">
          <slot name="info-i-icon" />
        </div>
        <div v-if="$slots['button']">
          <slot name="button" />
        </div>
      </div>
      <h6 class="px-4" v-html="subtitle" />
    </div>

    <div class="p-4">
      <slot />
    </div>

    <div v-if="$slots['actions']" class="p-4 flex items-center flex-row-reverse space-x-reverse space-x-4 print:hidden">
      <slot name="actions" />
    </div>
  </section>
</template>

<script>
  export default {
    name: "ComponentCard",

    props: {
      title: {
        type: String,
        default: "",
      },
      subtitle: {
        type: String,
        default: "",
      },
    },
  };
</script>
