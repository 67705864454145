<template>
  <div id="bg-login" class="flex flex-grow flex-1 bg-cover bg-top -mb-4 -mx-4">
    <div class="flex-1 px-5 bg-gradient-to-b from-gray-50 grid place-items-center">
      <div class="p-4 rounded-md shadow-md sm:w-96 w-md bg-white">
        <template v-if="!isPos">
          <h1 class="text-lg font-semibold">MediCheck &ndash; Login</h1>

          <component-alert
            v-if="browserWarning || browserSupported === false"
            type="warning"
            :test-id="$page.props.testId"
          >
            Ihr Browser ist veraltet oder wird offiziell nicht unterstützt. Dies kann zu unerwünschten Folgen bei der
            Benutzung dieser Software führen.
          </component-alert>

          <form
            ref="loginForm"
            method="post"
            name="loginForm"
            :action="loginUrl"
            :data-test="$page.props.testId + '-form'"
          >
            <input
              type="hidden"
              name="logintype"
              value="login"
              :data-test="$page.props.testId + '-form-login-type-input'"
            />

            <input type="hidden" name="pid" value="707" :data-test="$page.props.testId + '-form-pid-input'" />

            <input
              type="hidden"
              name="redirect_url"
              :value="$route('onboarding')"
              :data-test="$page.props.testId + '-form-redirect-url-input'"
            />

            <input
              type="hidden"
              name="tx_felogin_pi1[noredirect]"
              value="0"
              :data-test="$page.props.testId + '-form-tx_felogin_pi1[noredirect]-input'"
            />

            <component-input
              ref="usernameInput"
              v-model="form.username"
              class="mt-2"
              label="E-Mail-Adresse des pharma4u-Accounts"
              name="user"
              :validation="isInvalidUsername ? 'Bitte geben Sie Ihre E-Mail-Adresse des pharma4u-Accounts an.' : null"
              :test-id="$page.props.testId + '-form-username'"
              @keyup.enter="performLoginAction"
            >
              <template #icon>
                <component-icon class="text-xl text-gray-500">email</component-icon>
              </template>
            </component-input>

            <component-input
              v-model="form.password"
              class="mt-4"
              type="password"
              label="Passwort"
              name="pass"
              :validation="isInvalidPassword ? 'Bitte geben Sie Ihr pharma4u Passwort ein.' : null"
              :test-id="$page.props.testId + '-form-password'"
              @keyup.enter="performLoginAction"
            >
              <template #icon>
                <component-icon class="text-xl text-gray-500">lock</component-icon>
              </template>
            </component-input>

            <p class="mt-4">
              <a
                href="https://www.pharma4u.de/allgemein/benutzer/login/?tx_felogin_pi1%5Bforgot%5D=1"
                class="text-mcred no-underline hover:underline"
                :data-test="$page.props.testId + '-form-password-forgot-link'"
              >
                Passwort vergessen?
              </a>
            </p>

            <div class="mt-4 flex justify-center">
              <component-button
                class="p4umc-primary w-full"
                label="Login"
                type="button"
                :test-id="$page.props.testId + '-form-submit'"
                @click="performLoginAction"
              />
            </div>
          </form>

          <p class="text-center mt-6">
            Noch kein Account vorhanden?

            <br />

            <a
              href="https://www.pharma4u.de/apotheker/medicheck/registrieren"
              class="text-mcred no-underline hover:underline"
              :data-test="$page.props.testId + '-register-link'"
            >
              Jetzt registrieren!
            </a>
          </p>
        </template>

        <template v-else>
          <h1 class="text-lg font-semibold">Benutzersitzung abgelaufen!</h1>

          <p class="mt-4">
            Leider ist Ihre Benutzersitzung abgelaufen. Bitte nutzen Sie Ihre WaWi, um diese Analyse wieder zu öffnen.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {computed, onBeforeMount, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import Cookies from "js-cookie";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";

  export default {
    name: "PagesLoginShow",

    components: {ComponentAlert, ComponentIcon, ComponentInput, ComponentButton},

    props: {
      title: {
        type: String,
        default: null,
      },
      p4uUrl: {
        type: String,
        default: null,
      },
      cookieDomain: {
        type: String,
        default: null,
      },
      browserWarning: {
        type: Boolean,
        default: false,
      },
      browserSupported: {
        type: Boolean,
        default: true,
      },
    },

    setup(props) {
      const form = useForm({
        username: "",
        password: "",
      });

      const hasTriedToSubmit = ref(false);
      const isPos = ref(false);
      const loginForm = ref(null);
      const loginUrl = ref("");

      onBeforeMount(() => {
        loginUrl.value = props.p4uUrl + "/allgemein/benutzer/login";
      });

      const isInvalidUsername = computed(() => hasTriedToSubmit.value && form.username.length === 0);
      const isInvalidPassword = computed(() => hasTriedToSubmit.value && form.password.length === 0);
      const isValidForm = computed(() => !isInvalidUsername.value || !isInvalidPassword.value);

      watch(
        () => form.username,
        () => {
          hasTriedToSubmit.value = false;
        },
      );

      watch(
        () => form.password,
        () => {
          hasTriedToSubmit.value = false;
        },
      );

      function performLoginAction() {
        hasTriedToSubmit.value = true;

        if (isValidForm.value) {
          Cookies.set("fe_typo_user", "56f2bbf46eadb8423eb49df04552c1f9", {
            expires: 1,
            path: "/",
            domain: props.cookieDomain,
          });

          loginForm.value.submit();
        }
      }

      return {
        /** const */
        form,
        isPos,
        loginForm,
        loginUrl,

        /** computed */
        isInvalidPassword,
        isInvalidUsername,

        /** function */
        performLoginAction,
      };
    },
  };
</script>

<style>
  #bg-login {
    background-image: url("/resources/assets/images/hero-image.jpg");
  }
</style>
