<template>
  <section v-if="prognosticRedlights.length > 0">
    <h2 class="text-center text-2xl font-semibold print:font-normal border-b-1 border-gray-200 pb-6">
      Potentielle Risiken
      <span class="font-light">(Prognose)</span>
    </h2>

    <div class="mt-8 space-y-4 print:break-before-avoid-page">
      <h3 class="text-xl font-semibold">Weitere ABP (arzneimittelbezogene Probleme)</h3>
      <pages-record-result-list-checks
        class="print:break-before-avoid-page"
        anchor-prefix="prognostic"
        :patient-id="patientId"
        :record-id="recordId"
        :checks="prognosticRedlights"
        @open:share-dialog="$emit('open:shareDialog')"
      />
    </div>
  </section>
</template>

<script>
  import PagesRecordResultListChecks from "@pages/Records/Components/Result/ResultList/ListChecks.vue";

  export default {
    name: "PagesRecordResultPrognosticResults",
    components: {PagesRecordResultListChecks},
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      prognosticRedlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
  };
</script>
