export const laborbardeviationValues = {
  raised: "Erhöht (↑)",
  lowered: "Erniedrigt (↓)",
  normal: "Im Normbereich",
};

export const bmiKey = 4;
export const pulseKey = 1;
export const bloodpressureKey = 3;
export const bodySizeKey = 182;
export const bodyWeightKey = 183;
export const creatinineKey = 177;
export const creatinineAllowedUnitIdForGfr = 9;
export const gfrKey = 137;
export const patientGfralgorithm = {
  "": "Nein",
  useCkdEpi: "CKD-EPI",
  useCkdEpiBlackEthnic: "CKD-EPI (schwarze Hautfarbe)",
  useMayoClinic: "Mayo-Klinik-Formel",
  useMdrd: "MDRD",
  useMdrdBlackEthnic: "MDRD (schwarze Hautfarbe)",
  useCockcroftGault: "Cockcroft-Gault",
};

export const recommendedLaborKeys = [
  13, // HbA1C
  14, // Cholesterin (gesamt)
  17, // TG (Triglyzeride)
  45, // Kalium
  46, // Natrium
];

export const mainLaborvalueList = [
  bloodpressureKey,
  bmiKey,
  bodySizeKey,
  bodyWeightKey,
  creatinineKey,
  gfrKey,
  pulseKey,
];
