<template>
  <div>
    <p class="leading-snug">
      Bitte beachten Sie, dass eine Kommunikation nur auf Basis einer vorliegenden Schweige&shy;pflicht&shy;entbindung
      vorgenommen werden kann.
    </p>
    <component-checkbox
      v-model="form.releasefromconfidentialityconfirmed"
      color="primary"
      class="mt-2"
      @change="save()"
    >
      Schweigepflichtentbindung liegt vor
    </component-checkbox>
  </div>
</template>
<script>
  import {useForm} from "@inertiajs/vue3";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "ComponentPatientReleaseConfidentiality",
    components: {ComponentCheckbox},
    props: {
      patient: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const form = useForm({
        releasefromconfidentialityconfirmed: props.patient.releasefromconfidentialityconfirmed !== null,
      });

      function save() {
        form.patch(route("patients.update", {patient: props.patient.id}), {
          preserveScroll: true,
        });
      }

      return {form, save};
    },
  };
</script>
