<template>
  <div>
    <div
      v-if="current > 0"
      class="h-3 w-full bg-gray-200 rounded grid"
      :style="`grid-template-columns: repeat(${max}, minmax(0, 1fr));`"
    >
      <span
        v-for="index of current"
        :key="index"
        class="bg-mcred h-full"
        :class="{'rounded-s': index === 1, 'rounded-e': index === max}"
      />
    </div>

    <div v-else class="h-3 w-full bg-gray-200 rounded">
      <div class="bg-mcred h-full rounded-s w-2" />
    </div>

    <div class="flex justify-between text-xs pt-1">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: "ComponentPatientSidebarPatientinputFoldableComplianceBar",

    props: {
      max: {
        type: Number,
        default: 4,
      },
      current: {
        type: Number,
        required: true,
      },
    },
  };
</script>
