<template>
  <component-dialog ref="refCryptDialog" dialog-width="medium" :test-id="testId">
    <template #title>Datenschutzpasswort</template>

    <template #content>
      <p :data-test="testId + '-dialog-error-message'">{{ privacy.getErrorMessage() }}</p>
    </template>

    <template #actions>
      <component-button
        class="p4umc-primary"
        label="Abbrechen"
        :test-id="testId + '-dialog-reload'"
        @click="reload()"
      />
    </template>
  </component-dialog>
</template>

<script>
  import {ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "CryptDialogsError",

    components: {ComponentButton, ComponentDialog},

    props: {
      privacy: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    setup() {
      const refCryptDialog = ref(null);

      const open = () => {
        refCryptDialog.value.open();
      };

      const close = () => {
        refCryptDialog.value.close();
      };

      const reload = () => {
        location.reload();
      };

      return {
        /** ref */
        refCryptDialog,

        /** function */
        open,
        close,
        reload,
      };
    },
  };
</script>
