<template>
  <section v-if="preventionRedlights.length > 0">
    <h2 class="text-center text-2xl font-semibold print:font-normal border-b-1 border-gray-200 pb-6">Prävention</h2>
    <div class="mt-8 space-y-4 print:break-before-avoid-page">
      <pages-record-result-list-checks
        anchor-prefix="prevention"
        :patient-id="patientId"
        :record-id="recordId"
        :checks="preventionRedlights"
        @open:share-dialog="$emit('open:shareDialog')"
      />
    </div>
  </section>
</template>

<script>
  import PagesRecordResultListChecks from "@pages/Records/Components/Result/ResultList/ListChecks.vue";

  export default {
    name: "PagesRecordResultPreventionResults",
    components: {PagesRecordResultListChecks},
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      preventionRedlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    emits: ["open:shareDialog"],
  };
</script>
