<template>
  <div class="mt-5 flex flex-col gap-3">
    <h3 class="text-lg leading-6 font-medium text-gray-700">BMP-Datenmatrix scannen</h3>

    <component-alert
      v-if="error.type && error.text"
      :type="error.type"
      class="!mb-0"
      :test-id="testId + '-device-camera-scanner-tab'"
    >
      {{ error.text }}
    </component-alert>

    <p v-else>Bitte jetzt die Datenmatrix mit Ihrer Gerätekamera einlesen.</p>

    <div v-if="!cameraDisabled">
      <div v-show="!cameraOn" class="h-40 flex justify-center items-center">
        <component-spinner class="w-10 h-10" :test-id="testId + '-device-camera-scanner-tab'" />
      </div>

      <div v-show="cameraOn">
        <component-barcode-scanner
          :barcode-formats="formats"
          @camera-on="onCameraOn"
          @error="onError"
          @detect="onDetect"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentBarcodeScanner from "@components/BarcodeScanner/BarcodeScanner.vue";
  import {Symbology} from "scandit-web-datacapture-barcode";
  import {computed, ref} from "vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "ComponentBmpImportDialogDeviceCameraScanTab",

    components: {ComponentSpinner, ComponentBarcodeScanner, ComponentAlert},

    props: {
      error: {
        type: Object,
        required: true,
      },
    },

    emits: ["error", "input"],

    setup(props, {emit}) {
      const formats = [Symbology.DataMatrix];

      const cameraOn = ref(false);
      const cameraDisabled = ref(false);

      const localError = ref({
        type: "",
        text: "",
      });

      /** @type {import("vue").ComputedRef<{type, text}>} */
      const displayError = computed(() => {
        return props.error?.type && props.error?.text ? props.error : localError.value;
      });

      const onCameraOn = () => {
        cameraOn.value = true;
      };

      const onError = (error) => {
        emit("error", "error", error.message);
        cameraDisabled.value = true;
      };

      const onDetect = (recognizedBarcodes) => {
        if (recognizedBarcodes.length === 1) {
          emit("input", recognizedBarcodes[0]._data);
        }
      };

      return {formats, cameraOn, onCameraOn, onError, onDetect, displayError, cameraDisabled};
    },
  };
</script>

<style scoped></style>
