export const sortList = (array, key, sortDirection = "asc") => {
  const sortedList = array.sort((a, b) => {
    if (sortDirection === "desc") {
      return b[key].localeCompare(a[key]);
    } else {
      return a[key].localeCompare(b[key]);
    }
  });

  return {
    sortedArray: sortedList,
    sortedIds: sortedList.map((item) => item.id),
  };
};

export const sortListByUsageAndFam = (array, sortDirection = "asc") => {
  const sortedList = array.sort((a, b) => {
    if (a.usage === null) return -1;
    if (b.usage === null) return 1;

    if (sortDirection === "asc") {
      if (a.usage !== b.usage) {
        return a.usage - b.usage;
      }
    } else {
      if (a.usage !== b.usage) {
        return b.usage - a.usage;
      }
    }

    return a.fam.localeCompare(b.fam);
  });

  return {
    sortedArray: sortedList,
    sortedIds: sortedList.map((item) => item.id),
  };
};

export const sortListByIndicationAndFam = (array, sortDirection = "asc") => {
  const sortedList = array.sort((a, b) => {
    const aIndicationsName = a.indications[0]?.name || "";
    const bIndicationsName = b.indications[0]?.name || "";

    const nameComparison = aIndicationsName.localeCompare(bIndicationsName);

    if (sortDirection === "desc") {
      return nameComparison === 0 ? b.fam.localeCompare(a.fam) : -nameComparison;
    } else {
      return nameComparison === 0 ? a.fam.localeCompare(b.fam) : nameComparison;
    }
  });

  return {
    sortedArray: sortedList,
    sortedIds: sortedList.map((item) => item.id),
  };
};
