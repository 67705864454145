<template>
  <tr
    class="hover:bg-gray-100 border-b-2 border-gray-200 leading-tight cursor-pointer"
    :data-test="testId + '-patient-row'"
    @click="(event) => clickRow(event, $route('patients.show', {patient: patient.id}))"
  >
    <!-- patient -->
    <td class="px-0.5 py-2">
      <component-tooltip v-if="patient.suffix || patient.prefix" tooltip="Nameszusatz" :force-inline="true">
        <span class="text-sm">{{ patient.suffix }} {{ patient.prefix }}</span>
      </component-tooltip>

      <div class="flex items-center space-x-2">
        <inertia-link
          :href="$route('patients.show', {patient: patient.id})"
          class="text-gray-700 hover:text-gray-900 cursor-pointer hover:underline font-semibold text-lg"
          :data-test="testId + '-patient-row-patient-show-inertia-link'"
        >
          {{ patient.lastname }}, {{ patient.firstname }}
        </inertia-link>

        <component-tooltip tooltip="Patientennotiz öffnen">
          <component-icon-button
            icon="assignment"
            class="p4umc-flat text-sm"
            :data-test="testId + '-patient-row-patient-open-note'"
            @click="openPatientNote()"
          />
        </component-tooltip>
      </div>

      <div class="flex items-center space-x-2">
        <component-tooltip tooltip="Geburtsdatum">
          <span class="text-xs" v-text="patient.birthdate" />
        </component-tooltip>

        <component-tooltip tooltip="Versichertennummer">
          <span class="text-xs" v-text="patient.insurancenumber" />
        </component-tooltip>
      </div>
    </td>

    <!-- status -->
    <td class="px-0.5 py-2">
      <patients-patient-information :patient="patient" :data-test="testId + '-patient-row-patient-open-note'" />
    </td>

    <!-- to dos -->
    <td v-if="$page.props.can.view_todos" class="px-0.5 py-2 text-sm">
      <component-tooltip v-if="patient?.todo_count === 0" tooltip="Neue Aufgabe anlegen" :force-inline="true">
        <component-icon-button
          icon="add"
          class="p4umc-flat"
          :data-test="testId + '-patient-row-patient-open-todo-dialog-button'"
          @click="openPatientTodos"
        />
      </component-tooltip>

      <a
        v-if="patient?.todo_count > 0"
        href="#"
        class="text-gray-900"
        :data-test="testId + '-patient-row-patient-open-todo-dialog-button'"
        @click.prevent="openPatientTodos()"
      >
        {{ patient?.todo_last?.label }}
      </a>

      <div v-if="patient?.todo_count > 1" class="text-xs">
        und
        <a
          href="#"
          :data-test="testId + '-patient-row-patient-open-todo-dialog-button'"
          @click.prevent="openPatientTodos()"
        >
          {{ patient?.todo_count - 1 }} weitere
        </a>
      </div>
    </td>

    <!-- last updates -->
    <td class="px-0.5 py-2">
      {{ patient.updated_at }}

      <div v-if="patient.username" class="text-xs">
        {{ patient.username }}
      </div>
    </td>

    <!-- actions -->
    <td class="px-0.5 py-2">
      <div class="flex justify-end">
        <component-tooltip
          :tooltip="
            patient.from_wawi
              ? 'Sie können den Patienten nicht löschen, da dieser aus der Warenwirtschaft übertragen wurde'
              : !$page.props.can.delete_patients
                ? 'Sie können den Patienten nicht löschen, da Berechtigungen fehlen'
                : 'Löschen'
          "
        >
          <component-icon-button
            icon="delete"
            :disabled="!$page.props.can.delete_patients || patient.from_wawi"
            :data-test="testId + '-patient-row-confirm-delete'"
            @click="confirmDelete()"
          />
        </component-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
  import {Link as InertiaLink, router} from "@inertiajs/vue3";

  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import PatientsPatientInformation from "@pages/Patients/Components/PatientInformation.vue";

  export default {
    name: "PatientsPatientRow",

    components: {
      PatientsPatientInformation,
      ComponentIconButton,
      InertiaLink,
      ComponentTooltip,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    emits: ["open:patientNote", "open:patientTodos", "confirm:delete"],

    setup(props, {emit}) {
      function openPatientNote() {
        emit("open:patientNote");
      }

      function openPatientTodos() {
        emit("open:patientTodos");
      }

      function confirmDelete() {
        emit("confirm:delete");
      }

      function clickRow(event, href) {
        let node = event.target ?? event;

        if (!node.nodeName) {
          return;
        }

        if (node.nodeName.toLowerCase() === "td") {
          // click on <td> => open link
          router.visit(href);
          return;
        }

        if (
          node.nodeName.toLowerCase() === "a" ||
          node.nodeName.toLowerCase() === "button" ||
          node.nodeName.toLowerCase() === "select" ||
          node.nodeName.toLowerCase() === "input" ||
          node.nodeName.toLowerCase() === "textarea" ||
          node.nodeName.toLowerCase() === "dialog"
        ) {
          // click on a clickable element (a or button)
          return;
        }

        if (node.parentNode) {
          // click on any other element: check if parent is <td>
          return clickRow(node.parentNode, href);
        }
      }

      return {
        /** function */
        openPatientNote,
        openPatientTodos,
        confirmDelete,
        clickRow,
      };
    },
  };
</script>
