<template>
  <div class="relative">
    <label
      :class="{'text-mcred': isInvalid, 'text-gray-500': !isInvalid}"
      class="duration-300 -z-1 origin-0 whitespace-nowrap text-sm"
      :for="fieldId"
      :data-test="testId + '-autocomplete-label'"
    >
      {{ label }}
    </label>

    <div ref="refTriggerElement" class="flex">
      <input
        :id="fieldId"
        ref="refInput"
        autocomplete="off"
        :value="searchTerm"
        type="text"
        :tabindex="tabindex"
        class="px-2 h-9"
        :placeholder="placeholder"
        :disabled="disabled"
        :data-test="testId + '-autocomplete-input'"
        @input="searchTermChanged"
        @focusin="handleFocusIn"
        @keydown.down="selectNext"
        @keydown.tab="onClickOutside"
      />

      <component-icon
        v-if="!disabled && clearable"
        :clickable="true"
        class="text-gray-500 text-base -ml-4 mt-2.5 w-4"
        :test-id="testId + '-autocomplete-clear'"
        @click="clearField"
      >
        clear
      </component-icon>
    </div>

    <div ref="refFloatingElement" class="absolute top-0 left-0 m-0 p-0 w-max shadow-lg" popover="manual">
      <div
        v-if="isLoading"
        class="bg-white z-10 p-3 w-96 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none flex justify-center"
      >
        <component-spinner class="w-6 h-6" :test-id="testId + '-autocomplete'" />
      </div>

      <div
        v-else-if="showEmptySlot"
        class="bg-white z-10 min-h-[32px] max-w-96 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none"
        :data-test="testId + '-autocomplete-dropdown-empty'"
      >
        <slot v-if="hasScopedPrependSlot && results.length > 0" name="autocomplete-prepend" :items="results" />
        <slot name="autocomplete-empty" :term="searchTerm" />
      </div>

      <div
        v-else-if="showRecommendationSlot"
        class="bg-white z-10 min-h-[32px] max-h-60 max-w-96 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none flex"
        :data-test="testId + '-autocomplete-dropdown-recommendations'"
        @click="toggleDropdown(300)"
      >
        <slot name="autocomplete-recommendation" :select-item="selectItem" :term="searchTerm" />
      </div>

      <div
        v-else-if="showDropdown"
        class="bg-white z-10 max-w-96 rounded-md"
        :data-test="testId + '-autocomplete-dropdown-results'"
      >
        <div v-if="showPrependSlot">
          <slot name="autocomplete-prepend" :items="results" />
        </div>

        <ul
          ref="refResultList"
          tabindex="-1"
          class="ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-x-hidden text-ellipsis overflow-y-auto space-y-2"
          role="listbox"
        >
          <li
            v-if="isInaccurate"
            class="py-1 px-3 text-xs text-gray-500"
            :data-test="testId + '-autocomplete-dropdown-inaccurate'"
          >
            Geben Sie mehr Buchstaben für exakte Suchtreffer ein.
          </li>

          <li
            v-for="(item, index) in filteredResults"
            :id="`refResultList${index}`"
            :key="index"
            class="relative z-10 cursor-pointer py-1 px-3 text-gray-900 hover:bg-gray-100 border-1 border-white focus:border-1 focus:border-mcred focus-visible:outline-none"
            role="option"
            :tabindex="showDropdown ? 0 : -1"
            :data-test="testId + '-autocomplete-dropdown-item-' + index"
            @click="selectItem(item)"
            @keydown.enter="selectItem(item)"
            @keydown.up="selectPrevious"
            @keydown.down="selectNext"
            @keydown.tab="onClickOutside"
          >
            <slot v-if="hasScopedItemSlot" name="autocomplete-item" :item="item" :term="searchTerm" />
            <template v-else>{{ item }}</template>
          </li>
        </ul>
      </div>
    </div>

    <span v-if="isInvalid" class="text-xs text-mcred" :data-test="testId + '-autocomplete-error-message'">
      {{ validation }}
    </span>

    <span class="text-xs text-gray-500" :data-test="testId + '-autocomplete-helper-text'">{{ helperText }}</span>
  </div>
</template>

<script>
  import {computed, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
  import {debounce, isString} from "lodash";
  import {computePosition} from "@floating-ui/vue";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "ComponentAutocomplete",

    components: {ComponentSpinner, ComponentIcon},

    props: {
      label: {
        type: String,
        default: "",
      },
      modelValue: {
        type: [String, Object],
        default: "",
      },
      items: {
        type: Array,
        default: () => [],
      },
      fetchMethod: {
        type: Function,
        default: null,
      },
      fetchPaginationMethod: {
        type: Function,
        default: null,
      },
      paginationLinks: {
        type: Object,
        default: () => {},
      },
      filterMethod: {
        type: Function,
        default: null,
      },
      keyName: {
        type: String,
        default: "",
      },
      minSearchTermLength: {
        type: Number,
        default: 3,
      },
      validation: {
        type: String,
        default: "",
      },
      helperText: {
        type: String,
        default: "",
      },
      tabindex: {
        type: [String, Number],
        default: 0,
      },
      message: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["opened", "closed", "cleared", "selected", "update:modelValue", "hasResults"],

    setup(props, {slots, emit}) {
      const refInput = ref(null);
      const refResultList = ref(null);
      const refTriggerElement = ref(null);
      const refFloatingElement = ref(null);

      const fieldId = ref(crypto.randomUUID());

      // data
      const searchTerm = ref("");
      const selectedItem = ref(null);
      const results = ref([]);
      const isLoading = ref(false);
      const isDirty = ref(false);
      const showDropdown = ref(false);
      const isInaccurate = ref(false);
      const oldItem = ref("");
      const activeIndex = ref(-1);

      // computed
      const hasItems = computed(() => props.items && props.items.length > 0);
      const hasFilteredItems = computed(() => filteredResults.value.length > 0);
      const hasScopedItemSlot = computed(() => !!slots["autocomplete-item"]);
      const hasScopedEmptySlot = computed(() => !!slots["autocomplete-empty"]);
      const hasScopedRecommendationSlot = computed(
        () => !!slots["autocomplete-recommendation"] && slots["autocomplete-recommendation"]()[0]?.children !== "v-if",
      );
      const hasScopedPrependSlot = computed(() => !!slots["autocomplete-prepend"]);
      const showEmptySlot = computed(
        () =>
          searchTerm.value !== "" &&
          !isLoading.value &&
          !hasFilteredItems.value &&
          !selectedItem.value &&
          hasScopedEmptySlot.value &&
          showDropdown.value,
      );
      const showRecommendationSlot = computed(
        () => showDropdown.value && searchTerm.value === "" && hasScopedRecommendationSlot.value,
      );
      const showPrependSlot = computed(
        () => showDropdown.value && searchTerm.value !== "" && hasScopedPrependSlot.value,
      );
      const isInvalid = computed(() =>
        typeof props.validation !== "string" ? false : props.validation.length > 0 && searchTerm.value.length > 0,
      );
      const hasSearchTerm = computed(
        () =>
          !!searchTerm.value &&
          searchTerm.value.trim().length >=
            props.minSearchTermLength + (searchTerm.value.trim().charAt(0) === "*" ? 1 : 0),
      );

      // watch all conditions which cause the "popover" refFloatingElement to show
      watch(
        [isLoading, showEmptySlot, showRecommendationSlot, showDropdown],
        ([newIsLoading, newShowEmptySlot, newShowRecommendationSlot, newShowDropdown]) => {
          if (newIsLoading || newShowEmptySlot || newShowRecommendationSlot || newShowDropdown) {
            refFloatingElement.value.showPopover();
            nextTick(() => {
              calculate();
            });
            window.addEventListener("resize", calculate);
          } else {
            window.removeEventListener("resize", calculate);
          }
        },
      );

      onMounted(() => {
        window.addEventListener("click", onClickOutside);
      });

      onUnmounted(() => {
        window.addEventListener("click", onClickOutside);
      });

      const calculate = debounce(() => {
        try {
          computePosition(refTriggerElement.value, refFloatingElement.value, {
            placement: "bottom-start", // << preferred placement
            // middleware: [inline()],
          }).then(({x, y, middlewareData, placement}) => {
            Object.assign(refFloatingElement.value.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });
        } catch (e) {
          // ignore
        }
      }, 400);

      const canFetch = computed(() => hasSearchTerm.value && typeof props.fetchMethod === "function");
      const canFilter = computed(() => results.value.length && typeof props.filterMethod === "function");
      const selectedItemTerm = computed(() => {
        if (selectedItem.value && typeof selectedItem.value === "object" && props.keyName.length > 0) {
          return selectedItem[props.keyName];
        } else if (isString(selectedItem.value)) {
          return selectedItem.value;
        }
        return "";
      });
      const filteredResults = computed(() => (canFilter.value ? props.filterMethod(results.value) : results.value));

      // Watcher !!!
      //TODO: Migrate
      watch(
        () => showDropdown.value,
        (show) => {
          if (show && filteredResults.value.length > 0) {
            emit("opened");
          } else {
            emit("closed");
          }
        },
      );

      watch(
        () => filteredResults.value,
        (results) => {
          emit("hasResults", results.length);
        },
      );

      // Setup
      searchTerm.value = props.modelValue || "";
      selectedItem.value = props.modelValue || null;
      oldItem.value = props.modelValue || null;

      // Methods
      const executeFetchHandler = debounce(() => {
        if (hasSearchTerm.value === false) {
          results.value = [];
          isLoading.value = false;
          return;
        }

        isInaccurate.value = false;
        isLoading.value = true;

        props
          .fetchMethod(searchTerm.value)
          .then((res) => {
            if (hasSearchTerm.value === false) {
              res = [];
              isLoading.value = false;
              return;
            }
            if (res === false) {
              // a cancel token ended the former
              // request and we keep waiting
              isLoading.value = true;
            }
            if (typeof results.value == "object") {
              isLoading.value = false;
              results.value = res;

              // A scanned "PZN" value starts with a '-'.
              const match = searchTerm.value.match(/^-(\d{7,8})/i);
              if (match && match[1] && res.length === 1) {
                selectItem(res[0]);
              }

              // when a search returns more than 500 results
              // we show a hint to type more chars
              isInaccurate.value = res.length >= 500;
            }
          })
          .catch((error) => {
            // TODO: this catch wont get called at the moment
            // TODO: and if, we cannot set the value of a prop here...
            isLoading.value = false;
            reset();
          });
      }, 1000);

      const handleFocusIn = ($event) => {
        $event.target.select();
        showDropdown.value = true;
      };

      const toggleDropdown = (timeout = 0) => {
        setTimeout(() => {
          showDropdown.value = !showDropdown.value;
        }, timeout);
      };

      const onClickOutside = ($event) => {
        if (
          typeof $event !== "undefined" &&
          refTriggerElement.value &&
          !refTriggerElement.value.contains($event.target) &&
          refFloatingElement.value &&
          !refFloatingElement.value.contains($event.target)
        ) {
          showDropdown.value = false;
          // handleBlur();
        }
      };

      const selectItem = (item) => {
        if (oldItem.value !== null && oldItem.value.length > 0) {
          emit("cleared", oldItem);
          reset();
        }

        isDirty.value = false;
        selectedItem.value = item;
        $_setSearchTerm(selectedItem.value);
        toggleDropdown();
        results.value = [];
        emit("selected", selectedItem.value);
      };

      const searchTermChanged = ($event) => {
        if (!$event.target.value) {
          if ($event.target.value === "") {
            clearField();
          }

          // MED-4490: reset searchTerm if value is empty, otherwise first letter
          searchTerm.value = "";
          emit("update:modelValue", searchTerm.value);
          return;
        }

        if (selectedItemTerm.value === $event.target.value) {
          return;
        }

        let localSearchTerm = $event.target.value;
        if (localSearchTerm.charAt(0) === "#") {
          localSearchTerm = localSearchTerm.slice(1);
        }

        searchTerm.value = localSearchTerm;

        if (hasItems.value) {
          $_filterItems();
        } else if (canFetch.value) {
          results.value = [];
          executeFetchHandler();
        }

        emit("update:modelValue", searchTerm.value);
      };

      const $_filterItems = () => {
        if (!!searchTerm.value === false) {
          return;
        }

        results.value = props.items.filter((item) => {
          if (typeof item === "object" && props.keyName.length > 0) {
            return item[props.keyName].toLowerCase().includes(searchTerm.value.toLowerCase());
          } else if (isString(item)) {
            return item.toLowerCase().includes(searchTerm.value.toLowerCase());
          } else {
            return true;
          }
        });
      };

      const $_setSearchTerm = (item) => {
        if (!item) {
          return;
        }

        if (typeof item === "object" && props.keyName.length > 0) {
          searchTerm.value = item[props.keyName];
        } else if (isString(item)) {
          searchTerm.value = item;
        }
      };

      const reset = () => {
        selectedItem.value = null;
        results.value = [];
        searchTerm.value = "";
      };

      const clearField = () => {
        emit("cleared", oldItem.value);
        reset();
      };

      const focus = () => {
        setTimeout(() => {
          refInput.value.focus();
        }, 10);
      };

      /** @param {KeyboardEvent} event*/
      const selectPrevious = (event) => {
        event.preventDefault();

        const index = [...refResultList.value.children].indexOf(event.target);

        if (index > 0) {
          refResultList.value.children.item(index - 1).focus();
        } else if (index === 0) {
          refResultList.value.children.item(refResultList.value.children.length - 1).focus();
        }
      };

      /** @param {KeyboardEvent} event*/
      const selectNext = (event) => {
        event.preventDefault();

        const index = [...refResultList.value.children].indexOf(event.target);

        //
        if (index === -1 && filteredResults.value.length > 0 && refResultList.value.children.item(0)) {
          refResultList.value.children.item(0).focus();

          // Still objects in list, select the next one
        } else if (index < refResultList.value.children.length - 1 && refResultList.value.children.item(index + 1)) {
          refResultList.value.children.item(index + 1).focus();

          // Last element, set focus on the first one
        } else if (index === refResultList.value.children.length - 1 && refResultList.value.children.item(0)) {
          refResultList.value.children.item(0).focus();
        }
      };

      return {
        /** ref */
        refInput,
        refResultList,
        refTriggerElement,
        refFloatingElement,

        /** const */
        fieldId,
        searchTerm,
        results,
        isLoading,
        showDropdown,
        isInaccurate,
        activeIndex,

        /** computed */
        hasScopedItemSlot,
        showRecommendationSlot,
        hasScopedPrependSlot,
        showEmptySlot,
        showPrependSlot,
        isInvalid,
        filteredResults,

        /** function */
        searchTermChanged,
        handleFocusIn,
        toggleDropdown,
        onClickOutside,
        clearField,
        selectItem,
        reset,
        focus,
        selectPrevious,
        selectNext,
      };
    },
  };
</script>
