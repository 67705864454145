<template>
  <component-dialog
    ref="refDialog"
    :dialog-title="title"
    dialog-type="information"
    @ok-button-clicked="redirect()"
    data-test="recordInvalid-dialog"
  >
    <template #content>
      <p v-if="type === 'delete'">
        Die in diesem Browser-Tab geöffnete Variante der Analyse wurde in einem anderen Browser-Tab durch Sie gelöscht.
      </p>
      <p v-if="type === 'reset'">Sie haben die in diesem Browser-Tab geöffnete Variante überschrieben.</p>
      <p class="mt-4">Sie werden auf die Detailseite des Patienten weitergeleitet.</p>
    </template>
  </component-dialog>
</template>

<script>
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {computed, inject, onBeforeUnmount, onMounted, ref} from "vue";
  import {router} from "@inertiajs/vue3";

  export default {
    name: "ComponentRecordInvalidatedDialog",

    components: {ComponentDialog},

    props: {
      record: {
        type: Object,
        required: true,
      },
      ignoreReset: {
        type: Boolean,
        default: false,
      },
    },

    expose: ["open", "close"],

    setup(props, {emit}) {
      const refDialog = ref(null);
      const type = ref(null);
      const broadcast = inject("$broadcast");

      const title = computed(() => {
        switch (type.value) {
          case "delete":
            return "Variante wurde gelöscht";
          case "reset":
            return "Variante wurde überschrieben";
          default:
            return "";
        }
      });

      onMounted(() => {
        broadcast.record.onMessage(props.record.id, handleBroadcastMessage);
      });

      onBeforeUnmount(() => {
        broadcast.record.offMessage(props.record.id, handleBroadcastMessage);
      });

      const handleBroadcastMessage = (data) => {
        switch (data?.action) {
          case "delete":
            type.value = "delete";
            open();
            break;
          case "reset":
            if (props.ignoreReset === false) {
              type.value = "reset";
              open();
            }
            break;
        }
      };

      function open() {
        refDialog.value.open();
      }

      function close() {
        refDialog.value.close();
      }

      function redirect() {
        router.get(route("patients.show", {patient: props.record.patient_id}));
      }

      return {
        refDialog,
        title,
        type,
        open,
        close,
        redirect,
      };
    },
  };
</script>
