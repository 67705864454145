<template>
  <section
    v-if="
      medicationBasedSymptomaticRedlights.length > 0 ||
      diseaseBasedSymptomaticRedlights.length > 0 ||
      laborvalueBasedSymptomaticRedlights.length > 0
    "
  >
    <h2 class="text-center text-2xl font-semibold print:font-normal border-b-1 border-gray-200 pb-6">
      Probleme / Symptome
      <span class="font-light">(aktuelle)</span>
      <div v-if="symptoms.length > 0" class="text-mcred-500 font-light">
        {{ symptoms.map((symptom) => symptom.symptom).join(", ") }}
      </div>
    </h2>

    <div v-if="medicationBasedSymptomaticRedlights.length > 0" class="mt-8 space-y-4 print:break-before-avoid-page">
      <h3 class="text-xl font-semibold">Ursachen mit Arzneimittelbezug</h3>
      <pages-record-result-list-checks
        class="print:break-before-avoid-page"
        anchor-prefix="symptomatic"
        :patient-id="patientId"
        :record-id="recordId"
        :checks="medicationBasedSymptomaticRedlights"
        @open:share-dialog="$emit('open:shareDialog')"
      />
    </div>

    <div v-if="diseaseBasedSymptomaticRedlights.length > 0" class="mt-8 space-y-4">
      <h3 class="text-xl font-semibold">Ursachen mit Erkrankungsbezug</h3>
      <pages-record-result-list-checks
        class="print:break-before-avoid-page"
        anchor-prefix="symptomatic"
        :patient-id="patientId"
        :record-id="recordId"
        :checks="diseaseBasedSymptomaticRedlights"
        @open:share-dialog="$emit('open:shareDialog')"
      />
    </div>

    <div v-if="laborvalueBasedSymptomaticRedlights.length > 0" class="mt-8 space-y-4">
      <h3 class="text-xl font-semibold">Ursachen mit Laborwertbezug</h3>
      <pages-record-result-list-checks
        class="print:break-before-avoid-page"
        anchor-prefix="symptomatic"
        :patient-id="patientId"
        :record-id="recordId"
        :checks="laborvalueBasedSymptomaticRedlights"
        @open:share-dialog="$emit('open:shareDialog')"
      />
    </div>
  </section>
</template>

<script>
  import {computed} from "vue";
  import PagesRecordResultListChecks from "@pages/Records/Components/Result/ResultList/ListChecks.vue";

  export default {
    name: "PagesRecordResultSymptomaticResults",
    components: {PagesRecordResultListChecks},
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      symptoms: {
        type: Array,
        default: () => {
          return [];
        },
      },
      symptomaticRedlights: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    setup(props) {
      const medicationBasedSymptomaticRedlights = computed(() => {
        return props.symptomaticRedlights.filter((check) => check.basis === "medication");
      });

      const diseaseBasedSymptomaticRedlights = computed(() => {
        return props.symptomaticRedlights.filter((check) => check.basis === "disease");
      });

      const laborvalueBasedSymptomaticRedlights = computed(() => {
        return props.symptomaticRedlights.filter((check) => check.basis === "laborvalue");
      });

      return {
        medicationBasedSymptomaticRedlights,
        diseaseBasedSymptomaticRedlights,
        laborvalueBasedSymptomaticRedlights,
      };
    },
  };
</script>
