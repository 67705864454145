<template>
  <div class="grid grid-cols-12 font-normal items-end text-sm gap-x-6 text-gray-700 pb-2">
    <div
      class="col-span-2 ml-2 flex items-center"
      :class="{'ml-6': isEditable, 'cursor-pointer': isEditable}"
      @click="sortByName"
    >
      <template v-if="isEditable">
        <template v-if="sortDirection === 'asc'">
          <component-icon>expand_more</component-icon>
        </template>

        <template v-else-if="sortDirection === 'desc'">
          <component-icon>expand_less</component-icon>
        </template>

        <template v-else>
          <component-icon>unfold_more</component-icon>
        </template>
      </template>

      Was
    </div>
    <div class="col-span-3">Wert (opt.)</div>
    <div class="col-span-2">Einheit (opt.)</div>
    <div class="col-span-2" :class="{'col-span-3': !isEditable}">Abweichung</div>
    <div class="col-span-2" :class="{'col-span-1': isEditable}">Von (Datum)</div>
    <div class="col-span-1" />
  </div>

  <ul ref="parent">
    <template v-for="laborValue in laborValues" :key="laborValue.id">
      <template v-if="isLoading">
        <labor-value-skeleton-row />
      </template>

      <template v-else>
        <labor-table-row :is-editable="isEditable" :laborvalue="laborValue" :is-dragging="isDragging" />
      </template>
    </template>
  </ul>
</template>

<script>
  import {computed, ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import {cloneDeep, filter, isEqual} from "lodash";
  import {useDragAndDrop} from "@formkit/drag-and-drop/vue";
  import {dropOrSwap} from "@formkit/drag-and-drop";

  import {sortList} from "@utils/Helpers/ListSorter.js";

  import ComponentIcon from "@components/Icons/Icon.vue";

  import {mainLaborvalueList} from "@pages/Records/Components/Sections/LaborBar/enums.js";

  import LaborTableRow from "@pages/Records/Components/Sections/LaborBar/Components/Row.vue";
  import LaborValueSkeletonRow from "@pages/Records/Components/Sections/LaborBar/Components/SkeletonRow.vue";

  export default {
    name: "LaborTable",

    components: {
      ComponentIcon,
      LaborValueSkeletonRow,
      LaborTableRow,
    },

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      recordData: {
        type: Object,
        required: true,
      },
      filteredLaborvalues: {
        type: Array,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();
      const isDragging = ref(false);

      const config = {
        dragHandle: ".drag-area",
        synthDropZoneClass: "ring-2 ring-mcred-300",
        plugins: [
          dropOrSwap({
            shouldSwap: false,
          }),
        ],
        onDragstart: (data) => {
          isDragging.value = true;
        },
        onDragend: (data) => {
          isDragging.value = false;
        },
      };

      const [parent, laborValues] = useDragAndDrop(props.filteredLaborvalues, config);

      const sortDirection = computed(() => {
        if (laborValues.value.length > 0) {
          const copyOfLaborValues = cloneDeep(laborValues.value);

          const laborValueIds = copyOfLaborValues.map((laborValue) => laborValue.id);
          const ascSortedObject = sortList(copyOfLaborValues, "type");

          if (isEqual(laborValueIds, ascSortedObject.sortedIds)) {
            return "asc";
          }

          if (isEqual(laborValueIds, ascSortedObject.sortedIds.reverse())) {
            return "desc";
          }
        }

        return "";
      });

      const newSortDirection = computed(() => {
        if (sortDirection.value === "" || sortDirection.value === "desc") {
          return "asc";
        }

        if (sortDirection.value === "asc") {
          return "desc";
        }

        return "";
      });

      watch(
        () => props.filteredLaborvalues,
        () => {
          laborValues.value = props.filteredLaborvalues;
        },
        {deep: true},
      );

      watch(
        () => laborValues.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = [
              ...filter(props.recordData.laborvalues, (laborvalue) => {
                return mainLaborvalueList.includes(laborvalue.mc_laborvalue_key);
              }).map((laborValue) => laborValue.id),
              ...oldValue.map((laborValue) => laborValue.id),
            ];

            const newSortOrder = [
              ...filter(props.recordData.laborvalues, (laborvalue) => {
                return mainLaborvalueList.includes(laborvalue.mc_laborvalue_key);
              }).map((laborValue) => laborValue.id),
              ...value.map((laborValue) => laborValue.id),
            ];

            if (!isEqual(oldSortOrder, newSortOrder)) {
              updateOrder(newSortOrder);
            }
          }
        },
      );

      const updateOrder = (newSortOrder) => {
        router.post(
          route("sort.store", {
            patient: page.props.patient.id,
            record: page.props.record.id,
            relation: "laborvalues",
          }),
          {
            ids: newSortOrder,
          },
          {
            preserveScroll: true,
            only: ["record", "flash"],
            onFinish: () => {
              isDragging.value = false;
            },
          },
        );
      };

      const sortByName = () => {
        if (!props.isEditable) return;

        const newSortOrder = [
          ...filter(props.recordData.laborvalues, (laborvalue) => {
            return mainLaborvalueList.includes(laborvalue.mc_laborvalue_key);
          }).map((laborValue) => laborValue.id),
        ];

        const sortedObject = sortList(laborValues.value, "type", newSortDirection.value);

        laborValues.value = sortedObject.sortedArray;
        updateOrder(newSortOrder.concat(sortedObject.sortedIds));
      };

      return {
        /** ref */
        parent,

        /** const */
        laborValues,
        isDragging,

        /** computed */
        sortDirection,

        /** function */
        sortByName,
      };
    },
  };
</script>
