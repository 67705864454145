// import helper method to deep merge configs
import {mcConfigDeepMerge} from "./vendor/medicheck/medicheckconfiguration/tailwind.config.js";

// import MediCheck default configuration
import defaultConfig from "./vendor/medicheck/medicheckconfiguration/tailwind.config.js";

// extend default config
export default mcConfigDeepMerge(defaultConfig, {
  theme: {
    extend: {
      keyframes: (theme) => ({
        "fade-in-up": {
          "0%": {
            opacity: "0",
            transform: "translateY(10px)",
          },
          "100%": {
            opacity: "1",
            transform: "translateY(0px)",
          },
        },
        glowing: {
          "0%": {backgroundColor: theme("colors.mcblue.50") + "33"},
          "10%": {backgroundColor: theme("colors.mcblue.100") + "EE"},
          "11%": {backgroundColor: theme("colors.mcblue.200") + "EE"},
          "12%": {backgroundColor: theme("colors.mcblue.100") + "EE"},
          "13%": {backgroundColor: theme("colors.mcblue.200") + "EE"},
          "14%": {backgroundColor: theme("colors.mcblue.100") + "EE"},
          "15%": {backgroundColor: theme("colors.mcblue.200") + "EE"},
          "16%": {backgroundColor: theme("colors.mcblue.100") + "EE"},
          "17%": {backgroundColor: theme("colors.mcblue.200") + "EE"},
          "18%": {backgroundColor: theme("colors.mcblue.100") + "EE"},
          "40%": {backgroundColor: theme("colors.mcblue.50") + "EE"},
          "60%": {backgroundColor: theme("colors.mcblue.50") + "33"},
          "100%": {backgroundColor: theme("colors.transparent")},
        },
      }),
      animation: {
        "fade-in-up": "fade-in-up 0.5s ease-out",
        glow: "glowing 1.5s ease-out 0.5s",
      },
    },
    //safelist: [],
  },
});
