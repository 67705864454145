<template>
  <div class="max-w-screen-2xl mx-auto pb-16">
    <dashboard-components-header class="max-w-screen-xl mx-auto" />

    <div class="mt-8 grid grid-cols-12 gap-8 max-w-screen-xl mx-auto">
      <div
        v-if="$page.props.can.create_records"
        class="min-h-48 col-span-12 sm:col-span-6 lg:col-span-4 relative overflow-visible"
      >
        <dashboard-quick-start-exclusive-widget class="absolute z-20 h-full w-full rounded-b-none" :test-id="testId" />
      </div>

      <dashboard-bmp-import-exclusive-widget
        v-if="$page.props.can.create_records"
        class="col-span-12 sm:col-span-6 lg:col-span-4"
        :test-id="testId"
      />

      <dashboard-patient-count-widget class="col-span-12 sm:col-span-6 lg:col-span-4" :test-id="testId" />

      <dashboard-first-steps-widget-wide
        class="col-span-12 sm:col-span-6"
        :class="{'lg:col-span-12': $page.props.can.create_records, 'lg:col-span-8': !$page.props.can.create_records}"
        :test-id="testId"
      />
    </div>
  </div>
</template>

<script>
  import DashboardBmpImportExclusiveWidget from "@pages/Dashboard/Components/BmpImportExclusiveWidget.vue";
  import DashboardComponentsHeader from "@pages/Dashboard/Components/Header.vue";
  import DashboardFirstStepsWidgetWide from "@pages/Dashboard/Components/FirstStepsWidgetWide.vue";
  import DashboardPatientCountWidget from "@pages/Dashboard/Components/PatientCountWidget.vue";
  import DashboardQuickStartExclusiveWidget from "@pages/Dashboard/Components/QuickStartExclusiveWidget.vue";

  export default {
    name: "ComponentDashboard",

    components: {
      DashboardBmpImportExclusiveWidget,
      DashboardComponentsHeader,
      DashboardFirstStepsWidgetWide,
      DashboardPatientCountWidget,
      DashboardQuickStartExclusiveWidget,
    },
  };
</script>
