<template>
  <table class="mt-3 table-auto w-full">
    <thead>
      <tr class="border-b-2 border-b-gray-400 leading-tight">
        <th />
        <th class="px-0.5 py-2 font-normal text-left w-1/3">Bisherige Daten</th>
        <th class="px-0.5 py-2 font-normal text-left w-1/3">Daten aus Pflegecheckliste</th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(attribute, index) in attributes"
        :key="index"
        class="hover:bg-gray-100 border-t-2 border-gray-200 leading-tight"
      >
        <th class="px-0.5 py-2 font-normal">{{ attribute.label }}</th>
        <td class="px-0.5 py-2" :data-encrypted="attribute.existingPatient" />
        <td class="px-0.5 py-2">{{ attribute.importPatient }}</td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td />
        <td class="px-0.5 py-2">
          <component-button
            class="p4umc-primary"
            :test-id="testId + '-nursing-home-comparison-perform-input'"
            @click="$emit('performInput', existingPatient, false)"
          >
            Daten beibehalten
          </component-button>
        </td>

        <td class="px-0.5 py-2">
          <component-button
            class="p4umc-primary"
            :test-id="testId + '-nursing-home-comparison-perform-input-existing-patient'"
            @click="$emit('performInput', existingPatient, true)"
          >
            Neue Daten übernehmen
          </component-button>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
  import {computed} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "PatientinputClientComponentImportDialogNursingHomeComparison",

    components: {ComponentButton},

    props: {
      existingPatient: {
        type: Object,
        required: true,
      },
      importPatientNursingHomeData: {
        type: Object,
        required: true,
      },
    },

    emits: ["performInput"],

    setup(props) {
      const attributes = computed(() => {
        const existingPatientAttributes = props.existingPatient.patient_attributes.findLast(
          (attr) => attr.type === "nursingHome",
        ).payload;

        return Object.entries(existingPatientAttributes).map(([key, value]) => {
          let label = null;

          switch (key) {
            case "nursing_home_name":
              label = "Pflegeeinrichtung";
              break;

            case "nursing_home_ward":
              label = "Station";
              break;

            case "nursing_home_room":
              label = "Raum";
              break;

            case "nursing_home_carer":
              label = "Pflegefachkraft";
              break;
          }

          const existingValue = Object.entries(props.importPatientNursingHomeData).findLast((data) => data[0] === key);

          return {
            label: label,
            existingPatient: value?.length > 0 ? value : "–",
            importPatient: existingValue && existingValue[1]?.length > 0 ? existingValue[1] : "–",
          };
        });
      });

      return {
        /** computed */
        attributes,
      };
    },
  };
</script>
