<template>
  <div v-if="info" class="py-2 sm:grid sm:grid-cols-4 sm:gap-4">
    <dt class="font-semibold text-gray-900">Weitere Angaben zum Patienten</dt>
    <dd class="mt-1 text-gray-700 sm:col-span-3 sm:mt-0" v-html="info" />
  </div>
</template>

<script>
  import {inject, ref} from "vue";

  export default {
    name: "PagesRecordResultPatientDataAdditionalInfo",
    props: {
      record: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const privacy = inject("$privacy");

      const info = ref(null);

      privacy.decryptValue(props.record.info).then((plaintext) => {
        if (plaintext !== null) {
          info.value = plaintext.replace(/(?:\r\n|\r|\n)/g, "<br />");
        }
      });

      return {info};
    },
  };
</script>
