<template>
  <component-dialog ref="refDialog" :dialog-title="title" dialog-type="information" dialog-width="medium">
    <template #content>
      <p>
        Angezeigt werden die Anzahl automatisiert detektierter Check-Treffer in den Farben rot (Risiken: sehr hoch),
        orange (Risiken: mittel-hoch), gelb (Risiken: gering-mittel) und grau (sonstige Risiken).
      </p>

      <p v-if="mostRiskColor === 'showGreenButton'" class="mt-4">
        Bei diesem FAM wurden keine automatisierten Check-Treffer gefunden. Bitte prüfen Sie bei Bedarf selbst auf
        Relevanz.
      </p>

      <ul v-if="hasStatistic" class="mt-4 space-y-4">
        <li
          v-for="(check, index) in medication.statistic.veryHighRisk"
          :key="'veryHighRisk' + index"
          class="flex items-baseline"
        >
          <component-icon class="mr-0 text-sm mt-0.5">remove</component-icon>
          <div class="text-sm overflow-hidden">
            <span
              v-if="resultConfig.veryhighrisk"
              class="cursor-pointer underline"
              @click="goToAnchor(check.id, 'veryHighRisk')"
            >
              {{ check.name }},
            </span>
            <span v-else>{{ check.name }},</span>
            Risiken: sehr hoch
            <span class="text-red-600">(rot)</span>
            : {{ check.count }} Treffer
          </div>
        </li>

        <li
          v-for="(check, index) in medication.statistic.highRisk"
          :key="'highRisk' + index"
          class="flex items-baseline"
        >
          <component-icon class="mr-0 text-sm mt-0.5">remove</component-icon>
          <div class="text-sm overflow-hidden">
            <span
              v-if="resultConfig.highrisk"
              class="cursor-pointer underline"
              @click="goToAnchor(check.id, 'highRisk')"
            >
              {{ check.name }},
            </span>
            <span v-else>{{ check.name }},</span>
            Risiken: mittel-hoch
            <span class="text-orange-500">(orange)</span>
            : {{ check.count }} Treffer
          </div>
        </li>

        <li
          v-for="(check, index) in medication.statistic.mediumRisk"
          :key="'mediumRisk' + index"
          class="flex items-baseline"
        >
          <component-icon class="mr-0 text-sm mt-0.5">remove</component-icon>
          <div class="text-sm overflow-hidden">
            <span
              v-if="resultConfig.midrisk"
              class="cursor-pointer underline"
              @click="goToAnchor(check.id, 'mediumRisk')"
            >
              {{ check.name }},
            </span>
            <span v-else>{{ check.name }},</span>
            Risiken: gering-mittel
            <span class="text-yellow-500">(gelb)</span>
            : {{ check.count }} Treffer
          </div>
        </li>

        <li v-for="(check, index) in medication.statistic.lowRisk" :key="'lowRisk' + index" class="flex items-baseline">
          <component-icon class="mr-0 text-sm mt-0.5">remove</component-icon>
          <div class="text-sm overflow-hidden">
            <span v-if="resultConfig.midrisk" class="cursor-pointer underline" @click="goToAnchor(check.id, 'lowRisk')">
              {{ check.name }},
            </span>
            <span v-else>{{ check.name }},</span>
            Risiken: sonstige
            <span class="text-gray-500">(grau)</span>
            : {{ check.count }} Treffer
          </div>
        </li>
      </ul>
    </template>
  </component-dialog>
</template>

<script>
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {computed, inject, nextTick, ref} from "vue";
  import {sumBy} from "lodash";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentRiskScoreDialog",

    components: {ComponentIcon, ComponentDialog},

    props: {},

    expose: ["open", "close"],

    setup(props, {emit}) {
      const resultConfig = inject("resultConfig");
      const refDialog = ref(null);

      const title = ref(null);
      const medication = ref(null);

      const hasStatistic = computed(() => {
        // medication is set and statistic is not empty
        return medication.value && Object.keys(medication.value.statistic).length > 0;
      });

      const mostRiskColor = computed(() => {
        if (!hasStatistic.value) return "dontShowButton";

        if (medication.value.statistic.veryHighRisk && sumBy(medication.value.statistic.veryHighRisk, "count") > 0)
          return "veryHighRisk";
        if (medication.value.statistic.highRisk && sumBy(medication.value.statistic.highRisk, "count") > 0)
          return "highRisk";
        if (medication.value.statistic.mediumRisk && sumBy(medication.value.statistic.mediumRisk, "count") > 0)
          return "mediumRisk";
        if (medication.value.statistic.lowRisk && sumBy(medication.value.statistic.lowRisk, "count") > 0)
          return "lowRisk";
        return "showGreenButton";
      });

      function open(med) {
        title.value = "Risiken von " + med.fam;
        medication.value = med;
        refDialog.value.open();
      }

      function close() {
        refDialog.value.close();
      }

      const riskClasses = {
        veryHighRisk: "red",
        highRisk: "orange",
        mediumRisk: "yellow",
        lowRisk: "grey",
        notShownChecks: "notShownChecks",
      };

      function goToAnchor(checkId, risk) {
        let claimClass = riskClasses[risk];

        // close layer
        close();

        let target = document.querySelector(`a[data-risk-goto-target='${claimClass}-${checkId}']`);

        // toggle open (note: aria-expanded value ist a string!)
        if (target.getAttribute("aria-expanded") === "false") {
          target.click();
        }

        nextTick(() => {
          // scroll into view
          target.scrollIntoView();

          if (target.getBoundingClientRect().top < 120) {
            const offset = 120; // offset of fixed header height
            window.scrollBy(0, -offset);
          }

          // highlight with glow animation
          target.closest("li").classList.add("animate-glow");
          setTimeout(() => {
            target.closest("li").classList.remove("animate-glow");
          }, 2000);
        });
      }

      return {
        refDialog,
        resultConfig,
        open,
        close,
        goToAnchor,
        title,
        medication,
        hasStatistic,
        mostRiskColor,
      };
    },
  };
</script>
