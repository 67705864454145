<template>
  <section
    v-if="record.doctorsummary || record.patientsummary || record.usersummary"
    class="hidden print:block space-y-6 border-2 p-2"
  >
    <div v-if="record.doctorsummary" class="flex justify-between space-x-4">
      <div class="hidden [.print-doc_&]:print:block [.print-archive_&]:print:block print:break-inside-avoid-page">
        <strong class="text-lg font-normal">Zusammenfassung an Arzt (Fachkreise):</strong>
        <div class="print:break-before-avoid-page" v-html="record.doctorsummary" />
      </div>

      <div
        v-if="hasThirdParty('p4u') && record.feedback_url"
        class="hidden print:hidden [.print-doc_&]:print:block mt-4"
      >
        <a
          :href="record.feedback_url"
          target="_blank"
          class="p4umc-primary p4umc-btn p-1 rounded border-2 outline-0 font-semibold uppercase hover:no-underline"
        >
          Rückmeldung geben
        </a>
        <div class="mt-2 text-xs leading-tight">zur Zusammenfassung</div>
      </div>
    </div>

    <div
      v-if="record.patientsummary"
      class="hidden [.print-patient_&]:print:block [.print-archive_&]:print:block print:break-inside-avoid-page"
    >
      <strong class="text-lg font-normal">Zusammenfassung an Patient:</strong>
      <div class="print:break-before-avoid-page" v-html="record.patientsummary" />
    </div>

    <div v-if="record.usersummary" class="hidden [.print-archive_&]:print:block print:break-inside-avoid-page">
      <strong class="text-lg font-normal">Notizen:</strong>
      <div class="print:break-before-avoid-page" v-html="record.usersummary" />
    </div>
  </section>
</template>

<script>
  import {inject} from "vue";

  export default {
    name: "PagesRecordResultSummaryForPrint",
    props: {
      record: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const hasThirdParty = inject("$hasThirdParty");

      return {hasThirdParty};
    },
  };
</script>
