export default {
  id: null,
  dosage_string_formatted: "0 - 0 - 0 - 0",
  dosagealternative: null,
  evening: null,
  evening_as_fraction: false,
  evening_formatted: "0",
  info: null,
  meal: null,
  morning: null,
  morning_as_fraction: false,
  morning_formatted: "0",
  night: null,
  night_as_fraction: false,
  night_formatted: "0",
  noon: null,
  noon_as_fraction: false,
  noon_formatted: "0",
  selected_unit: null,
  selected_unit_key_doe: null,
};
