import NumberHelper from "@utils/Helpers/NumberHelper.js";

/**
 *
 */
export default class Gfr {
  constructor() {
    this.missingAttributes = false;
    this.gender = "male";
    this.age = 0;
    this.weight = 0;
    this.creatinine = 0;
  }

  /**
   * Sets the patient gender
   *
   * @param gender
   * @return {Gfr}
   */
  setGender(gender) {
    this.gender = gender;

    return this;
  }

  /**
   * Sets the age
   *
   * @param age
   * @return {Gfr}
   */
  setAge(age) {
    this.age = age;

    return this;
  }

  /**
   * Sets the weight
   *
   * @param weight
   */
  setWeight(weight) {
    this.weight = weight;

    return this;
  }

  /**
   * Sets the creatinie
   *
   * @param creatinine
   * @return {Gfr}
   */
  setCreatinine(creatinine) {
    this.creatinine = creatinine;

    return this;
  }

  /**
   * Calculates the gfr
   *
   * @return {*}
   */
  getGfr(formal) {
    if (this.gender === "" || this.age === "") {
      this.missingAttributes = true;
      return null;
    }

    if (formal === "useCkdEpi") {
      return this._getGfrByCkdEpi();
    } else if (formal === "useCkdEpiBlackEthnic") {
      return this._getGfrByCkdEpiBlackEthnic();
    } else if (formal === "useMayoClinic") {
      return this._getGfrByMayoClinic();
    } else if (formal === "useMdrd") {
      return this._getGfrByMdrd();
    } else if (formal === "useMdrdBlackEthnic") {
      return this._getGfrByMdrdBlackEthnic();
    } else if (formal === "useCockcroftGault") {
      return this._getGfrByCockroftGault();
    }

    return null;
  }

  /**
   * CKD-EPI
   * https://flexikon.doccheck.com/de/CKD-EPI-Formel
   *
   * @return {*}
   * @private
   */
  _getGfrByCkdEpi(round = true) {
    const creatinineFactor = NumberHelper.formatStringAsFloat(this.creatinine);
    const genderFactorK = this.gender === "female" ? 0.7 : 0.9;
    const genderFactorA = this.gender === "female" ? -0.329 : -0.411;
    const genderFactorG = this.gender === "female" ? 1.018 : 1;
    const ageFactor = parseInt(this.age);
    const min = Math.min(creatinineFactor / genderFactorK, 1);
    const max = Math.max(creatinineFactor / genderFactorK, 1);
    const gfr = 141 * Math.pow(min, genderFactorA) * Math.pow(max, -1.209) * Math.pow(0.993, ageFactor) * genderFactorG;

    return round ? NumberHelper.round(gfr) : gfr;

    // To calculate the body surface
    // KOF = Wurzel(Größe * Gewicht / 3600)
  }

  /**
   * CKD-EPI by black ethnicity
   * https://flexikon.doccheck.com/de/CKD-EPI-Formel
   *
   * @return {*}
   * @private
   */
  _getGfrByCkdEpiBlackEthnic() {
    const gfrWithoutBlackEthnic = this._getGfrByCkdEpi(false);
    return NumberHelper.round(gfrWithoutBlackEthnic * 1.159);
  }

  /**
   * Mayo-Klinik-Formel
   * https://flexikon.doccheck.com/de/Mayo-Klinik-Formel
   *
   * @return {*}
   * @private
   */
  _getGfrByMayoClinic() {
    let creatinineFactor = NumberHelper.formatStringAsFloat(this.creatinine);
    const genderFactor = this.gender === "female" ? 0.205 : 0;
    const ageFactor = parseInt(this.age);

    if (creatinineFactor < 0.8) {
      creatinineFactor = 0.8;
    }

    const gfr = Math.exp(
      1.911 + 5.249 / creatinineFactor - 2.114 / Math.pow(creatinineFactor, 2) - 0.00686 * ageFactor - genderFactor,
    );

    return NumberHelper.round(gfr);
  }

  /**
   * MDRD calculation
   * https://flexikon.doccheck.com/de/MDRD-Formel
   *
   * @return {*}
   * @private
   */
  _getGfrByMdrd(round = true) {
    const genderFactor = this.gender === "female" ? 0.742 : 1;
    const ageFactor = Math.pow(parseInt(this.age), -0.203);
    const creatinineFactor = Math.pow(NumberHelper.formatStringAsFloat(this.creatinine), -1.154);

    const gfr = 186 * creatinineFactor * ageFactor * genderFactor;

    return round ? NumberHelper.round(gfr) : gfr;
  }

  /**
   * MDRD black etnic calculation
   * https://flexikon.doccheck.com/de/MDRD-Formel
   *
   * @return {*}
   * @private
   */
  _getGfrByMdrdBlackEthnic() {
    const gfrWithoutBlackEthnic = this._getGfrByMdrd(false);
    return NumberHelper.round(gfrWithoutBlackEthnic * 1.212);
  }

  /**
   * Cockroft Gault
   * https://flexikon.doccheck.com/de/Cockcroft-Gault-Formel
   *
   * @return {*}
   * @private
   */
  _getGfrByCockroftGault() {
    if (this.weight === "" || this.weight === 0) {
      this.missingAttributes = true;
      return null;
    } else {
      this.missingAttributes = false;

      const genderFactor = this.gender === "female" ? 0.85 : 1;
      const ageFactor = 140 - parseInt(this.age);
      const weightFactor = NumberHelper.formatStringAsFloat(this.weight) / 72;
      const creatinineFactor = NumberHelper.formatStringAsFloat(this.creatinine);

      return NumberHelper.round((ageFactor / creatinineFactor) * weightFactor * genderFactor);
    }
  }
}

// Initialize the gfr instance
const gfrCalculator = new Gfr();

export {gfrCalculator};
