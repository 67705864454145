<template>
  <section class="mt-10">
    <div class="p-4 bg-white shadow-sm">
      <div class="mb-4">
        <h2 class="text-2xl font-semibold">Bereits übermittelte Fragebögen bzw. Checklisten</h2>
      </div>

      <!-- filter :: start -->
      <form @submit.prevent="submitFilter">
        <div class="flex justify-between items-baseline">
          <component-input
            v-model="form.q.search"
            autocomplete="off"
            class="max-w-52"
            placeholder="Suche"
            :test-id="testId + '-data-section'"
          >
            <template #icon>
              <component-icon class="text-xl text-gray-600">search</component-icon>
            </template>
          </component-input>

          <component-filter-flags
            v-model="form.flags"
            :available-flags="availableFlags"
            :test-id="testId + '-data-section'"
          />
        </div>
      </form>
      <!-- filter :: end -->

      <article class="mt-4">
        <template v-if="form.processing">
          <div class="w-full grid place-content-center min-h-32">
            <component-spinner class="h-8 w-8" :test-id="testId + '-data-section'" />
          </div>
        </template>

        <template v-else>
          <table class="table-auto w-full">
            <thead>
              <tr class="border-t-2 border-t-gray-200 leading-tight">
                <th class="px-0.5 py-2 font-normal text-left align-top">
                  <div class="">Name, Vorname</div>
                  <div class="text-xs">Geburtsdatum, Versichertennummer</div>
                </th>

                <th class="px-0.5 py-2 font-normal text-left align-top">Herkunft</th>

                <th class="px-0.5 py-2 font-normal text-left align-top">
                  <div>
                    <button
                      class="flex items-center space-x-2 text-left"
                      :data-test="testId + '-data-section-sort-by-status'"
                      @click.prevent="orderBy('-status')"
                    >
                      <span>Bearbeitungsstand</span>
                      <component-icon v-if="form.order === 'status'">expand_less</component-icon>
                      <component-icon v-else-if="form.order === '-status'">expand_more</component-icon>
                      <component-icon v-else>unfold_more</component-icon>
                    </button>
                  </div>
                </th>

                <th class="px-0.5 py-2 font-normal text-left align-top">
                  <div>
                    <button
                      class="flex items-center space-x-2 text-left"
                      :data-test="testId + '-data-section-sort-by-status'"
                      @click.prevent="orderBy('-created_at')"
                    >
                      <span>Eingesendet</span>
                      <component-icon v-if="form.order === 'created_at'">expand_less</component-icon>
                      <component-icon v-else-if="form.order === '-created_at'">expand_more</component-icon>
                      <component-icon v-else>unfold_more</component-icon>
                    </button>
                  </div>
                </th>

                <th />
              </tr>
            </thead>

            <tbody>
              <patientinput-client-component-data-section-table-row
                v-for="transfer in transfers"
                :key="transfer.id"
                :transfer="transfer"
                :test-id="testId + '-data-section'"
                @start-import="startImport"
                @delete="startDelete"
              />
            </tbody>
          </table>
        </template>

        <!-- Paginator -->
        <component-pagination
          v-if="!form.processing && transfersEncrypted.meta"
          :links="transfersEncrypted.meta.links"
          :test-id="testId + '-data-section'"
        />
      </article>
    </div>
  </section>

  <patientinput-client-component-delete-dialog
    ref="refDeleteDialog"
    :transfer="transferToDelete"
    :test-id="testId + '-data-section'"
    @cancel-delete="cancelDelete"
    @perform-delete="performDelete"
  />

  <patientinput-client-component-import-dialog ref="refImportDialog" :test-id="testId + '-data-section'" />
</template>

<script>
  import {computed, inject, onMounted, ref, watch} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";
  import {compactDecrypt, importPKCS8} from "jose";
  import {cloneDeep, debounce} from "lodash";

  import ComponentFilterFlags from "@components/Filter/FilterFlags.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentPagination from "@components/Pagination/Pagination.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  import PatientinputClientComponentDataSectionTableRow from "@pages/PatientinputClient/Components/DataSectionTableRow.vue";
  import PatientinputClientComponentDeleteDialog from "@pages/PatientinputClient/Components/DeleteDialog.vue";
  import PatientinputClientComponentImportDialog from "@pages/PatientinputClient/Components/ImportDialog.vue";

  export default {
    name: "PatientinputClientComponentDataSection",

    components: {
      ComponentFilterFlags,
      ComponentIcon,
      ComponentInput,
      ComponentPagination,
      ComponentSpinner,
      PatientinputClientComponentDataSectionTableRow,
      PatientinputClientComponentDeleteDialog,
      PatientinputClientComponentImportDialog,
    },

    setup() {
      const privacy = inject("$privacy");
      const page = usePage();

      const refImportDialog = ref(null);
      const refDeleteDialog = ref(null);

      const transfers = ref([]);
      const transferToDelete = ref(null);

      const availableFlags = {
        origin: {
          label: "Herkunft",
          nullable: false,
          options: {
            default: "Patientenfragebogen",
            checklist: "Pflegecheckliste",
          },
        },
        status: {
          label: "Bearbeitungsstand",
          nullable: false,
          options: {
            default: "unbearbeitet",
            processed: "in Bearbeitung",
            completed: "abgeschlossen",
            rejected: "gelöscht",
          },
        },
        createdWithinWeek: "Innerhalb der letzten 7 Tage eingesendet",
        createdWithinMonth: "Innerhalb der letzten 30 Tage eingesendet",
      };

      const form = useForm({
        order: page.props?.filters?.order || "-created_at",
        q: {
          search: page.props?.filters?.q?.search || null,
        },
        flags: page.props?.filters?.flags || [],
      });

      const transfersEncrypted = computed(() => page.props.transfersEncrypted);

      watch(
        () => page.props.transfersEncrypted,
        () => {
          filterTransfers();
        },
      );

      watch(
        () => form.order,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      watch(
        () => form.q.search,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      watch(
        () => form.flags,
        debounce(function (newFlags, oldFlags) {
          submitFilter();
        }, 500),
      );

      onMounted(async () => {
        filterTransfers();
      });

      const orderBy = (by) => {
        if (form.order.includes(by)) {
          // already sorted by this colum, so flip direction
          if (by.slice(0, 1) === "-") {
            // asc
            form.order = by.slice(1); // remove "-"
          } else {
            // desc
            form.order = "-" + by; // add "-"
          }
        } else {
          // not already sorted by this column, asc is default
          form.order = by;
        }
      };

      const filterTransfers = () => {
        form.processing = true;

        privacy.whenCryptReady(async () => {
          const privateKeyDecrypted = await privacy.decryptValue(page.props.privateKeyEncrypted);
          const privateKey = await importPKCS8(privateKeyDecrypted, "RSA-OAEP");

          const promises = Array.from(page.props.transfersEncrypted.data).map((encryptedTransfer) => {
            const transfer = cloneDeep(encryptedTransfer);

            return compactDecrypt(transfer.data, privateKey).then(({plaintext}) => {
              const decryptedTransfer = new TextDecoder().decode(plaintext);
              transfer.data = JSON.parse(decryptedTransfer);
              return transfer;
            });
          });

          Promise.all(promises).then((decryptedTransfers) => {
            if (form?.q?.search?.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/)) {
              transfers.value = decryptedTransfers;
            } else {
              const words = (form?.q?.search?.toLowerCase() || "").split(" ").filter((word) => word.trim() !== "");

              transfers.value = words.length
                ? decryptedTransfers.filter((transfer) => {
                    return words.every((word) => {
                      if (/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/.test(word)) {
                        const [day, month, year] = word.split(".");
                        word = year + "-" + month.padStart(2, "0") + "-" + day.padStart(2, "0");
                      }

                      return (
                        (transfer.data.masterdata.patient.lastname &&
                          transfer.data.masterdata.patient.lastname.toLowerCase().includes(word.toLowerCase())) ||
                        (transfer.data.masterdata.patient.firstname &&
                          transfer.data.masterdata.patient.firstname.toLowerCase().includes(word.toLowerCase())) ||
                        (transfer.data.masterdata.patient.birthdate &&
                          transfer.data.masterdata.patient.birthdate.includes(word.toLowerCase())) ||
                        (transfer.data.masterdata.patient.insurancenumber &&
                          transfer.data.masterdata.patient.insurancenumber.toLowerCase().startsWith(word.toLowerCase()))
                      );
                    });
                  })
                : decryptedTransfers;
            }

            form.processing = false;
          });
        });
      };

      const submitFilter = () => {
        form.get(route("patientinput-client.index"), {preserveState: true, preserveScroll: true, replace: true});
      };

      const startImport = (transfer) => {
        refImportDialog.value.open(transfer);
      };

      const startDelete = (transfer) => {
        transferToDelete.value = transfer;
        refDeleteDialog.value.open();
      };

      const cancelDelete = () => {
        transferToDelete.value = null;
      };

      const performDelete = () => {
        form.processing = true;

        router.delete(route("patientinput-client.destroy", {transfer: transferToDelete.value.id}), {
          onSuccess: () => {
            transferToDelete.value = null;
          },
        });
      };

      return {
        /** ref */
        refDeleteDialog,
        refImportDialog,

        /** const */
        transfers,
        transferToDelete,
        form,
        availableFlags,

        /** computed */
        transfersEncrypted,

        /** function */
        submitFilter,
        orderBy,
        startImport,
        startDelete,
        cancelDelete,
        performDelete,
      };
    },
  };
</script>
