<template>
  <div>
    <slot
      :has="hasExistingPatients"
      :has-exact="hasExactExistingPatients"
      :has-proximate="hasProximateExistingPatients"
    />

    <table v-if="showTable" class="mt-3 table-auto w-full">
      <thead>
        <tr class="border-b-2 border-b-gray-200 leading-tight">
          <th class="px-0.5 py-2 font-normal text-left align-top">
            <div class="">Name, Vorname</div>
            <div class="text-xs">Geburtsdatum, Versichertennummer</div>
          </th>

          <th class="px-0.5 py-2 font-normal text-left align-top">
            <div>Letzte Änderung</div>
          </th>

          <th class="px-0.5 py-2 font-normal text-right align-top">
            <div v-if="hasExactExistingPatients && hasProximateExistingPatients" class="inline-flex justify-end">
              <component-checkbox v-model="filterEnabled" :test-id="testId + '-existing-patient-select-filter-enabled'">
                Nur genaue Treffer
              </component-checkbox>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="patient in filteredExistingPatients"
          :key="patient.id"
          class="hover:bg-gray-100 border-t-2 border-gray-200 leading-tight"
        >
          <td class="px-0.5 py-2">
            <div>{{ patient.lastname }}, {{ patient.firstname }}</div>

            <div v-if="patient.birthdate" class="text-xs">
              {{ patient.birthdate }}
              <span v-if="patient.insurancenumber">, {{ patient.insurancenumber }}</span>
            </div>

            <div v-else-if="patient.insurancenumber">{{ patient.insurancenumber }}</div>
          </td>

          <td class="px-0.5">{{ patient.updated_at }}</td>

          <td class="px-0.5">
            <div class="flex justify-end">
              <a
                :href="$route('patients.show', {patient: patient.id})"
                target="_blank"
                :data-test="testId + '-existing-patient-select-details-link'"
              >
                <component-button>Details</component-button>
              </a>

              <component-button
                class="p4umc-primary ms-2"
                :test-id="testId + '-existing-patient-select-existing-patient'"
                @click="selectExistingPatient(patient)"
              >
                Auswählen
              </component-button>
            </div>
          </td>
        </tr>

        <tr v-if="filteredExistingPatients.length === 0">
          <td colspan="3" class="py-2 px-0.5 text-gray-500">
            Es wurden keine vollständig identischen Patienten gefunden. Klicken Sie auf „Nur genaue Treffer“, um auch
            ähnliche Treffe anzuzeigen.
          </td>
        </tr>
      </tbody>

      <tfoot v-if="pagesCount > 1">
        <tr>
          <td colspan="3" class="font-medium">
            <div class="flex items-start justify-between">
              <div class="flex-1 flex">
                <button
                  class="border-t-2 border-t-gray-200 pr-4 pl-0 md:pl-4 py-4 text-sm"
                  :class="{
                    'hover:border-gray-600 hover:text-gray-600': currentPageNum > 0,
                    'hover:cursor-default': currentPageNum === 0,
                  }"
                  :data-test="testId + '-existing-patient-select-prev-page'"
                  @click="prevPage"
                >
                  <component-tooltip tooltip="vorherige Seite" :force-inline="true">
                    <component-icon>arrow_back_ios</component-icon>
                  </component-tooltip>

                  <span class="md:hidden px-2">vorherige Seite</span>
                </button>

                <div class="flex-1 border-t-2 border-t-gray-200" />
              </div>

              <div class="hidden md:flex">
                <button
                  v-for="page in pages"
                  :key="page.page"
                  class="inline-flex items-center border-t-2 border-t-gray-200 p-4 text-sm font-medium hover:no-underline"
                  :class="{
                    'border-t-mcred text-mcred': page.page === currentPageNum,
                    'hover:border-gray-600 hover:text-gray-700': page.page !== currentPageNum && page.page !== null,
                    'hover:cursor-default': page.page === null,
                  }"
                  :data-test="testId + '-existing-patient-select-set-page'"
                  @click="setPage(page.page)"
                >
                  {{ page.label }}
                </button>
              </div>

              <div class="flex-1 flex">
                <div class="flex-1 border-t-2 border-t-gray-200" />

                <button
                  class="border-t-2 border-t-gray-200 pr-4 pl-0 md:pl-4 py-4 text-sm"
                  :class="{
                    'hover:border-gray-600 hover:text-gray-600': currentPageNum < pagesCount - 1,
                    'hover:cursor-default': currentPageNum === pagesCount - 1,
                  }"
                  :data-test="testId + '-existing-patient-select-next-page'"
                  @click="nextPage"
                >
                  <span class="md:hidden px-2">nächste Seite</span>

                  <component-tooltip tooltip="nächste Seite" :force-inline="true">
                    <component-icon>arrow_forward_ios</component-icon>
                  </component-tooltip>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import {computed, onMounted, ref} from "vue";
  import {cloneDeep} from "lodash";

  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "ComponentExistingPatientSelect",

    components: {ComponentIcon, ComponentTooltip, ComponentCheckbox, ComponentButton},

    props: {
      dataTest: {
        type: String,
        default: "component-existingPatientSelect",
      },
      currentPatient: {
        required: true,
        type: Object,
      },
      existingPatients: {
        required: true,
        type: Array,
      },
      showTable: {
        type: Boolean,
        default: true,
      },
    },

    emits: ["existingPatientSelected"],

    setup(props, {emit}) {
      const paginationItemsPerPage = 7;

      const allExistingPatientPages = ref([]);
      const currentPageNum = ref(0);
      const filterEnabled = ref(true);
      const filteredExistingPatientPages = ref([]);

      const pagesCount = computed(() => {
        return (filterEnabled.value ? filteredExistingPatientPages : allExistingPatientPages).value.length;
      });

      const filteredExistingPatients = computed(() => {
        return (
          (filterEnabled.value ? filteredExistingPatientPages : allExistingPatientPages).value[currentPageNum.value] ??
          []
        );
      });

      const hasExistingPatients = computed(() => {
        return allExistingPatientPages.value.length > 0;
      });

      const hasExactExistingPatients = computed(() => {
        return filteredExistingPatientPages.value.length > 0;
      });

      const hasProximateExistingPatients = computed(() => {
        let exactCount = 0;

        filteredExistingPatientPages.value.forEach((page) => {
          exactCount += page.length;
        });

        let proximateCount = 0;

        allExistingPatientPages.value.forEach((page) => {
          proximateCount += page.length;
        });

        return proximateCount > exactCount;
      });

      const pages = computed(() => {
        const count = (filterEnabled.value ? filteredExistingPatientPages : allExistingPatientPages).value.length;

        let p = Array.from({length: count}, (_, i) => ({page: i, label: i + 1}));

        if (count < 5) {
          return p;
        }

        return p
          .map((pC, index) => {
            return index >= 2 &&
              index <= count - 3 &&
              (currentPageNum.value - 1 > index || currentPageNum.value + 1 < index)
              ? {page: null, label: "..."}
              : pC;
          })
          .filter((pC, index, pA) => (typeof pC.label === "number" ? true : typeof pA[index - 1].label === "number"));
      });

      onMounted(() => {
        decryptProps();
      });

      const decryptProps = async () => {
        allExistingPatientPages.value = getExistingPatientPages(props.currentPatient, props.existingPatients, false);
        filteredExistingPatientPages.value = getExistingPatientPages(
          props.currentPatient,
          props.existingPatients,
          true,
        );

        filterEnabled.value = filteredExistingPatientPages.value.length > 0;
      };

      const getExistingPatientPages = (currentPatient, existingPatients, filterOn) => {
        const filteredPatients = filterExistingPatients(
          currentPatient,
          existingPatients,
          filterOn ? ["firstname", "lastname", "gender", "birthdate", "insurancenumber"] : ["birthdate"],
        );

        const patients = filteredPatients.map((patient) => {
          const clonedPatient = cloneDeep(patient);
          const d = new Date(patient.birthdate ?? null);
          clonedPatient.birthdate = isNaN(d.getTime()) ? null : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
          return clonedPatient;
        });

        return Array.from({length: Math.ceil(patients.length / paginationItemsPerPage)}, (v, i) => {
          return patients.slice(i * paginationItemsPerPage, i * paginationItemsPerPage + paginationItemsPerPage);
        });
      };

      const setPage = (page) => {
        if (page !== null) {
          currentPageNum.value = page;
        }
      };

      const prevPage = () => {
        if (currentPageNum.value > 0) {
          currentPageNum.value--;
        }
      };

      const nextPage = () => {
        if (currentPageNum.value < pagesCount.value - 1) {
          currentPageNum.value++;
        }
      };

      const selectExistingPatient = (patient) => {
        emit("existingPatientSelected", patient);
      };

      return {
        /** const */
        currentPageNum,
        filterEnabled,
        filteredExistingPatients,

        /** computed */
        pages,
        pagesCount,
        hasExistingPatients,
        hasExactExistingPatients,
        hasProximateExistingPatients,

        /** function */
        selectExistingPatient,
        setPage,
        prevPage,
        nextPage,
      };
    },
  };
</script>
