<template>
  <li class="grid grid-cols-12 gap-x-2 border-gray-300 animate-pulse py-2">
    <div class="col-span-4 h-[28px] skeleton-input-loader" />
    <div class="col-span-2 h-[28px] skeleton-input-loader" />
    <div class="col-span-2 h-[28px] skeleton-input-loader" />
    <div class="col-span-3 h-[28px] skeleton-input-loader" />
    <div class="col-span-1 h-[28px] skeleton-input-loader" />
  </li>
</template>

<script>
  export default {
    name: "SymptomSkeletonRow",

    setup() {
      const skeletonClass = "m-1 bg-gray-300 rounded-full";

      return {skeletonClass};
    },
  };
</script>
