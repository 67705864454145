export const circumstance = {
  OLDER_PATIENT: 523, // 'Patient (älter, ab 65 Jahre)'
  GERIATRIC_PATIENT: 4589, // 'Patient (geriatrisch, ab 75 Jahre)'
  PREGNANT_WOMAN: 531, // 'Schwangerschaft'
  LACTATING_WOMAN: 532, // 'Stillzeit'
  CLIMACTERIC: 524, // 'Klimakterium’
  POST_CLIMACTERIC: 525, // 'Postklimakterium'
  CHILDBEARING_AGED_WOMAN: 526, // 'Frau im gebärfähigen Alter'
  PROCREATIVE_MAN: 529, // 'Mann im zeugungsfähigen Alter'
  CONTACT_LENS_WEARER: 527, // 'Kontaktlinsenträger'
  ATHLETE: 528, // 'Leistungssportler'
  SMOKER: 530, // 'Raucher'
};
