<template>
  <nav aria-label="Progress" class="sticky top-0 z-10 bg-white">
    <ol role="list" class="flex items-center justify-center">
      <li
        v-for="({name}, stepIdx) in steps"
        :key="stepIdx"
        :class="[stepIdx !== steps.length - 1 ? 'pr-3 sm:pr-10 md:pr-16' : '', 'relative']"
        class="bg-gray-50 py-3"
      >
        <template v-if="stepIdx + 1 < currentStep">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-mcred" />
          </div>
          <button
            class="relative flex h-7 w-7 sm:h-8 sm:w-8 items-center justify-center rounded-full bg-mcred hover:bg-mcred-450 hover:no-underline"
            :title="name"
            :disabled="disabled"
            @click="stepClicked(stepIdx)"
          >
            <component-icon class="sm:text-xl text-white">check</component-icon>
            <span class="sr-only">{{ name }}</span>
          </button>
        </template>

        <template v-else-if="stepIdx + 1 === currentStep">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-200" />
          </div>
          <button
            class="relative flex h-7 w-7 sm:h-8 sm:w-8 items-center justify-center rounded-full border-2 border-mcred text-mcred bg-white hover:no-underline cursor-default"
            aria-current="step"
            :title="name"
            :disabled="disabled"
            tabindex="-1"
          >
            <span aria-hidden="true">{{ stepIdx + 1 }}</span>
            <span class="sr-only">{{ name }}</span>
          </button>
        </template>

        <template v-else>
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-200" />
          </div>
          <button
            class="group relative flex h-7 w-7 sm:h-8 sm:w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:no-underline cursor-default"
            :title="name"
            :disabled="disabled"
            tabindex="-1"
          >
            <span aria-hidden="true" class="text-gray-300">{{ stepIdx + 1 }}</span>
            <span class="sr-only">{{ name }}</span>
          </button>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "PatientinputComponentStepper",

    components: {ComponentIcon},

    props: {
      steps: {
        type: Array,
        required: true,
      },
      currentStep: {
        type: Number,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["step-clicked"],

    setup(props, ctx) {
      const stepClicked = (stepIdx) => {
        if (!props.disabled) {
          ctx.emit("step-clicked", stepIdx + 1);
        }
      };

      return {stepClicked};
    },
  };
</script>
