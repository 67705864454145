<template>
  <div v-if="isLoading" class="flex flex-col items-center">
    <component-spinner class="h-12 w-12 mt-8" />
  </div>
  <p v-else v-html="text" />
</template>

<script>
  import {onBeforeMount, ref, inject, reactive} from "vue";

  import ComponentSpinner from "@components/Spinner.vue";
  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  export default {
    name: "ComponentCoreInfoDialogText",

    components: {ComponentSpinner},

    props: {
      data: {
        type: Object,
        required: true,
      },
      dialogType: {
        type: String,
        default: "",
      },
    },

    setup(props) {
      const text = ref("");
      const isLoading = ref(true);
      const coreLegacyDetailsData = reactive({
        dosage: {
          errorMessage: "Kein Text gefunden.",
          key: props.data?.key,
        },
        fi: {
          errorMessage: "Keine Dokumente gefunden.",
          key: props.data?.abdata_key_fam,
        },
        interaction: {
          errorMessage: "Kein Text gefunden.",
          key: props.data?.key,
        },
        guideline: {
          errorMessage: "Kein Text gefunden.",
          key: props.data?.key,
          medicationType: props.data?.medicationType,
        },
      });

      onBeforeMount(() => {
        loadMedicalInfoText();
      });

      const loadMedicalInfoText = () => {
        switch (props.dialogType) {
          case "dosage":
          case "fi":
          case "interaction":
          case "guideline":
            if (props.data?.url) loadContentFromUrl(props.data.url);
            else loadLegacyContentFromUrl();
            break;
          case "splitable":
          case "suspendability":
          case "mortarability":
            loadContentFromUrl(props.data.url);
            break;
        }
      };

      const loadContentFromUrl = (url) => {
        coreRouter.get(url, {
          onSuccess: (response) => {
            if (response) {
              isLoading.value = false;
              text.value = new DOMParser().parseFromString(response, "text/html").querySelector("main").innerHTML;
            }
          },
          onError: () => {
            isLoading.value = false;
            text.value = coreLegacyDetailsData[props.dialogType].errorMessage ?? "Keine Inhalte gefunden.";
          },
        });
      };

      const loadLegacyContentFromUrl = () => {
        coreRouter.loadDetailsFromLegacyLinks(props.dialogType, coreLegacyDetailsData[props.dialogType].key, {
          onSuccess: (response) => {
            isLoading.value = false;
            text.value = new DOMParser().parseFromString(response, "text/html").querySelector("main").innerHTML;
          },
          onError: () => {
            isLoading.value = false;
            text.value = coreLegacyDetailsData[props.dialogType].errorMessage ?? "Keine Inhalte gefunden.";
          },
        });
      };

      return {text, isLoading};
    },
  };
</script>
