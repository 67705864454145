<template>
  <form :data-test="testId + '-mobility-attribute'" @submit.prevent="save">
    <component-card class="relative group hover:bg-gray-100" :data-test="testId + '-mobility-attribute-form'">
      <div class="flex items-baseline justify-between">
        <h6 class="text-xl">Eingeschränkte Mobilität</h6>

        <component-spinner
          v-if="form.processing"
          class="size-4"
          :data-test="testId + '-mobility-attribute-form-card'"
        />
        <component-unsaved-changes v-else-if="form.isDirty || form.recentlySuccessful" :form="form" />
        <span v-else class="text-xs text-gray-500">Aktualisiert {{ attribute.updated_at }}</span>
      </div>

      <template v-if="activeEdit">
        <ul class="mt-4 flex flex-col space-y-4">
          <li v-for="(label, key) in mobilityRestriction" :key="key">
            <component-checkbox v-model="form.payload[key]" :data-test="testId + '-mobility-attribute-form-card-key'">
              {{ label }}
            </component-checkbox>
          </li>
        </ul>
      </template>

      <template v-else>
        <ul class="mt-0.5 flex flex-col">
          <template v-for="(label, key) in mobilityRestriction" :key="key">
            <li v-if="form.payload[key]">{{ label }}</li>
          </template>
        </ul>

        <div class="absolute hidden group-hover:flex right-2 bottom-2 flex-row-reverse space-x-4 space-x-reverse">
          <component-icon-button
            type="button"
            class="p4umc-primary"
            icon="edit"
            :data-test="testId + '-mobility-attribute-form-card-edit'"
            @click="activeEdit = true"
          />

          <component-icon-button
            type="button"
            icon="delete"
            :data-test="testId + '-mobility-attribute-form-card-delete'"
            @click="confirmRemove()"
          />
        </div>
      </template>

      <template v-if="activeEdit" #actions>
        <component-button
          class="p4umc-primary"
          label="Speichern"
          :disabled="form.processing"
          :data-test="testId + '-mobility-attribute-form-card-save'"
        />

        <component-button
          type="button"
          label="Abbrechen"
          :disabled="form.processing"
          :data-test="testId + '-mobility-attribute-form-cancel-edit'"
          @click="cancelEdit()"
        />
      </template>
    </component-card>
  </form>

  <component-confirmation-dialog
    ref="refConfirmationDialog"
    title="Sind Sie sicher?"
    content="Die Angabe „Mobilität“ wird gelöscht."
    :data-test="testId + '-mobility-attribute'"
    @confirmed="remove()"
  />
</template>

<script>
  import {inject, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import {mobilityRestriction} from "@pages/Patients/Components/PatientAttributes/Enums.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";

  export default {
    name: "PatientsPatientAttributesMobilityAttribute",

    components: {
      ComponentButton,
      ComponentCard,
      ComponentCheckbox,
      ComponentConfirmationDialog,
      ComponentIconButton,
      ComponentSpinner,
      ComponentUnsavedChanges,
    },

    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      recentlyAdded: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const refConfirmationDialog = ref(null);

      const activeEdit = ref(props.recentlyAdded);
      const form = useForm({
        type: props.attribute.type,
        payload: {
          bedridden: props.attribute?.payload?.bedridden ?? false,
          rollator: props.attribute?.payload?.rollator ?? false,
          stick: props.attribute?.payload?.stick ?? false,
          wheelchair: props.attribute?.payload?.wheelchair ?? false,
        },
      });

      watch(
        () => props.recentlyAdded,
        (newValue) => {
          activeEdit.value = newValue;
        },
      );

      function cancelEdit() {
        // was recently added AND was NOT saved once
        if (props.recentlyAdded && !form.wasSuccessful) {
          remove();
        } else {
          form.reset();
          activeEdit.value = false;
        }
      }

      function save() {
        form.put(route("attributes.update", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            activeEdit.value = false;
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      function confirmRemove() {
        refConfirmationDialog.value.open();
      }

      function remove() {
        form.delete(route("attributes.destroy", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "updated"});
          },
        });
      }

      return {
        /** enum */
        mobilityRestriction,

        /** ref */
        refConfirmationDialog,

        /** const */
        activeEdit,
        form,

        /** function */
        cancelEdit,
        save,
        confirmRemove,
        remove,
      };
    },
  };
</script>
