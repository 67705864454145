<template>
  <patientinput-component-scanner-dialog
    ref="refScannerContainer"
    dialog-title="Medikament (PZN) scannen"
    dialog-type="information"
    :formats="formats"
    loader-class="aspect-video"
    scanner-class="aspect-video"
    :paused="paused"
    :test-id="testId + '-pzn-scanner'"
    @detect="onDetect"
    @ok-button-clicked="$emit('ready')"
  >
    <p>Bitte scannen Sie den Strichcode oder quadratischen Datamatrix-Code Ihres Medikaments.</p>

    <template #paused>
      <component-alert
        v-if="scannedPzn && scannedPzn !== 'alreadyScanned'"
        type="succes"
        class="w-full mx-3 !mb-0"
        :test-id="testId + '-pzn-scanner'"
      >
        <p>
          PZN
          <span class="font-mono">{{ scannedPzn }}</span>
          wurde erkannt.
        </p>

        <p class="mt-2">
          <component-button
            class="p4umc-primary"
            :test-id="testId + '-pzn-scanner-continue-scan'"
            @click="paused = false"
          >
            Weiterscannen
          </component-button>
        </p>

        <p class="mt-2">
          <component-button :test-id="testId + '-pzn-scanner-wrong-pzn-scanned'" @click="wrongPznScanned">
            Falsch? Erneut versuchen
          </component-button>
        </p>
      </component-alert>

      <component-alert
        v-else-if="scannedPzn === 'alreadyScanned'"
        type="info"
        class="w-full mx-3 !mb-0"
        :test-id="testId + '-pzn-scanner'"
      >
        <p class="mb-2">Sie haben dieses Medikament bereits gescannt bzw. in Ihrem Medikationsplan.</p>

        <p>
          <component-button
            class="p4umc-primary"
            :test-id="testId + '-pzn-scanner-continue-scan'"
            @click="paused = false"
          >
            Weiterscannen
          </component-button>
        </p>
      </component-alert>

      <component-alert v-else type="error" class="w-full mx-3 !mb-0" :test-id="testId + '-pzn-scanner'">
        <p class="mb-2">Es wurde keine gültige PZN erkannt.</p>

        <p>
          <component-button class="p4umc-primary" :test-id="testId + '-pzn-scanner-scan-again'" @click="paused = false">
            Erneut versuchen
          </component-button>
        </p>
      </component-alert>
    </template>
  </patientinput-component-scanner-dialog>
</template>

<script>
  import {ref} from "vue";
  import {Symbology} from "scandit-web-datacapture-barcode";

  import Pzn from "@utils/Pzn.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  import PatientinputComponentScannerDialog from "@pages/Patientinput/Components/Scanners/ScannerDialog.vue";

  export default {
    name: "PatientinputComponentPznScannerDialog",

    components: {
      PatientinputComponentScannerDialog,
      ComponentButton,
      ComponentAlert,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["ready"],

    setup(props) {
      const refScannerContainer = ref(null);
      const paused = ref(false);
      const scannedPzn = ref(null);

      const formats = [Symbology.Code39, Symbology.DataMatrix, Symbology.Code128];

      const open = () => {
        paused.value = false;
        scannedPzn.value = null;
        refScannerContainer.value.open();
      };

      const close = () => {
        refScannerContainer.value.close();
      };

      const onDetect = (detectedCodes) => {
        if (detectedCodes.length === 1 && Pzn.validatePzn(detectedCodes[0]._data)) {
          if (
            props.form.medication.pzns.some((pzn) => parseInt(pzn) === parseInt(Pzn.getPzn(detectedCodes[0]._data)))
          ) {
            scannedPzn.value = "alreadyScanned";
          } else {
            scannedPzn.value = Pzn.getPzn(detectedCodes[0]._data);
            props.form.medication.pzns.push(scannedPzn.value);
          }
        } else {
          scannedPzn.value = null;
        }

        paused.value = true;
      };

      const wrongPznScanned = () => {
        props.form.medication.pzns.pop();
        paused.value = false;
      };

      return {
        /** ref */
        refScannerContainer,

        /** const */
        paused,
        scannedPzn,
        formats,

        /** function */
        open,
        close,
        onDetect,
        wrongPznScanned,
      };
    },
  };
</script>
