export default class Pzn {
  /**
   *
   * @param {String} scannedBarcode
   * @return {boolean}
   */
  static validatePzn(scannedBarcode) {
    return !!Pzn.getPzn(scannedBarcode);
  }

  static getPzn(scannedBarcode) {
    return Pzn.getPznFromCode39(scannedBarcode) || Pzn.getPznFromDatamatrix(scannedBarcode);
  }

  /**
   * @private
   * @param pzn
   * @return {boolean}
   */
  static validatePznCheckdigit(pzn) {
    const pznParts = pzn.split("");

    let sum = 0;

    for (let i = 0; i < pznParts.length - 1; i++) {
      sum += parseInt(pznParts[i]) * (i + 1);
    }

    return parseInt(pznParts[pznParts.length - 1]) === sum % 11;
  }

  /**
   * @private
   * @param scannedCode39
   * @return {*|null}
   */
  static getPznFromCode39(scannedCode39) {
    const regex = new RegExp("-\\d{7,8}", "g");

    if (!regex.test(scannedCode39)) {
      return null;
    }

    let pzn = scannedCode39.replace("-", "");

    if (pzn.length === 7) {
      pzn = `0${pzn}`;
    }

    if (!Pzn.validatePznCheckdigit(pzn)) {
    } else {
      return scannedCode39.replace("-", "");
    }
  }

  /**
   * @private
   * @param scannedCode39
   * @return {boolean}
   */
  static validateCode39Pzn(scannedCode39) {
    return !!Pzn.getPznFromCode39(scannedCode39);
  }

  static validateNtin(ntin) {
    const ntinParts = ntin.split("");

    let sum = 0;

    for (let i = 0; i < ntinParts.length - 1; i++) {
      sum += parseInt(ntinParts[i]) * (i === 0 || i % 2 === 0 ? 3 : 1);
    }

    const nextTen = Math.ceil(sum / 10) * 10;

    return parseInt(ntinParts[ntinParts.length - 1]) === nextTen - sum;
  }

  /**
   *
   * @param {String} scannedDatamatrix
   */
  static getPznFromGs1(scannedDatamatrix) {
    const regex = new RegExp("04150\\d{9}", "g");
    const results = regex.exec(scannedDatamatrix);

    if (results?.length !== 1) {
      return null;
    } else if (!Pzn.validateNtin(results[0])) {
      return null;
    }

    const pzn = results[0].slice(5, -1);

    if (!Pzn.validatePznCheckdigit(pzn)) {
      return null;
    } else {
      return pzn;
    }
  }

  static validatePpn(ppn) {
    const ntinParts = ppn.split("");

    let sum = 0;

    for (let i = 0; i < ntinParts.length - 2; i++) {
      sum += ntinParts[i].charCodeAt(0) * (i + 2);
    }

    return parseInt(`${ntinParts[ntinParts.length - 2]}${ntinParts[ntinParts.length - 1]}`) === sum % 97;
  }

  static getPznFromAsc(scannedDatamatrix) {
    const regex = new RegExp("11\\d{10}", "g");
    const results = regex.exec(scannedDatamatrix);

    if (results?.length !== 1) {
      return null;
    } else if (!Pzn.validatePpn(results[0])) {
      return null;
    }

    const pzn = results[0].slice(2, -2);

    if (!Pzn.validatePznCheckdigit(pzn)) {
      return null;
    } else {
      return pzn;
    }
  }

  /**
   * @private
   * @param scannedDatamatrix
   */
  static getPznFromDatamatrix(scannedDatamatrix) {
    return Pzn.getPznFromGs1(scannedDatamatrix) || Pzn.getPznFromAsc(scannedDatamatrix);
  }

  /**
   * @private
   * @param scannedDatamatrix
   * @return {boolean}
   */
  static validateDatamatrixPzn(scannedDatamatrix) {
    return !!Pzn.getPznFromDatamatrix(scannedDatamatrix);
  }
}
