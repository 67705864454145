/**
 * @see App\Models\Medication\Enum\Usage
 * @type {{0: string, 1: string, 2: string}}
 */
export const medicationUsage = {
  0: "Bedarfsmedikation",
  1: "Kurzzeitmedikation",
  2: "Dauermedikation",
};

/**
 * @see App\Models\Medication\Enum\Meal
 * @type {{A11: string, A22: string, A04: string, A07: string}}
 */
export const medicationMeal = {
  A04: "vor den Mahlzeiten",
  A11: "zwischen den Mahlzeiten",
  A07: "zu oder nach den Mahlzeiten",
  A22: "unabhängig von den Mahlzeiten",
};

/**
 * @see App\Services\DosageUnit\DosageUnitMapper
 * @type {{keyDoe: number, unit: string}}
 */
export const dosageUnits = [
  {keyDoe: 19, unit: "Messlöffel"},
  {keyDoe: 17, unit: "Messbecher"},
  {keyDoe: 26, unit: "Stück"},
  {keyDoe: 31, unit: "Pkg."},
  {keyDoe: 10, unit: "Flasche"},
  {keyDoe: 3, unit: "Beutel"},
  {keyDoe: 13, unit: "Hub"},
  {keyDoe: 29, unit: "Tropfen"},
  {keyDoe: 28, unit: "Teelöffel"},
  {keyDoe: 9, unit: "Esslöffel"},
  {keyDoe: 7, unit: "E"},
  {keyDoe: 27, unit: "Tasse"},
  {keyDoe: 1, unit: "Applikatorfüllung"},
  {keyDoe: 2, unit: "Augenbadewanne"},
  {keyDoe: 4, unit: "Dosierbriefchen"},
  {keyDoe: 5, unit: "Dosierpipette"},
  {keyDoe: 6, unit: "Dosierspritze"},
  {keyDoe: 8, unit: "Einzeldosis"},
  {keyDoe: 11, unit: "Glas"},
  {keyDoe: 15, unit: "Likörglas"},
  {keyDoe: 18, unit: "Messkappe"},
  {keyDoe: 20, unit: "Messschale"},
  {keyDoe: 22, unit: "Mio E"},
  {keyDoe: 23, unit: "Mio IE"},
  {keyDoe: 24, unit: "Pipettenteilstrich"},
  {keyDoe: 25, unit: "Sprühstoß"},
  {keyDoe: 14, unit: "IE"},
  {keyDoe: 30, unit: "cm"},
  {keyDoe: 16, unit: "l"},
  {keyDoe: 21, unit: "ml"},
  {keyDoe: 12, unit: "g"},
  {keyDoe: 37, unit: "kg"},
  {keyDoe: 38, unit: "mg"},
];

export const daysNumber = {
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag",
  5: "Freitag",
  6: "Samstag",
  7: "Sonntag",
};

export const monthNumber = {
  1: "Januar",
  2: "Februar",
  3: "März",
  4: "April",
  5: "Mai",
  6: "Juni",
  7: "Juli",
  8: "August",
  9: "September",
  10: "Oktober",
  11: "November",
  12: "Dezember",
};
