<template>
  <component
    :is="href ? 'inertia-link' : 'button'"
    :href="href"
    :class="{'!no-underline': href}"
    class="p4umc-btn overflow-hidden p-1 rounded-full border-2 focus-visible:ring ring-red-500 outline-0 disabled:text-gray-400 disabled:border-gray-200 disabled:bg-gray-200 disabled:hover:bg-gray-200"
    :data-test="testId + (href ? '-inertia-link' : '-icon-button')"
    @click="$emit('click')"
  >
    <slot>
      <component-icon>{{ icon }}</component-icon>
    </slot>
  </component>
</template>

<script>
  import {Link as InertiaLink} from "@inertiajs/vue3";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentIconButton",

    components: {
      InertiaLink,
      ComponentIcon,
    },

    props: {
      icon: {
        type: String,
        default: "call_to_action",
      },
      href: {
        type: String,
        default: null,
      },
    },

    emits: ["click"],
  };
</script>
