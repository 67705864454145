<template>
  <component-dialog
    ref="refDialog"
    dialog-width="small"
    :dialog-title="form.wasSuccessful ? 'Widerruf erfolgreich' : 'Sind Sie sicher?'"
    :test-id="testId + '-record-share-revoke'"
  >
    <template #content>
      <div v-if="form.wasSuccessful">
        <span v-if="currentNotification.audience === 'group'">Der Seminarlink wurde deaktiviert.</span>
        <span v-else>Die Freigabe wurde dem Empfänger wieder entzogen.</span>
      </div>

      <div v-else>
        <template v-if="currentNotification.audience === 'group'">
          <p>
            Wenn Sie die Freigabe widerrufen, wird der Seminarlink deaktiviert. Bisher damit geteilte Fälle bleiben den
            Teilnehmern erhalten.
          </p>
          <p class="mt-4">Wenn Sie das nicht möchten, können Sie zusätzlich die Fälle einzeln widerrufen.</p>
        </template>
        <template v-else>
          <p>Wenn Sie die Freigabe widerrufen, hat der Empfänger keinen Zugriff mehr auf diese Variante.</p>
        </template>
      </div>
    </template>

    <template #actions>
      <component-button
        v-if="!form.wasSuccessful"
        :disabled="form.processing"
        class="p4umc-primary"
        label="Widerrufen"
        :test-id="testId + '-record-share-revoke-dialog-revoke'"
        @click="revoke()"
      />

      <component-button
        :label="form.wasSuccessful ? 'Schließen' : 'Abbrechen'"
        :test-id="testId + '-record-share-revoke-dialog-close'"
        @click="close()"
      />
    </template>
  </component-dialog>
</template>

<script>
  import {ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "ComponentRecordShareRevokeDialog",

    components: {
      ComponentButton,
      ComponentDialog,
    },

    props: {
      record: {
        type: Object,
        default: () => {
          return null;
        },
      },
      notification: {
        type: Object,
        default: () => {
          return null;
        },
      },
    },

    expose: ["open", "close"],

    emits: ["revoked"],

    setup(props, {emit}) {
      const refDialog = ref(null);

      const currentRecord = ref(props.record);
      const currentNotification = ref(props.notification);

      let form = useForm({});

      watch(
        () => props.record,
        (record) => {
          currentRecord.value = record;
        },
      );

      function open(notification) {
        if (typeof notification !== "undefined") {
          form.reset();
          form.wasSuccessful = false;
          currentNotification.value = notification;
        }
        refDialog.value.open();
      }

      function close() {
        refDialog.value.close();
      }

      function revoke() {
        form.delete(
          route("sharedrecords.destroy", {
            patient: currentRecord.value.patient_id,
            record: currentRecord.value.id,
            sharedrecord: currentNotification.value.sharedrecord_id,
          }),
          {
            preserveScroll: true,
            onSuccess: () => {
              emit("revoked");
            },
          },
        );
      }

      return {
        /** ref */
        refDialog,

        /** const */
        currentNotification,
        form,

        /** function */
        open,
        close,
        revoke,
      };
    },
  };
</script>
