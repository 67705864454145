<template>
  <div class="shadow-md border-1 rounded-lg bg-white flex flex-col" :data-test="testId + '-widget'">
    <h3 v-if="title || hasTitleSlot" class="px-4 mt-4 font-semibold text-2xl" :data-test="testId + '-widget-title'">
      <slot name="title" />
      {{ title }}
    </h3>

    <div class="flex-1 p-4" :data-test="testId + '-widget-content'">
      <slot name="default" />
    </div>

    <div
      v-if="hasActionsSlot && !hideActions"
      class="p-4 bg-gray-50 flex flex-row-reverse space-x-2 space-x-reverse"
      :data-test="testId + '-widget-action'"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";

  export default {
    name: "DashboardWidget",

    props: {
      title: {
        type: String,
        default: null,
      },
      hideActions: {
        type: Boolean,
        default: false,
      },
    },

    setup(props, {slots}) {
      const hasActionsSlot = computed(() => !!slots.actions);
      const hasTitleSlot = computed(() => !!slots.title);

      return {
        /** computed */
        hasActionsSlot,
        hasTitleSlot,
      };
    },
  };
</script>
