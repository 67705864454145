import _ from "lodash";

export function updateCompleteForm(form, newData) {
  form.abdata_key_fam = newData.abdata_key_fam;
  form.additionalinformation = newData.additionalinformation;
  form.core = newData.core ?? form.core;
  form.deviantmedication = newData.deviantmedication;
  form.dosage = newData.dosage;
  form.fam = newData.fam;
  form.form = newData.form;
  form.id = newData.id;
  form.indication_unknown = newData.indication_unknown;
  form.indications = newData.indications;
  form.row = newData.row;
  form.selected_package = newData.selected_package;
  form.selected_package_pzn = newData.selected_package_pzn;
  form.statistic = newData.statistic;
  form.substances = newData.substances;
  form.usage = newData.usage;
}

export function updateFormSingleValue(form, key, newValue) {
  form[key] = newValue;
}

export function updateForm(form, key, value) {
  const keys = key.split(".");

  if (keys.length === 1) {
    form[key] = value;
    return;
  }

  let currentObj = form;

  for (let i = 0; i < keys.length - 1; i++) {
    if (!currentObj[keys[i]]) {
      currentObj[keys[i]] = {};
    }

    currentObj = currentObj[keys[i]];
  }

  currentObj[keys[keys.length - 1]] = value;
}
