export function updateCompleteForm(form, newData) {
  form.id = newData.id;
  form.type = newData.type;
  form.date = newData.date;
  form.deviation = newData.deviation;
  form.value = newData.value;
  form.mc_laborvalue_id = newData.mc_laborvalue_id;
  form.mc_laborvalue_key = newData.mc_laborvalue_key;
  form.mc_unit_id = newData.mc_unit_id;
  form.unit = newData.unit;
  form.reference_symptoms = newData.core.referenceSymptoms;
  form.core = newData.core ?? {};
}
