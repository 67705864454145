<template>
  <div :class="{'flex flex-wrap space-x-1.5': inline}">
    <h6 class="text-gray-600" :class="{'font-semibold': inline, 'text-xs uppercase': !inline}">
      Sondenpatient{{ inline ? ":" : "" }}
    </h6>
    <div :class="{'mt-0.5 text-sm': !inline}">
      <span v-if="tubePatientTubePosition[attribute.payload?.tube_position]">
        Endlage im {{ tubePatientTubePosition[attribute.payload?.tube_position] }}.
      </span>
      <span v-else>Der Patient hat eine Sonde.</span>
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";
  import {tubePatientTubeModel, tubePatientTubePosition} from "@pages/Patients/Components/PatientAttributes/Enums.js";

  export default {
    name: "ComponentPatientSidebarDegreeOfCareAttribute",
    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      return {
        tubePatientTubeModel,
        tubePatientTubePosition,
      };
    },
  };
</script>
