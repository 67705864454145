<template>
  <component-dropdown ref="refDropdown" class="p-px rounded-full" :class="dropdownColorClass" alignment="left">
    <template #button>
      <div class="flex items-center justify-end">
        <component-status-badge
          class="whitespace-nowrap"
          :label="statusName[form.status || 'default']"
          :color="badgeColor"
        />
        <component-icon class="-ml-0.5">expand_more</component-icon>
      </div>
    </template>
    <template #dropdown>
      <ul class="bg-white flex-row space-y-px text-xs text-gray-900">
        <li>
          <button class="w-full whitespace-nowrap px-1 py-1 bg-gray-100 hover:bg-gray-200" @click="save(null)">
            offen
          </button>
        </li>
        <li>
          <button
            class="w-full whitespace-nowrap px-1 py-1 bg-orange-100 hover:bg-orange-200"
            @click="save('inProgress')"
          >
            in Bearbeitung
          </button>
        </li>
        <li>
          <button
            class="w-full whitespace-nowrap px-1 py-1 bg-mcgreen-100 hover:bg-mcgreen-200"
            @click="save('completed')"
          >
            abgeschlossen
          </button>
        </li>
        <li>
          <button
            class="w-full whitespace-nowrap px-1 py-1 bg-mcblue-100 hover:bg-mcblue-200"
            @click="save('discarded')"
          >
            irrelevant
          </button>
        </li>
      </ul>
    </template>
  </component-dropdown>
</template>
<script>
  import {useForm} from "@inertiajs/vue3";
  import {computed, ref, watch} from "vue";
  import ComponentStatusBadge from "@components/Badges/StatusBadge.vue";
  import ComponentDropdown from "@components/Dropdown/Dropdown.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentRecordStatus",

    components: {ComponentIcon, ComponentDropdown, ComponentStatusBadge},

    props: {
      record: {
        type: Object,
        default: () => {},
      },
    },

    setup(props, {emit}) {
      const refDropdown = ref(null);

      const form = useForm({
        status: props.record?.status,
      });

      watch(
        () => props.record,
        (record) => {
          form.status = record?.status;
        },
      );

      const statusName = {
        inProgress: "in Bearbeitung",
        discarded: "irrelevant",
        completed: "abgeschlossen",
        default: "offen",
      };

      function save(status) {
        let old = form.status;
        form.status = status;
        refDropdown.value.close();

        form.patch(route("records.update", {patient: props.record.patient_id, record: props.record.id}), {
          preserveScroll: true,
          headers: {
            // avoid updating the edited_at values when just changing the status
            "Mc-Silent-Update": true,
          },
          onError: () => {
            form.status = old;
          },
        });
      }

      const badgeColor = computed(() => {
        switch (form.status) {
          case "inProgress":
            return "orange";
          case "discarded":
            return "blue";
          case "completed":
            return "green";
          default:
            return "gray";
        }
      });

      const dropdownColorClass = computed(() => {
        switch (form.status) {
          case "inProgress":
            return "bg-orange-100 text-orange-800 hover:bg-orange-300";
          case "discarded":
            return "bg-mcblue-100 text-mcblue-800 hover:bg-mcblue-300";
          case "completed":
            return "bg-mcgreen-100 text-mcgreen-800 hover:bg-mcgreen-300";
          default:
            return "bg-gray-100 text-gray-800 hover:bg-gray-300";
        }
      });

      return {refDropdown, form, save, badgeColor, dropdownColorClass, statusName};
    },
  };
</script>
