<template>
  <section class="print:h-[3.5cm]">
    <div class="flex justify-around items-start">
      <header class="flex flex-col items-center">
        <h2 class="text-3xl font-semibold print:font-normal">Medikationsanalyse</h2>

        <div v-if="hasThirdParty('p4u')" class="text-md md:text-lg text-center">
          <span class="print:hidden">Analyse ({{ analysisTypes[resultConfig.analysistype] }})</span>

          <template v-if="record.shared_with_me === false">
            <span v-if="$page.props.client?.company">
              <span class="print:hidden">von:</span>
              <span class="hidden print:inline">Lizenziert für</span>
              {{ $page.props.client?.company }}
            </span>

            <address class="not-italic">
              <template v-if="$page.props.client?.zip || $page.props.client?.city">
                {{ $page.props.client?.address }},
              </template>
              <template v-else>
                {{ $page.props.client?.address }}
              </template>
              {{ $page.props.client?.zip }} {{ $page.props.client?.city }}
            </address>
          </template>
        </div>
      </header>
    </div>

    <div class="hidden text-center mt-2 [.print-doc_&]:print:block">Arzt-Dokument</div>

    <div class="hidden text-center mt-2 [.print-patient_&]:print:block">Patienten-Dokument</div>

    <div class="hidden text-center mt-2 [.print-archive_&]:print:block">
      Archiv-Dokument (nur zur internen Dokumentation; alle Ergebnisse)
    </div>
  </section>
</template>

<script>
  import {inject} from "vue";
  import {analysisTypes} from "@pages/Records/Components/Result/Enums.js";

  export default {
    name: "PagesRecordResultHead",

    props: {
      record: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    setup() {
      const hasThirdParty = inject("$hasThirdParty");
      const resultConfig = inject("resultConfig");
      return {hasThirdParty, analysisTypes, resultConfig};
    },
  };
</script>
