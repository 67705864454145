<template>
  <section class="mt-6 bg-white max-w-screen-xl">
    <h2 class="sr-only text-2xl font-semibold">Einstellungen</h2>
    <article class="p-4 flex items-start flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
      <!-- article::main -->
      <div class="w-full lg:max-w-screen-md space-y-6">
        <component-card
          title="Sonstige Einstellungen"
          class="rounded-lg border-2 border-gray-200"
          :test-id="$page.props.testId"
        >
          <ul class="min-w-full space-y-4">
            <li v-if="hasThirdParty('p4u')">
              <p class="whitespace-nowrap font-medium text-left text-xl">Warnhinweise</p>

              <ul>
                <li class="-mx-1 px-1 py-3 hover:bg-gray-200 border-b border-gray-300">
                  <component-checkbox
                    v-model="configuration.bmpManagerInfo"
                    color="accent"
                    :test-id="$page.props.testId + '-bmp-manager-info'"
                  >
                    Warnhinweis „BMP-Manager öffnen“ anzeigen
                  </component-checkbox>
                </li>
              </ul>
            </li>

            <li v-if="hasThirdParty('p4u')">
              <p class="whitespace-nowrap font-medium text-left text-xl mt-8">Benachrichtigungseinstellungen</p>

              <ul>
                <li class="-mx-1 px-1 py-3 hover:bg-gray-200 border-b border-gray-300">
                  <component-checkbox
                    v-model="configuration.notifyComments"
                    color="accent"
                    :test-id="$page.props.testId + '-notify-comments'"
                  >
                    Benachrichtigung bei Kommentaren per E-Mail
                    <span v-if="mail">an {{ mail }}</span>
                  </component-checkbox>
                </li>
              </ul>
            </li>

            <li>
              <p class="whitespace-nowrap font-medium text-left text-xl mt-8">Check-Treffer ein/ausklappen</p>

              <ul>
                <li class="-mx-1 px-1 py-3 hover:bg-gray-200 border-b border-gray-300">
                  <component-checkbox
                    v-model="configuration.doNotCloseClaimsAutomatically"
                    color="accent"
                    :test-id="$page.props.testId + '-do-not-close-claims-automatically'"
                  >
                    Check-Treffer nicht automatisch schließen
                  </component-checkbox>
                </li>
              </ul>
            </li>
          </ul>

          <template #actions>
            <component-button
              class="p4umc-primary"
              label="Speichern"
              :test-id="$page.props.testId + '-save-account-settings'"
              @click="saveAccountSettings"
            />
          </template>
        </component-card>
      </div>
    </article>
  </section>
</template>

<script>
  import {computed, inject, onBeforeMount} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "PagesSettingsShow",

    components: {ComponentButton, ComponentCheckbox, ComponentCard},

    setup() {
      const page = usePage();

      const privacy = inject("$privacy");
      const hasThirdParty = inject("$hasThirdParty");

      const configuration = useForm({
        bmpManagerInfo: true,
        notifyComments: true,
        doNotCloseClaimsAutomatically: false,
      });

      const mail = computed(() => privacy.getCurrentUser()?.email);

      onBeforeMount(() => {
        mapUserSettings();
      });

      const mapUserSettings = () => {
        if (page.props.settings.data.length > 0) {
          for (const objectItem of page.props.settings.data) {
            configuration[objectItem.name] = objectItem.amount;
          }
        }
      };

      const saveAccountSettings = () => {
        configuration.post(route("settings.store", {user: privacy.getCurrentUser().id}), {preserveScroll: true});
      };

      return {
        /** inject */
        hasThirdParty,

        /** const */
        configuration,

        /** computed */
        mail,

        /** function */
        saveAccountSettings,
      };
    },
  };
</script>
