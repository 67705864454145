<template>
  <div class="flex items-center space-x-2 print:hidden">
    <h6 class="text-sm font-semibold">Kommunikation im PDF</h6>
    <component-info-icon>
      <template #content>
        <p class="max-w-sm">
          Wählen Sie Ergebnisse aus, die Sie im PDF-Dokument an den Arzt oder den Patienten kommunizieren möchten.
        </p>
      </template>
    </component-info-icon>
  </div>

  <ul class="divide-y divide-gray-100 [&>*]:py-2 print:divide-none">
    <li class="print:hidden [.print-doc_&]:print:block [.print-archive_&]:print:block">
      <div class="flex justify-between flex-wrap print:hidden">
        <component-tooltip tooltip="Ergebnis wird an Arzt übermittelt" placement="left">
          <component-checkbox
            v-model="internalCommunicationdoc"
            @click="internalHidden = false"
            :data-test="dataTest + '-checkbox-internalCommunicationdoc'"
          >
            an Arzt
          </component-checkbox>
        </component-tooltip>

        <component-tooltip
          v-if="hasThirdParty('p4u') && claim.confidential_comment_count > 0"
          tooltip="Rückmeldung des Arztes"
          placement="left"
          :force-inline="false"
          class="-mt-0.5 mr-1"
        >
          <button class="text-sm" @click="$emit('open:confidentialCommentsDialog')">
            <component-badge class="text-mcred">
              <template #icon>{{ claim.confidential_comment_count }}</template>
              Arztrückmeldung
            </component-badge>
          </button>
        </component-tooltip>
      </div>

      <component-tooltip
        :class="{hidden: !internalCommunicationdoc}"
        tooltip="Hinweise an Arzt werden in PDF-Dokument übernommen"
        placement="left"
        :force-inline="false"
        class="mt-2 text-sm print:block"
      >
        <div class="hidden print:hidden [.print-doc_&]:print:block font-semibold pb-1 mt-2">Hinweise:</div>
        <div class="hidden print:hidden [.print-archive_&]:print:block font-semibold pb-1 mt-2">Hinweise an Arzt:</div>
        <component-textarea
          v-model="internalUsernote"
          rows="2"
          placeholder="Hinweise an Arzt"
          class="mt-0 mb-0 pb-0"
          background="bg-gray-100 print:hidden"
          :data-test="dataTest + '-textarea-internalUsernote'"
        />
        <div class="hidden print:block min-h-20 bg-gray-100 border-b-2 border-b-gray-200" v-text="internalUsernote" />
      </component-tooltip>
    </li>

    <li v-if="hasThirdParty('p4u') && claim.feedback_url" class="hidden print:hidden [.print-doc_&]:print:block">
      <a
        :href="claim.feedback_url"
        target="_blank"
        class="p4umc-primary p4umc-btn p-1 rounded border-2 outline-0 font-semibold uppercase hover:no-underline"
        :data-test="dataTest + '-link'"
      >
        Rückmeldung geben
      </a>
      <div class="mt-2 text-xs leading-tight">zu den genannten Problemen und möglichen Lösungen</div>
    </li>

    <li class="print:hidden [.print-patient_&]:print:block [.print-archive_&]:print:block">
      <div class="print:hidden">
        <component-tooltip tooltip="Ergebnis wird an Patienten übermittelt" placement="left">
          <component-checkbox
            v-model="internalCommunicationpatient"
            @click="internalHidden = false"
            :data-test="dataTest + '-checkbox-internalCommunicationpatient'"
          >
            an Patient
          </component-checkbox>
        </component-tooltip>
      </div>

      <component-tooltip
        :class="{hidden: !internalCommunicationpatient}"
        tooltip="Hinweise an Patient werden in PDF-Dokument übernommen"
        placement="left"
        :force-inline="false"
        class="block mt-2 text-sm print:block"
      >
        <div class="hidden print:hidden [.print-patient_&]:print:block font-semibold pb-1 mt-2">Hinweise:</div>
        <div class="hidden print:hidden [.print-archive_&]:print:block font-semibold pb-1 mt-2">
          Hinweise an Patient:
        </div>
        <component-textarea
          v-model="internalUsernotepatient"
          rows="2"
          placeholder="Hinweise an Patient"
          class="mt-0 mb-0 pb-0"
          background="bg-gray-100 print:hidden"
          :data-test="dataTest + '-textarea-internalUsernotepatient'"
        />
        <div
          class="hidden print:block min-h-20 bg-gray-100 border-b-2 border-b-gray-200"
          v-text="internalUsernotepatient"
        />
      </component-tooltip>
    </li>

    <li class="print:hidden">
      <component-tooltip :tooltip="hiddenTooltip" placement="left">
        <component-checkbox
          v-model="internalHidden"
          :disabled="internalCommunicationdoc || internalCommunicationpatient"
          :data-test="dataTest + '-checkbox-internalHidden'"
        >
          Ergebnis ausblenden
        </component-checkbox>
      </component-tooltip>
    </li>
  </ul>
</template>

<script>
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import {ref, inject, watch, computed} from "vue";
  import ComponentBadge from "@components/Badges/Badge.vue";

  export default {
    name: "PagesRecordResultListClaimInteractionPdf",
    components: {
      ComponentBadge,
      ComponentTextarea,
      ComponentCheckbox,
      ComponentTooltip,
      ComponentInfoIcon,
    },
    props: {
      claim: {
        type: Object,
        required: true,
      },
      communicationdoc: {
        type: Boolean,
        required: true,
      },
      communicationpatient: {
        type: Boolean,
        required: true,
      },
      hidden: {
        type: Boolean,
        required: true,
      },
      usernote: {
        type: [String, null],
        required: true,
      },
      usernotepatient: {
        type: [String, null],
        required: true,
      },
      dataTest: {
        type: String,
        default: "claimInteractionPdf",
      },
    },
    emits: [
      "update:communicationdoc",
      "update:communicationpatient",
      "update:hidden",
      "update:usernote",
      "update:usernotepatient",
      "open:confidentialCommentsDialog",
    ],

    setup(props, {emit}) {
      const hasThirdParty = inject("$hasThirdParty");
      const internalCommunicationdoc = ref(props.communicationdoc);
      const internalCommunicationpatient = ref(props.communicationpatient);
      const internalHidden = ref(props.hidden);
      const internalUsernote = ref(props.usernote);
      const internalUsernotepatient = ref(props.usernotepatient);

      watch(
        [
          internalHidden, // 0
          internalUsernote, // 1
          internalUsernotepatient, // 2
          internalCommunicationdoc, // 3
          internalCommunicationpatient, // 4
        ],
        (newValue, oldValue) => {
          for (let i = 0, l = newValue.length; i < l; i++) {
            if (newValue[i] !== oldValue[i]) {
              switch (i) {
                case 0:
                  emit("update:hidden", newValue[i]);
                  break;
                case 1:
                  emit("update:usernote", newValue[i]);
                  break;
                case 2:
                  emit("update:usernotepatient", newValue[i]);
                  break;
                case 3:
                  emit("update:communicationdoc", newValue[i]);
                  break;
                case 4:
                  emit("update:communicationpatient", newValue[i]);
                  break;
              }
            }
          }
        },
      );

      watch(
        () => props.communicationdoc,
        (newValue) => {
          internalCommunicationdoc.value = newValue;
        },
      );

      watch(
        () => props.communicationpatient,
        (newValue) => {
          internalCommunicationpatient.value = newValue;
        },
      );

      watch(
        () => props.hidden,
        (newValue) => {
          internalHidden.value = newValue;
        },
      );

      const hiddenTooltip = computed(() => {
        if (internalCommunicationdoc.value || internalCommunicationpatient.value) {
          return "Auswahl nicht möglich, da Ergebnis für einen Bericht ausgewählt ist";
        }
        return internalHidden.value
          ? "Ergebnis wurde als „nicht relevant“ markiert"
          : "Ergebnis wird als „nicht relevant“ markiert";
      });

      return {
        hasThirdParty,
        internalCommunicationdoc,
        internalCommunicationpatient,
        internalHidden,
        internalUsernote,
        internalUsernotepatient,
        hiddenTooltip,
      };
    },
  };
</script>
