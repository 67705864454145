<template lang="html">
  <div>
    <div class="flex">
      <template v-for="(tab, index) in tabs">
        <template v-if="tab.props?.title">
          <button
            :key="tab.props.title"
            :class="{'border-mcred border-b-2': tab.props.title === activeTab}"
            class="text-mcred uppercase font-semibold inline-block p-4 rounded-t-md hover:bg-gray-100 cursor-pointer"
            :data-test="tab.props['test-id'] + '-tab'"
            @click="selectTab(index)"
          >
            <span v-if="tab.props?.infoMessage > 0">
              <component-badge>
                <template #icon>{{ tab.props?.infoMessage }}</template>

                {{ tab.props.title }}
              </component-badge>
            </span>

            <span v-else>{{ tab.props.title }}</span>
          </button>
        </template>
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
  import {onBeforeMount, onMounted, provide, reactive, toRefs} from "vue";

  import ComponentBadge from "@components/Badges/Badge.vue";

  export default {
    name: "ComponentTabs",

    components: {
      ComponentBadge,
    },

    props: {
      activeTab: {
        type: String,
        default: "",
      },
    },

    emits: ["click"],

    setup(_, {emit, slots}) {
      const state = reactive({
        selectedIndex: 0,
        tabs: [],
        count: 0,
      });

      onBeforeMount(() => {
        if (slots.default) {
          state.tabs = slots.default().filter((child) => child.type.name === "ComponentTab");
        }
      });

      onMounted(() => {
        const tabIndex = state.tabs.findIndex((tab) => tab.props?.title === _.activeTab);
        selectTab(tabIndex > 0 ? tabIndex : 0);
      });

      provide("tabsProvider", state);

      const selectTab = (i) => {
        state.selectedIndex = i;
        state.tabs.forEach((tab, index) => {
          tab.isActive = index === i;
          if (index === i) {
            emit("click", tab.props.title);
          }
        });
      };

      return {
        /** const */
        ...toRefs(state),

        /** function */
        selectTab,
      };
    },
  };
</script>
