<template>
  <component-dialog ref="refDialog" dialog-width="small" :test-id="testId + '-patient-todo'">
    <template #title>
      Aufgaben für
      <span :data-encrypted="currentPatient.firstname" />
      <span :data-encrypted="currentPatient.lastname" />
    </template>

    <template #content>
      <div v-if="processing" class="flex justify-center">
        <component-spinner class="h-8 w-8" :test-id="testId + '-patient-todo-dialog'" />
      </div>

      <component-todo-list v-else :patient="currentPatient" :layer="true" :test-id="testId + '-patient-todo-dialog'" />
    </template>

    <template #actions>
      <component-button label="Schließen" :test-id="testId + '-patient-todo-dialog-close'" @click="close()" />
    </template>
  </component-dialog>
</template>

<script>
  import {ref, watch} from "vue";
  import {router} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentTodoList from "@components/Lists/TodoList.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "ComponentPatientTodosDialog",

    components: {ComponentSpinner, ComponentTodoList, ComponentButton, ComponentDialog},

    expose: ["open", "close"],

    props: {
      patients: {
        type: Object,
        default: () => {},
      },
    },

    setup(props) {
      const refDialog = ref(null);
      const currentPatient = ref(null);
      const processing = ref(false);

      watch(
        () => props.patients,
        (patients) => {
          if (currentPatient.value) {
            currentPatient.value = patients.data.find((patient) => patient.id === currentPatient.value.id);
          }
        },
      );

      function open(patient) {
        processing.value = true;
        currentPatient.value = patient;
        refDialog.value.open();

        // because the following router.reload() only reloads the current
        // url and does not allow to append a get parameter, we have to
        // inject the withTodos parameter manually. this parameter
        // will include all to-do items for this single patient.
        let url = new URL(window.location.href);
        url.searchParams.set("withTodos", patient.id);
        history.pushState({}, "", url);

        router.reload({
          only: ["patients"],
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => {
            processing.value = false;
          },
          onError: () => {
            processing.value = false;
            close();
          },
        });
      }

      function close() {
        refDialog.value.close();
      }

      return {
        /** ref */
        refDialog,

        /** const */
        processing,
        currentPatient,

        /** function */
        open,
        close,
      };
    },
  };
</script>
