/**
 *
 * @param {{}} currentPatient
 * @param {Array} existingPatients
 * @param {Array} keys
 * @param looseComparison
 * @return {{}[]}
 */
export default function (currentPatient, existingPatients, keys, looseComparison = false) {
  return existingPatients.filter((ePatient) => {
    for (const cKey of keys) {
      if (Object.hasOwn(currentPatient, cKey) && Object.hasOwn(ePatient, cKey)) {
        let cP = (currentPatient[cKey] ?? "").toString().toLowerCase();
        let eP = (ePatient[cKey] ?? "").toString().toLowerCase();

        if (looseComparison === false && cP !== eP) {
          return false;
        }
        if (looseComparison === true && !eP.startsWith(cP)) {
          return false;
        }
      }
    }

    // always check on the id (we do not want to notify
    // an existing patient for himself)
    if (
      Object.hasOwn(currentPatient, "id") &&
      Object.hasOwn(ePatient, "id") &&
      currentPatient["id"] !== null &&
      typeof currentPatient["id"] !== "undefined" &&
      currentPatient["id"] === ePatient["id"]
    ) {
      return false;
    }

    return true;
  });
}
