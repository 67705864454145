<template>
  <component-dialog
    ref="refDialog"
    dialog-width="medium"
    :dialog-title="$page.props.can.view_shared_records ? 'Fallfreigabe und Zusammenarbeit' : 'Kommentare'"
    dialog-type="information"
    :test-id="testId + '-record-share-status'"
  >
    <template #content>
      <div class="flow-root">
        <ul v-if="notifications.length" role="list" class="space-y-8">
          <li v-for="(notification, key) in notifications" :key="key" class="relative [&:last-child>span]:hidden">
            <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

            <div class="relative flex space-x-3">
              <div>
                <span
                  class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                  :class="{
                    'bg-gray-400':
                      notification.type !== recordNotifications.completedFromMe &&
                      notification.type !== recordNotifications.commented,
                    'bg-mcgreen-400': notification.type === recordNotifications.completedFromMe,
                    'bg-mcblue-400': notification.type === recordNotifications.commented,
                  }"
                >
                  <component-icon class="text-white text-xl">{{ getActionIcon(notification) }}</component-icon>
                </span>
              </div>

              <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p class="text-sm text-gray-700">{{ getIntroText(notification) }}</p>

                  <div>
                    <template
                      v-if="notification.audience === 'group' && notification.type === recordNotifications.sharedByMe"
                    >
                      {{ notification.link }}
                    </template>

                    <template v-if="notification.audience === 'direct'">
                      <strong class="font-semibold">
                        {{ notification.partner_company || notification.partner_name }}
                      </strong>

                      <em
                        v-if="
                          notification.type === recordNotifications.commented &&
                          notification.confidential &&
                          !notification.partner_name
                        "
                      >
                        Extern hinzugezogener Arzt
                      </em>

                      <span v-if="notification.partner_company && notification.partner_name">
                        ({{ notification.partner_name }})
                      </span>

                      <span v-if="notification.type === recordNotifications.commented" class="text-sm text-gray-500">
                        <strong v-if="notification.acceptance === 'accepted'" class="font-bold text-mcgreen">
                          hat Vorschlag akzeptiert
                        </strong>

                        <strong v-else-if="notification.acceptance === 'declined'" class="font-bold text-mcred">
                          hat Vorschlag abgelehnt
                        </strong>

                        <span v-else>&nbsp;hat kommentiert</span>
                      </span>

                      <div
                        v-if="notification.type === recordNotifications.commented"
                        class="mt-1 -mx-2 p-2 bg-gray-100 rounded-lg italic text-sm w-96"
                      >
                        {{ notification.content }}
                      </div>
                    </template>
                  </div>

                  <div class="mt-2">
                    <component-tooltip
                      v-if="
                        currentRecord.can_complete_this_record &&
                        notification.showCompleteButton &&
                        currentRecord.shared_owners &&
                        currentRecord.shared_owners[0] &&
                        currentRecord.shared_owners[0].id
                      "
                      :tooltip="'Bearbeitung abschließen und an „' + ownerName + '“ zurücksenden'"
                      placement="bottom"
                      :force-inline="true"
                    >
                      <component-button
                        class="p4umc-primary"
                        :label="'Zurücksenden'"
                        :icon-button="true"
                        :test-id="testId + '-record-share-status-dialog-send-back'"
                        @click="confirmComplete(currentRecord.shared_owners[0].id)"
                      >
                        <component-icon>done</component-icon>
                      </component-button>
                    </component-tooltip>

                    <component-tooltip
                      v-if="
                        notification.status !== sharedrecordStatus.revoked &&
                        notification.type === recordNotifications.sharedByMe
                      "
                      tooltip="Freigabe widerrufen"
                      placement="bottom"
                      :force-inline="true"
                    >
                      <component-button
                        class="p4umc-primary"
                        label="Widerrufen"
                        :icon-button="true"
                        :test-id="testId + '-record-share-status-confirm-revoke'"
                        @click="confirmRevoke(notification)"
                      >
                        <component-icon>person_add_disabled</component-icon>
                      </component-button>
                    </component-tooltip>
                  </div>
                </div>

                <div class="text-right text-sm text-gray-500">
                  <component-tooltip :tooltip="getVerbalizedActionText(notification)">
                    <time class="whitespace-nowrap">
                      {{ notification.created_at }}
                    </time>
                  </component-tooltip>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div v-if="notifications.length === 0" class="space-y-4">
          <p v-if="currentRecord.shared_owners.length">
            Diese Medikationsanalyse wurde für Sie freigegeben von
            {{ currentRecord.shared_owners[0]?.owner_company }}
            <template v-if="currentRecord.shared_owners[0]?.owner_name">
              ({{ currentRecord.shared_owners[0]?.owner_name }})
            </template>
            und kann aufgrund eines Fehlers nicht zurückgesendet werden.
          </p>

          <p>
            In Ausnahmefällen kann es zu Problemen mit der Historie kommen, insb. wenn eine Medikationsanalyse vor
            langer Zeit freigegeben wurde.
          </p>

          <p>
            Sollte der Fehler wiederholt auftreten, wenden Sie sich bitte an den Support unter Angabe der folgenden
            Kennziffer:

            <code v-if="currentRecord.shared_owners.length">
              shared-record={{ currentRecord.shared_owners[0]?.id }}
            </code>
            <code v-else>origin-record={{ currentRecord.id }}</code>
          </p>
        </div>
      </div>
    </template>
  </component-dialog>

  <component-record-share-revoke-dialog
    ref="refConfirmRevokeDialog"
    :patient="patient"
    :record="currentRecord"
    :test-id="testId + '-record-share-status'"
    @revoked="refresh()"
  />

  <component-record-share-complete-dialog
    ref="refConfirmCompleteDialog"
    :patient="patient"
    :record="currentRecord"
    :test-id="testId + '-record-share-status'"
    @completed="refresh()"
  />
</template>

<script>
  import {computed, ref} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentRecordShareCompleteDialog from "@components/Dialogs/RecordShareCompleteDialog.vue";
  import ComponentRecordShareRevokeDialog from "@components/Dialogs/RecordShareRevokeDialog.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import {recordNotifications, sharedrecordStatus} from "@pages/Records/Enums/Enums.js";

  export default {
    name: "ComponentRecordShareStatusDialog",

    components: {
      ComponentButton,
      ComponentDialog,
      ComponentIcon,
      ComponentRecordShareCompleteDialog,
      ComponentRecordShareRevokeDialog,
      ComponentTooltip,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: [Object, null],
        default: null,
      },
    },

    expose: ["open", "close"],

    setup(props) {
      const refDialog = ref(null);
      const refConfirmRevokeDialog = ref(null);
      const refConfirmCompleteDialog = ref(null);

      const currentRecord = ref(props.record);

      let form = useForm({
        email: "",
        notify_recipient: false,
        info: currentRecord.value?.info ?? null,
        aliasname: "",
      });

      const notifications = computed(() => {
        let alreadyShown = false;
        return (currentRecord.value.notifications ?? []).map((notification) => {
          if (
            !alreadyShown &&
            (notification.type === recordNotifications.sharedWithMe ||
              notification.type === recordNotifications.editedFromMe ||
              notification.type === recordNotifications.returnedToMe)
          ) {
            alreadyShown = true;
            // add property to show complete button only once
            notification.showCompleteButton = true;
            return notification;
          }
          return notification;
        });
      });

      const ownerName = computed(() => {
        return currentRecord.value?.shared_owners[0]?.owner_company
          ? currentRecord.value?.shared_owners[0]?.owner_company
          : currentRecord.value?.shared_owners[0]?.owner_name;
      });

      function open(record) {
        if (typeof record !== "undefined") {
          currentRecord.value = record;
        }
        // always refresh on open
        refresh();
        refDialog.value.open();
      }

      function refresh() {
        setTimeout(() => {
          router.reload({
            only: ["record", "records"],
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
              // because we received the currentRecord via the
              // self::open() method, we do not recognize
              // changes after a record has been revoked
              // or completed. therefore we have to
              // search for our record in the page
              // props and refresh it
              const pageProps = usePage().props;

              // Patients/Show contains the records in "records.data"
              if (typeof pageProps.records !== "undefined") {
                currentRecord.value =
                  pageProps.records.data.find((record) => record.id === currentRecord.value.id) || currentRecord.value;
              }
              // check result page contains the record in "record"
              else if (typeof pageProps.record !== "undefined") {
                currentRecord.value = pageProps.record;
              }
            },
          });
        }, 600);
      }

      function close() {
        form.reset();
        form.clearErrors();
        form.wasSuccessful = false;
        refDialog.value.close();
      }

      function save() {
        form.clearErrors();

        form.post(
          route("sharedrecords.store", {
            patient: props.patient.id,
            record: currentRecord.value.id,
          }),
          {
            preserveScroll: true,
            onError: (error) => {
              if (error.exception) {
                console.error(error.exception);
              }
              form.setError(
                "server_side",
                "Bei der Verarbeitung ist leider ein Fehler aufgetreten. Bitte prüfen Sie die E-Mail-Adresse des Empfängers.",
              );
            },
          },
        );
      }

      function getActionIcon(notification) {
        if (notification.type === recordNotifications.sharedByMe) return "call_made";
        else if (notification.type === recordNotifications.sharedWithMe) return "call_received";
        else if (notification.type === recordNotifications.takenAwayFromMe) return "person_add_disabled";
        else if (notification.type === recordNotifications.revokedByMe) return "person_add_disabled";
        else if (notification.type === recordNotifications.completedFromMe) return "done";
        else if (notification.type === recordNotifications.returnedToMe) return "keyboard_backspace";
        else if (notification.type === recordNotifications.editedFromMe) return "edit";
        else if (notification.type === recordNotifications.commented)
          return notification.confidential ? "custom:doctor" : "mode_comment";
      }

      function getIntroText(notification) {
        if (notification.type === recordNotifications.sharedByMe && notification.audience === "group")
          return "Sie haben für diese Variante eine Seminar-Freigabe erstellt";
        else if (notification.type === recordNotifications.sharedByMe) return "Sie haben diese Variante geteilt mit";
        else if (notification.type === recordNotifications.sharedWithMe)
          return "Diese Variante wurde mit Ihnen geteilt von";
        else if (notification.type === recordNotifications.takenAwayFromMe) return "Freigabe wurde widerrufen von";
        else if (notification.type === recordNotifications.revokedByMe && notification.audience === "group")
          return "Sie haben die Seminar-Freigabe widerrufen";
        else if (notification.type === recordNotifications.revokedByMe)
          return "Sie haben die Freigabe widerrufen gegenüber";
        else if (notification.type === recordNotifications.completedFromMe)
          return "Sie haben diese Variante zurückgesendet an";
        else if (notification.type === recordNotifications.returnedToMe) {
          if (notification.with_changes) {
            return "Diese Variante wurde mit inhaltlichen Änderungen an Sie zurückgesendet von";
          } else {
            return "Diese Variante wurde an Sie zurückgesendet von";
          }
        } else if (notification.type === recordNotifications.editedFromMe)
          return "Sie haben Änderungen an dieser Variante vorgenommen";
      }

      function getVerbalizedActionText(notification) {
        if (notification.type === recordNotifications.sharedByMe) return "freigegeben am";
        else if (notification.type === recordNotifications.sharedWithMe) return "freigegeben am";
        else if (notification.type === recordNotifications.takenAwayFromMe) return "widerrufen am";
        else if (notification.type === recordNotifications.revokedByMe) return "widerrufen am";
        else if (notification.type === recordNotifications.completedFromMe) return "zurückgesendet am";
        else if (notification.type === recordNotifications.returnedToMe) return "zurückerhalten am";
        else if (notification.type === recordNotifications.editedFromMe) return "bearbeitet am";
        else if (notification.type === recordNotifications.commented) {
          if (notification.acceptance === "accepted") return "Vorschlag akzeptiert am";
          else if (notification.acceptance === "declined") return "Vorschlag abgelehnt am";
          else return "kommentiert am";
        }
      }

      function confirmRevoke(notification) {
        refConfirmRevokeDialog.value.open(notification);
      }

      function confirmComplete(sharedrecordId) {
        refConfirmCompleteDialog.value.open(sharedrecordId);
      }

      return {
        /** enum */
        recordNotifications,
        sharedrecordStatus,

        /** ref */
        refDialog,
        refConfirmCompleteDialog,
        refConfirmRevokeDialog,

        /** const */
        currentRecord,
        form,

        /** computed */
        ownerName,
        notifications,

        /** function */
        getActionIcon,
        getIntroText,
        getVerbalizedActionText,
        confirmRevoke,
        confirmComplete,
        open,
        close,
        save,
        refresh,
      };
    },
  };
</script>
