<template>
  <div class="leading-4 whitespace-nowrap">
    <component-tooltip v-if="record.label" placement="bottom" tooltip="Interne Bezeichnung" :force-inline="true">
      <a class="text-xs text-gray-900" @click.prevent="openEditRecordLabel()">{{ record.label }}</a>
    </component-tooltip>
    <a v-else class="text-xs" href="#" @click.prevent="openEditRecordLabel()">Bezeichnung vergeben</a>
  </div>
  <component-record-edit-label-dialog ref="refEditRecordLabelDialog" />
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentRecordEditLabelDialog from "@components/Dialogs/RecordEditLabelDialog.vue";
  import {ref} from "vue";

  export default {
    name: "ComponentRecordLabel",
    components: {ComponentRecordEditLabelDialog, ComponentTooltip},
    props: {
      record: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const refEditRecordLabelDialog = ref(null);
      function openEditRecordLabel() {
        refEditRecordLabelDialog.value.open(props.record);
      }

      return {refEditRecordLabelDialog, openEditRecordLabel};
    },
  };
</script>
