<template>
  <div @click="blink" class="group relative self-start" :class="{'cursor-help': short !== null}">
    <span
      v-if="short !== null"
      class="group-hover:invisible inline-flex items-center rounded-md bg-red-50 px-1 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 whitespace-nowrap"
      :class="{invisible: open}"
      v-text="short"></span>
    <span
      class="inline-flex items-center rounded-md bg-red-50 px-1 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 whitespace-nowrap"
      :class="{hidden: short !== null && !open, 'absolute top-1 right-0 z-50 group-hover:block': short !== null}">
      <slot></slot>
    </span>
  </div>
</template>

<script>
  import {ref} from "vue";

  export default {
    name: "ComponentExpandableBadge",

    props: {
      short: {
        type: String,
        default: null,
      },
    },

    setup() {
      const open = ref(false);

      const blink = () => {
        open.value = true;
        setTimeout(() => {
          open.value = false;
        }, 3000);
      };

      return {open, blink};
    },
  };
</script>
