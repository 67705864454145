<template>
  <div class="flex items-center px-2 pb-4 space-x-2">
    <!-- Headline is editable -->
    <template v-if="isEditable">
      <div class="flex items-center space-x-1">
        <h5 class="text-2xl">Labor- / Vitalwerte</h5>
        <component-info-icon placement="top">
          <template #content>
            <p>
              Hinweis: Nur Labor- / Vitalwerte außerhalb der Norm werden automatisiert geprüft. (z. B. im Hinblick
              darauf, ob Arzneimittel in der Medikation für die Abweichung verantwortlich sind oder ob die Probleme /
              Symptome des Patienten mit den Labor- / Vitalwertabweichungen in Verbindung stehen könnten)
            </p>
          </template>
        </component-info-icon>
      </div>
    </template>

    <!-- Headline is not editable -->
    <template v-else>
      <h5 class="text-2xl">Labor- / Vitalwerte</h5>
      <component-button class="p4umc-primary" label="Bearbeiten" @click="changeIsEditable" />
    </template>
  </div>

  <div v-if="unloadableLaborvaluesSolved.length > 0 || unloadableLaborvaluesUnsolved.length > 0" class="px-4">
    <component-alert type="warning">
      <p>In diesem Bereich befinden sich Labor- / Vitalwerte, die in der aktualisierten Datenbank ersetzt wurden.</p>

      <div v-if="unloadableLaborvaluesUnsolved.length > 0">
        <p class="mt-2">
          Bitte tauschen Sie folgende Labor- / Vitalwerte durch geeignete neue aus, damit sie im Check erkannt und
          berücksichtigt werden:
        </p>

        <ul class="mt-2 space-y-1 list-disc pl-6">
          <li v-for="(item, index) in unloadableLaborvaluesUnsolved" :key="index">
            <em>{{ item.type }}</em>
          </li>
        </ul>
      </div>

      <div v-if="unloadableLaborvaluesSolved.length > 0">
        <p class="mt-2">Folgende Labor- / Vitalwerte konnten bereits automatisch aktualisiert werden:</p>

        <ul class="mt-2 space-y-1 list-disc pl-6">
          <li v-for="(item, index) in unloadableLaborvaluesSolved" :key="index">
            <em>{{ item.type }}</em>
          </li>
        </ul>
      </div>
    </component-alert>
  </div>

  <div v-if="isGenderDiverse" class="px-4">
    <component-alert type="warning">
      <p>
        Eine Überprüfung der Labor-/Vitalwerte ist für das für den Patienten hinterlegten Geschlecht "divers" nicht
        möglich, es werden daher die Referenzbereiche für das Geschlecht "weiblich" verwendet.
      </p>
    </component-alert>
  </div>

  <!-- Main labor values -->
  <main-labor-values :record-data="recordData" :is-editable="isEditable" :is-loading="isLoading" />

  <div v-if="isEditable || filteredLaborValues.length > 0" class="mt-4">
    <h6 class="text-xl mb-4 px-4">Weitere Labor-/Vitalwerte</h6>
  </div>

  <!-- Table -->
  <template v-if="filteredLaborValues.length > 0">
    <labor-table
      :record-data="recordData"
      :filtered-laborvalues="filteredLaborValues"
      :is-editable="isEditable"
      :is-loading="isLoading"
    />
  </template>

  <!-- Search -->
  <labor-search v-if="isEditable" :record-data="recordData" />

  <!-- Dialogs -->
  <template v-if="isEditable">
    <labor-table-gfr-calculator ref="refLaborValueGfrDialog" :record-data="recordData" />
  </template>
</template>

<script>
  import {computed, provide, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";
  import {filter} from "lodash";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  import {laborbardeviationValues, mainLaborvalueList} from "@pages/Records/Components/Sections/LaborBar/enums.js";

  import LaborSearch from "@pages/Records/Components/Sections/LaborBar/Components/Search.vue";
  import LaborTable from "@pages/Records/Components/Sections/LaborBar/Components/Table.vue";
  import MainLaborValues from "@pages/Records/Components/Sections/LaborBar/Components/MainLaborValues.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import LaborTableGfrCalculator from "@pages/Records/Components/Sections/LaborBar/Components/GfrCalculator.vue";

  export default {
    name: "RecordComponentSectionLaborBar",

    components: {
      LaborTableGfrCalculator,
      ComponentInfoIcon,
      ComponentAlert,
      ComponentButton,
      LaborSearch,
      LaborTable,
      MainLaborValues,
    },

    props: {
      isEditable: {
        type: Boolean,
        require: true,
      },
      changeIsEditable: {
        type: Function,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      unloadableLaborvaluesSolved: {
        type: Array,
        required: true,
      },
      unloadableLaborvaluesUnsolved: {
        type: Array,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const refLaborValueGfrDialog = ref(null);

      const isGenderDiverse = computed(() => page.props.patient.gender === "diverse");

      const filteredLaborValues = computed(() => {
        return filter(props.recordData.laborvalues, (laborvalue) => {
          return mainLaborvalueList.indexOf(laborvalue.mc_laborvalue_key) === -1;
        });
      });

      const openDialog = (dialogName, data) => {
        switch (dialogName) {
          case "refLaborValueGfrDialog":
            refLaborValueGfrDialog.value.open(data);
            break;
        }
      };

      provide("laborValueOpenDialog", openDialog);

      return {
        /** enums */
        laborbardeviationValues,

        /** ref */
        refLaborValueGfrDialog,

        /** computed */
        isGenderDiverse,
        filteredLaborValues,
      };
    },
  };
</script>
