<template>
  <dashboard-widget :test-id="testId + '-patient-count'">
    <template #default>
      <inertia-link
        :href="$route('patients.index')"
        class="group hover:no-underline flex items-baseline space-x-3"
        :data-test="testId + '-patient-count-widget-route-to-patient-index-inertia-link-icon-button'"
      >
        <component-icon class="p-2 text-2xl bg-mcred group-hover:bg-mcred-450 rounded-full text-white font-normal">
          view_list
        </component-icon>

        <span class="text-gray-700 group-hover:text-gray-900 text-4xl font-semibold">
          {{ $page.props.patientCount ?? 0 }}
        </span>

        <span class="text-gray-700 group-hover:text-gray-900 text-2xl font-semibold leading-tight">
          {{ $page.props.patientCount === 1 ? "Patient" : "Patienten" }}
        </span>
      </inertia-link>
    </template>

    <template #actions>
      <component-link-button
        class="p4umc-flat"
        label="alle Patienten"
        :href="$route('patients.index')"
        :test-id="testId + '-patient-count-widget-patient-list'"
      />
    </template>
  </dashboard-widget>
</template>

<script>
  import {Link as InertiaLink} from "@inertiajs/vue3";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentLinkButton from "@components/Buttons/LinkButton.vue";

  import DashboardWidget from "./Widget.vue";

  export default {
    name: "DashboardPatientCountWidget",

    components: {ComponentLinkButton, DashboardWidget, ComponentIcon, InertiaLink},
  };
</script>
