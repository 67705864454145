<template>
  <component-checkbox
    :key="indicationData.name"
    :name="'indication' + indicationData.name"
    color="accent"
    :model-value="isChecked"
    :disabled="isDisabled"
    @change="handleChange"
  >
    {{ indicationData.name }}
    <span v-if="indicationData.name_icdc">
      <span v-if="!indicationData.name">{{ indicationData.abdata_key_icdc }}</span>
      ({{ indicationData.name_icdc }})
    </span>
    <br v-if="!indicationData.abdata_key_miv" />
    <em v-if="!indicationData.abdata_key_miv">
      <small>
        Diesem ICD-Code kann kein MediCheck-Code zugeordnet werden (Checks funktionieren tw. nicht). Wenn möglich, bitte
        anderen wählen.
      </small>
    </em>
    <br
      v-if="
        indicationData.gender === genderList['male'] ||
        indicationData.gender === genderList['female'] ||
        indicationData.gender === genderList['diverse']
      "
    />
    <em v-if="indicationData.gender === genderList['male']"><small>männlicher Patient</small></em>
    <em v-else-if="indicationData.gender === genderList['female']"><small>weibliche Patientin</small></em>
    <em v-else-if="indicationData.gender === genderList['diverse']">
      <small>divers geschlechtliche(r) Patient(in)</small>
    </em>
  </component-checkbox>
</template>

<script>
  import {computed} from "vue";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import {usePage} from "@inertiajs/vue3";

  export default {
    name: "MedicationComponentIndicationCheckboxRow",

    components: {ComponentCheckbox},

    props: {
      isChecked: {
        type: Boolean,
        required: true,
        default: false,
      },
      indicationData: {
        type: Object,
        default: () => {},
      },
    },

    emits: ["setIndication"],

    setup(props, {emit}) {
      const page = usePage();

      const genderList = {
        male: 1,
        female: 2,
        unspecific: 3,
        diverse: 4,
      };

      const isDisabled = computed(() => {
        return (
          props.indicationData.gender !== 3 && props.indicationData.gender !== genderList[page.props.patient.gender]
        );
      });

      const handleChange = (newValue) => {
        emit("setIndication", newValue, props.indicationData);
      };

      return {genderList, isDisabled, handleChange};
    },
  };
</script>
