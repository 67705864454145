<template>
  <component-dialog ref="refCryptDialog" dialog-width="medium" :test-id="testId">
    <template #title>
      <span class="hidden print:inline">MediCheck</span>
      Datenschutzpasswort
    </template>

    <template #content>
      <div class="print:hidden">
        <template v-if="isLoading">
          <div class="text-center">
            <component-spinner class="h-12 w-12 mt-8" :test-id="testId + '-dialog-spinner'" />
          </div>
        </template>

        <template v-else>
          <p>
            Bitte geben Sie Ihr Datenschutzpasswort ein, um personenbezogene Daten lokal (clientseitig / im Browser) zu
            entschlüsseln.
          </p>

          <form autocomplete="off" @submit.prevent="verifyPassword">
            <component-input
              v-model="passwordPlaintext"
              class="mt-4"
              type="password"
              label="Passwort"
              autocomplete="off"
              :validation="privacy.getErrorMessage()"
              :maxlength="64"
              :show-maxlength-count="false"
              :show-max-length-message="'Die maximal mögliche Zeichenanzahl beträgt 64 Zeichen.'"
              :test-id="testId + '-dialog-password-plaintext'"
              @keyup.enter="verifyPassword"
            />
          </form>

          <p class="mt-6">
            Das Datenschutzpasswort (mind. 8 Zeichen lang) haben Sie separat festgelegt.
            <br />
            Hinweis: Mitarbeiter von pharma4u (MediCheck) haben keine Möglichkeit, diese Daten zu lesen oder
            wiederherzustellen.
            <a href="#" @click.prevent="userHasForgottenPassword()">Ich habe mein Passwort vergessen</a>
          </p>

          <p class="mt-4 flex items-center space-x-4">
            <component-checkbox
              v-model="rememberPassword"
              class="w-full"
              color="primary"
              :test-id="testId + '-dialog-remember-me'"
            >
              Datenschutzpasswort bis zum nächsten Logout speichern
            </component-checkbox>
          </p>
        </template>
      </div>

      <layout-print-recovery-code :recovery-code="privacy.getRecoveryCode()" :test-id="testId + '-dialog'" />
    </template>

    <template #actions>
      <component-button
        :disabled="!valid || privacy.hasCryptStatus('PENDING')"
        class="p4umc-primary"
        label="Entschlüsseln"
        :test-id="testId + '-dialog-encrypt'"
        @click="verifyPassword"
      />

      <component-button
        v-if="valid"
        tabindex="-1"
        label="Erneut drucken"
        :test-id="testId + '-dialog-print-again'"
        @click="reprintRecoveryCode"
      />

      <component-button
        v-if="!valid"
        class="p4umc-flat"
        tabindex="-1"
        label="Abmelden"
        :test-id="testId + '-dialog-logout'"
        @click="logout"
      />

      <component-button
        v-if="valid"
        class="p4umc-primary p4umc-flat"
        tabindex="-1"
        label="Abmelden"
        :test-id="testId + '-dialog-logout'"
        @click="logout"
      />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref, watch} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  import LayoutPrintRecoveryCode from "@components/Layout/Components/PrintRecoveryCode.vue";

  export default {
    name: "CryptDialogsDefault",

    components: {
      ComponentButton,
      ComponentCheckbox,
      ComponentDialog,
      ComponentInput,
      ComponentSpinner,
      LayoutPrintRecoveryCode,
    },

    props: {
      privacy: {
        type: Object,
        required: true,
        default: () => {},
      },
      isLoading: {
        type: Boolean,
        required: true,
        default: false,
      },
      logout: {
        type: Function,
        required: true,
        default: () => {},
      },
    },

    setup(props) {
      const refCryptDialog = ref(null);

      const passwordPlaintext = ref("");
      const rememberPassword = ref(false);

      const valid = computed(() => validPasswordMinLength.value && !props.privacy.getErrorMessage() !== null);

      const validPasswordMinLength = computed(
        () => passwordPlaintext.value.trim().length >= props.privacy.getPasswordMinLength(),
      );

      watch(
        () => passwordPlaintext.value,
        (newValue) => {
          props.privacy.plaintextPassword(newValue);
        },
      );

      const open = () => {
        refCryptDialog.value.open();
      };

      const close = () => {
        if (refCryptDialog.value) refCryptDialog.value.close();
      };

      const verifyPassword = (callback, errorCallback) => {
        callback =
          typeof callback === "function"
            ? callback
            : () => {
                props.privacy.setCurrentStatus("VERIFICATION_PENDING");
                props.privacy.initCrypt();
                props.privacy.setEncryptLocalStorage(rememberPassword.value ?? null);
                props.privacy.decryptStaticContent();

                setTimeout(() => {
                  close();
                }, 800);
              };

        errorCallback =
          typeof errorCallback === "function"
            ? errorCallback
            : () => {
                props.privacy.setErrorMessage("Das Passwort ist nicht korrekt.");
                props.privacy.setCurrentStatus("VERIFICATION_INVALID");
              };

        props.privacy.verifyPassword(callback, errorCallback);
      };

      const userHasForgottenPassword = () => {
        passwordPlaintext.value = "";
        props.privacy.setErrorMessage(null);
        props.privacy.setCurrentStatus("RECOVERY");
      };

      const reprintRecoveryCode = () => {
        verifyPassword(() => {
          setTimeout(() => {
            printRecoveryCode();
          }, 1000);
        });
      };

      const printRecoveryCode = () => {
        window.print();
        props.privacy.setRecoveryCodeHasBeenPrinted(true);
      };

      return {
        /** ref */
        refCryptDialog,

        /** const */
        passwordPlaintext,
        rememberPassword,

        /** computed */
        valid,

        /** function */
        open,
        close,
        verifyPassword,
        userHasForgottenPassword,
        reprintRecoveryCode,
      };
    },
  };
</script>
