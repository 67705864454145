export default class Bmp {
  /**
   * Validates on start or end tag
   *
   * @param {String} trimmedXml
   */
  static validateXml(trimmedXml) {
    return !(trimmedXml.match(/^<MP/) === null || trimmedXml.match(/MP>$/) === null);
  }

  /**
   * Parse XML wit DOMParser
   * @private
   *
   * @param {String} trimmedXml
   * @param {String} tagName
   * @param {String} attributeName
   *
   * @return {String}
   * @throws {Error}
   * */
  static getFromXml(trimmedXml, tagName, attributeName) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(trimmedXml, "text/xml");
    const value = xmlDoc.getElementsByTagName(tagName).item(0)?.getAttribute(attributeName);

    if (!value) {
      throw new Error();
    }

    return value;
  }

  /**
   * Returns the current page number from the xml
   *
   * @param {String} trimmedXml
   * @return {Number}
   */
  static getCurrentPageNumber(trimmedXml) {
    try {
      return parseInt(this.getFromXml(trimmedXml, "MP", "a"));
    } catch (e) {
      return 1;
    }
  }

  /**
   * Returns the max page number from the xml data
   *
   * @param {String} trimmedXml
   * @return {Number}
   */
  static getTotalPageNumber(trimmedXml) {
    try {
      return parseInt(this.getFromXml(trimmedXml, "MP", "z"));
    } catch (e) {
      return 1;
    }
  }

  /**
   * Returns the max page number from the xml data
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getUniqueInstanceId(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "MP", "U");
    } catch (e) {
      throw new Error("Invalid Number");
    }
  }

  /**
   * Returns the patient firstname
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getPatientFirstname(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "P", "g");
    } catch (e) {
      throw new Error("Invalid Firstname");
    }
  }

  /**
   * Returns the patient lastname
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getPatientLastname(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "P", "f");
    } catch (e) {
      throw new Error("Invalid Firstname");
    }
  }

  /**
   * Returns the patient gender
   *
   * @param {String} trimmedXml
   * @return {?String}
   */
  static getPatientGender(trimmedXml) {
    try {
      switch (this.getFromXml(trimmedXml, "P", "s")) {
        case "M":
          return "male";
        case "W":
          return "female";
        case "D":
          return "diverse";
        default:
          return null;
      }
    } catch (e) {
      return null;
    }
  }

  /**
   * Returns the patient birthdate
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getPatientBirthdate(trimmedXml) {
    try {
      const patientBirthdateAttribute = this.getFromXml(trimmedXml, "P", "b");

      const birthdate = patientBirthdateAttribute.match(/\d{4}(0[0-9]|1[012])(0[0-9]|[12][0-9]|3[01])/)[0];

      return `${birthdate.substring(0, 4)}-${birthdate.substring(4, 6)}-${birthdate.substring(6, 8)}`;
    } catch (e) {
      throw new Error("Invalid Birthdate");
    }
  }

  /**
   * Returns the patient insurance number
   *
   * @param {String} trimmedXml
   * @return {?String}
   */
  static getPatientInsurancenumber(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "P", "egk");
    } catch (e) {
      return null;
    }
  }
}
