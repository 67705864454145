<template>
  <li class="grid grid-cols-12 gap-x-2 pb-2 border-b-1 border-gray-300 animate-pulse">
    <div class="col-span-5 h-[27px] skeleton-input-loader" />
    <div class="w-8 h-[27px] skeleton-input-loader" />

    <div class="col-span-12 h-[14px] skeleton-input-loader" />

    <div class="col-span-3 h-[27px] skeleton-input-loader" />
    <div class="col-span-2 h-[27px] skeleton-input-loader" />
    <div class="col-span-2 h-[27px] skeleton-input-loader" />
    <div class="col-span-2 h-[27px] skeleton-input-loader" />
    <div class="col-span-2 h-[27px] skeleton-input-loader" />
    <div class="col-span-1 h-[27px] skeleton-input-loader" />
  </li>
</template>

<script>
  export default {
    name: "MedicationSkeletonRow",
  };
</script>
