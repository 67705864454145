<template>
  <div class="border-1 border-mcgreen text-mcgreen-700 p-2">
    <div
      class="cursor-default flex items-center space-x-2 bg-mcgreen-100 py-3 px-2 -mx-2 -mt-2 border-b-1 border-mcgreen"
    >
      <component-icon class="text-xl">tag_faces</component-icon>
      <h3 class="inline font-semibold">Ohne Befund</h3>
      <span class="font-light">(Greenlight Check):</span>
    </div>

    <ul class="mt-2 space-y-2 print:break-before-avoid-page">
      <li v-for="check in filteredChecks" :key="check.id" class="flex items-start space-x-2 cursor-help">
        <component-icon class="mt-0.5 text-xl">check_circle_outline</component-icon>
        <component-tooltip :tooltip="check.claims[0].description">
          <div>{{ check.claims[0].claim }}</div>
          <pre v-if="!check.claims[0].claim">{{ check }}</pre>
        </component-tooltip>
      </li>
      <li v-if="checks.length === 0">Es wurden keine Ergebnisse „ohne Befund“ ermittelt.</li>
    </ul>
  </div>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import {computed} from "vue";

  export default {
    name: "PagesRecordResultOverviewGreenlights",
    components: {ComponentTooltip, ComponentIcon},
    props: {
      checks: {
        type: Array,
        required: true,
      },
    },
    setup(props) {
      const filteredChecks = computed(() => {
        return props.checks.filter((check) => {
          return check.claims.length > 0;
        });
      });

      return {filteredChecks};
    },
  };
</script>
