<template>
  <div class="w-full text-ellipsis relative">
    <div>
      <label
        v-if="label.length > 0"
        :class="{'text-mcred': error?.length, 'text-gray-500': !error?.length}"
        class="duration-300 -z-1 origin-0 truncate cursor-default text-sm"
      >
        {{ label }}
      </label>

      <div class="flex">
        <input
          v-model="currentValue"
          type="date"
          :disabled="disabled"
          class="placeholder-shown:text-sm cursor-pointer h-9 p-2"
          max="2100-12-31"
          @change="handleChange"
          @input="handleInput"
        />
      </div>
    </div>

    <div>
      <div v-if="error?.length" class="text-xs text-mcred" data-test="errorMessage">
        {{ error }}
      </div>
      <div v-if="helperText && !error?.length" class="text-xs text-gray-500">{{ helperText }}</div>
    </div>
  </div>
</template>

<script>
  import {onMounted, ref, watch} from "vue";

  export default {
    name: "ComponentFullDateInput",

    props: {
      label: {
        type: String,
        default: "",
      },
      modelValue: {
        type: String,
        required: false,
        default: null,
      },
      error: {
        type: [String],
        default: "",
      },
      helperText: {
        type: String,
        default: null,
      },
      showClearIcon: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["change"],

    setup(props, {emit}) {
      const currentValue = ref(null);

      onMounted(() => {
        currentValue.value = props.modelValue;
      });

      watch(
        () => props.modelValue,
        (newValue) => {
          currentValue.value = newValue;
        },
      );

      const handleChange = () => {
        emit("change", currentValue.value);
      };

      const handleInput = () => {
        emit("change", currentValue.value);
      };

      return {currentValue, handleChange, handleInput};
    },
  };
</script>
