import "trix";

/**
 * @typedef {Object} Editor
 */

/**
 * @typedef {HTMLElement} Trix
 * @property {Editor} editor
 * @property {String} value
 * */

Trix.config.toolbar.getDefaultHTML = () => {
  return `<div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon" data-trix-attribute="bold" data-trix-key="b" title="${Trix.config.lang.bold}" tabindex="-1">
            <div class="select-none p4umc-icon inline">format_bold</div>
        </button>
        <button type="button" class="trix-button trix-button--icon" data-trix-attribute="italic" data-trix-key="i" title="${Trix.config.lang.italic}" tabindex="-1">
            <div class="select-none p4umc-icon inline">format_italic</div>
        </button>
        <button type="button" class="trix-button trix-button--icon" data-trix-attribute="strike" title="${Trix.config.lang.strike}" tabindex="-1">
            <div class="select-none p4umc-icon inline">strikethrough_s</div>
        </button>
        <button type="button" class="trix-button trix-button--icon" data-trix-attribute="underline" title="${Trix.config.lang.underline}" tabindex="-1">
            <div class="select-none p4umc-icon inline">format_underlined</div>
        </button>
      </span>

      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon" data-trix-attribute="bullet" title="${Trix.config.lang.bullets}" tabindex="-1">
            <div class="select-none p4umc-icon inline">format_list_bulleted</div>
        </button>
        <button type="button" class="trix-button trix-button--icon" data-trix-attribute="number" title="${Trix.config.lang.numbers}" tabindex="-1">
            <div class="select-none p4umc-icon inline">format_list_numbered</div>
        </button>
      </span>

      <span class="trix-button-group-spacer"></span>

      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon" data-trix-action="undo" data-trix-key="z" title="${Trix.config.lang.undo}" tabindex="-1">
            <div class="select-none p4umc-icon inline">undo</div>
        </button>
        <button type="button" class="trix-button trix-button--icon" data-trix-action="redo" data-trix-key="shift+z" title="${Trix.config.lang.redo}" tabindex="-1">
            <div class="select-none p4umc-icon inline">redo</div>
        </button>
      </span>
    </div>`;
};

Trix.config.lang.bold = "Fett";
Trix.config.lang.italic = "Kursiv";
Trix.config.lang.strike = "Durchgestrichen";
Trix.config.lang.underline = "Unterstrichen";

Trix.config.lang.bullets = "Liste";
Trix.config.lang.numbers = "Nummerierte Liste";

Trix.config.lang.undo = "Rückgängig";
Trix.config.lang.redo = "Wiederholen";

Trix.config.blockAttributes.default.tagName = "p";

Trix.config.textAttributes.underline = {
  tagName: "u",
  inheritable: true,
  parser(element) {
    const style = window.getComputedStyle(element);
    return style.textDecoration === "underline";
  },
};

delete Trix.config.blockAttributes.attachmentGallery;
delete Trix.config.textAttributes.frozen;

Trix.config.htmlSanitizerAllowedAttributes.forEach((attribute, index) => {
  if (["style", "src", "width", "height", "class"].includes(attribute)) {
    delete Trix.config.htmlSanitizerAllowedAttributes[index];
  }
});

["data-layer", "data-keyfat", "data-keyint", "data-guidelineid"].forEach((attribute) => {
  Trix.config.htmlSanitizerAllowedAttributes.push(attribute);

  Trix.config.textAttributes[attribute] = {
    groupTagName: "a",
    parser(element) {
      const matchingSelector = `a:not(${Trix.config.attachments.attachmentSelector})`;
      const link = element.closest(matchingSelector);
      if (link) {
        return link.getAttribute(attribute);
      }
    },
  };
});

Trix.config.htmlSanitizerAllowedElements.push(
  "div",
  "p",
  "em",
  "i",
  "strong",
  "b",
  "u",
  "del",
  "ul",
  "ol",
  "li",
  "a",
  "br",
  "span",
);

Trix.config.htmlSanitizerAllowedProtocols.push("http:", "https:");
