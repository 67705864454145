<template>
  <div>
    <div class="flex flex-col items-center">
      <component-spinner class="h-12 w-12 mt-8" :test-id="$page.props.testId" />

      <div class="w-[400px] mt-8 ml-[125px] overflow-hidden">
        <h2 class="font-medium text-xl">Ihre Eingabe wird geprüft auf:</h2>

        <transition-group tag="ul" class="mt-4 flex-col-reverse" name="fade">
          <li
            v-for="(buzzWord, index) in showBuzzWords"
            :key="index"
            :class="{'mt-2': buzzWord.type === 'list-item'}"
            :data-test="$page.props.testId + '-' + buzzWord.text"
          >
            {{ buzzWord.text }}
          </li>
        </transition-group>
      </div>
    </div>

    <component-dialog
      ref="errorDialog"
      dialog-type="information"
      dialog-title="Bei der Berechnung des Ergebnisses trat ein Fehler auf"
      :test-id="$page.props.testId"
    >
      <template #content>
        <div>
          <p>
            Die Ursache kann eine mangelnde Internetverbindung sein. Bitte prüfen Sie ggf. Ihre Verbindung und klicken
            Sie danach auf „Analyse neu starten“.
          </p>
          <p>
            Sollte diese Fehlermeldung erneut erscheinen, öffnen Sie bitte das Eingabeformular der Variante und klicken
            Sie auf den „Analysieren“-Button am Ende der Eingabeseite.
          </p>
        </div>
      </template>
    </component-dialog>
  </div>
</template>

<script>
  import {inject, onMounted, ref, watch} from "vue";
  import {router} from "@inertiajs/vue3";

  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";

  export default {
    name: "PageExecutionShow",

    components: {
      ComponentSpinner,
      ComponentDialog,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
      buzzwords: {
        type: Object,
        default: () => {
          return {};
        },
      },
      patient: {
        type: Object,
        default: null,
      },
      record: {
        type: Object,
        default: null,
      },
      executeUrl: {
        type: String,
        default: "/",
      },
      recordShowUrl: {
        type: String,
        default: "/",
      },
    },

    setup(props) {
      const privacy = inject("$privacy");
      const broadcast = inject("$broadcast");
      const buzzwordInterval = ref(null);
      const showBuzzWords = ref([]);
      const buzzwordsFinished = ref(false);
      const current = ref(0);
      const intervalTimer = ref(300);
      const success = ref(false);
      const errorDialog = ref(null);
      const error = ref(false);
      const encryptedPatientData = ref(null);

      onMounted(() => {
        _initializePatientData();
        _startBuzzwordAnimation();
      });

      watch(success, (newValue) => {
        if (newValue && buzzwordsFinished.value) {
          _redirectToResultPage();
        }
      });

      watch(buzzwordsFinished, (newValue) => {
        if (newValue && success.value) {
          _redirectToResultPage();
        }
      });

      watch(encryptedPatientData, (newValue) => {
        if (newValue) {
          _startAnalysisProcess();
        }
      });

      watch(error, (newValue) => {
        if (newValue) {
          errorDialog.value.open();
        }
      });

      function _initializePatientData() {
        privacy.whenCryptReady(async () => {
          encryptedPatientData.value = await privacy
            .getCryptObject()
            .decrypt(props.patient["birthdate"])
            .then((birthdate) => {
              return {
                birthdate: birthdate,
                gender: props.patient["gender"],
              };
            });
        });
      }

      function _startBuzzwordAnimation() {
        buzzwordInterval.value = window.setInterval(() => {
          if (current.value >= props.buzzwords.length) {
            clearInterval(buzzwordInterval.value);
            buzzwordsFinished.value = true;
          } else {
            showBuzzWords.value.push(props.buzzwords[current.value]);
            current.value++;
          }
        }, intervalTimer.value);
      }

      function _startAnalysisProcess() {
        router.on("invalid", (event) => {
          event.preventDefault();

          if (event.detail.response.status === 204) {
            success.value = true;
          }
        });

        router.post(
          props.executeUrl,
          {encPatient: encryptedPatientData.value},
          {
            onSuccess: () => {
              success.value = true;
            },
            onError: () => {
              error.value = true;
            },
          },
        );
      }

      function _redirectToResultPage() {
        broadcast.postMessage({action: "reload.records"});
        router.get(props.recordShowUrl);
      }

      return {
        /** const */
        showBuzzWords,
      };
    },
  };
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .flex-col-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
</style>
