<template>
  <div>
    <dashboard-widget class="min-h-48" :test-id="testId + '-bmp-import-exclusive'">
      <template #title>
        <a
          class="group hover:no-underline flex items-center space-x-3"
          :data-test="testId + '-bmp-import-exclusive-widget-open-bmp-dialog-icon-button'"
          @click.prevent="openBmpImportDialog()"
        >
          <component-icon class="p-2 bg-mcgreen group-hover:bg-mcgreen-450 rounded-full text-white font-normal">
            barcode_reader
          </component-icon>

          <span class="text-gray-700 group-hover:text-gray-900">BMP importieren</span>
        </a>
      </template>

      <template #default>
        <p class="text-lg">Starten Sie mit einem Medikationsplan</p>
      </template>

      <template #actions>
        <component-button
          class="p4umc-accent"
          label="BMP importieren"
          data-test="dashboard-bmpImportWidget-button-openBmpDialog"
          :test-id="testId + '-bmp-import-exclusive-widget-open-bmp-dialog'"
          @click="openBmpImportDialog()"
        />
      </template>
    </dashboard-widget>

    <component-bmp-import-dialog ref="refBmpImportDialog" :test-id="testId + '-bmp-import-exclusive-widget'" />
  </div>
</template>
<script>
  import {ref} from "vue";

  import ComponentBmpImportDialog from "@components/Dialogs/BmpImportDialog/BmpImportDialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  import DashboardWidget from "./Widget.vue";

  export default {
    name: "DashboardBmpImportExclusiveWidget",

    components: {
      ComponentBmpImportDialog,
      ComponentButton,
      ComponentIcon,
      DashboardWidget,
    },

    setup() {
      const refBmpImportDialog = ref(null);

      function openBmpImportDialog() {
        refBmpImportDialog.value.open();
      }

      return {
        /** ref */
        refBmpImportDialog,

        /** function */
        openBmpImportDialog,
      };
    },
  };
</script>
