<template>
  <div class="mb-4 flex items-center space-x-2">
    <h6 class="text-sm font-semibold cursor-default">
      Kommentare
      <template v-if="hasThirdParty('p4u')">(Kollegen / Fallfreigabe)</template>
    </h6>
    <component-info-icon>
      <template #content>
        <p class="max-w-sm">
          Kommentare werden in Echtzeit für diejenigen MediCheck-Nutzer sichtbar, die Zugriff auf diese Variante
          besitzen.
          <br />
          <span class="font-semibold">
            Hinweis: Kommentare gehen nicht in die Arzt- oder Patientenkommunikation ein.
          </span>
        </p>
      </template>
    </component-info-icon>
  </div>

  <component-comment-list
    :patient-id="patientId"
    :record-id="recordId"
    :tight="true"
    :comments="comments"
    @show-all="$emit('open:commentsDialog')"
    :data-test="dataTest + '-commentList'"
  />

  <form class="mt-4" @submit.prevent="save()">
    <component-tooltip
      v-if="!editAuthor"
      :force-inline="false"
      tooltip="Sie kommentieren mit diesem Namen"
      placement="left"
    >
      <div class="text-sm py-2 cursor-text hover:bg-yellow-50" @click="editAuthor = true">
        {{ form.data().author || "Bitte HIER Ihren Namen eintragen" }}
      </div>
    </component-tooltip>
    <component-input
      v-else
      v-model="form.author"
      placeholder="Bitte HIER Ihren Namen eintragen"
      :validation="form.errors.author"
      @blur="editAuthor = false"
      :data-test="dataTest + '-input-author'"
    />

    <component-textarea v-model="form.content" placeholder="Kommentar" rows="2" :validation="form.errors.content" />

    <div class="mt-1 flex justify-end">
      <component-button
        type="submit"
        class="p4umc-primary p4umc-flat"
        :icon-button="true"
        label="Speichern"
        :disabled="form.processing"
        :data-test="dataTest + '-button-save'"
      >
        <component-icon>mode_comment</component-icon>
      </component-button>
    </div>
  </form>

  <div v-if="showShareButton" class="mt-6 pt-2 border-t border-gray-200">
    <p class="text-sm">Möchten Sie diese Variante mit Kollegen besprechen?</p>
    <div class="flex justify-end">
      <component-button
        class="p4umc-primary p4umc-flat"
        label="Fallfreigabe"
        :icon-button="true"
        @click="$emit('open:shareDialog')"
        :data-test="dataTest + '-button-openShareDialog'"
      >
        <component-icon class="text-xl">share</component-icon>
      </component-button>
    </div>
  </div>
</template>

<script>
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import {ref, inject, watch, computed} from "vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentCommentList from "@components/Lists/CommentList.vue";
  import hasThirdParty from "@utils/Helpers/HasThirdParty.js";

  export default {
    name: "PagesRecordResultListClaimInteractionChat",
    components: {
      ComponentCommentList,
      ComponentInput,
      ComponentIcon,
      ComponentButton,
      ComponentTextarea,
      ComponentTooltip,
      ComponentInfoIcon,
    },
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      comments: {
        type: Array,
        required: true,
      },
      commentSection: {
        type: String,
        required: true,
      },
      hideShare: {
        type: Boolean,
        default: false,
      },
      dataTest: {
        type: String,
        default: "claimInteractionChat",
      },
    },
    emits: ["open:commentsDialog", "open:shareDialog"],
    setup(props) {
      const hasThirdParty = inject("$hasThirdParty");
      const sharedWithMe = inject("sharedWithMe");
      const editAuthor = ref(false);

      const pageProps = usePage().props;

      const form = useForm({
        area: props.commentSection === "default" ? "default" : "result",
        section: props.commentSection,
        content: null,
        author: pageProps.client.company
          ? pageProps.client.company +
            " (" +
            (pageProps.client.first_name ?? "") +
            " " +
            (pageProps.client.last_name ?? "") +
            ")".trim()
          : ((pageProps.client.first_name ?? "") + " " + (pageProps.client.last_name ?? "")).trim(),
      });

      function save() {
        form.post(route("comments.store", {patient: props.patientId, record: props.recordId}), {
          preserveScroll: true,
          onSuccess: () => {
            form.reset("content");
          },
        });
      }

      const showShareButton = computed(() => {
        return (
          !props.hideShare &&
          hasThirdParty("p4u") &&
          ((sharedWithMe && pageProps.can.allow_shared_document_forwarding) || !sharedWithMe)
        );
      });

      return {hasThirdParty, showShareButton, editAuthor, form, save};
    },
  };
</script>
