<template>
  <div class="w-full">
    <label
      :class="{'text-mcred': isInvalid, 'text-gray-500': !isInvalid & !large, 'text-sm': !large}"
      :for="fieldId"
      :data-test="testId + '-textarea-label'"
    >
      {{ label }}
    </label>

    <textarea
      :id="fieldId"
      ref="refTextarea"
      v-model="currentValue"
      class="resize-none overflow-y-auto"
      :class="background"
      :rows="rows"
      autocomplete="off"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      :data-test="testId + '-textarea'"
      @input="handleInput"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
      @blur="$emit('blur', $event)"
    />

    <input v-model="internalValue" type="hidden" :data-test="testId + '-textarea-internal-value'" />

    <div class="flex justify-between cursor-default">
      <div id="error" class="text-xs w-full text-mcred" :data-test="testId + '-textarea-error-message'">
        {{ validation }}
      </div>

      <span
        v-if="helperText && !isInvalid"
        id="helper"
        class="text-xs absolute text-gray-500"
        :data-test="testId + '-textarea-helper-text'"
        v-html="helperText"
      />

      <div
        v-if="maxlength"
        class="text-xs text-gray-500 text-right"
        :class="{'text-mcred': currentLength >= maxlength}"
        :data-test="testId + '-textarea-max-length-counter'"
      >
        {{ currentLength }}/{{ maxlength }}
      </div>
    </div>
  </div>
</template>

<script>
  import {computed, ref, watch, inject} from "vue";

  export default {
    name: "ComponentTextarea",

    props: {
      name: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      modelValue: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: "",
      },
      maxlength: {
        type: [Number, String],
        default: null,
      },
      validation: {
        type: String,
        default: null,
      },
      helperText: {
        type: String,
        default: null,
      },
      rows: {
        type: [Number, String],
        default: 5,
      },
      background: {
        type: String,
        default: "bg-white",
      },
      encrypted: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["keyup", "keydown", "blur", "input", "changed", "change", "update:modelValue"],

    setup(props, {emit}) {
      const privacy = inject("$privacy");

      const refTextarea = ref(null);

      const fieldId = ref(crypto.randomUUID());
      const currentValue = ref(null);
      const internalValue = ref(props.modelValue);

      const isInvalid = computed(() => props.validation);

      const currentLength = computed(() => {
        if (!currentValue.value) {
          return 0;
        }
        return currentValue.value.length;
      });

      watch(
        () => props.modelValue,
        (newValue) => {
          internalValue.value = newValue;
        },
      );

      watch(
        internalValue,
        (newValue) => {
          if (props.encrypted) {
            privacy.decryptValue(internalValue.value).then((plaintext) => {
              currentValue.value = plaintext;
            });
          } else {
            currentValue.value = newValue;
          }
        },
        {immediate: true},
      );

      const handleInput = () => {
        if (props.encrypted && currentValue.value.trim() !== "") {
          // encrypt non-empty values
          privacy.encryptValue(currentValue.value).then((hash) => {
            internalValue.value = hash;

            emit("update:modelValue", internalValue.value);
            emit("input", internalValue.value);
            emit("change", internalValue.value);
          });
        } else {
          // set empty values to null
          internalValue.value = currentValue.value || null;

          emit("update:modelValue", internalValue.value);
          emit("input", internalValue.value);
          emit("change", internalValue.value);
        }
      };

      const focus = () => {
        refTextarea.value.focus();
      };

      return {
        /** ref */
        refTextarea,

        /** const */
        fieldId,
        internalValue,
        currentValue,

        /** computed */
        currentLength,
        isInvalid,

        /** function */
        handleInput,
        focus,
      };
    },
  };
</script>
