<template>
  <component
    :is="href && !isExternal ? 'inertia-link' : 'a'"
    v-bind="$attrs"
    :href="href"
    class="p4umc-btn hover:no-underline p-1 rounded border-2 focus-visible:ring ring-red-500 outline-0 disabled:text-gray-400 disabled:border-gray-200 disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:border-gray-200"
    :disabled="disabled"
    :data-test="testId + (href && !isExternal ? '-inertia-link' : '-link') + '-button'"
  >
    <div :class="{'flex items-center': hasSlot && hasLabel}">
      <span
        v-if="hasLabel"
        class="font-medium uppercase"
        :data-test="testId + (href && !isExternal ? '-inertia-link' : '-link') + '-button-label'"
      >
        {{ label }}
      </span>
      <slot />
    </div>
  </component>
</template>

<script>
  import {Link as InertiaLink} from "@inertiajs/vue3";
  import {computed} from "vue";

  export default {
    name: "ComponentLinkButton",
    components: {InertiaLink},

    props: {
      label: {
        type: String,
        default: "",
      },
      href: {
        type: String,
        default: "#",
      },
      disabled: {
        type: Boolean,
        default: true,
      },
      isExternal: {
        type: Boolean,
        default: false,
      },
    },

    setup(props, {slots}) {
      const hasSlot = computed(() => !!slots.default);

      const hasLabel = computed(() => props.label && props.label.length > 0);

      return {
        /** computed */
        hasLabel,
        hasSlot,
      };
    },
  };
</script>
