<template>
  <div class="text-center">
    <component-tooltip placement="top" :tooltip="`Fallbeispiel „${name}“`" :test-id="testId">
      <component-icon-button
        class="w-10 h-10 md:w-16 md:h-16 !p-0 !border-0"
        :test-id="testId"
        @click="$emit('select', demodataId)"
      >
        <img :src="imgSrc" alt="Avatar" class="hover:opacity-80 rounded-full h-full w-full" />
      </component-icon-button>
    </component-tooltip>

    <div class="text-mcblue pt-1.5">
      {{ name }}

      <br />
    </div>

    <div class="text-mcblue pt-1.5 font-light">
      {{ disease }}

      <br />
    </div>
  </div>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import {data} from "autoprefixer";

  export default {
    name: "ComponentDemodataDialogSelectableDemocase",

    components: {ComponentIconButton, ComponentTooltip},

    props: {
      demodataId: {
        type: Number,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      disease: {
        type: String,
        required: true,
      },
      imgSrc: {
        type: [String, Object],
        required: true,
      },
    },

    emits: ["select"],
  };
</script>
