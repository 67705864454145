class Fraction {
  constructor() {
    this.fractions = [
      {
        fractionChar: "½",
        fractionString: "1/2",
        decimal: 0.5,
      },
      {
        fractionChar: "⅓",
        fractionString: "1/3",
        decimal: 0.33,
        decimalRounded: true,
      },
      {
        fractionChar: "¼",
        fractionString: "1/4",
        decimal: 0.25,
      },
      {
        fractionChar: "⅔",
        fractionString: "2/3",
        decimal: 0.66,
        decimalRounded: true,
      },
      {
        fractionChar: "¾",
        fractionString: "3/4",
        decimal: 0.75,
      },
      {
        fractionChar: "⅛",
        fractionString: "1/8",
        decimal: 0.125,
      },
    ];
  }

  /**
   * @param input {string}
   * @return {string}
   */
  normalizeInputToString(input) {
    return input.toString().trim().replaceAll(",", ".");
  }

  /**
   * @param input {string}
   * @return {string}
   */
  formatToGermanDecimalOrNaN(input) {
    return new Intl.NumberFormat("de-DE", {maximumFractionDigits: 2, useGrouping: false}).format(input);
  }

  /**
   * @param input {string}
   * @return {null|string}
   */
  formatToGermanDecimal(input) {
    const inputAsFloat = this.getDecimalValueOrNaN(input);

    if (isNaN(inputAsFloat)) {
      return null;
    }

    return this.formatToGermanDecimalOrNaN(inputAsFloat);
  }

  findFraction(input) {
    input = this.normalizeInputToString(input);

    const inputFloat = parseFloat(input);

    return this.fractions.find((fractionEl) => {
      if (fractionEl.fractionChar === input) {
        return true;
      } else if (fractionEl.fractionString === input) {
        return true;
      } else if (input.match(/[^0-9|^.]/)) {
        return false;
      } else if (fractionEl.decimal === inputFloat) {
        return true;
      } else if (fractionEl.decimalRounded && input.startsWith(fractionEl.decimal.toString())) {
        return true;
      } else {
        return false;
      }
    });
  }

  getFractionChar(input) {
    const result = this.findFraction(input);

    if (result) {
      return result.fractionChar;
    } else {
      return null;
    }
  }

  hasFraction(input) {
    return !!this.findFraction(input);
  }

  getDecimalValueOrNaN(input) {
    input = this.normalizeInputToString(input);
    const inputAsFloat = parseFloat(input);

    const fractionEl = this.findFraction(input);

    if (fractionEl) {
      return fractionEl.decimal;
    }

    return inputAsFloat;
  }

  getDecimalValue(input) {
    const decimalValueOrNaN = this.getDecimalValueOrNaN(input);

    if (isNaN(decimalValueOrNaN)) {
      return 0;
    } else {
      return decimalValueOrNaN;
    }
  }

  calculateFloatFromFraction(fraction) {
    if (!fraction) {
      return NaN;
    }

    const up_down = fraction.split("/");

    return parseInt(up_down[0]) / parseInt(up_down[1]);
  }
}

export default new Fraction();
