<template>
  <div :class="{'enable-resize': enableResize}">
    <label
      v-if="label.length > 0"
      class="duration-300 -z-1 origin-0 text-sm truncate cursor-default"
      :class="{'text-mcred': validation, 'text-gray-500': !validation}"
      :data-test="testId + '-trix-editor-label'"
    >
      {{ label }}
    </label>

    <div class="py-1" :class="{disabled: disabled, 'hide-toolbar': hideToolbar}" @click="$emit('click', $event)">
      <trix-editor
        ref="refTrix"
        class="text-clip overflow-y-auto overflow-x-hidden text-left"
        :class="editorClass"
        :contenteditable="!disabled"
        :placeholder="placeholder"
        :data-test="testId + '-trix-editor'"
        @trix-initialize="initEventHandler"
        @trix-change="trixChangeEventHandler"
        @paste="pasteEventHandler"
        @trix-blur="blurEventHandler"
        @trix-file-accept="fileAcceptEventHandler"
        @mousedown="mousedownEventHandler"
        @keypress="keypressEventHandler"
      />

      <div class="text-xs text-mcred" :data-test="testId + '-trix-editor-error-message'">
        <slot name="validation">{{ validation }}</slot>
      </div>

      <div v-if="helperText || (maxlength && !disabled)" class="flex justify-between flex-wrap mt-1">
        <div
          v-if="helperText"
          class="text-xs text-gray-500"
          :data-test="testId + '-trix-editor-helper-text'"
          v-html="helperText"
        />

        <div v-else />

        <div
          v-if="maxlength && !disabled"
          class="text-xs text-gray-500"
          :class="{'text-mcred': charCount >= maxlength}"
          :data-test="testId + '-trix-editor-max-length-counter'"
        >
          {{ charCount }}/{{ maxlength }}
        </div>
      </div>
    </div>

    <div ref="slotContent" class="hidden">
      <slot />
    </div>
  </div>
</template>

<script>
  import {computed, inject, onBeforeUnmount, ref, watch} from "vue";

  import "./Utils/Editor.js";

  export default {
    name: "ComponentEditor",

    props: {
      modelValue: {
        type: String,
        default: "",
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: [String, Boolean],
        default: false,
      },
      hideToolbar: {
        type: Boolean,
        default: false,
      },
      maxlength: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      enableResize: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
        default: "",
      },
      validation: {
        type: String,
        default: null,
      },
      helperText: {
        type: String,
        default: null,
      },
      editorClass: {
        type: String,
        default: null,
      },
      encrypted: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["initialized", "update:modelValue", "click", "mousedown", "blur", "decrypted"],

    setup(props, {slots, emit}) {
      const privacy = inject("$privacy");

      const initValue = ref("");
      const charCount = ref(0);
      const slotObserver = ref(null);

      const refTrix = ref(null);
      const slotContent = ref(null);

      const maxlength = computed(() => props.maxlength ?? 50000);

      watch(
        () => props.modelValue,
        (newValue) => {
          if (refTrix.value && newValue !== refTrix.value.value) {
            if (props.encrypted) {
              privacy.decryptValue(newValue).then((res) => {
                if (res !== refTrix.value.value) {
                  loadHtml(newValue);
                }
              });
            } else {
              loadHtml(newValue);
            }
          }
        },
      );

      onBeforeUnmount(() => {
        slotObserver?.value?.disconnect();
      });

      const loadHtml = (html, between = null) => {
        const loader = (html) => {
          refTrix.value.editor.loadHTML(html);
          if (typeof between === "function") between();
          initValue.value = refTrix.value.value;
        };

        if (props.encrypted) {
          privacy.decryptValue(html).then((res) => {
            // update initValue after decryption
            // to properly detect changes (dirty state)
            initValue.value = res;
            loader(res);
            emit("decrypted", res);
          });
        } else {
          loader(html);
        }
      };

      const initEventHandler = () => {
        if (!refTrix.value) return;

        if (!props.disabled && props.autofocus) {
          focus();
        }

        if (props.modelValue && props.modelValue !== "") {
          loadHtml(props.modelValue);
        } else if (slotContent.value?.innerHTML?.length > 0) {
          loadHtml(slotContent.value.innerHTML, () => {
            refTrix.value.value = refTrix.value.value.replaceAll("<p>&nbsp;", "<p>").replaceAll("<br>&nbsp;", "<br>");
          });
        }

        emit("initialized", initValue.value);

        slotObserver.value = new MutationObserver(() => {
          refTrix.value.editor.loadHTML(slotContent.value.innerHTML);
          refTrix.value.value = refTrix.value.value.replaceAll("<p>&nbsp;", "<p>").replaceAll("<br>&nbsp;", "<br>");
          initValue.value = refTrix.value.value;
        });

        slotObserver.value.observe(slotContent.value, {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        });
      };

      const trixChangeEventHandler = () => {
        // do not emit an updated value if nothing has changed
        if (refTrix.value.value === initValue.value) return;

        if (props.encrypted) {
          privacy.encryptValue(refTrix.value.value).then((res) => {
            emit("update:modelValue", res);
          });
        } else {
          emit("update:modelValue", refTrix.value.value);
        }

        charCount.value = refTrix.value.editor.getDocument().getLength() - 1;
      };

      const blurEventHandler = () => emit("blur");

      const pasteEventHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("Text");

        const selectedLength = refTrix.value.editor.getSelectedDocument().getLength() - 1;

        if (
          pastedData &&
          pastedData !== "" &&
          charCount.value + pastedData.length - selectedLength <= maxlength.value
        ) {
          refTrix.value.editor.insertString(pastedData);
        }
      };

      const fileAcceptEventHandler = (event) => event.preventDefault();

      const mousedownEventHandler = (event) => emit("mousedown", event);

      const keypressEventHandler = (event) => {
        if (charCount.value >= maxlength.value) {
          event.preventDefault();
          event.stopPropagation();
        }
      };

      const focus = () => {
        if (refTrix.value && !props.disabled) refTrix.value.focus();
      };

      const blur = () => {
        if (refTrix.value && !props.disabled) refTrix.value.blur();
      };

      return {
        /** ref */
        refTrix,

        /** const */
        charCount,
        slotContent,

        /** function */
        initEventHandler,
        trixChangeEventHandler,
        blurEventHandler,
        pasteEventHandler,
        fileAcceptEventHandler,
        mousedownEventHandler,
        keypressEventHandler,

        /** function (external) */
        focus,
        blur,
      };
    },
  };
</script>

<style scoped>
  /* see resources/css/editor.css */
</style>
