<template>
  <patientinput-component-step title="Fast fertig" :test-id="testId + '-end'">
    <template v-if="form.hasErrors">
      <component-alert type="error" :test-id="testId + '-end-step'">
        <p>Beim Absenden ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>
      </component-alert>
    </template>

    <template v-if="form.meta.is_patient">
      <component-textarea
        v-model="form.additional"
        large
        label="Möchten Sie sonst noch etwas mitteilen?"
        :maxlength="500"
        :test-id="testId + '-end-step-additional'"
      />
    </template>

    <template v-else>
      <p>Sie können die Checkliste nun absenden.</p>
    </template>

    <template #navigation>
      <component-button
        class="p4umc-primary sm:order-2 flex-1"
        :disabled="form.processing"
        :test-id="testId + '-end-step-processing'"
        @click="$emit('navigate', 'submit')"
      >
        <template v-if="form.processing">
          <div class="flex items-center justify-center">
            <component-spinner class="h-5 w-5" :test-id="testId + '-end-step-processing'" />
          </div>
        </template>

        <template v-else>Absenden</template>
      </component-button>

      <component-button class="flex-1" :test-id="testId + '-end-step-back'" @click="$emit('navigate', '-')">
        Zurück
      </component-button>
    </template>

    <template #bottom>
      <p>Ihre Angaben werden sicher Ende-zu-Ende verschlüsselt übertragen.</p>
    </template>
  </patientinput-component-step>
</template>

<script>
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";

  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepEnd",

    components: {ComponentAlert, ComponentSpinner, ComponentTextarea, PatientinputComponentStep, ComponentButton},

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["navigate"],
  };
</script>
