<template>
  <div
    v-if="customIcon === null"
    class="select-none p4umc-icon"
    :class="{'cursor-pointer': clickable}"
    :data-test="testId + '-icon'"
    @click="$emit('click')"
  >
    <slot />
  </div>

  <svg
    v-else-if="customIcon === 'patient'"
    xmlns="http://www.w3.org/2000/svg"
    :class="{'size-4': smallCustomIcon, 'size-6': !smallCustomIcon}"
    viewBox="0 0 24 24"
    fill="currentColor"
    :data-test="testId + '-icon-svg-patient'"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill="currentColor"
      d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 9c2.7 0 5.8 1.29 6 2v1H6v-.99c.2-.72 3.3-2.01 6-2.01m0-11C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
    />
  </svg>

  <svg
    v-else-if="customIcon === 'doctor'"
    xmlns="http://www.w3.org/2000/svg"
    :class="{'size-4': smallCustomIcon, 'size-6': !smallCustomIcon}"
    viewBox="0 0 24 24"
    :data-test="testId + '-icon-svg-doctor'"
  >
    <g fill="none" stroke="currentColor" transform="scale(0.44), translate(7,7)">
      <g stroke-width="4">
        <path
          d="M3.636 3.89c-4.787 20.066 9.147 22.778 9.147 22.778M21.15 3.89c4.421 18.838-8.368 22.778-8.368 22.778"
        />
      </g>
      <path stroke-linecap="round" stroke-width="3" d="M1.819 4.156l4-1M18.799 3.27l4.1.43" />
      <g stroke-width="3">
        <circle cx="33.2" cy="17.6" r="4.5" />
      </g>
      <path d="M12.774 26.583c-.7 5.1 4.059 11.466 12.484 10.131s8.075-8.975 7.882-15.05" stroke-width="4" />
    </g>
  </svg>
</template>

<script>
  import {onMounted, ref, useSlots} from "vue";

  export default {
    name: "ComponentIcon",

    props: {
      clickable: {
        type: Boolean,
        default: false,
      },
      custom: {
        type: String,
        default: null,
      },
    },

    emits: ["click"],

    setup(props) {
      const slots = useSlots();

      const customIcon = ref(props.custom);
      const smallCustomIcon = ref(true);

      onMounted(() => {
        // handle a slot value like "custom:icon" like a custom prop
        const defaultIcon =
          typeof slots.default === "function" && slots.default().length ? slots.default()[0].children : null;
        if (defaultIcon !== null && defaultIcon.startsWith("custom:")) {
          customIcon.value = defaultIcon.split(":")[1];
          smallCustomIcon.value = false;
        }
      });

      return {
        /** const */
        customIcon,
        smallCustomIcon,
      };
    },
  };
</script>
