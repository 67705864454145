<template>
  <component-dialog
    ref="refDialog"
    dialog-width="medium"
    :dialog-title="dialogTitle"
    :is-loading="isLoading"
    :test-id="testId + '-record-share'"
  >
    <template #content>
      <component-tabs :active-tab="activeTab" @click="tabChanged">
        <component-tab title="Direktfreigabe" :test-id="testId + '-record-share-dialog-direct-share'">
          <div v-if="$page.props.can.is_nident_token" class="mt-6 space-y-4">
            <p>
              Sie sind aktuell über Ihre Warenwirtschaft in den MediCheck eingesprungen, daher kann nicht auf Daten der
              pharma4u zum Abgleich der Empfängeradresse zugegriffen werden.
            </p>

            <p class="mt-4">
              Sie können diese Variante direkt an eine Person teilen, wenn Sie sich mit Ihren pharma4u-Zugangsdaten
              direkt im MediCheck einloggen.
            </p>
          </div>

          <template v-else>
            <div v-if="form.wasSuccessful" class="mt-6 space-y-4">
              <p>
                Erfolgreich geteilt mit:
                <br />
                <strong>{{ form.email }}</strong>
              </p>

              <p v-if="form.notify_recipient">
                Der Empfänger wird per E-Mail über Ihre Freigabe benachrichtigt und kann die geteilte Variante dort über
                einen Link öffnen bzw. bearbeiten.
              </p>

              <p v-else>
                Der Empfänger wird
                <em>nicht</em>
                per E-Mail benachrichtigt.
              </p>
            </div>

            <div v-else class="mt-6 grid grid-cols-3 gap-4">
              <component-input
                v-model="form.email"
                class="col-start-1 col-span-2"
                label="E-Mail-Adresse des pharma4u-Accounts"
                :validation="form.errors.email"
                :test-id="testId + '-record-share-dialog-direct-share-tab-email'"
              />

              <component-input
                v-model="form.aliasname"
                class="col-start-1 col-span-2"
                label="Alias-Name / Kurznotiz (max. 30 Zeichen)"
                maxlength="30"
                :validation="form.errors.aliasname"
                :test-id="testId + '-record-share-dialog-direct-share-tab-alias-name'"
              />

              <div class="col-span-3">
                <component-textarea
                  v-model="form.info"
                  label="Weiteres zum Patienten"
                  :validation="form.errors.info"
                  :test-id="testId + '-record-share-dialog-direct-share-tab-info'"
                />

                <component-alert type="error" class="mt-2" :test-id="testId + '-record-share-dialog-direct-share-tab'">
                  Bitte keine personenbezogenen Daten wie bspw. Name oder Geburtsdatum eingeben.
                </component-alert>
              </div>

              <component-checkbox
                v-model="form.notify_recipient"
                color="primary"
                class="col-span-3"
                :test-id="testId + '-record-share-dialog-direct-share-tab-notify-recipient'"
              >
                Empfänger per E-Mail benachrichtigten
              </component-checkbox>

              <div v-if="form.errors.server_side" class="text-mcred col-span-3">{{ form.errors.server_side }}</div>
            </div>
          </template>
        </component-tab>

        <component-tab
          v-if="$page.props.can.allow_shared_document_forwarding"
          title="Linkfreigabe"
          :test-id="testId + '-record-share-dialog-share-link'"
        >
          <div v-if="form.wasSuccessful" class="mt-6 space-y-4">
            <p>
              Die Seminar- / Linkfreigabe wurde erfolgreich erstellt. Bitte kopieren Sie sich den untenstehenden Link
              und senden Sie ihn an Ihren Kollegen / Teilnehmern.
            </p>

            <div class="flex flex-wrap border-b border-gray-300 pb-6 justify-between">
              <div class="flex items-center">
                <strong v-text="shareLinkUrl" />
                <textarea ref="refShareLinkUrl" style="width: 0px; height: 0px; opacity: 0" v-text="shareLinkUrl" />
              </div>

              <div>
                <component-button
                  class="p4umc-primary"
                  label="Link kopieren"
                  :icon-button="true"
                  :test-id="testId + '-record-share-dialog-share-link-tab-copy-to-clipboard'"
                  @click="copyToClipboard()"
                >
                  <component-icon v-if="copyingShareLinkUrl">content_paste</component-icon>
                  <component-icon v-else>content_copy</component-icon>
                </component-button>
              </div>
            </div>

            <p>
              Wenn der Empfänger diesen Link aufruft, wird diese Variante umgehend freigegeben, so als hätten Sie eine
              direkte Fallfreigabe erstellt.
            </p>
          </div>

          <div v-else class="mt-6 space-y-4">
            <p>
              Bei einer Seminar- / Linkfreigabe erhalten Sie einen Link, den Sie ihren Kollegen / Teilnehmern bspw. per
              E-Mail zukommen lassen. Mit dem Aufruf dieses Links erhält ein Berechtigter Zugriff auf diese Variante.
            </p>

            <div class="grid grid-cols-3 gap-4">
              <component-input
                v-model="form.aliasname"
                class="col-start-1 col-span-2"
                label="Alias-Name / Kurznotiz (max. 30 Zeichen)"
                maxlength="30"
                :validation="form.errors.aliasname"
                :test-id="testId + '-record-share-dialog-share-link-tab-alias-name'"
              />

              <div class="col-span-3">
                <component-textarea
                  v-model="form.info"
                  label="Weiteres zum Patienten"
                  :validation="form.errors.info"
                  :test-id="testId + '-record-share-dialog-share-link-tab-info'"
                />

                <component-alert type="error" class="mt-2" :test-id="testId + '-record-share-dialog-share-link-tab'">
                  Bitte keine personenbezogenen Daten wie bspw. Name oder Geburtsdatum eingeben.
                </component-alert>
              </div>

              <div v-if="form.errors.server_side" class="text-mcred col-span-3">{{ form.errors.server_side }}</div>
            </div>
          </div>
        </component-tab>
      </component-tabs>
    </template>

    <template #actions>
      <component-button
        v-if="!$page.props.can.is_nident_token && !form.wasSuccessful"
        class="p4umc-primary"
        :label="activeTab === 'Direktfreigabe' ? 'Freigeben' : 'Link erstellen'"
        :disabled="form.processing"
        :test-id="testId + '-record-share-dialog-save'"
        @click="save()"
      />

      <component-button
        :label="form.wasSuccessful ? 'Schließen' : 'Abbrechen'"
        :test-id="testId + '-record-share-dialog-close'"
        @click="close()"
      />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, ref} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentTab from "@components/Tab/Tab.vue";
  import ComponentTabs from "@components/Tab/Tabs.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";

  export default {
    name: "ComponentRecordShareDialog",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentCheckbox,
      ComponentDialog,
      ComponentIcon,
      ComponentInput,
      ComponentTab,
      ComponentTabs,
      ComponentTextarea,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: [Object, null],
        default: null,
      },
    },

    expose: ["open", "close"],

    setup(props) {
      const privacy = inject("$privacy");

      const refDialog = ref(null);
      const refShareLinkUrl = ref(null);

      const currentRecord = ref(props.record);
      const isLoading = ref(false);
      const shareLinkUrl = ref(null);
      const copyingShareLinkUrl = ref(false);

      const activeTab = ref("Direktfreigabe");

      let form = useForm({
        email: "",
        notify_recipient: true,
        info: null,
        aliasname: "",
      });

      const dialogTitle = computed(() => {
        if (activeTab.value === "Direktfreigabe") {
          if (form.wasSuccessful) {
            return "Variante wurde geteilt";
          } else if (form.errors.length) {
            return "Variante konnte nicht geteilt werden";
          }

          return "Mit wem möchten Sie diese Variante teilen?";
        } else if (activeTab.value === "Linkfreigabe") {
          if (form.wasSuccessful) {
            return "Seminar- / Linkfreigabe wurde erstellt";
          } else if (form.errors.length) {
            return "Seminar - / Linkfreigabe konnte nicht erstellt werden";
          }

          return "Seminar- / Linkfreigabe";
        }

        return "";
      });

      const tabChanged = (newActiveTab) => {
        form.clearErrors();
        form.wasSuccessful = false;
        activeTab.value = newActiveTab;
      };

      function open(record) {
        // set defaults
        form.email = "";
        form.notify_recipient = true;
        form.aliasname = "";

        if (typeof record !== "undefined") {
          currentRecord.value = record;
        }

        privacy.decryptValue(currentRecord.value.info).then((plaintext) => {
          if (plaintext !== null) {
            form.info = plaintext;
          }
        });

        refDialog.value.open();
      }

      function close() {
        form.reset();
        form.clearErrors();
        form.wasSuccessful = false;
        refDialog.value.close();
      }

      function save() {
        isLoading.value = true;
        form.clearErrors();

        if (activeTab.value === "Direktfreigabe") {
          // store shared record
          form.post(
            route("sharedrecords.store", {
              patient: props.patient.id,
              record: currentRecord.value.id,
            }),
            {
              preserveScroll: true,
              onFinish: () => {
                isLoading.value = false;
              },
              onError: (error) => {
                if (error.exception) {
                  console.error(error.exception);
                }
                form.setError(
                  "server_side",
                  "Bei der Verarbeitung ist leider ein Fehler aufgetreten. Bitte prüfen Sie die E-Mail-Adresse des Empfängers.",
                );
              },
            },
          );
        } else {
          // store share link
          form
            .transform((data) => {
              delete data.email;
              delete data.notify_recipient;
              return data;
            })
            .post(
              route("sharelinks.store", {
                patient: props.patient.id,
                record: currentRecord.value.id,
              }),
              {
                preserveScroll: true,
                onFinish: () => {
                  isLoading.value = false;
                },
                onError: (error) => {
                  if (error.url) {
                    // that's not an error but the only method receive a
                    // response url without throwing the inertia form
                    // helper away and using a plain ajax request 🤯
                    shareLinkUrl.value = error.url;
                    form.wasSuccessful = true;
                  } else {
                    if (error.exception) {
                      // Well, that's an error.
                      console.error(error.exception);
                    }

                    form.setError("server_side", "Leider ist ein Fehler aufgetreten.");
                  }
                },
              },
            );
        }
      }

      function copyToClipboard() {
        copyingShareLinkUrl.value = true;

        setTimeout(() => {
          copyingShareLinkUrl.value = false;
        }, 1000);

        // Copy link to clipboard
        navigator.clipboard.writeText(refShareLinkUrl.value.value).catch((err) => {
          console.error("Fehler beim Kopieren des Textes: ", err);
        });
      }

      return {
        /** ref */
        refDialog,
        refShareLinkUrl,

        /** const */
        isLoading,
        activeTab,
        form,
        shareLinkUrl,
        copyingShareLinkUrl,

        /** computed */
        dialogTitle,

        /** function */
        tabChanged,
        open,
        close,
        save,
        copyToClipboard,
      };
    },
  };
</script>
