<template>
  <div>
    <!-- fam name / redhand / fi button -->
    <div class="flex items-center">
      <span
        v-if="medicationForm.value.abdata_key_fam === null || medicationForm.value.abdata_key_fam === 0"
        class="text-lg font-medium text-mcred pr-1"
      >
        **
      </span>
      <span class="text-lg font-medium hyphens-auto">{{ medicationForm.value.fam }}</span>

      <div v-if="medicationForm.value.redhand" class="relative ml-2">
        <component-tooltip tooltip="Rote-Hand-Brief (ggf. weitere Dokumente)">
          <button
            class="rounded-full bg-gray-200 w-7 h-7 text-sm"
            @click="openDialog('refCoreInfoDialog', medicationForm.value)"
          >
            FI
          </button>
          <component-icon class="text-mcred text-sm absolute -top-1 -right-1">pan_tool</component-icon>
        </component-tooltip>
      </div>

      <div v-else class="ml-2">
        <component-tooltip tooltip="Fachinformation (ggf. weitere Dokumente)">
          <button
            class="rounded-full bg-gray-200 w-7 h-7 text-sm"
            @click="openDialog('refCoreInfoDialog', medicationForm.value)"
          >
            FI
          </button>
        </component-tooltip>
      </div>
    </div>

    <!-- substances / form / manufacturer / pzn --->
    <div class="text-xs text-gray-500 mt-2">
      <template v-if="medicationForm.value.core.substancesText && medicationForm.value.core.substancesText.length > 0">
        {{ medicationForm.value.core.substancesText + " / " ?? "-" + " / " }}
      </template>

      <template v-if="medicationForm.value.form && medicationForm.value.form.length > 0">
        {{ medicationForm.value.form + " / " }}
      </template>

      <template v-if="medicationForm.value.core.manufactureText">
        {{ medicationForm.value.core.manufactureText + " / " }}
      </template>

      <span v-if="medicationForm.value.core.selectedPackageLabel === 'außer Vertrieb'">
        {{ medicationForm.value.core.selectedPackageLabel }}
      </span>

      <span v-else>PZN: {{ medicationForm.value.selected_package_pzn }}</span>

      <template v-if="medicationForm.value.core.package">{{ " / " + medicationForm.value.core.package }}</template>
    </div>

    <div class="mt-4 grid grid-cols-12 gap-x-2 gap-y-4">
      <!-- medication -->
      <div class="col-span-4 md:col-span-2">
        <component-select
          :id="'usage' + medicationForm.value.id"
          ref="usage"
          label="Art"
          :name="'usage' + medicationForm.value.id"
          :disabled="!isEditable"
          :model-value="medicationForm.value.usage"
          :nullable="true"
          :options="medicationUsage"
          @change="updateUsage"
        />
      </div>

      <div class="col-span-4 md:col-span-2 m-auto">
        <p class="text-gray-500 text-sm mb-1 ml-2">Dosierung</p>
        <div class="flex gap-1">
          <component-dosage-input
            :id="'medicationDataDosageMorning' + medicationForm.value.id"
            :value="dosageForm.value.morning"
            :name="'medicationDataDosageMorning' + medicationForm.value.id"
            :as-fraction="dosageForm.value.morning_as_fraction"
            :disabled="!isEditable"
            @input="updateDosage($event, 'morning')"
            @is-invalid="handleInvalidDosage"
          />

          <component-dosage-input
            :id="'medicationDataDosageNoon' + medicationForm.value.id"
            :value="dosageForm.value.noon"
            :name="'medicationDataDosageNoon' + medicationForm.value.id"
            :as-fraction="dosageForm.value.noon_as_fraction"
            :disabled="!isEditable"
            @input="updateDosage($event, 'noon')"
            @is-invalid="handleInvalidDosage"
          />

          <component-dosage-input
            :id="'medicationDataDosageEvening' + medicationForm.value.id"
            :value="dosageForm.value.evening"
            :name="'medicationDataDosageEvening' + medicationForm.value.id"
            :as-fraction="dosageForm.value.evening_as_fraction"
            :disabled="!isEditable"
            @input="updateDosage($event, 'evening')"
            @is-invalid="handleInvalidDosage"
          />

          <component-dosage-input
            :id="'medicationDataDosageNight' + medicationForm.value.id"
            :value="dosageForm.value.night"
            :name="'medicationDataDosageNight' + medicationForm.value.id"
            :as-fraction="dosageForm.value.night_as_fraction"
            :disabled="!isEditable"
            @input="updateDosage($event, 'night')"
            @is-invalid="handleInvalidDosage"
          />
        </div>
      </div>

      <div class="col-span-4 md:col-span-2">
        <component-select
          :id="'dosageUnit' + medicationForm.value.id"
          ref="unitfield"
          v-model="currentDosageUnit"
          label="Einheit:"
          :data-hash-id="medicationForm.value.id"
          :disabled="!isEditable"
          :options="currentDosageUnitList"
          key-value="keyDoe"
          key-name="unit"
          return-type="object"
          @change="updateDosageUnit"
        />
      </div>

      <div class="col-span-6 md:col-span-3">
        <component-select
          :id="'meal' + medicationForm.value.id"
          ref="meal"
          v-model="dosageForm.value.meal"
          :name="'meal' + medicationForm.value.id"
          :disabled="!isEditable"
          label="Mahlzeit"
          key-name="name"
          :nullable="true"
          :options="medicationMeal"
          @change="updateMeal"
        />
      </div>

      <div class="col-span-6 md:col-span-3">
        <medication-component-indication-display
          show-label
          :indications="medicationForm.value.indications"
          :indications-unknown="medicationForm.value.indication_unknown"
          :is-active="isEditable"
          :disabled="!isEditable"
          :is-discontinued="!!medicationForm.value.additionalinformation?.discontinued"
          @open-dialog="openIndicationDialog"
        />

        <template v-if="isEditable">
          <medication-component-indication-dialog
            ref="refIndicationMenuDialog"
            :is-detail-dialog="true"
            @form-change="$emit('changeForm', $event)"
            @close="$emit('resetIndications')"
          />
        </template>
      </div>
      <!-- medication end -->
    </div>
  </div>
</template>

<script>
  import {computed, inject, onBeforeMount, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import {cloneDeep} from "lodash";

  import dosageModel from "@models/dosageModel.js";

  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentDosageInput from "@components/Inputs/DosageInput.vue";

  import * as dosageHelper from "@pages/Records/Helper/dosageHelper.js";
  import {medicationMeal, medicationUsage} from "@pages/Records/Components/Sections/MedicationBar/enums.js";
  import MedicationComponentIndicationDisplay from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationDisplay.vue";
  import MedicationComponentIndicationDialog from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationDialog.vue";

  export default {
    name: "MedicationComponentDetailDialogMedicationData",

    components: {
      MedicationComponentIndicationDialog,
      MedicationComponentIndicationDisplay,
      ComponentDosageInput,
      ComponentSelect,
      ComponentIcon,
      ComponentTooltip,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      medicationForm: {
        type: Object,
        required: true,
      },
    },

    emits: ["invalid", "valid", "save", "changeForm", "resetIndications"],

    setup(props, {emit}) {
      const openDialog = inject("medicationOpenDialog");

      const refIndicationMenuDialog = ref(null);

      const dosageForm = useForm({
        value: null,
      });

      const currentDosageUnit = computed(() => dosageHelper.getCurrentDosageUnit(dosageForm.value));

      const currentDosageUnitList = computed(() => dosageHelper.getDosageUnitList(props.medicationForm.value.core));

      watch(
        () => props.medicationForm.value.dosage,
        () => {
          dosageForm.value = cloneDeep(props.medicationForm.value.dosage);
        },
        {deep: true},
      );

      onBeforeMount(() => {
        dosageForm.value = cloneDeep(dosageModel);
        Object.assign(dosageForm.value, props.medicationForm.value.dosage);
      });

      const handleInvalidDosage = (dosageId) => {
        emit("invalid", dosageId);
      };

      const updateDosage = (newValues, type) => {
        const asFraction = type + "_as_fraction";

        dosageHelper.updateDosage(dosageForm.value, type, newValues, () => {
          emit("changeForm", {key: "dosage." + type, newValue: dosageForm.value[type]});
          emit("changeForm", {key: "dosage." + asFraction, newValue: dosageForm.value[asFraction]});

          emit("valid", newValues.id);
        });
      };

      const updateUsage = (newValue) => {
        emit("changeForm", {key: "usage", newValue: newValue});
      };

      const updateDosageUnit = ({keyDoe, unit}) => {
        dosageHelper.updateDosageUnit(dosageForm.value, keyDoe, unit, () => {
          emit("changeForm", {key: "dosage.selected_unit", newValue: dosageForm.value.selected_unit});
          emit("changeForm", {key: "dosage.selected_unit_key_doe", newValue: dosageForm.value.selected_unit_key_doe});
        });
      };

      const updateMeal = () => {
        emit("changeForm", {key: "dosage.meal", newValue: dosageForm.value.meal});
      };

      const openIndicationDialog = () => {
        if (props.isEditable) {
          refIndicationMenuDialog.value.open({
            medication: props.medicationForm.value,
            medicationKeyFam: props.medicationForm.value.abdata_key_fam,
            medicationHashId: props.medicationForm.value.hashId,
          });
        }
      };

      return {
        /** enum */
        medicationMeal,
        medicationUsage,

        /** helper */
        dosageHelper,

        /** inject */
        openDialog,

        /** ref */
        refIndicationMenuDialog,

        /** const */
        dosageForm,

        /** computed */
        currentDosageUnit,
        currentDosageUnitList,

        /** function */
        handleInvalidDosage,
        updateDosage,
        updateUsage,
        updateDosageUnit,
        updateMeal,
        openIndicationDialog,
      };
    },
  };
</script>
