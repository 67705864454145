<template>
  <component-breadcrumb
    class="mt-2"
    :links="[
      {
        label: 'Patienten',
        url: patient.is_fake ? $route('patients.index', {filter: 'shared-with-me'}) : $route('patients.index'),
        testId: 'patient-index',
      },
      {
        encryptedLabel: [patient.firstname, patient.lastname],
        url: $route('patients.show', {patient: patient.id}),
        testId: 'patient-show',
      },
      {
        label: 'Patient bearbeiten',
      },
    ]"
  />

  <section class="mt-6 bg-white max-w-screen-xl">
    <div class="pt-4 px-4">
      <h2 class="text-2xl font-semibold">
        <span :data-encrypted="patient.firstname" />
        <span :data-encrypted="patient.lastname" />
        bearbeiten
      </h2>

      <p v-if="patient.is_fake">
        Dieser Patient wurde mit Ihnen geteilt, daher wurden personenbezogene Daten nicht weitergegeben.
      </p>
    </div>

    <article class="p-4 flex items-start flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
      <!-- article::main -->
      <div class="w-full lg:max-w-screen-md space-y-6">
        <patients-patient-master-data :patient="patient" :test-id="$page.props.testId" />
        <patients-patient-attributes-data :patient="patient" :test-id="$page.props.testId" />
        <patients-patient-contacts-data v-if="!patient.is_fake" :patient="patient" :test-id="$page.props.testId" />
      </div>

      <!-- article::aside -->
      <div class="grid gap-6 grid-cols-1 md:grid-cols-2 lg:w-96 lg:grid-cols-1">
        <patients-patient-note-data :patient="patient" :test-id="$page.props.testId" />

        <patients-patient-documents
          v-if="!patient.is_fake && hasThirdParty('p4u')"
          :patient="patient"
          :test-id="$page.props.testId"
        />

        <patients-patient-delete
          v-if="$page.props.can.delete_patients"
          :patient="patient"
          :test-id="$page.props.testId"
        />
      </div>
    </article>
  </section>
</template>

<script>
  import hasThirdParty from "@utils/Helpers/HasThirdParty.js";

  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";

  import PatientsPatientAttributesData from "@pages/Patients/Components/PatientAttributesData.vue";
  import PatientsPatientContactsData from "@pages/Patients/Components/PatientContactsData.vue";
  import PatientsPatientDelete from "@pages/Patients/Components/PatientDelete.vue";
  import PatientsPatientDocuments from "@pages/Patients/Components/PatientDocuments.vue";
  import PatientsPatientMasterData from "@pages/Patients/Components/PatientMasterData.vue";
  import PatientsPatientNoteData from "@pages/Patients/Components/PatientNoteData.vue";

  export default {
    name: "PagesPatientEdit",

    components: {
      ComponentBreadcrumb,
      PatientsPatientAttributesData,
      PatientsPatientContactsData,
      PatientsPatientDelete,
      PatientsPatientDocuments,
      PatientsPatientMasterData,
      PatientsPatientNoteData,
    },

    props: {
      patient: {
        type: Object,
        default: null,
      },
    },

    setup() {
      return {
        /** function */
        hasThirdParty,
      };
    },
  };
</script>
