<template>
  <div id="bg-login" class="flex flex-grow flex-1 bg-cover bg-top -mb-4 -mx-4" />
</template>

<script>
  import {defineComponent} from "vue";

  export default defineComponent({
    name: "PagesClientCreate",
  });
</script>
