<template>
  <section class="mt-6 bg-white max-w-screen-xl">
    <h2 class="sr-only text-2xl font-semibold">Datenschutzpasswort</h2>

    <article class="p-4 flex items-start flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
      <!-- article::main -->
      <div class="w-full lg:max-w-screen-md space-y-6">
        <component-card
          v-if="!privacy.hasAccountEducationStatus()"
          title="Datenschutzpasswort ändern"
          class="rounded-lg border-2 border-gray-200"
          :test-id="$page.props.testId + '-privacy-password'"
        >
          <div
            v-if="hasThirdParty('p4u') && hasThirdParty('nident')"
            class="py-2 max-w-xl"
            :data-test="$page.props.testId + '-info-message-wawi-user'"
          >
            Sie haben Ihr Datenschutzpasswort in Ihrer Warenwirtschaft hinterlegt. Da Sie sich dort Ihr aktuelles
            Datenschutzpasswort anzeigen lassen können, ist die Änderung des Datenschutzpassworts derzeit nicht möglich.
          </div>

          <div v-else>
            <div class="flex flex-col gap-5">
              <template v-if="step === 'currentPassword'">
                <component-alert type="warning" class="!mb-0" :test-id="$page.props.testId + '-close-all-browser'">
                  <div class="flex items-center">
                    <component-icon class="p4umc-primary text-xl mr-2">warning</component-icon>

                    <span>Alle weiteren Browser-Fenster schließen, in denen MediCheck geöffnet ist!</span>
                  </div>
                </component-alert>

                <h5 class="text-xl">Schritt 1: Ihr aktuelles Datenschutzpasswort</h5>

                <template v-if="requiresToSelectTheCurrentUser">
                  <p>
                    In Ihrem MediCheck sind mehrere Nutzer hinterlegt. Das System konnte den aktuell eingeloggen Nutzer
                    nicht erkennen. Bitte wählen Sie aus, wer aktuell eingeloggt ist.
                  </p>

                  <component-select
                    v-model="selectedCurrentUser"
                    label="Aktueller Nutzer"
                    key-name="name"
                    return-type="object"
                    :options="availableUsers"
                    data-test="privacyPassword-select-users"
                    :test-id="$page.props.testId + '-users'"
                    @change="onChange"
                  />
                </template>

                <template v-if="!requiresToSelectTheCurrentUser || selectedCurrentUser">
                  <template v-if="authenticationMode === 'currentPassword'">
                    <p>
                      Damit Sie Ihr Datenschutzpasswort ändern können, müssen Sie zuvor Ihr bisheriges
                      Datenschutzpasswort eingeben.
                    </p>

                    <form autocomplete="off" @submit.prevent>
                      <component-input
                        v-model="currentPassword"
                        autocomplete="off"
                        label="Aktuelles Datenschutzpasswort"
                        :validation="currentErrorMsg"
                        type="password"
                        :test-id="$page.props.testId + '-current-privacy-password'"
                      />
                    </form>

                    <p>
                      <a
                        :data-test="$page.props.testId + '-use-recovery-code-button'"
                        @click.prevent="switchAuthenticationMode('recoveryCode')"
                      >
                        Nutzen Sie Ihren Recovery-Code
                      </a>
                      , wenn Sie Ihr aktuelles Passwort vergessen haben.
                    </p>
                  </template>

                  <template v-else-if="authenticationMode === 'recoveryCode'">
                    <p>
                      Den 64 Zeichen langen Recovery-Code haben Sie sich beim Einrichten des Datenschutzpassworts
                      ausgedruckt oder gespeichert.
                    </p>

                    <form autocomplete="off" @submit.prevent>
                      <component-input
                        v-model="currentRecoveryCode"
                        autocomplete="off"
                        label="Recovery-Code"
                        :validation="currentErrorMsg"
                        type="text"
                        maxlength="64"
                        :test-id="$page.props.testId + '-current-recovery-code'"
                      />
                    </form>

                    <p>
                      Erinnern Sie sich wieder an
                      <a
                        href="#"
                        :data-test="$page.props.testId + '-use-privacy-password-button'"
                        @click.prevent="switchAuthenticationMode('currentPassword')"
                      >
                        Ihr aktuelles Passwort
                      </a>
                      ?
                    </p>
                  </template>

                  <div class="flex justify-end">
                    <component-button
                      label="weiter"
                      class="p4umc-primary"
                      :disabled="!validCurrentAuthentication"
                      :test-id="$page.props.testId + '-next-step'"
                      @click="switchStep('newPassword')"
                    />
                  </div>
                </template>
              </template>

              <template v-else-if="step === 'newPassword'">
                <h5 class="text-xl">Schritt 2: Ihr neues Datenschutzpasswort</h5>

                <div v-if="privacy.hasCryptStatus('ENCRYPTION_UPDATE')" class="flex flex-col items-center">
                  <component-spinner class="h-12 w-12" :test-id="$page.props.testId + '-encryption-update'" />
                </div>

                <form v-else autocomplete="off" class="flex flex-col gap-4" @submit.prevent>
                  <component-spinner v-show="!strengthCheckerLoaded" class="h-10 w-10" :test-id="$page.props.testId" />

                  <div v-show="strengthCheckerLoaded">
                    <component-input
                      v-model="passwordPlaintext"
                      label="Neues Passwort"
                      :validation="privacy.getErrorMessage()"
                      type="password"
                      :test-id="$page.props.testId + '-new-privacy-password'"
                      @blur="checkOnLength()"
                    />

                    <div class="text-xs text-gray-500 mt-2">
                      Mind. 8 Zeichen.
                      <br />
                      Mind. Passwortstärke mittel (orange)
                    </div>
                  </div>

                  <component-password-strength
                    v-show="strengthCheckerLoaded && passwordPlaintext.length > 0"
                    :password="passwordPlaintext"
                    :test-id="$page.props.testId"
                    @loaded="strengthCheckerLoaded = true"
                    @score-changed="passwordStrength = $event"
                  />

                  <template v-if="isPasswordStrongEnough">
                    <component-input
                      v-model="passwordConfirmation"
                      label="Wiederholung des neuen Passworts"
                      :validation="passwordConfirmationErrorMsg"
                      type="password"
                      :test-id="$page.props.testId + '-new-privacy-password-confirmation'"
                      @blur="checkOnLength()"
                    />
                  </template>
                </form>

                <div class="flex justify-end">
                  <component-button
                    class="p4umc-primary"
                    :disabled="
                      !validCurrentAuthentication || !valid || !validPasswordConfirmation || passwordStrength < 4
                    "
                    label="weiter"
                    :test-id="$page.props.testId + '-new-privacy-password-save'"
                    @click="changePassword()"
                  />
                </div>
              </template>

              <template v-else-if="step === 'passwordHasBeenChanged'">
                <div class="flex space-x-2">
                  <component-icon class="text-mcgreen text-2xl">done</component-icon>
                  <span>Ihr Datenschutzpasswort wurde erfolgreich geändert.</span>
                </div>

                <div class="flex justify-end">
                  <component-button
                    class="p4umc-primary"
                    label="fertig"
                    :test-id="$page.props.testId + '-set-step-to-done'"
                    @click="switchStep('currentPassword')"
                  />
                </div>
              </template>
            </div>
          </div>
        </component-card>

        <component-card
          v-else
          title="Datenschutzpasswort ändern"
          class="rounded-lg border-2 border-gray-200"
          :test-id="$page.props.testId + '-education'"
        >
          <p>
            Sie verwenden eine Version des MediCheck, in der
            <strong>nur fiktive</strong>
            bzw. anonymisierte oder didaktische
            <strong>und zu Lehr- bzw. Ausbildungszwecken</strong>
            konzipierte oder
            <strong>modifizierte Fälle</strong>
            bearbeitet werden dürfen.
          </p>

          <p class="mt-4">Ein Datenschutzpasswort wurde daher nicht festgelegt.</p>
        </component-card>
      </div>

      <div class="grid gap-6 grid-cols-1 md:grid-cols-2 lg:w-96 lg:grid-cols-1">
        <component-card
          class="rounded-lg border-2 border-gray-200"
          :test-id="$page.props.testId + '-change-password-info'"
        >
          Wenn Sie stattdessen Ihr Login-Passwort ändern möchten, ändern Sie dies bitte

          <a
            :href="p4uLinkChangePassword"
            class="text-mcred group/link hover:no-underline"
            target="_blank"
            :data-test="$page.props.testId + '-change-login-password-link'"
          >
            <span class="group-hover/link:underline">in Ihrem pharma4u-Benutzerkonto</span>

            <component-icon
              class="inline text-sm leading-tight text-gray-500 group-hover/link:text-gray-900 group-hover/link:no-underline"
              :clickable="true"
              title="Öffnet in neuem Tab"
            >
              launch
            </component-icon>
          </a>

          im Abschnitt „Login-Daten“.
        </component-card>
      </div>
    </article>
  </section>
</template>

<script>
  import {computed, inject, onMounted, ref, watch} from "vue";
  import _ from "lodash";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentPasswordStrength from "@components/PasswordStrength/PasswordStrength.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "PagePrivacyPassword",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentCard,
      ComponentIcon,
      ComponentInput,
      ComponentPasswordStrength,
      ComponentSelect,
      ComponentSpinner,
    },

    props: {
      p4uLinkChangePassword: {
        type: String,
        default: "",
      },
    },

    setup() {
      const hasThirdParty = inject("$hasThirdParty");
      const privacy = inject("$privacy");

      const authenticationMode = ref("currentPassword");
      const currentErrorMsg = ref("");
      const currentPassword = ref("");
      const currentRecoveryCode = ref("");
      const passwordConfirmation = ref("");
      const passwordConfirmationErrorMsg = ref(null);
      const passwordPlaintext = ref("");
      const passwordStrength = ref(0);
      const requiresToSelectTheCurrentUser = ref(false);
      const selectedCurrentUser = ref(null);
      const step = ref("currentPassword");
      const strengthCheckerLoaded = ref(false);
      const validCurrentAuthentication = ref(false);

      const availableUsers = computed(() => {
        return _.map(privacy.getUsers(), function (user) {
          return {
            value: user.id,
            name: user.name ? user.name + " (" + user.email + ")" : user.email,
          };
        });
      });

      const recoveryCodeLabel = computed(() => {
        const length = privacy.getRecoveryCodeLength() - currentRecoveryCodeLength.value;

        if (privacy.getRecoveryCodeLength() > currentRecoveryCodeLength.value) {
          return "Recovery-Code " + "(noch " + length + " Zeichen)";
        } else {
          return "Recovery-Code";
        }
      });

      const valid = computed(() => validPasswordMinLength.value && privacy.getErrorMessage() === null);

      const validPasswordConfirmation = computed(
        () => passwordConfirmation.value !== "" && passwordConfirmationErrorMsg.value === null,
      );

      const validPasswordMinLength = computed(
        () => passwordPlaintext.value.trim().length >= privacy.getPasswordMinLength(),
      );

      const currentRecoveryCodeLength = computed(() => {
        return currentRecoveryCode.value ? currentRecoveryCode.value.split(" ").join("").trim().length : 0;
      });

      const isPasswordStrongEnough = computed(() => passwordStrength.value >= import.meta.env.VITE_PASSWORD_STRENGTH);

      watch(
        () => passwordConfirmation.value,
        () => {
          comparePasswords();
        },
      );

      watch(
        () => currentPassword.value,
        (newValue) => {
          if (newValue.trim().length) verifyCurrentPassword();
        },
      );

      watch(
        () => currentRecoveryCode.value,
        (newValue) => {
          if (newValue !== null && newValue.length) verifyCurrentRecoveryCode();
        },
      );

      watch(
        () => selectedCurrentUser,
        (id) => {
          const currentUser = _.find(privacy.getUsers(), {id: id});

          if (currentUser) privacy.setCurrentUser(currentUser);

          switch (authenticationMode.value) {
            case "currentPassword":
              if (currentPassword.value) verifyCurrentPassword();
              break;
            case "recoveryCode":
              if (currentRecoveryCode.value) verifyCurrentRecoveryCode();
              break;
          }
        },
      );

      onMounted(() => {
        if (privacy.getCurrentUser() === null) {
          if (privacy.getUsers().length === 1) {
            privacy.setCurrentUser(privacy.getUsers()[0]);
          } else if (privacy.getUsers().length > 1) {
            requiresToSelectTheCurrentUser.value = true;
          }
        }
      });

      const onChange = (newUser) => {
        selectedCurrentUser.value = newUser;
      };

      const switchStep = (newStep) => {
        step.value = newStep;
      };

      const switchAuthenticationMode = (newAuthMode) => {
        reset();
        authenticationMode.value = newAuthMode;
      };

      const comparePasswords = () => {
        if (validPasswordMinLength.value && passwordConfirmation.value === "") {
          passwordConfirmationErrorMsg.value = "Bitte wiederholen Sie Ihre Eingabe.";
        } else if (validPasswordMinLength.value && passwordPlaintext.value !== passwordConfirmation.value) {
          passwordConfirmationErrorMsg.value = "Passwort stimmt nicht überein.";
        } else {
          passwordConfirmationErrorMsg.value = null;
        }
      };

      const reset = () => {
        currentPassword.value = "";
        currentRecoveryCode.value = "";
        passwordPlaintext.value = "";
        passwordConfirmation.value = "";
        currentErrorMsg.value = null;
        passwordConfirmationErrorMsg.value = null;
        validCurrentAuthentication.value = false;
      };

      const checkOnLength = () => {
        if (validPasswordMinLength.value === false) {
          privacy.setErrorMessage("Ihr Passwort muss mindestens 8 Zeichen lang sein.");
        } else if (passwordPlaintext.value.length > 64) {
          privacy.setErrorMessage("Die maximal mögliche Zeichenanzahl beträgt 64 Zeichen.");
        } else {
          privacy.setErrorMessage(null);
        }
      };

      const changePassword = () => {
        if (!valid.value) {
          privacy.setErrorMessage("Ihr Passwort muss mindestens 8 Zeichen lang sein.");
        } else {
          const callback = () => {
            privacy.initCrypt();
            privacy.setEncryptLocalStorage();

            privacy.whenCryptReady(() => {
              switchStep("passwordHasBeenChanged");
              reset();
            });
          };

          const errorCallback = () => {
            passwordPlaintext.value = "";
            passwordConfirmation.value = "";
            privacy.setErrorMessage("Ihr neues Passwort konnte nicht gespeichert werden.");
          };

          privacy.hashPlaintextPassword(passwordPlaintext.value).then((hashedPassword) => {
            privacy.setHashedPassword(hashedPassword);
            let avoidStatusUpdate = true;
            privacy.patchCurrentUserEncryption(callback, errorCallback, hashedPassword, avoidStatusUpdate);
          });
        }
      };

      const verifyCurrentPassword = () => {
        if (currentPassword.value.length < privacy.getPasswordMinLength()) {
          currentErrorMsg.value = "Ihr aktuelles Passwort ist mindestens 8 Zeichen lang.";
          validCurrentAuthentication.value = false;
        } else {
          privacy
            .hashPlaintextPassword(currentPassword.value)
            .then((hashedPassword) => {
              privacy
                .verifyPasswordWithUserEncryptionChecksum(hashedPassword)
                .then((user) => {
                  if (user.id !== privacy.getCurrentUser().id) {
                    currentErrorMsg.value = "Ihr aktuelles Passwort ist nicht korrekt.";
                    validCurrentAuthentication.value = false;
                  } else {
                    currentErrorMsg.value = null;
                    validCurrentAuthentication.value = true;
                  }
                })
                .catch(() => {
                  // TODO log error on any way
                  currentErrorMsg.value = "Ihr aktuelles Passwort ist nicht korrekt.";
                  validCurrentAuthentication.value = false;
                });
            })
            .catch(() => {
              // TODO log error on any way
              currentErrorMsg.value = "Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.";
              validCurrentAuthentication.value = false;
            });
        }
      };

      const verifyCurrentRecoveryCode = _.debounce(function () {
        if (currentRecoveryCodeLength.value !== privacy.getRecoveryCodeLength()) {
          currentErrorMsg.value = "Der Recovery-Code muss genau 64 Zeichen lang sein.";
          validCurrentAuthentication.value = false;
        } else {
          privacy
            .verifyRecoveryCodeWithClientEncryptionChecksum(currentRecoveryCode.value.split(" ").join(""))
            .then(() => {
              currentErrorMsg.value = null;
              validCurrentAuthentication.value = true;
            })
            .catch(() => {
              currentErrorMsg.value = "Der Recovery-Code ist nicht korrekt.";
              validCurrentAuthentication.value = false;
            });
        }
      });

      return {
        /** inject */
        hasThirdParty,
        privacy,

        /** const */
        authenticationMode,
        currentErrorMsg,
        currentPassword,
        currentRecoveryCode,
        passwordConfirmation,
        passwordConfirmationErrorMsg,
        passwordPlaintext,
        passwordStrength,
        requiresToSelectTheCurrentUser,
        selectedCurrentUser,
        step,
        strengthCheckerLoaded,
        validCurrentAuthentication,

        /** computed */
        availableUsers,
        isPasswordStrongEnough,
        recoveryCodeLabel,
        valid,
        validPasswordConfirmation,

        /** function */
        onChange,
        switchStep,
        switchAuthenticationMode,
        checkOnLength,
        changePassword,
      };
    },
  };
</script>

<style lang="css" scoped>
  @media print {
    .hide-print {
      display: none;
    }
    .recovery {
      display: block;
      max-width: 80%;
      font-size: 1.4em;
      word-break: break-all;
    }
  }
  @media screen {
    .hide-screen {
      display: none;
    }
  }
</style>
