<template>
  <component-dialog ref="refCoreInfoDialog" dialog-type="information" :dialog-width="dialogWidth">
    <template #content>
      <div v-if="type === medicalInfoTypes.text">
        <component-core-info-dialog-text :data="payload" :dialog-type="currentDialogType" />
      </div>

      <div v-else>Unbekannter Typ!</div>
    </template>

    <template #actions>
      <component-button v-if="type === medicalInfoTypes.alternative && searchEnabled" @click="searchForAlternatives">
        Jetzt suchen
      </component-button>

      <component-button
        v-if="type === medicalInfoTypes.alternative && searchEnabled === false"
        disabled
        label="Jetzt suchen"
      />

      <component-button class="p4umc-primary" label="Schließen" @click="close" />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref} from "vue";

  import {medicalInfoTypes} from "@components/Dialogs/CoreInfoDialog/enum.js";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCoreInfoDialogText from "@components/Dialogs/CoreInfoDialog/CoreInfoDialogText.vue";

  export default {
    name: "ComponentCoreInfoDialog",

    components: {ComponentCoreInfoDialogText, ComponentButton, ComponentDialog},

    props: {
      dialogType: {
        type: String,
        default: "fi",
      },
    },

    setup(props) {
      const refCoreInfoDialog = ref(null);

      const alternativeSearch = ref(null);
      const currentDialogType = ref(props.dialogType);
      const payload = ref(null);
      const searchEnabled = ref(false);
      const title = ref("");
      const type = ref(medicalInfoTypes.text);

      const dialogWidth = computed(() => (currentDialogType.value === "fi" ? "medium" : "large"));

      const open = (data) => {
        if (typeof data.type !== "undefined") {
          currentDialogType.value = data.type;
        }

        if (typeof data.referenceName !== "undefined") {
          title.value = data.referenceName;
        } else if (typeof data.dosagealternative !== "undefined") {
          title.value = "Alternativsuche „" + data.fam + "“";
        }

        payload.value = data;

        refCoreInfoDialog.value.open();
      };

      const close = () => {
        refCoreInfoDialog.value.close();

        title.value = "";
        payload.value = null;
      };

      const enableSearch = (newValue) => {
        searchEnabled.value = newValue.enable;
      };

      const searchForAlternatives = () => {
        if (alternativeSearch.value) alternativeSearch.value.search();
      };

      return {
        /** enum */
        medicalInfoTypes,

        /** ref */
        refCoreInfoDialog,

        /** const */
        alternativeSearch,
        currentDialogType,
        payload,
        searchEnabled,
        title,
        type,

        /** computed */
        dialogWidth,

        /** function */
        open,
        close,
        enableSearch,
        searchForAlternatives,
      };
    },
  };
</script>
