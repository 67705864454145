<template>
  <span
    class="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium"
    :class="{
      'bg-orange-100 text-orange-800': color === 'yellow' || color === 'orange' || color === 'warning',
      'bg-mcred-100 text-mcred-800': color === 'red' || color === 'error',
      'bg-mcblue-100 text-mcblue-800': color === 'blue' || color === 'info',
      'bg-mcgreen-100 text-mcgreen-800': color === 'green' || color === 'success',
      'bg-gray-100 text-gray-800': color === 'gray' || color === null,
    }"
  >
    <svg
      v-if="showDot"
      class="h-1.5 w-1.5"
      :class="{
        'fill-orange-500': color === 'yellow' || color === 'orange' || color === 'warning',
        'fill-mcred-500': color === 'red' || color === 'error',
        'fill-mcblue-500': color === 'blue' || color === 'info',
        'fill-mcgreen-500': color === 'green' || color === 'success',
        'fill-gray-500': color === 'gray' || color === null,
      }"
      viewBox="0 0 6 6"
      aria-hidden="true"
      :data-test="testId + '-status-badge'"
    >
      <circle cx="3" cy="3" r="3" />
    </svg>

    {{ label }}

    <slot />
  </span>
</template>
<script>
  export default {
    name: "ComponentStatusBadge",
    props: {
      label: {
        type: String,
        default: null,
      },
      color: {
        type: String,
        default: null,
      },
      showDot: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
