<template>
  <li class="grid grid-cols-12 gap-x-6 p-2">
    <div class="col-span-2">
      <component-input
        label="Größe in m"
        :model-value="sizeValue"
        :error="sizeError"
        :disabled="fieldsDisabled"
        :validation="sizeError"
        @input="handleSizeChange"
        @focusin="setFocus"
        @blur="updateSize"
      />
    </div>

    <div class="col-span-3">
      <component-input
        label="Gewicht in kg"
        :model-value="weightValue"
        :error="weightError"
        :disabled="fieldsDisabled"
        @focusin="setFocus"
        @blur="updateWeight"
      />
    </div>

    <div class="col-span-2">
      <component-input
        label="BMI in kg/m²"
        :model-value="form.bmi.value"
        :helper-text="bmiReferenceText"
        :disabled="fieldsDisabled"
        @focusin="setFocus"
        @blur="updateBmi"
      />
    </div>

    <div class="col-span-3">
      <component-select
        :id="'deviation' + form.bmi.id"
        :name="'deviation' + form.bmi.id"
        :model-value="form.bmi.deviation"
        :options="laborbardeviationValues"
        key-name="name"
        value-name="value"
        label="Abweichung:"
        :disabled="fieldsDisabled"
        @change="updateBmiDeviation"
      />
    </div>

    <div class="col-span-2">
      <component-full-date-input
        ref="date"
        label="Von (Datum):"
        :model-value="form.bmi.date"
        :disabled="fieldsDisabled"
        @change="updateBmiDate"
      />
    </div>
  </li>
</template>

<script>
  import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep, debounce} from "lodash";

  import NumberHelper from "@utils/Helpers/NumberHelper.js";

  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";

  import {laborbardeviationValues} from "@pages/Records/Components/Sections/LaborBar/enums.js";
  import {
    getReferenceValue,
    getReferenceText,
    getDeviationFromValue,
  } from "@pages/Records/Components/Sections/LaborBar/Utils/laborvalue.js";

  export default {
    name: "MainLaborValuesBmiRow",

    components: {ComponentFullDateInput, ComponentSelect, ComponentInput},

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      size: {
        type: Object,
        required: true,
      },
      weight: {
        type: Object,
        required: true,
      },
      bmi: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const weightError = ref("");
      const hasFocus = ref(false);
      const sizeValue = ref("");

      const form = useForm({
        size: cloneDeep(props.size) ?? {},
        weight: cloneDeep(props.weight) ?? {},
        bmi: cloneDeep(props.bmi) ?? {},
      });

      const fieldsDisabled = computed(() => !props.isEditable || form.processing);

      const sizeError = computed(() => (sizeValue.value[0] >= 3 ? "Keine sinvolle Größe" : ""));

      const weightValue = computed(() => {
        return form.weight.value
          ? NumberHelper.formatFloatAsGermanNumberString(NumberHelper.formatStringAsFloat(form.weight.value))
          : "";
      });

      const bmiReferenceValue = computed(() => {
        return getReferenceValue(form.bmi, page.props.patient.gender);
      });

      const bmiReferenceText = computed(() => {
        if (!bmiReferenceValue.value || !form.bmi.value || form.bmi.value === "") {
          return null;
        }

        return getReferenceText(bmiReferenceValue.value);
      });

      watch(
        () => form.bmi.value,
        (value) => {
          if (form.size.value && form.weight.value) {
            if (
              value !==
              Math.round(
                parseFloat(form.weight.value.replace(",", ".")) /
                  Math.pow(parseFloat(form.size.value.replace(",", ".")), 2),
              ).toString()
            ) {
              form.weight.value = null;
              form.size.value = null;
            }
          }
        },
      );

      onBeforeMount(() => {
        if (!form.bmi || !form.bmi.mc_unit_id) {
          form.bmi.mc_unit_id = form.bmi.core.unitList[0].id;
          form.bmi.unit = form.bmi.core.unitList[0].unit;
        }
      });

      onMounted(() => {
        sizeValue.value = form.size.value
          ? NumberHelper.formatFloatAsGermanNumberString(NumberHelper.formatStringAsFloat(form.size.value))
          : "";
      });

      const setFocus = () => {
        hasFocus.value = true;
      };

      const clearFocus = () => {
        hasFocus.value = false;
      };

      const tryToCalculateBmi = () => {
        if (form.size.value && form.weight.value) {
          const size = NumberHelper.formatStringAsFloat(form.size.value);
          const weight = NumberHelper.formatStringAsFloat(form.weight.value);

          form.bmi.value = Math.round(weight / Math.pow(size, 2)).toString();
          form.bmi.unit = form.bmi.core.unitList[0].unit;
          form.bmi.mc_unit_id = form.bmi.core.unitList[0].id;
          form.bmi.deviation = getDeviationFromValue(
            NumberHelper.formatStringAsFloat(form.bmi.value),
            bmiReferenceValue.value.low,
            bmiReferenceValue.value.high,
          );
        }
      };

      const patchLaborvalue = debounce(() => {
        if (sizeError.value.length > 0) {
          return;
        }

        if (!form.isDirty) return;

        if (!hasFocus.value) {
          form.put(
            route("laborvalues.mass-update", {
              patient: page.props.patient.id,
              record: page.props.record.id,
            }),
            {
              preserveScroll: true,
              onError: (error) => {
                console.error(error);
              },
            },
          );
        }
      }, 3000);

      const updateSize = (blurEvent) => {
        clearFocus();

        if (sizeError.value.length > 0) {
          return;
        }

        const newSize = blurEvent?.target.value;

        if (newSize) {
          form.size.value = newSize.toString().replace("m", "").replace(".", ",").trim();
        } else {
          form.size.value = null;
        }

        form.size.unit = form.size.core.unitList[0].unit;
        form.size.mc_unit_id = form.size.core.unitList[0].id;

        tryToCalculateBmi();

        patchLaborvalue();
      };

      const updateWeight = (blurEvent) => {
        clearFocus();

        if (sizeError.value.length > 0) {
          return;
        }

        const newWeight = blurEvent?.target.value;

        if (newWeight === "" || newWeight >= 400) {
          weightError.value = "Bitte ein sinnvolles Gewicht eingeben.";
          return;
        } else {
          weightError.value = null;
        }

        if (newWeight) {
          form.weight.value = newWeight.toString().replace("kg", "").replace(",", ".").trim();
        } else {
          form.weight.value = null;
        }

        form.weight.unit = form.weight.core.unitList[0].unit;
        form.weight.mc_unit_id = form.weight.core.unitList[0].id;

        tryToCalculateBmi();

        patchLaborvalue();
      };

      const updateBmi = (blurEvent) => {
        clearFocus();

        const newBmi = blurEvent?.target.value;

        if (newBmi) {
          form.bmi.value = newBmi.toString();
        } else {
          form.bmi.value = null;
        }

        form.bmi.unit = form.bmi.core.unitList[0].unit;
        form.bmi.mc_unit_id = form.bmi.core.unitList[0].id;

        form.bmi.deviation = getDeviationFromValue(newBmi, bmiReferenceValue.value.low, bmiReferenceValue.value.high);

        patchLaborvalue();
      };

      const updateBmiDeviation = (newBmiDevation) => {
        form.bmi.deviation = newBmiDevation;
        form.bmi.value = null;
        patchLaborvalue();
      };

      const updateBmiDate = (newBmiDate) => {
        form.bmi.date = newBmiDate;
        patchLaborvalue();
      };

      const handleSizeChange = (newValue) => {
        if (newValue && newValue.length === 2 && newValue[1] !== ",") {
          sizeValue.value = newValue[0] + "," + newValue[1];
        } else {
          sizeValue.value = newValue ?? "";
        }
      };

      return {
        /** enums */
        laborbardeviationValues,

        /** const */
        weightError,
        hasFocus,
        form,
        sizeValue,

        /** computed */
        sizeError,
        fieldsDisabled,
        weightValue,
        bmiReferenceText,

        /** function */
        updateWeight,
        updateSize,
        updateBmi,
        updateBmiDeviation,
        updateBmiDate,
        setFocus,
        handleSizeChange,
      };
    },
  };
</script>
