<template>
  <div v-if="importedAt" class="text-sm mt-1">
    <span>Import aus WaWi: {{ importedAt }}</span>
  </div>
</template>

<script>
  import {computed} from "vue";

  export default {
    name: "RecordsComponentsImportedFromWawi",

    props: {
      record: {
        type: Object,
        default: () => {},
      },
    },

    setup(props) {
      const importedAt = computed(() => {
        if (props.record?.imported_at) {
          return new Date(props.record.imported_at).toLocaleString();
        }
        return null;
      });

      return {importedAt};
    },
  };
</script>
