import axios from "axios";
import Cookies from "js-cookie";

export const coreRouter = {
  /**
   * Source urls
   */
  coreBaseUrl: import.meta.env.MC_CORE_URL,

  pharmaceuticalServices: "/api/v1/pharmaceutical-services",

  pacUrl: "/api/v2/abdata/article/pac",
  diseaseUrl: "/api/v2/abdata/cave2/min",
  laborValueUrl: "/api/v1/am/laborvalues",
  symptomsUrl: "/api/v2/abdata/cave2/min?filter=symptoms",
  indicationsUrl: "/api/v2/abdata/cave2/min?filter=indications",
  diseasesUrl: "/api/v2/abdata/cave2/min?filter=diseases",

  patientinputPacUrl: "/api/v2/patientinput/pac",
  patientinputSymptomsUrl: "/api/v2/patientinput/symptoms",
  patientinputDiseasesUrl: "/api/v2/patientinput/diseases",

  detailDosageUrl: "/details/abdata/fat/",
  detailsInteractionUrl: "/details/abdata/int/",
  detailsGuidelineUrl: "/details/core/guideline/",
  detailsDocumentUrl: "/details/abdata/documents/",

  min_cUrl: "/api/v2/abdata/cave2/min",
  vitalityUrl: "/api/v2/abdata/cave2/vitality",

  alternativeUrl: "/api/v1/alternative",

  /**
   * Axios cancel function
   */
  cancelMedication: null,
  cancelDiseases: null,
  cancelSymptoms: null,
  cancelLaborValues: null,
  cancelDom: null,

  /**
   * Is a request is already running
   */
  isLoading: false,
  isExecutingChecks: false,
  hasExecutionErrors: false,

  requestCache: (function () {
    let cachedResponses = {};
    let requestTimes = {};

    function add(url, promise) {
      const currentTime = new Date().getTime();
      cachedResponses[btoa(url)] = promise;
      requestTimes[btoa(url)] = currentTime;
      setTimeout(
        () => {
          delete cachedResponses[btoa(url)];
          delete requestTimes[btoa(url)];
        },
        10 * 60 * 1000,
      );
    }

    function has(url) {
      return typeof cachedResponses[btoa(url)] !== "undefined";
    }

    function get(url) {
      const currentTime = new Date().getTime();
      const cachedTime = requestTimes[btoa(url)];
      if (has(url) && currentTime - cachedTime < 15 * 60 * 1000) {
        return cachedResponses[btoa(url)];
      } else {
        delete cachedResponses[btoa(url)];
        delete requestTimes[btoa(url)];
        return null;
      }
    }

    return {
      add: (url, promise) => add(url, promise),
      has: (url) => has(url),
      get: (url) => get(url),
    };
  })(),

  /**
   * @private
   * @return {string}
   */
  getTokenValue() {
    return Cookies.get("mc_token");
  },

  /**
   * @private
   * @param {Object} response
   * @param {Object} callbacks
   */
  successResponse(response, callbacks) {
    if (response.data && typeof callbacks.onSuccess === "function") callbacks.onSuccess(response.data);
    if (typeof callbacks.onFinish === "function") callbacks.onFinish();

    this.isLoading = false;
    this.resetCancelTokens();
  },

  /**
   * @private
   * @param {string} error
   * @param {Object} callbacks
   */
  errorResponse(error, callbacks) {
    if (typeof callbacks.onError === "function") callbacks.onError(error);
    if (typeof callbacks.onFinish === "function") callbacks.onFinish();

    this.isLoading = false;
  },

  /**
   * @private
   */
  resetCancelTokens() {
    this.cancelMedication = null;
    this.cancelDiseases = null;
    this.cancelSymptoms = null;
    this.cancelLaborValues = null;
    this.cancelDom = null;
  },

  /**
   * @private
   * @param {string} url
   * @param {Object} params
   */
  urlQueryBuilder(url, params) {
    let fullUrl = url + "?";

    fullUrl += Object.keys(params)
      .map((key) => `${key}=${Array.isArray(params[key]) ? params[key].join(",") : params[key]}`)
      .join("&");

    return fullUrl;
  },

  /**
   * @private
   * @param {string} url
   * @param {Object} callbacks
   */
  cachedAxiosRequest(url, callbacks) {
    if (!this.requestCache.has(url)) {
      this.requestCache.add(
        url,
        axios.request({
          url: this.coreBaseUrl + url,
          method: "get",
          headers: {
            Authorization: `Bearer ${this.getTokenValue()}`,
          },
        }),
      );
    }

    const cachedRequest = this.requestCache.get(url);

    if (cachedRequest) {
      cachedRequest
        .then((response) => {
          this.successResponse(response, callbacks);
        })
        .catch((error) => {
          this.errorResponse(error, callbacks);
        });
    } else {
      this.requestCache
        .get(url)
        .then((response) => {
          this.successResponse(response, callbacks);
        })
        .catch((error) => {
          this.errorResponse(error, callbacks);
        });
    }
  },

  /**
   * @public
   * @param {string} url
   * @param {Object} callbacks
   * @param {Object} config
   * @param {?"cancelMedication"|"cancelDiseases"|"cancelSymptoms"|"cancelLaborValues"|"cancelDom"} abortController
   */
  get(url, callbacks, config = {}, abortController = "cancelDom") {
    let fullUrl = url;
    this.isLoading = true;

    if (abortController && this[abortController]) {
      this[abortController].abort();
    }

    if (abortController) {
      this[abortController] = new AbortController();
    }

    if (!fullUrl.startsWith("http")) {
      fullUrl = this.coreBaseUrl + url;
    }

    axios
      .request({
        url: fullUrl,
        data: {withCredentials: true},
        method: "get",
        signal: abortController ? this[abortController].signal : null,
        headers: {
          Authorization: `Bearer ${this.getTokenValue()}`,
        },
        ...config,
      })
      .then((response) => {
        this.successResponse(response, callbacks);
      })
      .catch((error) => {
        this.errorResponse(error, callbacks);
      });
  },

  /**
   * @public
   * @param {string} url
   * @param {Object} data
   * @param {Object} callbacks
   * @param {Object} config
   * @param {?"cancelMedication"|"cancelDiseases"|"cancelSymptoms"|"cancelLaborValues"|"cancelDom"} abortController
   */
  post(url, data, callbacks, config = {}, abortController = "cancelDom") {
    this.isLoading = true;

    if (abortController && this[abortController]) {
      this[abortController].abort();
    }

    if (abortController) {
      this[abortController] = new AbortController();
    }

    axios
      .request({
        url: this.coreBaseUrl + url,
        method: "post",
        data: data,
        signal: abortController ? this[abortController].signal : null,
        preserveScroll: true,
        headers: {
          Authorization: `Bearer ${this.getTokenValue()}`,
        },
        ...config,
      })
      .then((response) => {
        this.successResponse(response, callbacks);
      })
      .catch((error) => {
        this.errorResponse(error, callbacks);
      });
  },

  /**
   * @public
   * @param {string} keyFams
   * @param {Object} callbacks
   */
  loadPharmaceuticalServices(keyFams, callbacks) {
    return this.post(this.pharmaceuticalServices, {key_fams: keyFams}, callbacks);
  },

  /**
   * @public
   * @param {string} query
   * @param {Object} callbacks
   */
  loadMedicationPac(query, callbacks) {
    return this.cachedAxiosRequest(this.pacUrl + query, callbacks);
  },

  /**
   * @public
   * @param {string} keyFam
   * @param {Object} callbacks
   */
  loadMedicationPacViaKeyFam(keyFam, callbacks) {
    return this.cachedAxiosRequest(this.pacUrl + "?key_fam=" + keyFam, callbacks);
  },

  /**
   * Search for laborvalues by key
   *
   * @param {Number|String} key
   * @param callbacks
   */
  searchForLaborvaluesByKey(key, callbacks) {
    this.cachedAxiosRequest(this.laborValueUrl + `/?search=key:${key}`, callbacks);
  },

  /**
   * @public
   * @param {string} type
   * @param {string} key
   * @param {Object} callbacks
   */
  loadDetailsFromLegacyLinks(type, key, callbacks) {
    let url;

    switch (type) {
      case "dosage":
        url = this.detailDosageUrl + key;
        break;
      case "interaction":
        url = this.detailsInteractionUrl + key;
        break;
      case "guideline":
        url = this.detailsGuidelineUrl + key;
        break;
      case "fi":
        url = this.detailsDocumentUrl + key;
        break;
    }

    return this.get(url, callbacks);
  },

  /**
   * @public
   * @param {string} keyFam
   * @param {Object} callbacks
   */
  loadMinDataViaKeyFam(keyFam, callbacks) {
    return this.cachedAxiosRequest(this.indicationsUrl + "&q[key_fam]=" + keyFam, callbacks);
  },

  /**
   * @public
   * @param {Object} params
   * @param {Object} callbacks
   */
  loadMinCData(params, callbacks) {
    return this.cachedAxiosRequest(this.urlQueryBuilder(this.min_cUrl, params), callbacks);
  },

  /**
   * @public
   * @param {string} url
   * @param {Object} callbacks
   */
  loadIndications(url, callbacks) {
    return this.cachedAxiosRequest(this.min_cUrl + url, callbacks);
  },

  /**
   * @public
   * @param {string} searchQuery
   * @param {Object} callbacks
   */
  searchIndicationByName(searchQuery, callbacks) {
    return this.get(this.min_cUrl + searchQuery, callbacks);
  },

  /**
   * @public
   * @param {string} pac
   * @param {Object} callbacks
   */
  searchPac(pac, callbacks) {
    this.get(this.pacUrl + "?q=" + pac, callbacks);
  },

  /**
   * @public
   * @param {string} indication
   * @param {Object} callbacks
   */
  searchIndication(indication, callbacks) {
    return this.get(this.indicationsUrl + indication.trim(), callbacks);
  },

  /**
   * @public
   * @param {string} url
   * @param {Object} callbacks
   */
  searchSymptoms(url, callbacks) {
    this.cachedAxiosRequest(this.symptomsUrl + url, callbacks);
  },

  /**
   * @public
   * @param {string} url
   * @param {Object} callbacks
   */
  searchDiseases(url, callbacks) {
    this.cachedAxiosRequest(this.diseasesUrl + url, callbacks);
  },

  /**
   * @public
   * @param {string} searchTerm
   * @param {string} gender
   * @param {{onSuccess: VoidFunction, onError: VoidFunction, onFinish: VoidFunction}} callbacks
   */
  searchDiseasesForPatientinput(searchTerm, gender, callbacks) {
    this.cachedAxiosRequest(
      this.patientinputDiseasesUrl + "?q[name]=" + searchTerm + "&q[gender]=" + gender,
      callbacks,
    );
  },

  /**
   * @public
   * @param {string} pac
   * @param {{onSuccess: VoidFunction, onError: VoidFunction, onFinish: VoidFunction}} callbacks
   */
  searchPacForPatientinput(pac, callbacks) {
    this.cachedAxiosRequest(this.patientinputPacUrl + "?q=" + pac, callbacks);
  },

  /**
   * @public
   * @param {string} searchTerm
   * @param {string} gender
   * @param {{onSuccess: VoidFunction, onError: VoidFunction, onFinish: VoidFunction}} callbacks
   */
  searchSymptomsForPatientinput(searchTerm, gender, callbacks) {
    this.cachedAxiosRequest(
      this.patientinputSymptomsUrl + "?q[name]=" + searchTerm + "&q[gender]=" + gender,
      callbacks,
    );
  },
};
