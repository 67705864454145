<template>
  <component-tooltip
    :tooltip="
      mostRiskColor !== 'dontShow'
        ? 'Angezeigt werden die Anzahl der Check-Treffer und die Farbe des höchsten Risikos dieses Arzneimittels.'
        : 'Für diese Arzneimittel wurden keine automatisierten Check-Treffer detektiert.'
    "
  >
    <button
      v-if="mostRiskColor !== 'showGreenButton' && mostRiskColor !== 'dontShowButton'"
      :class="{
        'border-very-high-risk': mostRiskColor === 'veryHighRisk',
        'border-high-risk': mostRiskColor === 'highRisk',
        'border-medium-risk': mostRiskColor === 'mediumRisk',
        'border-low-risk': mostRiskColor === 'lowRisk',
      }"
      class="border-[3px] rounded-full cursor-pointer bg-white w-7 h-7"
      @click="openDialog()"
    >
      <p class="text-sm">{{ totalRiskCount }}</p>
    </button>
    <button
      v-else-if="mostRiskColor === 'showGreenButton'"
      class="border-mcgreen border-[3px] rounded-full cursor-pointer bg-white w-7 h-7"
      @click="openDialog()"
    >
      <p class="text-sm">0</p>
    </button>
  </component-tooltip>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import {computed} from "vue";
  import {sumBy} from "lodash";

  export default {
    name: "ComponentRiskCircle",
    components: {ComponentTooltip},
    props: {
      medication: {
        type: Object,
        required: true,
      },
    },
    setup(props, {emit}) {
      const mostRiskColor = computed(() => {
        if (!props.medication.statistic || props.medication.statistic.length === 0) return "dontShowButton";
        if (
          props.medication.statistic &&
          props.medication.statistic.veryHighRisk &&
          sumBy(props.medication.statistic.veryHighRisk, "count") > 0
        )
          return "veryHighRisk";
        if (
          props.medication.statistic &&
          props.medication.statistic.highRisk &&
          sumBy(props.medication.statistic.highRisk, "count") > 0
        )
          return "highRisk";
        if (
          props.medication.statistic &&
          props.medication.statistic.mediumRisk &&
          sumBy(props.medication.statistic.mediumRisk, "count") > 0
        )
          return "mediumRisk";
        if (
          props.medication.statistic &&
          props.medication.statistic.lowRisk &&
          sumBy(props.medication.statistic.lowRisk, "count") > 0
        )
          return "lowRisk";
        return "showGreenButton";
      });

      const totalRiskCount = computed(() => {
        let count = 0;

        if (!props.medication?.statistic) {
          return count;
        }

        Object.entries(props.medication?.statistic).forEach(([key, risks]) => {
          if (key !== "skipped") {
            risks.forEach((check) => {
              count += check.count;
            });
          }
        });

        return count;
      });

      function openDialog() {
        emit("open:riskScore");
      }

      return {mostRiskColor, totalRiskCount, openDialog};
    },
  };
</script>
