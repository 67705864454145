<template>
  <li class="grid grid-cols-12 gap-x-6 p-2 border-gray-300 animate-pulse">
    <div class="h-[75px] col-span-2">
      <div class="h-[17px] w-1/2 skeleton-input-loader" />
      <div class="h-[35px] w-full skeleton-input-loader" />
    </div>

    <div class="h-[75px] col-span-3">
      <div class="h-[17px] w-1/2 skeleton-input-loader" />
      <div class="h-[35px] skeleton-input-loader" />
    </div>

    <div class="h-[75px] col-span-2">
      <div class="h-[17px] w-1/2 skeleton-input-loader" />
      <div class="h-[35px] skeleton-input-loader" />
    </div>

    <div class="h-[75px] col-span-3">
      <div class="h-[17px] w-1/2 skeleton-input-loader" />
      <div class="h-[35px] skeleton-input-loader" />
    </div>

    <div class="h-[75px] col-span-2">
      <div class="h-[17px] w-1/2 skeleton-input-loader" />
      <div class="h-[35px] skeleton-input-loader" />
    </div>
  </li>
</template>

<script>
  export default {
    name: "LaborValueSkeletonRowMainValues",
  };
</script>
