<template>
  <section class="mt-6 bg-white">
    <div class="mx-4">
      <component-foldable
        title="Was ermöglicht der Patientenfragebogen / die Checkliste?"
        :open="!hasTransfers"
        :test-id="testId + '-info-section'"
      >
        <div class="max-w-6xl flex flex-col gap-3">
          <p>
            Mit Hilfe des Patientenfragebogens bzw. der Checkliste für Pflegefachkräfte haben Sie die Möglichkeit, Daten
            für die Medikationsanalyse vorab von dem Patienten oder im Rahmen der Heimversorgung von der Pflegefachkraft
            (entspricht Arbeitshilfe Checkliste für die Pflegefachkräfte nach BAK) digital erfassen zu lassen und davon
            ausgehend eine Medikationsanalyse zu starten.
          </p>

          <p>
            Zum digitalen Patientenfragebogen bzw. zur Checkliste gelangen Patienten oder Pflegefachkräfte einfach über
            den Link oder QR-Code, den Sie mit den untenstehenden Buttons generieren und an Patienten oder
            Pflegefachkräfte weitergeben können.
          </p>

          <p>
            Bitte beachten Sie, dass nicht-importierte Fragebögen bzw. Checklisten nach 90 Tagen automatisch
            unwiderruflich gelöscht werden.
          </p>
        </div>
      </component-foldable>

      <div class="py-4 flex flex-wrap gap-3">
        <component-button
          :class="{'p4umc-primary': !hasTransfers}"
          label="Link für Patienten"
          :test-id="testId + '-info-section-open-patient-share-dialog'"
          @click="openShareDialog(false)"
        />

        <component-button
          :class="{'p4umc-primary': !hasTransfers}"
          label="Link für Pflegefachkräfte"
          :test-id="testId + '-info-section-open-staff-share-dialog'"
          @click="openShareDialog(true)"
        />
      </div>
    </div>
  </section>

  <patientinput-client-component-share-dialog
    ref="refShareDialog"
    :client-company="clientCompany"
    profile-edit-link:="profileEditLink"
    :test-id="testId + '-info-section'"
  />
</template>

<script>
  import {computed, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentFoldable from "@components/Foldable/Foldable.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import PatientinputClientComponentShareDialog from "@pages/PatientinputClient/Components/ShareDialog.vue";

  export default {
    name: "PatientinputClientComponentInfoSection",

    components: {PatientinputClientComponentShareDialog, ComponentButton, ComponentFoldable},

    setup() {
      const page = usePage();

      const refShareDialog = ref(null);

      const hasTransfers = computed(() => page.props.transfersEncrypted.data.length > 0);
      const clientCompany = computed(() => page.props.clientCompany);
      const profileEditLink = computed(() => page.props.links.p4uProfile);

      const openShareDialog = (isCarer = false) => {
        refShareDialog.value.open(isCarer);
      };

      return {
        /** ref */
        refShareDialog,

        /** computed */
        hasTransfers,
        clientCompany,
        profileEditLink,

        /** function */
        openShareDialog,
      };
    },
  };
</script>
