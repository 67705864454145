<template>
  <component-dialog
    ref="refScannerDialog"
    :dialog-title="dialogTitle"
    :dialog-type="dialogType"
    :primary-button-disabled="primaryButtonDisabled"
    :test-id="testId"
    @ok-button-clicked="$emit('okButtonClicked')"
  >
    <template #content>
      <div v-if="errorMsg">
        <component-alert type="error" :test-id="testId">{{ errorMsg }}</component-alert>
      </div>

      <div v-else-if="!cameraReady" class="flex justify-center items-center" :class="loaderClass">
        <component-spinner class="w-12" :test-id="testId" />
      </div>

      <div v-show="cameraReady && !errorMsg" class="flex flex-col gap-3">
        <slot />

        <component-barcode-scanner
          :class="scannerClass"
          :barcode-formats="formats"
          :paused="paused"
          @camera-on="onReady"
          @detect="$emit('detect', $event)"
          @error="onError"
        >
          <div v-show="paused" class="h-full w-full flex flex-col justify-center items-center bg-white/75 gap-3 p-3">
            <slot name="paused">
              <component-spinner class="w-12" :test-id="testId + '-barcode-scanner'" />
            </slot>
          </div>
        </component-barcode-scanner>
      </div>
    </template>
  </component-dialog>
</template>

<script>
  import {onBeforeUnmount, ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentBarcodeScanner from "@components/BarcodeScanner/BarcodeScanner.vue";

  export default {
    name: "PatientinputComponentScannerDialog",

    components: {
      ComponentBarcodeScanner,
      ComponentAlert,
      ComponentSpinner,
      ComponentDialog,
    },

    props: {
      dialogTitle: {
        required: true,
        type: String,
      },
      dialogType: {
        type: String,
        default: "",
      },
      formats: {
        required: true,
        type: Array,
      },
      loaderClass: {
        type: String,
        default: "",
      },
      scannerClass: {
        type: String,
        default: "",
      },
      primaryButtonDisabled: {
        type: Boolean,
        default: false,
      },
      paused: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["detect", "okButtonClicked"],

    setup() {
      const refScannerDialog = ref(null);

      const cameraReady = ref(false);
      const errorMsg = ref(null);
      const setupTime = ref(0);
      let tempTime = 0;

      const open = () => {
        cameraReady.value = false;
        errorMsg.value = null;
        refScannerDialog.value.open();
        tempTime = Date.now();
      };

      const close = () => {
        refScannerDialog.value.close();
      };

      const onReady = () => {
        cameraReady.value = true;
        setupTime.value = Date.now() - tempTime;
      };

      const onError = (error) => {
        errorMsg.value = error.message;
      };

      onBeforeUnmount(() => {
        close();
      });

      return {
        /** ref */
        refScannerDialog,

        /** const */
        cameraReady,
        errorMsg,
        setupTime,

        /** function */
        open,
        close,
        onReady,
        onError,
      };
    },
  };
</script>
