<template>
  <div class="mt-5 flex flex-col gap-3">
    <h3 class="text-lg leading-6 font-medium text-gray-700">BMP-XML hochladen</h3>

    <component-alert v-if="error.type && error.text" :type="error.type" class="!mb-0" :test-id="testId + '-upload-tab'">
      {{ error.text }}
    </component-alert>

    <label
      class="flex flex-col gap-3 items-center justify-center rounded border border-dashed border-gray-700/25 px-6 py-10 cursor-pointer hover:border-mcred"
      :class="{'!border-mcred': fileDragging}"
      :data-test="testId + '-upload-tab-input-label'"
      @drag.prevent="fileDragging = true"
      @dragend.prevent="fileDragging = false"
      @dragenter.prevent="fileDragging = true"
      @dragover.prevent="fileDragging = true"
      @dragleave.prevent="fileDragging = false"
      @drop.prevent="processFileDrop"
    >
      <component-icon class="text-5xl">upload_file</component-icon>

      <span class="text-sm">BMP-XML-Datei per Drag-and-Drop oder Klicken hochladen</span>

      <input
        type="file"
        class="sr-only"
        accept="text/xml"
        :data-test="testId + '-upload-tab-input'"
        @change.prevent="processFileUpload"
      />
    </label>

    <p>oder BMP-XML-Code manuell einfügen</p>

    <textarea
      class="rounded border border-gray-700/25 resize-y"
      placeholder="Hier BMP-XML-Code einfügen"
      rows="3"
      :data-test="testId + '-upload-tab-textarea'"
      @input="processTextInput"
    />
  </div>
</template>

<script>
  import {ref} from "vue";
  import {debounce} from "lodash";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentBmpImportDialogUploadTab",
    components: {ComponentIcon, ComponentAlert},

    props: {
      error: {
        type: Object,
        required: true,
      },
    },

    emits: ["error", "input"],

    setup(props, {emit}) {
      const fileDragging = ref(false);

      const processFileUpload = (event) => {
        processFileList(event.target.files);
      };

      const processFileDrop = (event) => {
        fileDragging.value = false;
        processFileList(event.dataTransfer.files);
      };

      const processFileList = async (fileList) => {
        if (fileList.length !== 1) {
          return setError("error", "Bitte laden Sie jeweils nur eine XML-Datei hoch.");
        }

        const item = fileList.item(0);

        if (item.type !== "text/xml") {
          return setError("error", "Bitte laden Sie nur XML-Dateien hoch.");
        }

        try {
          let text = await item.text();

          if (text.includes("�")) {
            text = new TextDecoder("iso-8859-1").decode(await item.arrayBuffer());
          }

          emit("input", text);
        } catch (e) {
          return setError(
            "error",
            "Beim Verarbeiten der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
          );
        }
      };

      const processTextInput = debounce((event) => {
        emit("input", event.target.value);
      }, 1000);

      const setError = (type, text) => {
        emit("error", type, text);
      };

      return {
        /** const */
        fileDragging,

        /** function */
        processFileUpload,
        processFileDrop,
        processTextInput,
      };
    },
  };
</script>
