class Broadcast {
  constructor(clientId) {
    this.channel = new BroadcastChannel(clientId);
    this.callbacks = {
      default: [],
    };

    this.channel.onmessage = (event) => {
      this.handleEvent(event);
    };
  }

  handleEvent(event) {
    if (event.data.broadcastItem && event.data.broadcastItemId) {
      if (typeof this.callbacks[event.data.broadcastItem + "-" + event.data.broadcastItemId] === "object") {
        this.callbacks[event.data.broadcastItem + "-" + event.data.broadcastItemId].forEach((callback) =>
          callback(event.data),
        );
      }
    } else {
      this.callbacks.default.forEach((callback) => callback(event.data));
    }
  }

  postMessage(message) {
    // always call handleEvent() manually, because events are not
    // broadcasted to the tab, which is already open (the tab
    // which emitted the broadcast event)
    this.handleEvent({data: message});
    // post message to browser tabs
    this.channel.postMessage(message);
  }

  onMessage(callback) {
    this.callbacks["default"].push(callback);
  }

  offMessage(callback) {
    this.callbacks["default"] = this.callbacks["default"].filter((cb) => cb !== callback);
  }

  close() {
    this.channel.close();
  }

  record = {
    postMessage: (recordId, message) => {
      this.postMessage({
        ...message,
        broadcastItem: "record",
        broadcastItemId: recordId,
      });
    },
    onMessage: (recordId, callback) => {
      if (typeof this.callbacks["record-" + recordId] === "undefined") {
        this.callbacks["record-" + recordId] = [];
      }

      this.callbacks["record-" + recordId].push(callback);
    },
    offMessage: (recordId, callback) => {
      if (typeof this.callbacks["record-" + recordId] !== "undefined") {
        this.callbacks["record-" + recordId] = this.callbacks["record-" + recordId].filter((cb) => cb !== callback);
      }
    },
  };

  patient = {
    postMessage: (patientId, message) => {
      this.postMessage({
        ...message,
        broadcastItem: "patient",
        broadcastItemId: patientId,
      });
    },
    onMessage: (patientId, callback) => {
      if (typeof this.callbacks["patient-" + patientId] === "undefined") {
        this.callbacks["patient-" + patientId] = [];
      }

      this.callbacks["patient-" + patientId].push(callback);
    },
    offMessage: (patientId, callback) => {
      if (typeof this.callbacks["patient-" + patientId] !== "undefined") {
        this.callbacks["patient-" + patientId] = this.callbacks["patient-" + patientId].filter((cb) => cb !== callback);
      }
    },
  };
}
export default Broadcast;
