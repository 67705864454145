class Fraction {
  constructor() {
    this.specialFractions = {
      "2/4": "½",
      "1/2": "½",
      "3/10": "⅓",
      "33/100": "⅓",
      "333/1000": "⅓",
      "1/3": "⅓",
      "33/50": "⅔",
      "2/3": "⅔",
      "659/1000": "⅔",
      "1/4": "¼",
      "3/4": "¾",
      "1/8": "⅛",
    };

    /**
     * Regardless of whether this makes sense or not: the following
     * fractions could be entered by the end user and will be
     * converted to their decimal notation by us.
     *
     * Note: most of these fractions are not officially supported, especially
     * within a BMP xml only '½' (1/2), '¼' (1/4) and '¾' (3/4) are valid.
     *
     * @type object
     */
    this.charFractions = [
      {fraction: "¼", decimal: 1 / 4},
      {fraction: "½", decimal: 1 / 2},
      {fraction: "¾", decimal: 3 / 4},
      {fraction: "⅐", decimal: 1 / 7},
      {fraction: "⅑", decimal: 1 / 9},
      {fraction: "⅒", decimal: 1 / 10},
      {fraction: "⅓", decimal: 1 / 3},
      {fraction: "⅔", decimal: 2 / 3},
      {fraction: "⅕", decimal: 1 / 5},
      {fraction: "⅖", decimal: 2 / 5},
      {fraction: "⅗", decimal: 3 / 5},
      {fraction: "⅘", decimal: 4 / 5},
      {fraction: "⅙", decimal: 1 / 6},
      {fraction: "⅚", decimal: 5 / 6},
      {fraction: "⅛", decimal: 1 / 8},
      {fraction: "⅜", decimal: 3 / 8},
      {fraction: "⅝", decimal: 5 / 8},
      {fraction: "⅞", decimal: 7 / 8},
    ];
  }

  /**
   * Returns the value as number for internal use
   *
   * @param value
   * @return {*}
   */
  getAsNumber(value) {
    if (this.isFloat(value)) {
      return value;
    } else if (this.isGermanFloat(value)) {
      value = value.toString().replace(",", ".");
      return parseFloat(value);
    } else if (value.toString().match(/[0-9]+\s+[0-9]\/[0-9]+/)) {
      let list = value.toString().split(" ");
      let integer = list[0];
      let fraction = list[1];

      return parseInt(integer) + this._calculateFloatFromFraction(fraction);
    } else if (this.isFraction(value)) {
      return this._calculateFloatFromFraction(value);
    } else if (this.isCharFraction(value)) {
      return this._calculateFloatFromCharFraction(value);
    } else if (typeof value === "string" && typeof parseFloat(value) === "number") {
      // it's just a number as a string, like '2'
      return parseFloat(value);
    }

    // at lease always return the original value to avoid returning undefined
    return value;
  }

  _calculateFloatFromFraction(fraction) {
    const up_down = fraction.split("/");

    return parseInt(up_down[0]) / parseInt(up_down[1]);
  }

  _calculateFloatFromCharFraction(value) {
    for (let i = 0, l = this.charFractions.length; i < l; ++i) {
      if (this.charFractions[i].fraction === value) {
        return this.charFractions[i].decimal;
      }
    }
    // return original value if fraction was not found
    return value;
  }

  /**
   * Checks if it is a float number
   *
   * @param value
   * @return {RegExpMatchArray | null}
   */
  isFloat(value) {
    return value.toString().match(/\./);
  }

  /**
   * Checks if it is a german formated float string
   *
   * @param value
   * @return {RegExpMatchArray | null}
   */
  isGermanFloat(value) {
    return value.toString().match(/\,/);
  }

  /**
   * Checks if the value is a slashed separated fraction
   *
   * @param value
   * @return {RegExpMatchArray | null}
   */
  isFraction(value) {
    return value.toString().match(/\//);
  }

  isCharFraction(value) {
    for (let i = 0, l = this.charFractions.length; i < l; ++i) {
      if (this.charFractions[i].fraction === value) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns the converted value: Fraction char or float value
   *
   * @param value
   * @returns {*}
   */
  isFloatOrFraction(value) {
    if (value === null) {
      return;
    }
    //Regex matchs = number + whitespace + number + "/" + number
    if (value.toString().match(/[0-9]+\s+[0-9]\/[0-9]+/)) {
      // value = 1 1/2
      return this.convertFractionGreaterThanOne(value);
    } else if (value.toString().match(/\//)) {
      // value = 1/2
      return this.convertFraction(value);
    } else {
      // value = 0.5 or 0,5 or 1.5 or 1,5
      return this.convertFloat(value);
    }
  }

  /**
   * Greater common divisor of a and b
   *
   * @param a
   * @param b
   * @returns {integer}
   */
  gcd(a, b) {
    return b ? this.gcd(b, a % b) : a;
  }

  /**
   * if number a special fraction
   *
   * @param number
   * @returns {*}
   */
  convertFraction(number) {
    let normalizedNumber = number.toString().trim();

    if (this.specialFractions[normalizedNumber]) {
      return this.specialFractions[normalizedNumber];
    } else {
      return normalizedNumber;
    }
  }

  /**
   * Converts to float
   *
   * @param number
   * @returns {*}
   */
  convertFloat(number) {
    let float = parseFloat(number.toString().replace(",", ".")); // , => .

    return this.calculateFractionFromFloat(float);
  }

  /**
   * Converts the fraction string
   *
   * @param number
   * @returns {string}
   */
  convertFractionGreaterThanOne(number) {
    let list = number.toString().split(" ");
    let integer = list[0];
    let fraction = list[1];
    let intFrac = integer + this.specialFractions[fraction];

    return intFrac;
  }

  /**
   * returns the number after "."
   *
   * @param number (0.5)
   * @returns {string}
   */
  rights(number) {
    let list = number.toString().split(".");
    let right = list[1];

    if (!right) {
      return "0";
    }

    right = right.substr(0, 3);
    return right;
  }

  /**
   * Builds the fraction string and selects the corresponding
   *
   * for 0.5
   * @param right  (5)
   * @param bottom (100000)
   * @returns {*}
   */
  divide(right, bottom) {
    let dividor = this.gcd(right, bottom);
    let topOfFrac = right / dividor;
    let bottomOfFrac = bottom / dividor;
    let fraction = topOfFrac + "/" + bottomOfFrac;

    return this.specialFractions[fraction];
  }

  /**
   * Calculates the mathimatical fraction string
   *
   * @param float
   * @returns {*}
   */
  calculateFractionFromFloat(float) {
    let fraction;

    if (float > 1) {
      // 1.5

      let floor = Math.floor(float);
      let residual = float - floor;
      let right = this.rights(residual);
      let bottom = Math.pow(10, right.length);

      fraction = this.divide(right, bottom);

      if (typeof fraction === "undefined") {
        fraction = float;
      } else {
        fraction = floor.toString() + fraction;
      }
    } else {
      // 0.5

      let right = this.rights(float);

      // five times 0 after 1 = 100000
      let bottom = Math.pow(10, right.length);
      fraction = this.divide(right, bottom);

      if (typeof fraction === "undefined") {
        fraction = float;
      }
    }

    return fraction;
  }
}

export default new Fraction();
