<template>
  <template v-if="showLabel">
    <p class="text-gray-500 text-sm mb-1 pl-2">Grund / Ind.</p>
  </template>

  <div
    id="indicationDiv"
    class="h-9 py-2 border-b border-gray-300 text-sm cursor-pointer pl-2 truncate"
    :class="[addContentDependingStyle, {'border-dashed !cursor-help	text-gray-500': disabled}]"
    @click="$emit('openDialog')"
  >
    <template v-if="hasDisplayMessage">
      <component-tooltip :force-inline="false" custom-class="border-1 ">
        <div class="truncate" :class="{'line-through': isDiscontinued}">{{ displayIndicationMessage }}</div>

        <template #tooltip>
          <p>
            <template v-for="indication in indications" :key="indication.id">
              - {{ indication.name }}
              <br />
            </template>
          </p>
        </template>
      </component-tooltip>
    </template>

    <template v-else-if="disabled">
      <span />
    </template>

    <template v-else>
      <span class="text-gray-500">Bitte auswählen</span>
    </template>
  </div>
</template>

<script>
  import {computed, ref} from "vue";
  import {map, uniqBy} from "lodash";

  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "MedicationComponentIndicationDisplay",

    components: {ComponentTooltip},

    props: {
      indications: {
        type: Object,
        required: true,
        default: () => {},
      },
      indicationsUnknown: {
        type: Number,
        required: true,
        default: 0,
      },
      isActive: {
        type: Boolean,
        required: true,
        default: false,
      },
      isDiscontinued: {
        type: Boolean,
        required: true,
      },
      showLabel: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["openDialog"],

    setup(props) {
      const maxIndicationRowLength = 28;
      const shortedIndicationInfo = ref(true);

      const indicationMessage = computed(() => buildIndicationMessage());
      const hasDisplayMessage = computed(() => displayIndicationMessage.value.length > 0);

      const addContentDependingStyle = computed(() => {
        return {
          lineheight: isSingleLine,
          empty: isEmpty,
          disabled: props.disabled,
        };
      });

      const displayIndicationMessage = computed(() => {
        if (props.indicationsUnknown === 1) {
          return "Unbekannt";
        }

        return indicationMessage.value;
      });

      const buildIndicationMessage = () => {
        let indicationName = "";

        if (props.indications && props.indications.length > 0) {
          indicationName = map(uniqBy(props.indications, "abdata_key_miv"), (indication) => {
            return indication.name || indication.name_icdc;
          }).join(", ");
        }

        return indicationName;
      };

      const isSingleLine = () => {
        return indicationMessage.value.length < maxIndicationRowLength;
      };

      const isEmpty = () => {
        return props.indications.length === 0;
      };

      return {
        /** const */
        shortedIndicationInfo,

        /** computed */
        hasDisplayMessage,
        addContentDependingStyle,
        displayIndicationMessage,
      };
    },
  };
</script>
