<template>
  <component-dialog
    ref="refDialog"
    dialog-title="Ausgefüllte Patientenerfassung löschen?"
    primary-label="Löschen"
    :test-id="testId + '-delete'"
    @cancel-button-clicked="$emit('cancelDelete')"
    @primary-button-clicked="$emit('performDelete')"
  >
    <template #content>
      Möchten Sie den ausgefüllten Patientenerfassungsbogen von
      {{ firstname }} {{ lastname }} (geb. {{ birthdate }}) sicher unwiderruflich löschen?

      <span v-if="transfer.status === 'processed'">Die dazugehörige Medikationsanalyse wird nicht gelöscht.</span>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";

  export default {
    name: "PatientinputClientComponentDeleteDialog",

    components: {ComponentDialog},

    props: {
      transfer: {
        type: Object,
        default: () => {},
      },
    },

    emits: ["cancelDelete", "performDelete"],

    setup: (props) => {
      const refDialog = ref(null);

      const firstname = computed(() => props.transfer?.data.masterdata.patient.firstname);

      const lastname = computed(() => props.transfer?.data.masterdata.patient.lastname);

      const birthdate = computed(() => {
        return new Date(props.transfer?.data.masterdata.patient.birthdate).toLocaleDateString("de-DE", {
          dateStyle: "medium",
        });
      });

      const open = () => {
        refDialog.value.open();
      };

      return {
        /** ref */
        refDialog,

        /** computed */
        firstname,
        lastname,
        birthdate,

        /** function */
        open,
      };
    },
  };
</script>
