<template>
  <div>
    scoll
    <br />
    scoll
    <br />
    scoll
    <br />
    scoll
    <br />
    scoll
  </div>
  <div class="grid grid-cols-1 gap-56">
    <section class="flex justify-center">
      placement: default (top)
      <component-tooltip
        tooltip="Das hier ist ein langer Tooltip Text, der sich automatisch ausrichten sollte. Mal sehen."
      >
        <div class="bg-mcred">HOVER ME</div>
      </component-tooltip>
    </section>

    <section class="flex justify-center">
      placement: top-start
      <component-tooltip
        tooltip="Das hier ist ein langer Tooltip Text, der sich automatisch ausrichten sollte. Mal sehen."
        placement="top-start"
      >
        <div class="bg-mcred">HOVER ME</div>
      </component-tooltip>
    </section>

    <section>
      placement: default (top)
      <div class="w-32 p-4 border-2 border-mcred relative overflow-hidden">
        <component-tooltip
          tooltip="Das hier ist ein langer Tooltip Text, der sich automatisch ausrichten sollte. Mal sehen."
        >
          <div class="bg-mcred">HOVER ME</div>
        </component-tooltip>
      </div>
    </section>

    <section>
      placement: default (top)
      <div class="flex justify-end">
        <div class="w-32 p-4 border-2 border-mcred relative right-0 overflow-hidden">
          <component-tooltip
            tooltip="Das hier ist ein langer Tooltip Text, der sich automatisch ausrichten sollte. Mal sehen."
          >
            <div class="bg-mcred">HOVER ME</div>
          </component-tooltip>
        </div>
      </div>
    </section>

    <section>
      placement: right
      <div class="w-32 p-4 border-2 border-mcred relative overflow-hidden">
        <component-tooltip placement="right">
          <template #tooltip>
            Das hier ist ein langer Tooltip Text, der sich automatisch ausrichten sollte. Mal sehen.
            <br />
            Hier auch
            <br />
            mit HTML
            <br />
            und Zeilenumbrüchen.
          </template>
          <div class="bg-mcred">HOVER ME</div>
        </component-tooltip>
      </div>
    </section>

    <section class="flex justify-end">
      placement: right
      <div class="w-32 p-4 border-2 border-mcred relative overflow-hidden">
        <component-tooltip placement="right">
          <template #tooltip>
            Das hier ist ein langer Tooltip Text, der sich automatisch ausrichten sollte. Mal sehen.
            <br />
            Hier auch
            <br />
            mit HTML
            <br />
            und Zeilenumbrüchen.
          </template>
          <div class="bg-mcred">HOVER ME</div>
        </component-tooltip>
      </div>
    </section>

    <div>
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
      scoll
      <br />
    </div>
  </div>
</template>

<script>
  import {ref, computed, watch, inject, defineAsyncComponent} from "vue";
  import {usePage, useForm, router} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentPagination from "@components/Pagination/Pagination.vue";
  import PatientsPatientHead from "@pages/Patients/Components/PatientHead.vue";
  import PatientsPatientRow from "@pages/Patients/Components/PatientRow.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTabButton from "@components/Buttons/TabButton.vue";
  import ComponentFilterFlags from "@components/Filter/FilterFlags.vue";
  import ComponentPatientNoteDialog from "@components/Dialogs/PatientNoteDialog.vue";
  import ComponentLinkButton from "@components/Buttons/LinkButton.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentPatientTodosDialog from "@components/Dialogs/PatientTodosDialog.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentDosageInput from "@components/Inputs/DosageInput.vue";
  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentRange from "@components/Inputs/Range.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "PagesPatientIndex",

    components: {
      ComponentTooltip,
    },

    props: {},

    setup(props) {
      const inputValue = ref(null);
      const selectValue = ref(null);
      const dateValue = ref(null);

      return {inputValue, selectValue, dateValue};
    },
  };
</script>
