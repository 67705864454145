export default {
  formatStringAsFloat(numberString) {
    if (typeof numberString === "number") {
      return numberString;
    } else if (typeof numberString !== "string" || numberString.length === 0 || typeof numberString === "undefined") {
      return 0;
    }

    let float = parseFloat(numberString.replace(",", "."));

    if (isNaN(float)) {
      return 0;
    }

    return float;
  },

  formatFloatAsGermanNumberString(float) {
    if (typeof float === "undefined" || float === null) {
      return "";
    }

    return float.toLocaleString("de-DE");
  },

  isFloat(value) {
    if (typeof value === "undefined" || value === null) {
      return false;
    }

    return Number(value) !== parseInt(value);
  },

  isInteger(value) {
    if (typeof value === "undefined" || value === null) {
      return false;
    }

    return this.isFloat(value) === false;
  },

  isStringFraction(string) {
    return string.indexOf("/") !== -1; // true
  },

  formatStringFractionAsDecimal(string) {
    let splitted = string.split("/");
    let result = parseInt(splitted[0], 10) / parseInt(splitted[1], 10);

    return result;
  },

  round(value, decimals) {
    var decimals = decimals || 0;
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  },
};
