<template>
  <component-dialog
    ref="refDialog"
    dialog-title="Easy-com Kommunikation und Dokumentation"
    dialog-width="medium"
    primary-label="Speichern"
    data-test="easyCom-dialog"
    @primary-button-clicked="save()"
  >
    <template #content>
      <ul class="space-y-6">
        <li class="space-y-2">
          <h3 class="font-bold">Arztkommunikation</h3>
          <component-checkbox
            v-model="form.doctorinfocreated"
            value="1"
            :disabled="disableEasyComEnd || form.processing"
            :class="{'cursor-wait': form.processing}"
            color="accent"
            data-test="easyCom-dialog-checkbox-doctorinfocreated"
          >
            <span :class="{'text-gray-500': disableEasyComEnd}">E-Mail gesendet</span>
            <span v-if="disableEasyComEnd">*</span>
          </component-checkbox>

          <component-checkbox
            v-model="form.doctordocumentcreated"
            value="1"
            :disabled="disableEasyComEnd || form.processing"
            :class="{'cursor-wait': form.processing}"
            color="accent"
            data-test="easyCom-dialog-checkbox-doctordocumentcreated"
          >
            <span :class="{'text-gray-500': disableEasyComEnd}">PDF gespeichert / gedruckt</span>
            <span v-if="disableEasyComEnd">*</span>
          </component-checkbox>
        </li>

        <li class="space-y-2">
          <h3 class="font-bold">Patientenkommunikation</h3>

          <component-checkbox
            v-model="form.patientinfocreated"
            value="1"
            :disabled="disableEasyComEnd || form.processing"
            :class="{'cursor-wait': form.processing}"
            color="accent"
            data-test="easyCom-dialog-checkbox-patientinfocreated"
          >
            <span :class="{'text-gray-500': disableEasyComEnd}">E-Mail gesendet</span>
            <span v-if="disableEasyComEnd">*</span>
          </component-checkbox>

          <component-checkbox
            v-model="form.patientdocumentcreated"
            value="1"
            :disabled="disableEasyComEnd || form.processing"
            :class="{'cursor-wait': form.processing}"
            color="accent"
            data-test="easyCom-dialog-checkbox-patientdocumentcreated"
          >
            <span :class="{'text-gray-500': disableEasyComEnd}">PDF gespeichert / gedruckt</span>
            <span v-if="disableEasyComEnd">*</span>
          </component-checkbox>
        </li>
        <li class="space-y-2">
          <h3 class="font-bold">Dokumentation / PCNE</h3>
          <div>
            <component-checkbox
              v-model="form.documentationcreated"
              value="1"
              :disabled="disableEasyComEnd || form.processing"
              :class="{'cursor-wait': form.processing}"
              color="accent"
              data-test="easyCom-dialog-checkbox-documentationcreated"
            >
              <span :class="{'text-gray-500': disableEasyComEnd}">Analyse im MediCheck dokumentiert</span>
              <span v-if="disableEasyComEnd">*</span>
            </component-checkbox>
            <div class="ml-6 text-gray-600 text-sm">
              (problembezogen in den Analyseergebnissen unter „Dokumentation / PCNE“)
            </div>
          </div>
        </li>
      </ul>

      <div class="mt-4 pt-4 border-t-1">
        <h3 class="font-bold">Status der Variante</h3>
        <div v-if="currentRecord?.closed" class="mt-1 text-sm text-gray-600">
          Diese Variante wurde von Ihnen am
          <span class="font-semibold text-gray-900">{{ currentRecord?.closed }}</span>
          auf „abgeschlossen“ gesetzt.
        </div>

        <component-record-status :record="currentRecord" class="mt-1" inner-class="bottom-8 min-w-24 max-w-32" />
      </div>

      <p v-if="disableEasyComEnd" class="mt-6 text-sm">
        * Für diese Dokumentation muss zunächst eine Analyse durchgeführt werden.
      </p>
    </template>
  </component-dialog>
</template>

<script>
  import {inject, nextTick, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  import ComponentRecordStatus from "@pages/Records/Components/RecordStatus.vue";

  export default {
    name: "ComponentRecordEasyComDialog",

    components: {ComponentRecordStatus, ComponentCheckbox, ComponentDialog},

    props: {
      disableEasyComEnd: {
        type: Boolean,
        default: false,
      },
    },

    expose: ["open", "close"],

    setup(props) {
      const refDialog = ref(null);
      const currentRecord = ref(null);

      const form = useForm({
        doctorinfocreated: false,
        doctordocumentcreated: false,
        patientinfocreated: false,
        patientdocumentcreated: false,
        documentationcreated: false,
      });

      const save = debounce(function () {
        nextTick(() => {
          form.patch(
            route("records.update", {patient: currentRecord.value.patient_id, record: currentRecord.value.id}),
            {
              headers: {
                // avoid updating the edited_at values when just changing the status
                "Mc-Silent-Update": true,
              },
              preserveScroll: true,
            },
          );
        });
      }, 800);

      function open(record) {
        currentRecord.value = record;

        form.doctorinfocreated = record.doctorinfocreated !== null;
        form.doctordocumentcreated = record.doctordocumentcreated !== null;
        form.patientinfocreated = record.patientinfocreated !== null;
        form.patientdocumentcreated = record.patientdocumentcreated !== null;
        form.documentationcreated = record.documentationcreated !== null;

        refDialog.value.open();
      }

      function close() {
        refDialog.value.close();
      }

      return {currentRecord, refDialog, form, save, open, close};
    },
  };
</script>
