<template lang="html">
  <div v-show="isActive">
    <slot :is-active="isActive" />
  </div>
</template>

<script>
  import {ref, inject, watch, onBeforeMount} from "vue";

  export default {
    name: "ComponentTab",

    props: {
      title: {
        type: String,
        default: "",
      },
      infoMessage: {
        type: [Number, String],
        default: "",
      },
    },

    setup() {
      const tabs = inject("tabsProvider");

      const index = ref(0);
      const isActive = ref(true);

      onBeforeMount(() => {
        index.value = tabs.count;
        tabs.count++;
        isActive.value = index.value === tabs.selectedIndex;
      });

      watch(
        () => tabs.selectedIndex,
        () => {
          isActive.value = index.value === tabs.selectedIndex;
        },
      );

      return {index, isActive};
    },
  };
</script>
