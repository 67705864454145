<template>
  <div v-if="record.laborvalues.length" class="py-2 sm:grid sm:grid-cols-4 sm:gap-4">
    <dt class="font-semibold text-gray-900">Labor- / Vitalwerte</dt>
    <dd class="mt-1 text-gray-700 sm:col-span-3 sm:mt-0">
      <ul>
        <!-- @formatter:off -->
        <li
          v-for="laborvalue in record.laborvalues"
          :key="laborvalue.id"
          class="inline after:content-[',_'] last:after:content-['']"
        >
          <span class="font-semibold">{{ laborvalue.type }}</span>
          <template v-if="laborvalue.deviation">
            <component-tooltip v-if="laborvalue.deviation === 'lowered'" tooltip="erniedrigt" :force-inline="true">
              (↓)
            </component-tooltip>
            <component-tooltip v-else-if="laborvalue.deviation === 'raised'" tooltip="erhöht" :force-inline="true">
              (↑)
            </component-tooltip>
            <component-tooltip v-else-if="laborvalue.deviation === 'normal'" tooltip="normal" :force-inline="true">
              (norm.)
            </component-tooltip>
          </template>
          <span>{{ laborvalue.value }} {{ laborvalue.unit }}</span>
          <span v-if="date">
            {{ date }}
          </span>
        </li>
        <!-- @formatter:on -->
      </ul>
    </dd>
  </div>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import {computed} from "vue";

  export default {
    name: "PagesRecordResultPatientDataLaborvalues",
    components: {ComponentTooltip},
    props: {
      record: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const date = computed(() => {
        if (props.record.laborvalue) {
          let date = new Date(props.record.laborvalue);
          if (isNaN(date.getTime())) {
            return null;
          }

          return date.toLocaleDateString("de-DE", {dateStyle: "medium"});
        }
        return null;
      });

      return {date};
    },
  };
</script>
