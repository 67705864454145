export const sharedrecordStatus = {
  active: "active",
  revoked: "revoked",
  completed: "completed",
};
export const recordNotifications = {
  sharedByMe: "HasBeenSharedByMe",
  sharedWithMe: "HasBeenSharedWithMe",
  revokedByMe: "HasBeenRevokedByMe",
  takenAwayFromMe: "HasBeenTakenAwayFromMe",
  completedFromMe: "HasBeenCompletedFromMe",
  returnedToMe: "HasBeenReturnedToMe",
  editedFromMe: "HasBeenEditedFromMe",
  commented: "HasBeenCommented",
};

// also see app/Models/Record/Enum/RecordOrigin.php
export const recordOriginKeys = {
  demo: "demo",
  bmpScan: "scan",
  posFullcheck: "pos.FullCheck",
  patientinput: "patientinput",
  carerinput: "carerinput",
  json: "json",
};

export const recordOriginTexts = {
  demo: "Beispielfall",
  scan: "BMP",
  "pos.FullCheck": "WaWi",
  patientinput: "Patientenfragebogen",
  carerinput: "Pflegecheckliste",
  json: "JSON-Datei",
};
