<template>
  <patientinput-component-step title="Medikation" :test-id="testId + '-medication'">
    <div class="flex flex-col gap-8">
      <section>
        <p>Bitte geben Sie nun Ihre Medikamente, Nahrungsergänzungsmittel etc. an.</p>
      </section>

      <section>
        <p>Scannen Sie den jeweiligen Barcode der Packungen ein, indem Sie auf „Barcodes scannen“ klicken.</p>

        <component-button
          class="w-full p4umc-primary"
          :test-id="testId + '-medication-step-barcode-scan'"
          @click="$refs.pznscanner.open()"
        >
          Barcodes scannen
        </component-button>
      </section>

      <section>
        <p>Oder tippen Sie den Medikamentennamen oder die PZN in die folgende Zeile ein.</p>

        <patientinput-medication-search :form="form" :test-id="testId + '-medication-step'" />
      </section>

      <section v-if="form.medication.pzns.length > 0">
        <h2 class="text-2xl">Ihre Medikamente</h2>

        <component-spinner v-if="loadingMedications" class="w-8" :test-id="testId + '-medication-step'" />

        <template v-else>
          <component-alert
            v-if="form.medication.pzns.length !== form.medication.list.length"
            type="warning"
            class="!mb-0"
            :test-id="testId + '-medication-step'"
          >
            Es wurden nicht alle gescannten Medikamente bzw. Medikamente aus dem Medikationsplan gefunden.
          </component-alert>

          <ul v-if="form.medication.list.length > 0" class="flex flex-col gap-2">
            <patientinput-medication-row
              v-for="(medication, index) in form.medication.list"
              :key="index"
              :medication="medication"
              :form="form"
              :test-id="testId + '-medication-step-' + index"
              @delete="confirmDelete"
            />
          </ul>
        </template>
      </section>
    </div>
  </patientinput-component-step>

  <patientinput-component-pzn-scanner-dialog ref="pznscanner" :form="form" :test-id="testId + '-medication-step'" />

  <component-dialog
    ref="refConfirmDeleteDialog"
    dialog-title="Medikament löschen?"
    primary-label="Ja, Löschen"
    :test-id="testId + '-medication-step-confirm'"
    @primary-button-clicked="deleteConfirmed"
  >
    <template #content>
      Sind Sie sicher, dass Sie das Medikament „{{ medicationToDelete.Name }}“ löschen möchten?
    </template>
  </component-dialog>
</template>

<script>
  import {onMounted, ref, watch} from "vue";

  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  import PatientinputComponentPznScannerDialog from "@pages/Patientinput/Components/Scanners/PznScannerDialog.vue";
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";
  import PatientinputMedicationRow from "@pages/Patientinput/Components/MedicationRow.vue";
  import PatientinputMedicationSearch from "@pages/Patientinput/Components/MedicationSearch.vue";

  export default {
    name: "PatientinputComponentStepMedication",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentDialog,
      ComponentSpinner,
      PatientinputComponentPznScannerDialog,
      PatientinputComponentStep,
      PatientinputMedicationRow,
      PatientinputMedicationSearch,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["navigate"],

    setup(props) {
      const refConfirmDeleteDialog = ref(null);

      const loadingMedications = ref(false);
      const medicationToDelete = ref(null);

      onMounted(() => {
        if (props.form.medication.pzns.length > 0) {
          loadingMedications.value = true;

          coreRouter.searchPacForPatientinput(props.form.medication.pzns.join(","), {
            onSuccess: (result) => {
              props.form.medication.list = result.data;
              loadingMedications.value = false;
            },
          });
        }
      });

      watch(
        () => props.form.medication.pzns,
        (pzns) => {
          props.form.medication.list = props.form.medication.list.filter((medication) =>
            pzns.some((pzn) => parseInt(medication.PZN) === parseInt(pzn)),
          );

          if (props.form.medication.list.length !== pzns.length) {
            loadingMedications.value = true;

            coreRouter.searchPacForPatientinput(pzns.join(","), {
              onSuccess: (result) => {
                props.form.medication.list = result.data;
                loadingMedications.value = false;
              },
            });
          }
        },
        {deep: true},
      );

      const confirmDelete = (medication) => {
        medicationToDelete.value = medication;
        refConfirmDeleteDialog.value.open();
      };

      const deleteConfirmed = () => {
        props.form.medication.pzns = props.form.medication.pzns.filter(
          (pzn) => parseInt(pzn) !== parseInt(medicationToDelete.value.PZN),
        );
        props.form.medication.list = props.form.medication.list.filter(
          ({PZN}) => parseInt(PZN) !== parseInt(medicationToDelete.value.PZN),
        );
        medicationToDelete.value = null;
      };

      return {
        /** ref */
        refConfirmDeleteDialog,

        /** const */
        loadingMedications,
        medicationToDelete,

        /** function */
        confirmDelete,
        deleteConfirmed,
      };
    },
  };
</script>

<style scoped lang="postcss">
  section {
    @apply flex flex-col gap-3;
  }
</style>
