<template>
  <section class="print:pt-20 print:break-inside-avoid-page">
    <div class="flex items-end">
      <div>
        <div class="text-sm h-7 border-b-1 border-gray-300 pr-4 mr-3">
          {{ new Date().toLocaleDateString("de-DE", {dateStyle: "medium"}) }}
        </div>
        <div class="mt-1 text-xs text-gray-500">Datum</div>
      </div>

      <div class="hidden print:block">
        <div class="text-sm h-7 border-b-1 border-gray-300 pr-4">
          {{ signature }}
        </div>
        <div class="mt-1 text-xs text-gray-500">Name, Unterschrift des verantwortlichen Apothekers</div>
      </div>

      <component-input
        v-model="signature"
        class="print:hidden"
        helper-text="Name, Unterschrift des verantwortlichen Apothekers"
        :hide-clear="true"
        data-test="signature-input-signature"
      />
    </div>

    <div v-if="abdataVersion" class="text-gray-400 text-sm cursor-default py-8">
      ABDATA Datenbestand vom {{ abdataVersion }}
    </div>
  </section>
</template>

<script>
  import {computed, ref} from "vue";
  import ComponentInput from "@components/Inputs/Input.vue";

  export default {
    name: "PagesRecordResultSignature",
    components: {ComponentInput},
    props: {
      record: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const signature = ref("");
      const abdataVersion = computed(() => {
        return props.record?.metadata?.version?.abdata;
      });

      return {abdataVersion, signature};
    },
  };
</script>
