<template>
  <component-dialog ref="refDosageDialog">
    <template #title>
      <template v-if="type === 'info'">Hinweis zur Dosierungsangabe</template>
      <template v-else>Dosierungsangabe ungültig</template>
    </template>

    <template #content>
      <template v-if="type === 'info'">
        <p>Die Dosierung "{{ currentValue }}" kann auch als "{{ recommendedValue }}" eingegeben werden.</p>
      </template>

      <template v-else>
        <p>
          Die angegebene

          <template v-if="!dosageTime">Dosierung</template>
          <template v-else>{{ dosageTime }}-Dosierung</template>

          <template v-if="currentValue">("{{ currentValue }}")</template>
          widerspricht der Spezifikation für einen bundeseinheitlichen Medikationsplan.
        </p>

        <template v-if="errorCode === 'valueNotSet'">
          <p>Es muss ein Wert gesetzt sein.</p>
        </template>

        <template v-if="errorCode === 'fractionError'">
          <p>Es dürfen nur die Brüche ½, ⅓, ¼, ⅔, ¾ und ⅛ eingegeben werden.</p>
        </template>

        <template v-else-if="errorCode === 'valueToLong'">
          <p>Die Dosierung darf maximal 4 Zeichen lang sein.</p>
        </template>

        <template v-else-if="errorCode === 'invalidChars'">
          <p>Es dürfen nur Zahlen, ein Komma oder ein Schrägstrich eingegeben werden.</p>
        </template>

        <template v-if="recommendedValue">
          <p>
            <strong>Stattdessen {{ recommendedValue }} übernehmen?</strong>
          </p>
        </template>
      </template>
    </template>

    <template #actions>
      <template v-if="recommendedValue">
        <component-button class="p4umc-primary" label="Übernehmen" @click="primaryButtonClicked" />
        <component-button label="Weiter bearbeiten" @click="cancelButtonClicked" />
      </template>

      <template v-else>
        <component-button class="p4umc-primary" label="Eingabe bearbeiten" @click="cancelButtonClicked" />
      </template>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "ComponentDosageDialog",

    components: {ComponentButton, ComponentDialog},

    emits: ["useRecommendedValue"],

    setup(_, {emit}) {
      const refDosageDialog = ref(null);

      const id = ref(null);
      const type = ref(null);
      const errorCode = ref(null);
      const currentValue = ref(null);
      const recommendedValue = ref(null);

      const dosageTime = computed(() => {
        if (id.value.toString().toLowerCase().includes("morning")) {
          return "Morgen";
        } else if (id.value.toString().toLowerCase().includes("noon")) {
          return "Mittag";
        } else if (id.value.toString().toLowerCase().includes("evening")) {
          return "Abend";
        } else if (id.value.toString().toLowerCase().includes("night")) {
          return "Nacht";
        }

        return null;
      });

      const open = (event) => {
        id.value = event.id;
        type.value = event.type;
        errorCode.value = event.errorCode;
        currentValue.value = event.currentValue;
        recommendedValue.value = event.recommendedValue;

        refDosageDialog.value.open();
      };

      const primaryButtonClicked = () => {
        emit("useRecommendedValue", {
          id: id.value,
        });

        refDosageDialog.value.close();
        reset();
      };

      const cancelButtonClicked = () => {
        refDosageDialog.value.close();
        reset();
      };

      const reset = () => {
        id.value = null;
        type.value = null;
        errorCode.value = null;
        currentValue.value = null;
        recommendedValue.value = null;
      };

      return {
        refDosageDialog,
        type,
        errorCode,
        currentValue,
        recommendedValue,
        dosageTime,
        open,
        primaryButtonClicked,
        cancelButtonClicked,
      };
    },
  };
</script>
