<template>
  <component-button :disabled="loading" @click="exportAsJson">
    <component-spinner v-if="loading" class="h-5 mr-3" />
    <span class="font-medium">Fall im JSON-Format herunterladen</span>
  </component-button>
</template>

<script>
  import {usePage} from "@inertiajs/vue3";
  import {inject, onBeforeUnmount, ref} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "RecordComponentJsonExporter",

    components: {ComponentSpinner, ComponentButton},

    setup() {
      const privacy = inject("$privacy");
      const axios = inject("$axios");
      const page = usePage();

      const loading = ref(false);
      const /** @type {import("vue").Ref<AbortController>} */ abortController = ref(null);

      const exportAsJson = async () => {
        loading.value = true;

        const decryptedPatient = await privacy.decryptPatient(page.props.patient);
        const now = new Date();
        const birthdate = new Date(decryptedPatient.birthdate);
        let age = now.getFullYear() - birthdate.getFullYear();
        const m = now.getMonth() - birthdate.getMonth();
        if (m < 0 || (m === 0 && now.getDate() < birthdate.getDate())) {
          age--;
        }

        if (abortController.value) {
          abortController.value.abort();
        }

        abortController.value = new AbortController();

        axios
          .post(
            route("api.records.json-export", {
              patient: page.props.patient.id,
              record: page.props.record.id,
            }),
            {
              age: age,
            },
            {
              signal: abortController.value.signal,
            },
          )
          .then((response) => {
            downloadObjectAsJson(response.data, "medicheck_export_" + Date.now());
          })
          .finally(() => {
            loading.value = false;
          });
      };

      const downloadObjectAsJson = (exportObj, exportName) => {
        // https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      };

      onBeforeUnmount(() => {
        if (abortController.value) {
          abortController.value.abort();
        }
      });

      return {loading, exportAsJson};
    },
  };
</script>
