/**
 * @see App\Models\Patient\Enum\Salutation.php
 * @type {{mr: string, mrs: string}}
 */
export const patientSalutation = {
  mr: "Herr",
  mrs: "Frau",
};

/**
 * @see App\Models\Patient\Enum\Gender.php
 * @type {{diverse: string, female: string, male: string}}
 */
export const patientGender = {
  male: "männlich",
  female: "weiblich",
  diverse: "divers",
  // indeterminate: "Unbestimmt",
};

/**
 * https://gkv-datenaustausch.de/media/dokumente/arbeitgeber/deuev/rundschreiben_anlagen/GemRS_Anlage_07.pdf
 * @type {string[]}
 */
export const suffixAutocomplete = [
  "Bar",
  "Baron",
  "Baroness",
  "Baronesse",
  "Baronin",
  "Brand",
  "Burggraf",
  "Burggräfin",
  "Condesa",
  "Earl",
  "Edle",
  "Edler",
  "Erbgraf",
  "Erbgräfin",
  "Erbprinz",
  "Erbprinzessin",
  "Ffr",
  "Freifr",
  "Freifräulein",
  "Freifrau",
  "Freih",
  "Freiherr",
  "Freiin",
  "Frf",
  "Frf.",
  "Frfr",
  "Frfr.",
  "Frh",
  "Frh.",
  "Frhr",
  "Frhr.",
  "Fst",
  "Fst.",
  "Fstn",
  "Fstn.",
  "Fürst",
  "Fürstin",
  "Gr",
  "Graf",
  "Gräfin",
  "Grf",
  "Grfn",
  "Grossherzog",
  "Großherzog",
  "Grossherzogin",
  "Großherzogin",
  "Herzog",
  "Herzogin",
  "Jhr",
  "Jhr.",
  "Jonkheer",
  "Junker",
  "Landgraf",
  "Landgräfin",
  "Markgraf",
  "Markgräfin",
  "Marques",
  "Marquis",
  "Marschall",
  "Ostoja",
  "Prinz",
  "Prinzessin",
  "Przin",
  "Rabe",
  "Reichsgraf",
  "Reichsgräfin",
  "Ritter",
  "Rr",
  "Truchsess",
  "Truchseß",
];

/**
 * https://gkv-datenaustausch.de/media/dokumente/arbeitgeber/deuev/rundschreiben_anlagen/GemRS_Anlage_06.pdf
 * @type {string[]}
 */
export const prefixAutocomplete = [
  "a",
  "aan de",
  "aan den",
  "al",
  "am",
  "an",
  "an der",
  "auf",
  "auf dem",
  "auf der",
  "auf m",
  "aufm",
  "auff m",
  "aus",
  "aus dem",
  "aus den",
  "aus der",
  "b",
  "be",
  "bei",
  "bei der",
  "beim",
  "ben",
  "bey",
  "bey der",
  "che",
  "cid",
  "d",
  "d.",
  "d'",
  "da",
  "da costa",
  "da las",
  "da silva",
  "dal",
  "dall",
  "dall'",
  "dalla",
  "dalle",
  "dallo",
  "das",
  "de",
  "degli",
  "dei",
  "den",
  "de l '",
  "de la",
  "de las",
  "de le",
  "de los",
  "del",
  "del coz",
  "deli",
  "dell",
  "dell'",
  "della",
  "delle",
  "delli",
  "dello",
  "der",
  "des",
  "di",
  "dit",
  "do",
  "do ceu",
  "don",
  "don le",
  "dos",
  "dos santos",
  "du",
  "dy",
  "el",
  "g",
  "gen",
  "gil",
  "gli",
  "grosse",
  "große",
  "i",
  "im",
  "in",
  "in de",
  "in den",
  "in der",
  "in het",
  "in't",
  "kl",
  "kleine",
  "l",
  "l.",
  "l'",
  "la",
  "le",
  "lee",
  "li",
  "lo",
  "m",
  "mc",
  "mac",
  "n",
  "o",
  "o'",
  "op",
  "op de",
  "op den",
  "op gen",
  "op het",
  "op te",
  "op ten",
  "oude",
  "pla",
  "pro",
  "s",
  "st.",
  "t",
  "te",
  "ten",
  "ter",
  "thi",
  "tho",
  "thom",
  "thor",
  "thum",
  "to",
  "tom",
  "tor",
  "tu",
  "tum",
  "unten",
  "unter",
  "unterm",
  "v.",
  "v. d.",
  "v. dem",
  "v. den",
  "v. der",
  "v.d.",
  "v.dem",
  "v.den",
  "v.der",
  "van",
  "van de",
  "van dem",
  "van den",
  "van der",
  "vande",
  "vandem",
  "vanden",
  "vander",
  "van gen",
  "van het",
  "van t",
  "ven",
  "ven der",
  "ver",
  "vo",
  "vom",
  "vom und zu",
  "von",
  "von und zu",
  "von und zu der",
  "von und zur",
  "von de",
  "von dem",
  "von den",
  "von der",
  "von la",
  "von zu",
  "von zum",
  "von zur",
  "vonde",
  "vonden",
  "vondem",
  "vonder",
  "von einem",
  "von mast",
  "vor",
  "vor dem",
  "vor den",
  "vor der",
  "vorm",
  "vorn",
  "y",
  "y del",
  "zu",
  "zum",
  "zur",
];
