<template>
  <div :class="{'flex flex-wrap space-x-1.5': inline}">
    <h6 class="text-gray-600" :class="{'font-semibold': inline, 'text-xs uppercase': !inline}">
      {{ attributeTitle }}{{ inline ? ":" : "" }}
    </h6>
    <div :class="{'mt-0.5 text-sm': !inline}">
      {{ attributeText }}
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";
  import {simpleAttributeText, simpleAttributeTitle} from "@pages/Patients/Components/PatientAttributes/Enums.js";

  export default {
    name: "ComponentPatientSidebarBedriddenAttribute",

    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const attributeTitle = computed(() => simpleAttributeTitle[props.attribute.type] ?? null);
      const attributeText = computed(() => simpleAttributeText[props.attribute.type] ?? null);

      return {attributeTitle, attributeText};
    },
  };
</script>
