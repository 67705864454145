<template>
  <component-dialog
    ref="refDemoDataDialog"
    dialog-title="Beispielfälle"
    dialog-width="medium"
    dialog-type="abort"
    :test-id="testId + '-demo-data'"
  >
    <template #content>
      <div v-if="loading" class="text-center py-10">
        <component-spinner class="w-10" :test-id="testId + '-demo-data-dialog'" />
      </div>

      <component-existing-patient-select
        v-else-if="existingPatients.length > 0"
        :existing-patients="existingPatients"
        :existing-patients-encrypted="true"
        :current-patient="demodata.patient"
        :current-patient-encrypted="false"
        :test-id="testId + '-demo-data-dialog'"
        @existing-patient-selected="addToExistingPatient"
      >
        <component-alert type="warning" class="mb-0" :test-id="testId + '-demo-data-dialog-existing-patient-select'">
          <span>
            Sie haben diesen Beispielfall bereits verwendet. Möchten Sie eine neue Medikationsanalyse für den
            bestehenden Patienten erstellen?
          </span>
        </component-alert>
      </component-existing-patient-select>

      <div v-else class="grid grid-cols-3 gap-6 mx-4 my-10">
        <component-demodata-dialog-selectable-democase
          :demodata-id="1"
          name="Ursula Uebel"
          disease="Parkinson"
          :img-src="UrsulaUebel"
          :test-id="testId + '-demo-data-dialog-ursula-uebel'"
          @select="demodataSelected"
        />

        <component-demodata-dialog-selectable-democase
          :demodata-id="2"
          name="Therese Torsade"
          disease="QT-Risiko"
          :img-src="ThereseTorsade"
          :test-id="testId + '-demo-data-dialog-therese-torsade'"
          @select="demodataSelected"
        />

        <component-demodata-dialog-selectable-democase
          :demodata-id="3"
          name="Beate Blass"
          disease="Anämie"
          :img-src="BeateBlass"
          :test-id="testId + '-demo-data-dialog-beate-blass'"
          @select="demodataSelected"
        />
      </div>
    </template>

    <template #actions>
      <component-button
        v-if="existingPatients.length > 0 && !loading"
        class="p4umc-primary"
        :test-id="testId + '-demo-data-dialog-add-to-new-patient'"
        @click="addToNewPatient"
      >
        Als neuen Patienten importieren
      </component-button>

      <component-button :test-id="testId + '-demo-data-dialog-close'" @click="close">Abbrechen</component-button>
    </template>
  </component-dialog>
</template>

<script>
  import {inject, reactive, ref, toRefs} from "vue";
  import {router} from "@inertiajs/vue3";

  import BeateBlass from "@images/demodata/BeateBlass.png";
  import ThereseTorsade from "@images/demodata/ThereseTorsade.png";
  import UrsulaUebel from "@images/demodata/UrsulaUebel.png";

  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentExistingPatientSelect from "@components/ExistingPatientSelect/ExistingPatientSelect.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  import ComponentDemodataDialogSelectableDemocase from "@components/Dialogs/DemodataDialog/SelectableDemocase.vue";

  export default {
    name: "ComponentDemodataDialog",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentDialog,
      ComponentExistingPatientSelect,
      ComponentSpinner,
      ComponentDemodataDialogSelectableDemocase,
    },

    setup() {
      const axios = inject("$axios");
      const privacy = inject("$privacy");

      const refDemoDataDialog = ref(null);

      const state = reactive({
        loading: false,
        demodata: {},
        existingPatients: [],
      });

      const open = () => {
        state.loading = false;
        state.demodata = {};
        state.existingPatients = [];
        refDemoDataDialog.value?.open();
      };

      const close = () => {
        refDemoDataDialog.value?.close();
      };

      const demodataSelected = async (id) => {
        state.loading = true;

        state.demodata = (await axios.get(route("api.demodata", {id: id}))).data;

        state.existingPatients = filterExistingPatients(
          state.demodata.patient,
          await Promise.all(
            (
              await axios.post(route("api.patients.search"), await privacy.encryptPatient(state.demodata.patient))
            ).data.data.map(async (patient) => await privacy.decryptPatient(patient)),
          ),
          ["firstname", "lastname", "gender", "birthdate"],
        );

        if (state.existingPatients.length > 0) {
          state.loading = false;
        } else {
          state.demodata.patient = await privacy.encryptPatient(state.demodata.patient);
          router.post(route("init-from-json", {origin: "demodata"}), state.demodata, {
            onSuccess: close,
          });
        }
      };

      const addToExistingPatient = async (patient) => {
        state.loading = true;
        state.demodata.patient = await privacy.encryptPatient(state.demodata.patient);
        router.post(route("init-from-json", {origin: "demodata", patient: patient.id}), state.demodata, {
          onSuccess: close,
        });
      };

      const addToNewPatient = async () => {
        state.loading = true;
        state.demodata.patient = await privacy.encryptPatient(state.demodata.patient);
        router.post(route("init-from-json", {origin: "demodata"}), state.demodata, {
          onSuccess: close,
        });
      };

      return {
        /** image */
        BeateBlass,
        ThereseTorsade,
        UrsulaUebel,

        /** ref */
        refDemoDataDialog,

        /** const */
        ...toRefs(state),

        /** function */
        addToExistingPatient,
        addToNewPatient,
        close,
        demodataSelected,
        open,
      };
    },
  };
</script>
