<template>
  <div
    class="grid grid-cols-[1fr_1fr_140px_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_] font-normal items-end text-sm gap-x-2 text-gray-700 pb-2 border-b-1 border-gray-300"
  >
    <div class="col-span-2 ml-2" :class="{'ml-7': isEditable}">
      <div class="flex items-center" :class="{'cursor-pointer': isEditable}" @click="sortByFam()">
        <template v-if="isEditable">
          <template v-if="sortDirection === 'fam.asc'">
            <component-icon>expand_more</component-icon>
          </template>

          <template v-else-if="sortDirection === 'fam.desc'">
            <component-icon>expand_less</component-icon>
          </template>

          <template v-else>
            <component-icon>unfold_more</component-icon>
          </template>
        </template>

        Arzneimittel
      </div>

      <div class="flex space-x-1 items-end" :class="{'cursor-pointer': isEditable}" @click="sortByUsageAndFam()">
        <div class="text-xs flex items-center">
          <template v-if="isEditable">
            <template v-if="sortDirection === 'usage.asc'">
              <component-icon>expand_more</component-icon>
            </template>

            <template v-else-if="sortDirection === 'usage.desc'">
              <component-icon>expand_less</component-icon>
            </template>

            <template v-else>
              <component-icon>unfold_more</component-icon>
            </template>
          </template>

          Art
        </div>
        <component-info-icon placement="top" class="-mb-1">
          <template #content>
            <div class="text-center font-bold">Begriffserklärungen Medikationsnart</div>

            <p class="mt-2">
              <span class="font-bold">Dauer:</span>
              Unter Dauermedikation versteht man die Anwendung eines oder mehrerer Medikamente über einen längeren
              Zeitraum (meist > 3 Monate).
            </p>

            <p class="mt-2">
              <span class="font-bold">Kurzzeit:</span>
              Unter der Kurzzeitmedikation versteht man die Anwendung über über ein kurzes, begrenztes Zeitintervall.
            </p>

            <p class="mt-2">
              <span class="font-bold">Bedarf:</span>
              Unter der Bedarfsmedikation versteht man die Anwendung bei entsprechenden Symptomen, z. B. Schmerzen oder
              Übelkeit, zusätzlich zur regulären Medikation
            </p>
          </template>
        </component-info-icon>
      </div>
    </div>

    <div class="col-span-1 flex space-x-1 items-end">
      <span class="text-xs">Dosierung</span>
      <component-info-icon placement="top" class="-mb-1">
        <template #content>
          <div class="text-center font-bold">Dosierungsangaben</div>
          <br />
          <p>
            Achtung: Automatisiert überprüft wurden nur die Tagesdosen fester Arzneiformen wie TAB, FTA, BTA, KAP, RET,
            LUT, Pulver, Granulate, SUPP gegen die unterste und die höchste in der Fachinformation hinterlegte
            Regeldosis (Dosisspanne) für Erwachsene, Ältere und Niereninsuffiziente. Eine indikationsabhängige
            Überprüfung der Dosis erfolgt nicht. Ebenso ist die automatisierte Dosisüberprüfung bei Doppelverordnungen
            nur eingeschränkt möglich. Bei Bedarf entnehmen Sie Detailinformationen bitte eigenverantwortlich aus der
            Fachinformation.
          </p>
        </template>
      </component-info-icon>
    </div>

    <div class="col-span-2 text-xs">Einheit</div>

    <div class="col-span-2 flex space-x-1 items-end">
      <span class="text-xs">Mahlzeit</span>
      <component-info-icon placement="top" class="-mb-1">
        <template #content>
          <div class="text-center font-bold">Begriffserklärungen</div>
          <p class="mt-2">
            <span class="font-bold">Einnahme vor den Mahlzeiten:</span>
            Kontakt mit Nahrung im GIT vermeiden, z. B. 30-60 Minuten vor der Mahlzeit.
          </p>

          <p class="mt-2">
            <span class="font-bold">Einnahme zwischen den Mahlzeiten:</span>
            Kontakt mit Nahrung im GIT vermeiden, z. B. mind.2 Stunden nach einer Mahlzeit einnehmen.
          </p>

          <p class="mt-2">
            <span class="font-bold">Einnahme zu oder nach den Mahlzeiten:</span>
            In zeitnahem Zusammenhang mit der Mahlzeit oder direkt bzw. innerhalb von 15 Minuten nach dem Essen
            einnehmen.
          </p>

          <p class="mt-2">
            <span class="font-bold">Einnahme unabhängig von den Mahlzeiten:</span>
            Nahrung hat keinen relevanten Einfluss auf die Bioverfügbarkeit → Patient kann frei wählen (nach
            Adhärenzaspekten).
          </p>
        </template>
      </component-info-icon>
    </div>

    <div
      class="col-span-3 xl:col-span-4 text-xs flex items-center"
      :class="{'cursor-pointer': isEditable}"
      @click="sortByIndication()"
    >
      <template v-if="isEditable">
        <template v-if="sortDirection === 'indication.asc'">
          <component-icon>expand_more</component-icon>
        </template>

        <template v-else-if="sortDirection === 'indication.desc'">
          <component-icon>expand_less</component-icon>
        </template>

        <template v-else>
          <component-icon>unfold_more</component-icon>
        </template>
      </template>

      Grund/Ind.
    </div>

    <div class="col-span-1 inline-flex text-xs justify-end">Details</div>
  </div>

  <ul ref="parent">
    <template v-for="medication in medications" :key="medication.id">
      <template v-if="isLoading">
        <medication-skeleton-row />
      </template>

      <template v-else>
        <medication-table-row :is-editable="isEditable" :medication="medication" :is-dragging="isDragging" />
      </template>
    </template>
  </ul>
</template>

<script>
  import {computed, ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import {cloneDeep, isEqual} from "lodash";
  import {useDragAndDrop} from "@formkit/drag-and-drop/vue";
  import {dropOrSwap} from "@formkit/drag-and-drop";

  import {sortList, sortListByIndicationAndFam, sortListByUsageAndFam} from "@utils/Helpers/ListSorter.js";

  import MedicationTableRow from "@pages/Records/Components/Sections/MedicationBar/Components/Row.vue";
  import MedicationSkeletonRow from "@pages/Records/Components/Sections/MedicationBar/Components/SkeletonRow.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "MedicationTable",

    components: {
      ComponentIcon,
      ComponentInfoIcon,
      MedicationSkeletonRow,
      MedicationTableRow,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup: function (props) {
      const page = usePage();
      const isDragging = ref(false);

      const config = {
        dragHandle: "[drag-handle]",
        synthDropZoneClass: "ring-2 ring-mcred-300",
        plugins: [
          dropOrSwap({
            shouldSwap: false,
          }),
        ],
        onDragstart: (data) => {
          isDragging.value = true;
        },
        onDragend: (data) => {
          isDragging.value = false;
        },
      };

      const [parent, medications] = useDragAndDrop(props.recordData.medications, config);

      const sortDirection = computed(() => {
        if (medications.value.length > 0) {
          const copyOfMedications = cloneDeep(medications.value);
          const medicationIds = copyOfMedications.map((medication) => medication.id);

          const indicationAscSortedObject = sortListByIndicationAndFam(copyOfMedications);

          if (isEqual(medicationIds, indicationAscSortedObject.sortedIds)) {
            return "indication.asc";
          }

          if (isEqual(medicationIds, indicationAscSortedObject.sortedIds.reverse())) {
            return "indication.desc";
          }

          const usageAscSortedObject = sortListByUsageAndFam(copyOfMedications);
          const usageDescSortedObject = sortListByUsageAndFam(copyOfMedications, "desc");

          if (isEqual(medicationIds, usageAscSortedObject.sortedIds)) {
            return "usage.asc";
          }

          if (isEqual(medicationIds, usageDescSortedObject.sortedIds)) {
            return "usage.desc";
          }

          const famAscSortedObject = sortList(copyOfMedications, "fam");

          if (isEqual(medicationIds, famAscSortedObject.sortedIds)) {
            return "fam.asc";
          }

          if (isEqual(medicationIds, famAscSortedObject.sortedIds.reverse())) {
            return "fam.desc";
          }
        }

        return "";
      });

      const newSortDirection = computed(() => {
        const splitValue = sortDirection.value.includes(".") ? sortDirection.value.split(".") : sortDirection.value;

        if (splitValue === "" || splitValue[1] === "desc") {
          return "asc";
        }

        if (splitValue[1] === "asc") {
          return "desc";
        }

        return "";
      });

      watch(
        () => props.recordData.medications,
        () => {
          medications.value = props.recordData.medications;
        },
        {deep: true},
      );

      watch(
        () => medications.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = oldValue.map((medication) => medication.id);
            const newSortOrder = value.map((medication) => medication.id);

            if (!isEqual(oldSortOrder, newSortOrder)) {
              updateOrder(newSortOrder);
            }
          }
        },
      );

      const updateOrder = (newSortOrder) => {
        router.post(
          route("sort.store", {
            patient: page.props.patient.id,
            record: page.props.record.id,
            relation: "medications",
          }),
          {
            ids: newSortOrder,
          },
          {
            preserveScroll: true,
            only: ["record", "flash"],
            onFinish: () => {
              isDragging.value = true;
            },
          },
        );
      };

      const sortByFam = () => {
        if (!props.isEditable) return;

        const sortedObject = sortList(medications.value, "fam", newSortDirection.value);
        medications.value = sortedObject.sortedArray;

        updateOrder(sortedObject.sortedIds);
      };

      const sortByUsageAndFam = () => {
        if (!props.isEditable) return;

        const sortedObject = sortListByUsageAndFam(medications.value, newSortDirection.value);
        medications.value = sortedObject.sortedArray;

        updateOrder(sortedObject.sortedIds);
      };

      const sortByIndication = () => {
        if (!props) return;

        const sortedObject = sortListByIndicationAndFam(medications.value, newSortDirection.value);
        medications.value = sortedObject.sortedArray;

        updateOrder(sortedObject.sortedIds);
      };

      return {
        /** ref */
        parent,

        /** const */
        medications,
        isDragging,

        /** computed */
        sortDirection,

        /** function */
        sortByFam,
        sortByUsageAndFam,
        sortByIndication,
      };
    },
  };
</script>
