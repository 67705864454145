<template>
  <div class="min-h-screen flex flex-col">
    <InertiaHead :title="title" class="print:hidden" />

    <div class="flex-grow pb-4 px-2 md:px-4">
      <div class="max-w-xl mx-auto h-full">
        <slot />
      </div>
    </div>

    <footer class="print:hidden bg-gray-200 print:hidden">
      <div
        class="md:flex md:flex-row-reverse md:justify-between md:flex-wrap md:items-center max-w-screen-lg mx-auto px-4 py-6"
      >
        <ul class="flex items-center space-x-8">
          <li>
            <a
              class="no-underline hover:underline text-gray-900"
              target="_blank"
              href="https://www.pharma4u.de/impressum"
            >
              Impressum
            </a>
          </li>
        </ul>

        <p class="text-gray-500">&copy; {{ new Date().getFullYear() }} pharma4u GmbH</p>
      </div>
    </footer>
  </div>
</template>

<script>
  import {Head as InertiaHead} from "@inertiajs/vue3";

  import LayoutFooter from "@components/Layout/Components/Footer.vue";
  import LayoutPatientinputHeader from "@components/Layout/Components/PatientinputHeader.vue";

  export default {
    name: "ComponentPatientinputLayout",

    components: {
      InertiaHead,
      LayoutPatientinputHeader,
      LayoutFooter,
    },

    props: {
      title: {
        type: String,
        default: null,
      },
    },
  };
</script>
