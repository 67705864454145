export const daysNumber = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const daysOfWeekGermanNames = {
  monday: "Montag",
  tuesday: "Dienstag",
  wednesday: "Mittwoch",
  thursday: "Donnerstag",
  friday: "Freitag",
  saturday: "Samstag",
  sunday: "Sonntag",
};

export const daysOfWeekGermanShort = {
  monday: "Mo",
  tuesday: "Di",
  wednesday: "Mi",
  thursday: "Do",
  friday: "Fr",
  saturday: "Sa",
  sunday: "So",
};

export const monthNumber = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
};

export const monthGermanNames = {
  january: "Januar",
  february: "Februar",
  march: "März",
  april: "April",
  may: "Mai",
  june: "Juni",
  july: "Juli",
  august: "August",
  september: "September",
  october: "Oktober",
  november: "November",
  december: "Dezember",
};

export const hourswritten = {
  six: "6:00",
  seven: "7:00",
  eight: "8:00",
  nine: "9:00",
  ten: "10:00",
  eleven: "11:00",
  twelve: "12:00",
  thirteen: "13:00",
  fourteen: "14:00",
  fifteen: "15:00",
  sixteen: "16:00",
  seventeen: "17:00",
  eighteen: "18:00",
  nineteen: "19:00",
  twenty: "20:00",
  twentyone: "21:00",
  twentytwo: "22:00",
};

export const hours = [
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
];
