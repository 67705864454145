<template>
  <patientinput-component-step title="Diagnosen" optional>
    <div class="flex flex-col gap-8">
      <section><p>Hier können Sie Diagnosen des Patienten angeben.</p></section>

      <section>
        <patientinput-component-autocomplete
          placeholder="Nach Diagnose suchen"
          :fetch-method="doSearch"
          @selected="diseaseSelected"
        >
          <template #autocomplete-item="{item}">
            <div class="min-h-7 flex items-center">
              {{ item.Name }}
            </div>

            <div v-if="form.diagnoses.mivs.some((miv) => miv === item.Key_MIV)" class="text-sm text-mcred-500">
              Diagnose wurde bereits hinterlegt
            </div>
          </template>
        </patientinput-component-autocomplete>
      </section>

      <section v-if="form.diagnoses.list.length > 0">
        <h2 class="text-2xl">Angegebene Diagnosen</h2>

        <ul class="flex flex-col gap-2">
          <li
            v-for="(disease, index) in form.diagnoses.list"
            :key="index"
            class="flex flex-row gap-5 justify-between items-center"
          >
            <div>{{ disease.Name }}</div>

            <component-icon-button
              title="Diagnose löschen"
              icon="delete"
              class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
              @click="confirmDelete(disease)"
            />
          </li>
        </ul>
      </section>
    </div>

    <component-dialog
      ref="refConfirmDeleteDialog"
      dialog-title="Diagnose löschen?"
      primary-label="Ja, Löschen"
      @primary-button-clicked="deleteConfirmed"
    >
      <template #content>
        Sind Sie sicher, dass Sie die Diagnose „{{ diagnoseToDelete.Name }}“ löschen möchten?
      </template>
    </component-dialog>
  </patientinput-component-step>
</template>

<script>
  import {ref} from "vue";

  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import PatientinputComponentAutocomplete from "@pages/Patientinput/Components/Autocomplete.vue";

  export default {
    name: "PatientinputComponentStepDiagnoses",

    components: {
      PatientinputComponentAutocomplete,
      ComponentDialog,
      ComponentIconButton,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const /** @type {import("vue").Ref} */ refConfirmDeleteDialog = ref(null);

      const /** @type {import("vue").Ref<?Object>} */ diagnoseToDelete = ref(null);

      const doSearch = (searchTerm) => {
        return new Promise((resolve) => {
          coreRouter.searchDiseasesForPatientinput(searchTerm, props.form.masterdata.patient.gender, {
            onSuccess: (response) => {
              resolve(response.data);
            },
            onError: () => {
              resolve(false);
            },
          });
        });
      };

      const diseaseSelected = (disease) => {
        if (!props.form.diagnoses.mivs.includes(disease.Key_MIV)) {
          props.form.diagnoses.mivs.push(disease.Key_MIV);
          props.form.diagnoses.list.push(disease);
        }
      };

      const confirmDelete = (disease) => {
        diagnoseToDelete.value = disease;
        refConfirmDeleteDialog.value.open();
      };

      const deleteConfirmed = () => {
        props.form.diagnoses.mivs = props.form.diagnoses.mivs.filter((miv) => miv !== diagnoseToDelete.value.Key_MIV);
        props.form.diagnoses.list = props.form.diagnoses.list.filter(
          ({Key_MIV}) => Key_MIV !== diagnoseToDelete.value.Key_MIV,
        );
        diagnoseToDelete.value = null;
      };

      return {
        refConfirmDeleteDialog,
        diagnoseToDelete,
        doSearch,
        diseaseSelected,
        confirmDelete,
        deleteConfirmed,
      };
    },
  };
</script>

<style scoped lang="postcss">
  section {
    @apply flex flex-col gap-3;
  }
</style>
