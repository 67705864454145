<template>
  <component-dialog ref="refCryptDialog" dialog-width="medium" :test-id="testId">
    <template #title>
      <span class="hidden print:inline">MediCheck</span>
      Datenschutzpasswort
    </template>

    <template #content>
      <div class="print:hidden">
        <template v-if="isLoading">
          <div class="text-center">
            <component-spinner class="h-12 w-12 mt-8" :test-id="testId + '-dialog'" />
          </div>
        </template>

        <template v-else>
          <p>
            Den 64 Zeichen langen Wiederher&shy;stellungs&shy;schlüssel (Recovery-Code) haben Sie sich beim Einrichten
            des Datenschutzpassworts ausgedruckt oder gespeichert.
          </p>

          <component-textarea
            v-model="recoveryCodePlaintext"
            label="Recovery-Code"
            :helper-text="
              recoveryCodePlaintextLength < privacy.getRecoveryCodeLength()
                ? 'noch ' + (privacy.getRecoveryCodeLength() - recoveryCodePlaintextLength) + ' Zeichen'
                : null
            "
            :validation="privacy.getErrorMessage()"
            :test-id="testId + '-dialog-recovery-code-plaintext'"
            @keyup.enter="verifyRecoveryCode"
          />

          <p class="mt-4">
            Sie können den Recovery-Code sowohl mit als auch ohne Leerzeichen eingeben.
            <br />
            Bitte beachten Sie, dass Sie zur Zeit Ihr Datenschutzpasswort nach Eingabe des
            Wiederher&shy;stellungs&shy;schlüssels noch nicht ändern können.
            <br />
            <a href="#" @click.prevent="userRemembersPasswordAgain">Ich erinnere mich wieder an mein Passwort</a>
          </p>
        </template>
      </div>
    </template>

    <template #actions>
      <component-button
        :disabled="!validRecoveryCodeLength"
        class="p4umc-primary"
        label="Mit Recovery-Code entschlüsseln"
        :test-id="testId + '-dialog-verify-recovery-code'"
        @click="verifyRecoveryCode"
        @keydown.enter="verifyRecoveryCode"
      />

      <component-button
        class="p4umc-flat"
        tabindex="-1"
        label="Abmelden"
        :test-id="testId + '-dialog-logout'"
        @click="logout"
      />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref} from "vue";
  import {router, usePage} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "CryptDialogsRecovery",

    components: {ComponentSpinner, ComponentButton, ComponentTextarea, ComponentDialog},

    props: {
      privacy: {
        type: Object,
        required: true,
        default: () => {},
      },
      isLoading: {
        type: Boolean,
        required: true,
        default: false,
      },
      logout: {
        type: Function,
        required: true,
        default: () => {},
      },
    },

    setup(props) {
      const page = usePage();

      const refCryptDialog = ref(null);

      const recoveryCodePlaintext = ref("");

      const validRecoveryCodeLength = computed(() => recoveryCodePlaintextLength.value === 64);
      const recoveryCodePlaintextLength = computed(() =>
        recoveryCodePlaintext.value !== null ? recoveryCodePlaintext.value.split(" ").join("").trim().length : 0,
      );

      const open = () => {
        refCryptDialog.value.open();
      };

      const close = () => {
        if (refCryptDialog.value) refCryptDialog.value.close();
      };

      const verifyRecoveryCode = () => {
        const callback = () => {
          props.privacy.setCurrentStatus("RECOVERY_PENDING");
          props.privacy.initCrypt("", "RECOVERY_VALID");

          setTimeout(() => {
            close();
            router.get(route("privacyPassword.show", {user: page.props.client.users[0].id}));
          });
        };

        const errorCallback = () => {
          props.privacy.setErrorMessage("Der Recovery-Code ist nicht korrekt.");
          props.privacy.setCurrentStatus("RECOVERY_VERIFICATION_INVALID");
        };

        props.privacy.verifyRecoveryCode(callback, errorCallback, recoveryCodePlaintext.value);
      };

      const userRemembersPasswordAgain = () => {
        recoveryCodePlaintext.value = null;
        props.privacy.setErrorMessage(null);
        props.privacy.setCurrentStatus("VERIFICATION_PENDING");
      };

      return {
        /** ref */
        refCryptDialog,

        /** const */
        recoveryCodePlaintext,

        /** computed */
        validRecoveryCodeLength,
        recoveryCodePlaintextLength,

        /** function */
        open,
        close,
        verifyRecoveryCode,
        userRemembersPasswordAgain,
      };
    },
  };
</script>
