<template>
  <ol>
    <li v-for="(claim, index) in claims" :key="claim.id" :class="{'opacity-50 hover:opacity-100': claim.hidden}">
      <pages-record-result-list-claim-item
        :patient-id="patientId"
        :record-id="recordId"
        :mc-check-id="mcCheckId"
        :claim="claim"
        :claim-number="index + 1"
        :has-sibling-claims="claims.length > 1"
        @open:share-dialog="$emit('open:shareDialog')"
      />
    </li>
  </ol>
</template>

<script>
  import PagesRecordResultListClaimItem from "@pages/Records/Components/Result/ResultList/ListClaimItem.vue";

  export default {
    name: "PagesRecordResultListClaims",
    components: {PagesRecordResultListClaimItem},
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      mcCheckId: {
        type: Number,
        required: true,
      },
      claims: {
        type: Array,
        required: true,
      },
    },
  };
</script>
