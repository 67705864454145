/**
 * Returns reduced DOM to print as pdf
 */
const readDom = (type) => {
  let tmpDiv = document.createElement("div");

  // copy body.innerHTML to tmpDiv
  tmpDiv.innerHTML = document.body.innerHTML;

  // get all html elements
  let allElements = tmpDiv.getElementsByTagName("*");

  // cleanup...
  for (let i = 0; i < allElements.length; i++) {
    let element = allElements[i];

    // delete active <dialog> <trix-toolbar> <trix-editor> elements
    if (
      element.tagName.toLowerCase() === "dialog" ||
      element.tagName.toLowerCase() === "trix-toolbar" ||
      element.tagName.toLowerCase() === "trix-editor"
    ) {
      element.remove();
    }

    // or delete element when hidden for current type
    // (type is doc, patient or archive)
    // ( (element is hidden OR print:hidden)
    //   AND NOT print:block for the type
    //   AND NOT print:block in general => then remove() it
    // )
    // OR element is print:hidden for the type => then remove() it
    else if (
      typeof type !== "undefined" &&
      (((element.classList.contains("hidden") || element.classList.contains("print:hidden")) &&
        !element.classList.contains("[.print-" + type + "_&]:print:block") &&
        !element.classList.contains("print:block")) ||
        element.classList.contains("[.print-" + type + "_&]:print:hidden"))
    ) {
      element.remove();
    }

    // or delete data-* attributes
    else {
      // get all attributes
      let attributes = element.attributes;

      // loop through attributes from end to start (to avoid issues when removing attributes)
      for (let j = attributes.length - 1; j >= 0; j--) {
        let attribute = attributes[j];

        // remove data-* attributes
        if (attribute.name.startsWith("data-")) {
          element.removeAttribute(attribute.name);
        }
      }
    }
  }

  return tmpDiv.innerHTML;
};

export default readDom;
