<template>
  <component-foldable
    v-if="showFoldable"
    :title="foldableTitle"
    :badge-label="badgeLabel"
    badge-color="blue"
    :test-id="testId + '-patient-input'"
  >
    <div>
      <ul class="space-y-4">
        <template v-for="(title, key) in attributes" :key="key">
          <li v-if="key === 'nursing_home'">
            <h6 class="text-xs text-gray-600 uppercase">{{ title }}</h6>

            <ul class="mt-0.5 text-sm">
              <li v-for="(nHTitle, nHKey) in nursingHomeAttributes" :key="nHKey" class="mb-1">
                <p class="font-semibold">
                  {{ nHTitle }}
                </p>

                <p class="flex gap-1">
                  <span :data-encrypted="record.metadata.patientinput.nursing_home[nHKey]" />

                  <component-icon-button
                    v-if="nHKey === 'name'"
                    class="p4umc-primary text-lg !p-0 font-normal"
                    icon="add"
                    :test-id="testId + '-patient-input-foldable-open-patient-contact-dialog'"
                    @click="openPatientContactDialog(record.metadata.patientinput.nursing_home[nHKey])"
                  />
                </p>
              </li>
            </ul>
          </li>

          <li v-else-if="key === 'compliance'">
            <h6 class="text-xs text-gray-600 uppercase">{{ title }}</h6>

            <div class="mt-0.5 text-sm flex flex-col gap-2">
              <div>
                <p class="font-semibold mb-1">Umgang mit Medikation</p>

                <component-patient-sidebar-patientinput-foldable-compliance-bar
                  :current="record.metadata.patientinput.compliance.confident - 1"
                >
                  <span>nicht sicher</span>
                  <span>sehr sicher</span>
                </component-patient-sidebar-patientinput-foldable-compliance-bar>
              </div>

              <div>
                <p class="font-semibold mb-1">Einnahme vergessen (Häufigkeit)</p>

                <component-patient-sidebar-patientinput-foldable-compliance-bar
                  :current="record.metadata.patientinput.compliance.forgotten - 1"
                >
                  <span>noch nie</span>
                  <span>sehr oft</span>
                </component-patient-sidebar-patientinput-foldable-compliance-bar>
              </div>

              <div>
                <p class="font-semibold mb-1">Alltags-Beeinträchtigung</p>

                <component-patient-sidebar-patientinput-foldable-compliance-bar
                  :current="record.metadata.patientinput.compliance.affect - 1"
                >
                  <span>überhaupt nicht</span>
                  <span>völlig</span>
                </component-patient-sidebar-patientinput-foldable-compliance-bar>
              </div>

              <div>
                <p class="font-semibold mb-1">Schmerzen</p>

                <component-patient-sidebar-patientinput-foldable-compliance-bar
                  :current="record.metadata.patientinput.compliance.pain - 1"
                  :max="9"
                >
                  <span>kein Schmerz</span>
                  <span>extremer Schmerz</span>
                </component-patient-sidebar-patientinput-foldable-compliance-bar>
              </div>
            </div>
          </li>

          <li v-else-if="key === 'doc_name' && record.metadata.patientinput[key]">
            <h6 class="text-xs text-gray-600 uppercase">{{ title }}</h6>
            <div class="mt-0.5 text-sm inline-flex gap-2">
              <p :data-encrypted="record.metadata.patientinput[key]" />

              <div>
                <component-icon-button
                  v-if="key === 'doc_name'"
                  class="p4umc-primary text-lg !p-0"
                  icon="add"
                  :test-id="testId + '-patient-input-foldable-open-patient-contact-dialog'"
                  @click="openPatientContactDialog(record.metadata.patientinput[key])"
                />
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </component-foldable>

  <component-patient-contact-dialog
    ref="refPatientContactDialog"
    :patient="patient"
    :test-id="testId + '-patient-input'"
  />
</template>

<script>
  import {computed, inject, ref} from "vue";

  import {recordOriginKeys} from "@pages/Records/Enums/Enums.js";

  import ComponentFoldable from "@components/Foldable/Foldable.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentPatientContactDialog from "@components/Dialogs/PatientContactDialog.vue";
  import ComponentPatientSidebarPatientinputFoldableComplianceBar from "@components/Sidebars/PatientSidebar/PatientinputFoldableComplianceBar.vue";

  export default {
    name: "ComponentPatientSidebarPatientinputFoldable",

    components: {
      ComponentFoldable,
      ComponentIconButton,
      ComponentPatientContactDialog,
      ComponentPatientSidebarPatientinputFoldableComplianceBar,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");

      const refPatientContactDialog = ref(null);

      const foldableTitle = computed(() => {
        return props.record.origin === recordOriginKeys.patientinput ? "Patientenfragebogen" : "Pflegecheckliste";
      });

      const nursingHomeAttributes = computed(() => {
        const attrs = {
          name: "Name",
          street: "Straße, Hausnr.",
          zip: "Postleitzahl",
          city: "Stadt",
        };

        return Object.keys(attrs)
          .filter((key) => props.record.metadata?.patientinput.nursing_home[key])
          .reduce((obj, key) => {
            obj[key] = attrs[key];
            return obj;
          }, {});
      });

      const attributes = computed(() => {
        const attrs = {
          doc_name: "Behandelnder Arzt / behandelnde Ärztin",
          nursing_home: "Einrichtung",
          compliance: "Compliance",
        };

        return Object.keys(attrs)
          .filter((key) => {
            if (key === "nursing_home") return Object.keys(nursingHomeAttributes.value).length > 0;
            else if (key === "compliance") return props.record.metadata.patientinput.is_patient;
            else return key !== "compliance" && props.record.metadata.patientinput[key];
          })
          .reduce((obj, key) => {
            obj[key] = attrs[key];
            return obj;
          }, {});
      });

      const badgeLabel = computed(() => Object.keys(attributes.value).length);

      const showFoldable = computed(() => {
        return (
          [recordOriginKeys.patientinput, recordOriginKeys.carerinput].includes(props.record.origin) &&
          Object.keys(attributes.value).length > 0
        );
      });

      const openPatientContactDialog = async (searchString = null) => {
        if (searchString) {
          searchString = await privacy.decryptValue(searchString);
        }

        refPatientContactDialog.value.open(searchString);
      };

      return {
        /** ref */
        refPatientContactDialog,

        /** computed */
        foldableTitle,
        nursingHomeAttributes,
        attributes,
        badgeLabel,
        showFoldable,

        /** function */
        openPatientContactDialog,
      };
    },
  };
</script>

<style scoped></style>
