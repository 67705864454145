<template>
  <component-card
    title="Patientenakte löschen"
    subtitle="Sie können die Patientenakte inkl. aller enthaltenen Daten löschen."
    class="rounded-lg border-2 border-gray-200"
    :test-id="testId + '-patient-delete'"
  >
    <template #actions>
      <component-button
        label="Löschen"
        :disabled="form.processing"
        :test-id="testId + '-patient-delete-card-delete'"
        @click="confirmRemove()"
      />
    </template>

    <component-confirmation-dialog
      ref="refConfirmationDialog"
      title="Patienten löschen?"
      content="Der Patient wird mit allen gespeicherten Daten (Medikationsanalysen etc.) gelöscht."
      :test-id="testId + '-patient-delete-card'"
      @confirmed="remove()"
    />
  </component-card>
</template>

<script>
  import {ref} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";

  export default {
    name: "PatientsPatientDelete",

    components: {
      ComponentConfirmationDialog,
      ComponentCard,
      ComponentButton,
    },

    props: {
      patient: {
        type: Object,
        default: () => ({}),
      },
    },

    setup(props) {
      const refConfirmationDialog = ref(null);

      const form = useForm({});

      function confirmRemove() {
        refConfirmationDialog.value.open();
      }

      function remove() {
        form.delete(route("patients.destroy", {patient: props.patient.id}));
      }

      return {
        /** ref */
        refConfirmationDialog,

        /** const */
        form,

        /** function */
        confirmRemove,
        remove,
      };
    },
  };
</script>
