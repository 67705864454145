<template>
  <component-dialog
    ref="refDialog"
    dialog-type="information"
    :dialog-title="confidential ? 'Rückmeldungen des Arztes' : 'Chat (Fallfreigabe)'"
    dialog-width="medium"
    primary-label="Schließen"
  >
    <template #content>
      <p v-if="confidential">
        Kommentare von externen Ärzten können nicht direkt von Ihnen beantwortet werden. Bei erneuten Rückfragen
        kommunizieren Sie bitte direkt mit dem jeweiligen Arzt.
      </p>
      <p v-else>
        Kommentare sind für diejenigen MediCheck-Nutzer sichtbar, die Zugriff auf diese Variante besitzen. Das können
        Kollegen sein, die mit Ihnen den MediCheck nutzen oder Personen, denen Sie diese Variante freigebeben haben.
        <br />
        Hinweis: Kommentare gehen nicht in die Arzt- oder Patientenkommunikation ein.
      </p>

      <component-comment-list
        class="my-8"
        :patient-id="patientId"
        :record-id="recordId"
        :comments="comments"
        :data-test="dataTest + '-commentList'"
      />
    </template>
  </component-dialog>
</template>
<script>
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentCommentList from "@components/Lists/CommentList.vue";
  import {ref} from "vue";

  export default {
    name: "ComponentCommentsDialog",
    components: {ComponentCommentList, ComponentDialog},
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      confidential: {
        type: Boolean,
        default: false,
      },
      comments: {
        type: Array,
        default: () => {
          return [];
        },
      },
      dataTest: {
        type: String,
        default: "comments-dialog",
      },
    },
    setup(props) {
      const refDialog = ref(null);

      function open() {
        refDialog.value.open();
      }

      return {refDialog, open};
    },
  };
</script>
