<template>
  <component-dialog ref="refEmailHasSentDialog" dialog-width="medium">
    <template #title>
      <div class="flex">
        <component-icon class="text-mcgreen text-2xl">check</component-icon>
        <span class="ml-3">E-Mail gesendet (verschlüsselt)</span>
      </div>
    </template>

    <template #content>
      Die E-Mail wurde erfolgreich an
      <strong>{{ sentEmailAddress }}</strong>
      versendet.
    </template>

    <template #actions>
      <component-button class="p4umc-primary" label="ok" @click="close" />
    </template>
  </component-dialog>
</template>

<script>
  import {ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "RecordComponentEmailHasSentDialog",

    components: {ComponentIcon, ComponentButton, ComponentDialog},

    setup() {
      const refEmailHasSentDialog = ref(null);

      const sentEmailAddress = ref(null);

      const open = (email) => {
        sentEmailAddress.value = email;
        refEmailHasSentDialog.value.open();
      };

      const close = () => {
        if (refEmailHasSentDialog.value) refEmailHasSentDialog.value.close();
      };

      return {refEmailHasSentDialog, sentEmailAddress, open, close};
    },
  };
</script>
