<template>
  <label
    v-if="hasOptionSlot"
    class="flex justify-between items-center space-x-2"
    :data-test="testId + '-checkbox-label'"
  >
    <div class="text-sm leading-tight" :class="{'cursor-pointer': !disabled}">
      <slot name="option" />
    </div>

    <input
      v-model="currentValue"
      type="checkbox"
      :disabled="disabled"
      class="!ring-offset-0 focus-visible:ring focus-visible:border-mcred !ring-red-500"
      :class="{
        'text-gray-800': color === 'default',
        'text-mcgreen': color === 'accent',
        'text-mcred': color === 'primary',
        'text-very-high-risk': color === 'veryhighrisk' || color === 'red',
        'text-high-risk': color === 'highrisk' || color === 'orange',
        'text-medium-risk': color === 'midrisk' || color === 'yellow',
        'text-low-risk': color === 'lowrisk' || color === 'gray',
        'opacity-60 bg-gray-300': disabled,
        'cursor-pointer': !disabled,
      }"
      :data-test="testId + '-checkbox'"
      @keydown.enter="toggleCurrentValue"
    />
  </label>

  <label v-else class="flex items-center space-x-2" :data-test="testId + '-checkbox-label'">
    <input
      v-model="currentValue"
      type="checkbox"
      :disabled="disabled"
      class="!ring-offset-0 focus-visible:ring focus-visible:border-mcred !ring-red-500"
      :class="{
        'text-gray-800': color === 'default',
        'text-mcgreen': color === 'accent',
        'text-mcred': color === 'primary',
        'text-very-high-risk': color === 'veryhighrisk' || color === 'red',
        'text-high-risk': color === 'highrisk' || color === 'orange',
        'text-medium-risk': color === 'midrisk' || color === 'yellow',
        'text-low-risk': color === 'lowrisk' || color === 'gray',
        'opacity-60 bg-gray-300': disabled,
        'cursor-pointer': !disabled,
        '!h-6 !w-6': large,
      }"
      :data-test="testId + '-checkbox'"
      @keydown.enter="toggleCurrentValue"
    />

    <div class="leading-tight" :class="{'cursor-pointer': !disabled, 'text-sm': !large, 'text-gray-500': disabled}">
      <slot />
      <span v-if="color === 'veryhighrisk'" class="mt-1 text-red-600">(rot)</span>
      <span v-if="color === 'highrisk'" class="mt-1 text-orange-500">(orange)</span>
      <span v-if="color === 'midrisk'" class="mt-1 text-yellow-500">(gelb)</span>
      <span v-if="color === 'lowrisk'" class="mt-1 text-gray-500">(grau)</span>
    </div>
  </label>
</template>

<script>
  import {computed, onBeforeMount, ref, useSlots, watch} from "vue";

  export default {
    name: "ComponentCheckbox",

    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: "default",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["input", "change", "update:modelValue"],

    setup(props, {emit}) {
      const slots = useSlots();
      const currentValue = ref(props.modelValue);

      const hasOptionSlot = computed(() => !!slots["option"]);

      watch(
        () => props.modelValue,
        (newValue) => {
          currentValue.value = newValue;
        },
      );

      watch(
        () => currentValue.value,
        (newValue) => {
          emit("update:modelValue", newValue);
          emit("input", newValue);
          emit("change", newValue);
        },
      );

      onBeforeMount(() => {
        currentValue.value = props.modelValue;
      });

      const toggleCurrentValue = () => {
        currentValue.value = !currentValue.value;
      };

      return {
        /** const */
        currentValue,

        /** computed */
        hasOptionSlot,

        /** function */
        toggleCurrentValue,
      };
    },
  };
</script>
