<template>
  <div>
    <component-breadcrumb class="mt-2" :links="[{label: 'Patientenfragebogen/Checkliste'}]" />

    <template v-if="keysGenerated">
      <patientinput-client-component-info-section :test-id="$page.props.testId" />
      <patientinput-client-component-data-section :test-id="$page.props.testId" />
    </template>

    <template v-else>
      <section class="mt-6">
        <div class="p-4 bg-white shadow-sm text-center">
          <component-spinner class="h-8 w-8" :test-id="$page.props.testId" />
        </div>
      </section>
    </template>
  </div>
</template>

<script>
  import {computed, inject, onMounted} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {exportPKCS8, exportSPKI, generateKeyPair} from "jose";

  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import PatientinputClientComponentInfoSection from "@pages/PatientinputClient/Components/InfoSection.vue";
  import PatientinputClientComponentDataSection from "@pages/PatientinputClient/Components/DataSection.vue";

  export default {
    name: "PagesPatientinputclientIndex",

    components: {
      ComponentBreadcrumb,
      ComponentSpinner,
      PatientinputClientComponentInfoSection,
      PatientinputClientComponentDataSection,
    },

    setup() {
      const page = usePage();
      const privacy = inject("$privacy");

      const keysGenerated = computed(() => {
        return page.props.publicKey && page.props.privateKeyEncrypted;
      });

      onMounted(async () => {
        if (!keysGenerated.value) {
          const {publicKey, privateKey} = await generateKeyPair("RSA-OAEP", {
            extractable: true,
          });

          const form = useForm({
            action: "storeKeys",
            public_key: await exportSPKI(publicKey),
            private_key: await privacy.encryptValue(await exportPKCS8(privateKey)),
          });

          form.post(route("patientinput-client.store"));
        }
      });

      return {
        /** computed */
        keysGenerated,
      };
    },
  };
</script>
