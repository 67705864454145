<template>
  <details class="group" :open="open">
    <summary
      class="flex items-center select-none bg-gradient-to-b from-gray-100 to-white -mx-4 px-4 py-2 border-t-1 border-gray-200 cursor-pointer hover:to-gray-100"
      :data-test="testId + '-foldable-trigger'"
    >
      <component-icon class="group-open:rotate-180 text-xl mr-1">arrow_drop_down</component-icon>

      <div class="w-full flex justify-between space-x-1">
        <span v-text="title" />

        <span v-if="badgeLabel !== null && badgeLabel > 0">
          <component-category-badge :label="badgeLabel" :color="badgeColor" />
        </span>
      </div>
    </summary>

    <div class="bg-white pb-2">
      <slot />
    </div>
  </details>
</template>
<script>
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";

  export default {
    name: "ComponentFoldable",

    components: {ComponentCategoryBadge, ComponentIcon},

    props: {
      title: {
        type: String,
        default: "DefaultTitle",
      },
      open: {
        type: [Boolean, String],
        default: false,
      },
      badgeLabel: {
        type: [String, Number],
        default: null,
      },
      badgeColor: {
        type: String,
        default: null,
      },
    },
  };
</script>
