export const analysisTypes = {
  1: "Typ 1",
  "2a": "Typ 2a",
  "2b": "Typ 2b",
  3: "Typ 3",
};

export const additionalinformationDecreed = {
  patient: "Patient",
  pharmacist: "Apotheker",
  retail: "Einzelhandel",
  otc: "OTC",
  doctor: "Allgemeinarzt",
  medicalcenter: "Aus Klinik",
  surgeon: "Chirurg",
  dermatologist: "Dermatologe",
  diabetologist: "Diabetologe",
  internist: "Internist",
  pediatrician: "Kinderarzt",
  cardiologist: "Kardiologe",
  neurologist: "Neurologe",
  orthopedist: "Orthopäde",
  psychiatrist: "Psychiater",
  pulmonologist: "Pulmologe",
  radiologist: "Radiologe",
  rheumatologist: "Rheumatologe",
  urologist: "Urologe",
  dentist: "Zahnarzt",
  other: "Anderer Facharzt",
  unknowndoctor: "Unbekannter Arzt",
  naturopaths: "Heilpraktiker",
  unknown: "Unbekannte Quelle",
};

export const currentSource = {
  bmp: "Medikationsplan / -datei",
  file: "Medikationsdatei",
  brownbag: "Brown Bag",
  other: "Andere Datenquelle",
};

/**
 * PCNE (version 9.0)
 */
export const pcneStructure = {
  "9.0": {
    problems: [
      {
        code: "P1",
        text: "mit Wirksamkeit der AM",
      },
      {
        code: "P1.1",
        text: "AM unwirksam",
      },
      {
        code: "P1.2",
        text: "AM nicht optimal (Wirkung)",
      },
      {
        code: "P1.3",
        text: "Unbehandelte Ind. (under-use)",
      },
      {
        code: "P2",
        text: "AMTS",
      },
      {
        code: "P2.1",
        text: "mit möglichem UAE",
      },
      {
        code: "P3",
        text: "Sonstiges",
      },
      {
        code: "P3.1",
        text: "Neg. Kosten-Nutzen-Verhältnis",
      },
      {
        code: "P3.2",
        text: "Unnötige AM (over-use)",
      },
      {
        code: "P3.3",
        text: "(Er-)Klärungsbedarf / Prüfen",
      },
      {
        code: "P3.4",
        text: "Andere",
      },
    ],
    causes: [
      {
        code: "C1",
        text: "Problem bei Wahl des AM",
      },
      {
        code: "C1.1",
        text: "AM nicht Leitlinien(LL)-konform",
      },
      {
        code: "C1.1a",
        text: "Kein Mittel der Wahl gemäß Leitlinien",
      },
      {
        code: "C1.1b",
        text: "AM wird in Leitlinie nicht empfohlen",
      },
      {
        code: "C1.1c",
        text: "ungeeignet PRISCUS-Liste",
      },
      {
        code: "C1.1d",
        text: "ungeeignet FORTA-Liste",
      },
      {
        code: "C1.1e",
        text: "Kontraindikation (KI) Erkankungen",
      },
      {
        code: "C1.1f",
        text: "relative KI Erkrankungen",
      },
      {
        code: "C1.1g",
        text: "fragliche KI Erkrankungen",
      },
      {
        code: "C1.1h",
        text: "Kontraindikation (KI) Alter",
      },
      {
        code: "C1.1i",
        text: "relative KI  Alter",
      },
      {
        code: "C1.1j",
        text: "Kontraindikation (KI) Allergie",
      },
      {
        code: "C1.1k",
        text: "Kontraindikation (KI) Kreuzallergie",
      },
      {
        code: "C1.1l",
        text: "TdP-Risiko durch QTc-Verlängerung",
      },
      {
        code: "C1.2",
        text: "AM Leitlinien (LL)-konform, aber KI",
      },
      {
        code: "C1.2a",
        text: "LL-konform, aber abs.KI Erkrankung",
      },
      {
        code: "C1.2b",
        text: "LL-konform, aber rel.KI Erkrankung",
      },
      {
        code: "C1.2c",
        text: "LL-konform, aber fragl.KI Erkrankg.",
      },
      {
        code: "C1.2d",
        text: "LL-konform, aber abs.KI Alter",
      },
      {
        code: "C1.2e",
        text: "LL-konform, aber rel.KI Alter",
      },
      {
        code: "C1.2f",
        text: "LL-konform, aber KI Allergie",
      },
      {
        code: "C1.2g",
        text: "LL-konform, aber KI Kreuzallergie",
      },
      {
        code: "C1.2h",
        text: "LL-konform, aber PRISCUS",
      },
      {
        code: "C1.2i",
        text: "LL-konform, aber FORTA",
      },
      {
        code: "C1.3",
        text: "keine Indikation (over-use)",
      },
      {
        code: "C1.4",
        text: "ungeeignete AM-Kombination / WW",
      },
      {
        code: "C1.4a",
        text: "AM-Interaktion (relevant)",
      },
      {
        code: "C1.4b",
        text: "AM-Interaktion (irrelevant)",
      },
      {
        code: "C1.4c",
        text: "Medikationskaskade",
      },
      {
        code: "C1.5",
        text: "Doppelmedikation",
      },
      {
        code: "C1.5a",
        text: "Doppelmedikation (Wirkstoff)",
      },
      {
        code: "C1.5b",
        text: "Pseudo-Doppelmedik. (Gruppe)",
      },
      {
        code: "C1.6",
        text: "Indikation ohne AM (under-use)",
      },
      {
        code: "C1.7",
        text: "zu viele AM für gleiche Indikation",
      },
      {
        code: "C2",
        text: "Problem bei Darreichungsform",
      },
      {
        code: "C2.1",
        text: "Ungeeignete Darreichungsform",
      },
      {
        code: "C2.1a",
        text: "Arzneiform nicht passend teilbar",
      },
      {
        code: "C2.1b",
        text: "Arzneiform nicht mörserbar",
      },
      {
        code: "C2.1c",
        text: "Arzneiform nicht suspendierbar",
      },
      {
        code: "C3",
        text: "Problem bei Dosierung",
      },
      {
        code: "C3.1",
        text: "Unterdosiert",
      },
      {
        code: "C3.2",
        text: "Überdosiert",
      },
      {
        code: "C3.2a",
        text: "Überdosiert Niereninsuffizienz",
      },
      {
        code: "C3.3",
        text: "Dosisgabe nicht häufig genug",
      },
      {
        code: "C3.4",
        text: "Dosisgabe zu häufig",
      },
      {
        code: "C3.5",
        text: "Einnahmezeit Fehler",
      },
      {
        code: "C3.5a",
        text: "Einnahmezeit nicht FI-konform",
      },
      {
        code: "C3.5b",
        text: "Einnahmezeit überflüssig",
      },
      {
        code: "C3.5c",
        text: "Einnahme Mahlzeit falsch",
      },
      {
        code: "C4",
        text: "Problem mit Dauer der Anwendung",
      },
      {
        code: "C4.1",
        text: "Behandlungsdauer zu kurz",
      },
      {
        code: "C4.1a",
        text: "Bedarfs- statt Dauermedikation",
      },
      {
        code: "C4.1b",
        text: "Kurzzeit- statt Dauermedikation",
      },
      {
        code: "C4.2",
        text: "Behandlungsdauer zu lang",
      },
      {
        code: "C4.2a",
        text: "Dauer- statt Bedarfsmedikation",
      },
      {
        code: "C4.2b",
        text: "Dauer- statt Kurzzeitmedikation",
      },
      {
        code: "C5",
        text: "Problem bei Abgabe / Logistik",
      },
      {
        code: "C5.1",
        text: "Arzneimittel nicht verfügbar",
      },
      {
        code: "C5.2",
        text: "Informationen nicht angeboten",
      },
      {
        code: "C5.3",
        text: "Falsches AM / Dosis OTC",
      },
      {
        code: "C5.4",
        text: "Abgabe-Fehler (Apotheke)",
      },
      {
        code: "C6",
        text: "Problem mit Anw. durch andere",
      },
      {
        code: "C6.1",
        text: "AM falsch verabreicht - Zeitpunkte",
      },
      {
        code: "C6.1a",
        text: "AM falsch verabreicht - Mahlzeit",
      },
      {
        code: "C6.2",
        text: "AM wird zu wenig verabreicht",
      },
      {
        code: "C6.3",
        text: "AM wird zu viel verabreicht",
      },
      {
        code: "C6.4",
        text: "AM wird gar nicht verabreicht",
      },
      {
        code: "C6.5",
        text: "Falsches AM wird verabreicht",
      },
      {
        code: "C6.6",
        text: "AM falsch verabreicht - Appl.-Weg",
      },
      {
        code: "C7",
        text: "Problem mit Adhärenz/Anw. -Patient",
      },
      {
        code: "C7.1",
        text: "Weniger AM als verordnet -Patient",
      },
      {
        code: "C7.2",
        text: "Mehr AM als verordnet -Patient",
      },
      {
        code: "C7.3",
        text: "AM-Missbrauch -Patient",
      },
      {
        code: "C7.4",
        text: "Unnötige AM  -Patient",
      },
      {
        code: "C7.5",
        text: "Nahrungsmittel-WW -Patient",
      },
      {
        code: "C7.6",
        text: "Falsche Lagerung -Patient",
      },
      {
        code: "C7.7",
        text: "Einnahmezeit Fehler -Patient",
      },
      {
        code: "C7.7a",
        text: "Falsche Einnahme Mahlzeit -Patient",
      },
      {
        code: "C7.8",
        text: "Applikation falsch -Patient",
      },
      {
        code: "C7.9",
        text: "Unfähig zur AM-Anw. -Patient",
      },
      {
        code: "C8",
        text: "Sonstige Probleme",
      },
      {
        code: "C8.1",
        text: "Fehlende Überwachung",
      },
      {
        code: "C8.1a",
        text: "Fehlendes Routine-Monitoring",
      },
      {
        code: "C8.1b",
        text: "Fehlende Überwachung TDM",
      },
      {
        code: "C8.1c",
        text: "Fehlende Überwachung Genotyp.",
      },
      {
        code: "C8.2",
        text: "anderer Grund; bitte spezifizieren",
      },
      {
        code: "C8.2a",
        text: "Probleme wegen Erkrankung",
      },
      {
        code: "C8.2b",
        text: "Probleme wegen Laborwertabw.",
      },
      {
        code: "C8.2c",
        text: "Laborwertabw. AM-bedingt",
      },
      {
        code: "C8.2d",
        text: "Zusatzinfos (Prävention) fehlen",
      },
      {
        code: "C8.2e",
        text: "Verdacht: AM-bedingte NW / UAW",
      },
      {
        code: "C8.3",
        text: "kein Grund für Problem erkennbar",
      },
    ],
    complianceCauses: [
      {
        code: "C7.1",
        text: "Weniger AM als verordnet -Patient",
      },
      {
        code: "C7.2",
        text: "Mehr AM als verordnet -Patient",
      },
      {
        code: "C7.3",
        text: "AM-Missbrauch -Patient",
      },
      {
        code: "C7.4",
        text: "Unnötige AM  -Patient",
      },
      {
        code: "C7.5",
        text: "Nahrungsmittel-WW -Patient",
      },
      {
        code: "C7.6",
        text: "Falsche Lagerung -Patient",
      },
      {
        code: "C7.7",
        text: "Einnahmezeit Fehler -Patient",
      },
      {
        code: "C7.7a",
        text: "Falsche Einnahme Mahlzeit -Patient",
      },
      {
        code: "C7.8",
        text: "Applikation falsch -Patient",
      },
      {
        code: "C7.9",
        text: "Unfähig zur AM-Anw. -Patient",
      },
    ],
    mealcontextCauses: [
      {
        code: "C3.5c",
        text: "Einnahme Mahlzeit falsch",
      },
      {
        code: "C7.7a",
        text: "Falsche Einnahme Mahlzeit -Patient",
      },
    ],
    communications: [
      {
        code: "I1.1",
        text: "Arzt wird nur informiert",
      },
      {
        code: "I1.2",
        text: "Arzt fragt nach Informationen",
      },
      {
        code: "I1.3",
        text: "Vorschläge an Arzt schicken",
      },
      {
        code: "I1.4",
        text: "Vorschläge mit Arzt besprechen",
      },
      {
        code: "I2.1",
        text: "Patientenberatung",
      },
      {
        code: "I2.2",
        text: "Patient wird schriftlich beraten",
      },
      {
        code: "I2.3",
        text: "Patient wird an Arzt verwiesen",
      },
      {
        code: "I2.4",
        text: "Infos an Familienmitglied / Pfleger",
      },
      {
        code: "I0.1",
        text: "Keine Kommunik. / Intervention",
      },
    ],
    docCommunications: [
      {
        code: "I1.1",
        text: "Arzt wird nur informiert",
      },
      {
        code: "I1.2",
        text: "Arzt hat Information erbeten",
      },
      {
        code: "I1.3",
        text: "Vorschläge an Arzt schicken",
      },
      {
        code: "I1.4",
        text: "Vorschläge mit Arzt besprechen",
      },
      {
        code: "I0.1",
        text: "Keine Kommunik. / Intervention",
      },
    ],
    patientCommunications: [
      {
        code: "I2.1",
        text: "Patientenberatung",
      },
      {
        code: "I2.2",
        text: "Patient wird schriftlich beraten",
      },
      {
        code: "I2.3",
        text: "Patient wird an Arzt verwiesen",
      },
      {
        code: "I2.4",
        text: "Infos an Familienmitglied / Pfleger",
      },
      {
        code: "I0.1",
        text: "Keine Kommunik. / Intervention",
      },
    ],
    interventions: [
      {
        code: "I3.1",
        text: "AM tauschen (zu...)",
      },
      {
        code: "I3.2",
        text: "Dosierung ändern (zu...)",
      },
      {
        code: "I3.3",
        text: "Darreichungsform ändern (zu...)",
      },
      {
        code: "I3.4",
        text: "Anweisung verändern (zu...)",
      },
      {
        code: "I3.5",
        text: "AM absetzen:",
      },
      {
        code: "I3.6",
        text: "neue AM ansetzen:",
      },
      {
        code: "I4.1",
        text: "andere Intervention:",
      },
      {
        code: "I4.2",
        text: "UAW wird an AMK gemeldet",
      },
    ],
    acceptances: [
      {
        code: "A1.1",
        text: "Akzeptiert: voll umgesetzt",
      },
      {
        code: "A1.2",
        text: "Akzeptiert: tw. umgesetzt",
      },
      {
        code: "A1.3",
        text: "Akzeptiert: nicht umgesetzt",
      },
      {
        code: "A1.4",
        text: "Akzeptiert: Umsetzung fraglich",
      },
      {
        code: "A2.1",
        text: "Nicht akzeptiert: nicht möglich",
      },
      {
        code: "A2.2",
        text: "Nicht akzeptiert: keine Zustimmung",
      },
      {
        code: "A2.3",
        text: "Nicht akzeptiert: (anderer Grund...)",
      },
      {
        code: "A2.4",
        text: "Nicht akzeptiert: unbekannt",
      },
      {
        code: "A3.1",
        text: "Mitgeteilt: Akzeptanz fraglich",
      },
      {
        code: "A3.2",
        text: "Vorschlag nicht mitgeteilt",
      },
    ],
    statuses: [
      {
        code: "O0.1",
        text: "Status Problem (ABP) unbekannt",
      },
      {
        code: "O1.1",
        text: "Problem (ABP) komplett gelöst",
      },
      {
        code: "O2.1",
        text: "Problem (ABP) teilweise gelöst",
      },
      {
        code: "O3.1",
        text: "Nicht gelöst, Patient unkooperativ",
      },
      {
        code: "O3.2",
        text: "Nicht gelöst, Arzt unkooperativ",
      },
      {
        code: "O3.3",
        text: "Nicht gelöst, Intervention ineffektiv",
      },
      {
        code: "O3.4",
        text: "Problemlösung nicht nötig / möglich",
      },
    ],
    defaultValues: [
      {
        mcCheckId: 13,
        pCode: "P2.1",
        cCode: "C1.5a",
      },
      {
        mcCheckId: 11,
        pCode: "P2.1",
        cCode: "C3.2",
      },
      {
        mcCheckId: 10,
        pCode: "P1.2",
        cCode: "C3.1",
      },
      {
        mcCheckId: 16,
        pCode: "P3.4",
        cCode: "C8.2a",
      },
      {
        mcCheckId: 9,
        pCode: "P2.1",
        cCode: "C8.1c",
      },
      {
        mcCheckId: 6,
        pCode: "P2.1",
        cCode: "C1.1d",
      },
      {
        mcCheckId: 5,
        pCode: "P2.1",
        cCode: "C1.1c",
      },
      {
        mcCheckId: 1,
        pCode: "P2.1",
        cCode: "C1.4",
      },
      {
        mcCheckId: 31,
        pCode: "P2.1",
        cCode: "C1.4",
      },
      {
        mcCheckId: 17,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
      // Legacy checks: Needed for old saved data
      {
        mcCheckId: 2,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
      {
        mcCheckId: 4,
        pCode: "P2.1",
        cCode: "C1.1j",
      },
      {
        mcCheckId: 3,
        pCode: "P2.1",
        cCode: "C1.1h",
      },
      {
        mcCheckId: 24,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
      {
        mcCheckId: 30,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
      // Legacy checks end
      {
        mcCheckId: 15,
        pCode: "P3.4",
        cCode: "C8.2b",
      },
      {
        mcCheckId: 12,
        pCode: "P2.1",
        cCode: "C8.2e",
      },
      {
        mcCheckId: 14,
        pCode: "P2.1",
        cCode: "C1.5b",
      },
      {
        mcCheckId: 8,
        pCode: "P3.3",
        cCode: "C8.1a",
      },
      {
        mcCheckId: 7,
        pCode: "P2.1",
        cCode: "C1.4c",
      },
      {
        mcCheckId: 18,
        pCode: "P1.2",
        cCode: "C7.1",
      },
      {
        mcCheckId: 19,
        pCode: "P3.4",
        cCode: "C2.1a",
      },
      {
        mcCheckId: 20,
        pCode: "P3.3",
        cCode: "C8.2d",
      },
      {
        mcCheckId: 21,
        pCode: "P1.2",
        cCode: "C1.1",
      },
      {
        mcCheckId: 22,
        pCode: "P1.2",
        cCode: "C3.5c",
      },
      {
        mcCheckId: 23,
        pCode: "P1.2",
        cCode: "C3.5a",
      },
      {
        mcCheckId: 25,
        pCode: "P2.1",
        cCode: "C1.1l",
      },
      {
        mcCheckId: 26,
        pCode: "P1.2",
        cCode: "C4.2a",
      },
      {
        mcCheckId: 27,
        pCode: "P1.1",
        cCode: "C5.2",
      },
      {
        mcCheckId: 28,
        pCode: "P2.1",
        cCode: "C1.4c",
      },
      {
        mcCheckId: 29,
        pCode: "P1.2",
        cCode: "C3.5b",
      },
      {
        mcCheckId: 32,
        pCode: "P2.1",
        cCode: "C1.1",
      },

      {
        mcCheckId: 33,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
      {
        mcCheckId: 34,
        pCode: "P2.1",
        cCode: "C1.1j",
      },
      {
        mcCheckId: 35,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
      {
        mcCheckId: 36,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
      {
        mcCheckId: 37,
        pCode: "P2.1",
        cCode: "C1.1e",
      },
    ],
  },
};

export const mcCheckIdToAnalysisType = [
  {
    mcCheckId: 13,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 11,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 10,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 16,
    analysisType: ["3"],
  },
  {
    mcCheckId: 9,
    analysisType: ["2b", "3"],
  },
  {
    mcCheckId: 6,
    analysisType: ["2b", "3"],
  },
  {
    mcCheckId: 5,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 1,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 31,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 17,
    analysisType: ["2a", "2b", "3"],
  },
  // Legacy checks: Needed for old saved data
  {
    mcCheckId: 2,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 4,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 3,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 24,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 30,
    analysisType: ["2a", "2b", "3"],
  },
  // Legacy checks end
  {
    mcCheckId: 15,
    analysisType: ["3"],
  },
  {
    mcCheckId: 12,
    analysisType: ["2a", "3"],
  },
  {
    mcCheckId: 14,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 8,
    analysisType: ["2b", "3"],
  },
  {
    mcCheckId: 7,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 18,
    analysisType: ["2a", "3"],
  },
  {
    mcCheckId: 19,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 20,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 21,
    analysisType: ["2b", "3"],
  },
  {
    mcCheckId: 22,
    analysisType: ["2a", "3"],
  },
  {
    mcCheckId: 23,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 25,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 26,
    analysisType: ["2b", "3"],
  },
  {
    mcCheckId: 27,
    analysisType: ["2a", "3"],
  },
  {
    mcCheckId: 28,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 29,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 32,
    analysisType: ["1", "2a", "2b", "3"],
  },
  {
    mcCheckId: 33,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 34,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 35,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 36,
    analysisType: ["2a", "2b", "3"],
  },
  {
    mcCheckId: 37,
    analysisType: ["2a", "2b", "3"],
  },
];

/**
 * Just a helper method to group mcCheckIdToAnalysisType by mcCheckId
 * @param data
 * @returns {*}
 */
function groupByMcCheckId(data) {
  return data.reduce((acc, item) => {
    item.analysisType.forEach((type) => {
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(item.mcCheckId);
    });
    return acc;
  }, {});
}

/**
 * Restructured variable from above.
 * 1: [13, 11, 10, 5, 1, ...],
 * 2a: [...],
 * 2b: [...],
 * 3: [...]
 */
export const analysisTypeGroupedByCheckId = groupByMcCheckId(mcCheckIdToAnalysisType);
