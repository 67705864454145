<template>
  <component-checkbox
    :model-value="checkboxValue"
    :color="color"
    :disabled="disabled"
    :large="large"
    :test-id="testId + '-non-unselectable'"
    @change="handleChange"
  >
    <slot />
  </component-checkbox>
</template>

<script>
  import {nextTick, ref} from "vue";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "PatientinputNonUnselectableCheckbox",

    components: {ComponentCheckbox},

    props: {
      color: {
        type: String,
        default: "default",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["click"],

    setup(props, {emit}) {
      const checkboxValue = ref(true);

      const handleChange = (isChecked) => {
        if (!isChecked) {
          emit("click");
          checkboxValue.value = false;
          nextTick(() => {
            checkboxValue.value = true;
          });
        }
      };

      return {
        /** props */
        checkboxValue,

        /** function */
        handleChange,
      };
    },
  };
</script>
