export const degreeOfCareLevel = {
  1: "Geringe Beeinträchtigung der Selbständigkeit",
  2: "Erhebliche Beeinträchtigung der Selbständigkeit",
  3: "Schwere Beeinträchtigung der Selbständigkeit",
  4: "Schwerste Beeinträchtigung der Selbständigkeit",
  5: "Schwerste Beeinträchtigung der Selbständigkeit mit besonderen Anforderungen an die pflegerische Versorgung",
};

export const mobilityRestriction = {
  bedridden: "Der Patient ist bettlägrig.",
  rollator: "Der Patient nutzt einen Rollator.",
  stick: "Der Patient nutzt einen Stock / eine Gehilfe.",
  wheelchair: "Der Patient sitzt im Rollstuhl.",
};

export const tubePatientTubeModel = {
  nasal: "Nasensonde",
  peg: "PEG-Sonde",
  button: "Buttonsonde",
  gastrostomy: "Gastrotube",
  jet_peg: "Jet-PEG-Sonde",
  pej: "PEJ-Sonde",
  fnkj: "FNKJ-Sonde",
};

export const tubePatientTubePosition = {
  stomach: "Magen",
  intestine: "Dünndarm",
};

// @deprecated
export const transplantPatientTransplantedOrgan = {
  kidney: "Niere",
  liver: "Leber",
  heart: "Herz",
  heartValve: "Herzklappe",
  lung: "Lunge",
  pancreas: "Bauchspeicheldrüse",
  intestine: "Darm",
  fabric: "Gewebe",
  skin: "Haut",
  eyes: "Horn- und Lederhaut oder Augen",
  bone: "Knochen/Knorpel",
  boneMarrow: "Knochenmark",
  stemCells: "Stammzellen",
};

export const simpleAttributeTitle = {
  insulinPump: "Insulinpumpe",
  palliativePatient: "Palliativpatient",
  port: "Port",
  stoma: "Stoma",
};

export const simpleAttributeText = {
  insulinPump: "Der Patient hat eine Insulinpumpe.",
  palliativePatient: "Der Patient ist ein Palliativpatient.",
  port: "Der Patient hat einen Port.",
  stoma: "Der Patient hat ein Stoma.",
};
