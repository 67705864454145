<template>
  <div class="inline-block relative">
    <slot />

    <div
      class="absolute -top-1.5 -right-2 inline-flex justify-center items-center rounded-full font-medium"
      :class="{
        'p-0 w-4 h-4 text-current text-lg': iconContainsBackground,
        'p-1 w-4 h-4 bg-current text-sm': !iconContainsBackground,
      }"
    >
      <span :class="{'text-white': !iconContainsBackground}">
        <slot name="icon">
          <component-icon class="fill-current">{{ icon }}</component-icon>
        </slot>
      </span>
    </div>
  </div>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";
  import {computed} from "vue";

  export default {
    name: "ComponentBadge",

    components: {
      ComponentIcon,
    },

    props: {
      icon: {
        type: String,
        default: null,
      },
    },

    setup(props) {
      const iconContainsBackground = computed(() => {
        if (!props.icon) {
          return false;
        }

        return (
          [
            "info",
            "help",
            "stars",
            "circle_notifications",
            "outbound",
            "lightbulb_circle",
            "add_circle",
            "remove_circle",
            "change_circle",
          ].indexOf(props.icon) >= 0
        );
      });

      return {iconContainsBackground};
    },
  };
</script>
