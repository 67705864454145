<template>
  <div>
    <div class="flex items-baseline space-x-2">
      <div class="w-40">
        <component-select
          id="alternativeDosageType"
          name="alternativeDosageType"
          :model-value="type"
          placeholder="Bitte auswählen"
          key-name="name"
          @change="handleTypeChange"
        >
          <template #default>
            <option value="special">Hinweise</option>
            <option value="multipleDay">[...] mal / Tag</option>
            <option value="specialDays">Jeden [...]. Tag</option>
            <option value="multipleWeek">[...] mal / Woche</option>
            <option value="specialWeeks">Jede [...]. Woche</option>
            <option value="onceAtMonth">1 mal / Monat</option>
            <option value="specialMonth">[...] mal / Jahr</option>
            <option value="multipleHours">Alle [...] Stunden</option>
          </template>
        </component-select>
      </div>

      <div v-if="showIntervalValue && typeWording" class="text-sm">
        {{ typeWording }}
      </div>

      <div v-if="showIntervalValue" class="w-8">
        <component-input
          v-model="intervalValue"
          hide-clear
          :dosage-field="true"
          maxlength="2"
          :show-maxlength-count="false"
          input-alignment="text-center"
        />
      </div>

      <div v-if="showIntervalValue" class="text-sm" :class="{pl: intervalWording && intervalWording.charAt(0) !== '.'}">
        {{ intervalWording }}
      </div>
    </div>

    <div class="mt-3 max-w-xl">
      <medication-component-detail-dialog-alternative-dosage-textarea
        v-if="show === 'special'"
        type="special"
        data-hash-id="special"
        :dosage-form="dosage"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <medication-component-detail-dialog-multiple-dosage-input
        v-if="show === 'multipleDay'"
        data-hash-id="multipleDay"
        :dosage-form="dosage"
        type="multipleDay"
        :interval-value="intervalValue"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <medication-component-detail-dialog-single-dosage-input
        v-if="show === 'specialDays'"
        data-hash-id="specialDays"
        :dosage-form="dosage"
        type="specialDays"
        :interval-value="intervalValue"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <medication-component-detail-dialog-multiple-dosage-input
        v-if="show === 'multipleWeek'"
        data-hash-id="multipleWeek"
        :dosage-form="dosage"
        type="multipleWeek"
        :interval-value="intervalValue"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <medication-component-detail-dialog-single-dosage-input
        v-if="show === 'specialWeeks'"
        data-hash-id="specialWeeks"
        :dosage-form="dosage"
        type="specialWeeks"
        :interval-value="intervalValue"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <medication-component-detail-dialog-single-dosage-input
        v-if="show === 'onceAtMonth'"
        data-hash-id="onceAtMonth"
        :dosage-form="dosage"
        type="onceAtMonth"
        :interval-value="intervalValue"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <medication-component-detail-dialog-multiple-dosage-input
        v-if="show === 'specialMonth'"
        data-hash-id="specialMonth"
        :dosage-form="dosage"
        type="specialMonth"
        :interval-value="intervalValue"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <medication-component-detail-dialog-single-dosage-input
        v-if="show === 'multipleHours'"
        data-hash-id="multipleHours"
        :dosage-form="dosage"
        type="multipleHours"
        :interval-value="intervalValue"
        @is-invalid="$emit('isInvalid', $event)"
        @is-valid="$emit('isValid', $event)"
        @change="handleChange"
      />

      <div v-if="errorMessage" class="text-xs w-full text-mcred mt-4">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
  import {computed, onBeforeMount, ref, watch} from "vue";
  import {usePage} from "@inertiajs/vue3";
  import {cloneDeep} from "lodash";

  import dosageModel from "@models/dosageModel.js";

  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentInput from "@components/Inputs/Input.vue";

  import {getAlternativeDosageMessage} from "@pages/Records/Helper/alternativeDosageMessage.js";

  import MedicationComponentDetailDialogAlternativeDosageTextarea from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/Components/AlternativeDosageTextarea.vue";
  import MedicationComponentDetailDialogSingleDosageInput from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/Components/SingleDosageInput.vue";
  import MedicationComponentDetailDialogMultipleDosageInput from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/Components/MultipleDosageInput.vue";

  export default {
    name: "MedicationComponentDetailDialogAlternativeDosageData",

    components: {
      MedicationComponentDetailDialogMultipleDosageInput,
      MedicationComponentDetailDialogSingleDosageInput,
      MedicationComponentDetailDialogAlternativeDosageTextarea,
      ComponentInput,
      ComponentSelect,
    },

    props: {
      medicationForm: {
        type: Object,
        default: () => {},
      },
    },

    emits: ["isInvalid", "isValid", "alternativeDosageIdsChanged", "initType", "change", "dosageChange"],

    setup(props, {emit}) {
      const page = usePage();

      const dosage = ref(null);
      const errorMessage = ref("");
      const intervalValue = ref("");
      const intervalWording = ref(null);
      const type = ref(null);
      const typeWording = ref(null);

      const show = computed(() => {
        if (type.value === "onceAtMonth" || type.value === "special") {
          return type.value;
        }

        if (intervalValue.value > 0 && type.value.length > 0) {
          return type.value;
        }

        return "";
      });

      const showIntervalValue = computed(() => type.value && type.value !== "onceAtMonth" && type.value !== "special");

      watch(
        () => props.medicationForm.dosage,
        () => {
          Object.assign(dosage.value, props.medicationForm.dosage);
        },
        {deep: true},
      );

      watch(
        () => intervalValue.value || type.value,
        () => {
          checkIntervalValue();
        },
      );

      onBeforeMount(() => {
        dosage.value = cloneDeep(dosageModel);
        Object.assign(dosage.value, props.medicationForm.dosage);

        if (!dosage.value.dosagealternative) {
          dosage.value.dosagealternative = {};
        }

        if (dosage.value.dosagealternative.type) {
          type.value = dosage.value.dosagealternative.type;
        } else {
          type.value = null;
        }

        intervalValue.value = dosage.value.dosagealternative.interval;
      });

      const handleTypeChange = (newValue) => {
        if (type.value !== newValue) {
          emit("change", newValue);

          intervalValue.value = 0;

          if (type.value === null || type.value === 0) {
            clearDefaultDosage();
          } else if (type.value !== null && !newValue) {
            restoreDefaultDosage();
          }
        }

        dosage.value.dosagealternative = {};
        emit("dosageChange", dosage.value);

        type.value = newValue;
        typeWording.value = getTypeWording();
        intervalWording.value = getIntervalWording();

        emit("alternativeDosageIdsChanged");
      };

      const clearDefaultDosage = () => {
        dosage.value.morning = 0;
        dosage.value.morning_as_fraction = false;

        dosage.value.noon = 0;
        dosage.value.noon_as_fraction = false;

        dosage.value.evening = 0;
        dosage.value.evening_as_fraction = false;

        dosage.value.night = 0;
        dosage.value.night_as_fraction = false;

        emit("dosageChange", dosage.value);
      };

      const restoreDefaultDosage = () => {
        const oldData = page.props.record.medications.find((medication) => medication.id === props.medicationForm.id);

        dosage.value.dosagealternative = {};

        dosage.value.morning = oldData.dosage.morning;
        dosage.value.morning_as_fraction = oldData.dosage.morning_as_fraction;

        dosage.value.noon = oldData.dosage.noon;
        dosage.value.noon_as_fraction = oldData.dosage.noon_as_fraction;

        dosage.value.evening = oldData.dosage.evening;
        dosage.value.evening_as_fraction = oldData.dosage.evening_as_fraction;

        dosage.value.night = oldData.dosage.night;
        dosage.value.night_as_fraction = oldData.dosage.night_as_fraction;

        emit("dosageChange", dosage.value);
      };

      const getTypeWording = () => {
        switch (type.value) {
          case "specialDays":
            return "jeden";

          case "specialWeeks":
            return "jede";

          case "multipleHours":
            return "alle";
        }

        return "";
      };

      const getIntervalWording = () => {
        switch (type.value) {
          case "multipleDay":
            return "mal / Tag";

          case "specialDays":
            return ". Tag";

          case "onceAtMonth":
            return "1 mal / Monat";

          case "specialMonth":
            return "mal / Jahr";

          case "multipleWeek":
            return "mal / Woche";

          case "specialWeeks":
            return ". Woche";

          case "multipleHours":
            return "Stunden";
        }

        return "";
      };

      const handleChange = ({text, newData}) => {
        dosage.value.dosagealternative.type = type.value;
        dosage.value.dosagealternative.interval = intervalValue.value;
        dosage.value.dosagealternative.dosagealternativedatas = newData;

        dosage.value.dosagealternative.text = getAlternativeDosageMessage(type.value, {
          type: type.value,
          interval: intervalValue.value,
          text: text,
          data: newData,
          unit: dosage.value.selected_unit,
        });

        emit("dosageChange", dosage.value);
      };

      const checkIntervalValue = () => {
        if (showIntervalValue.value) {
          if (intervalValue.value === null || intervalValue.value === "0" || intervalValue.value === 0) {
            errorMessage.value = "Bitte tragen Sie einen gültigen Intervall ein.";
            emit("isInvalid", "checkIntervalValue");
          } else {
            errorMessage.value = "";
            emit("isValid", "checkIntervalValue");
          }
        } else {
          errorMessage.value = "";
          emit("isValid", "checkIntervalValue");
        }
      };

      return {
        /** const */
        dosage,
        errorMessage,
        intervalValue,
        intervalWording,
        type,
        typeWording,

        /** computed */
        show,
        showIntervalValue,

        /** computed */
        handleTypeChange,
        clearDefaultDosage,
        restoreDefaultDosage,
        handleChange,
      };
    },
  };
</script>
