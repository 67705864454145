/**
 * @see App\Models\Record\Enum\UserStatus
 * @type {{string: string}}
 */

export const status = {
  pharmacist: "Apotheker/in",
  pta: "PTA",
  pharmacyengineer: "Pharmazieingenieur/in",
  pharmacyassistant: "Apothekerassistent/in",
  phip: "PhiP",
  tutor: "Tutor/in",
  student: "Student/in",
};
