<template>
  <patientinput-component-step
    :title="form.meta.is_patient ? 'Lebensumstände' : 'Weitere Angaben'"
    :test-id="testId + '-circumstances'"
  >
    <p>Bitte kreuzen Sie zutreffende Lebensumstände an.</p>

    <template v-for="(circumstance, index) in circumstances" :key="index">
      <!-- Patient Attribute with special config -->
      <template v-if="typeof form.circumstances.attributes[circumstance.key] === 'object'">
        <component-checkbox
          v-model="form.circumstances.attributes[circumstance.key].selected"
          color="primary"
          large
          :test-id="testId + '-circumstances-step-' + circumstance.testId"
          @change="(event) => attributeCircumstancesChanged(circumstance, event)"
        >
          {{ circumstance.label }}
        </component-checkbox>

        <div
          v-if="
            Object.hasOwn(circumstance, 'attributeOptions') && form.circumstances.attributes[circumstance.key].selected
          "
          class="pl-9 flex flex-col gap-4"
        >
          <template v-for="(option, oIndex) in circumstance.attributeOptions" :key="oIndex">
            <component-select
              v-if="option.type === 'select'"
              v-model="form.circumstances.attributes[circumstance.key].payload[option.attribute]"
              :label="option.label"
              :options="option.options"
              large
              :test-id="testId + '-circumstances-step-' + circumstance.testId"
            />

            <component-textarea
              v-else-if="option.type === 'textarea'"
              v-model="form.circumstances.attributes[circumstance.key].payload[option.attribute]"
              :label="option.label"
              large
              :test-id="testId + '-circumstances-step-' + circumstance.testId"
            />

            <component-input
              v-else
              v-model="form.circumstances.attributes[circumstance.key].payload[option.attribute]"
              :type="option.type"
              :label="option.label"
              large
              autocomplete="off"
              :test-id="testId + '-circumstances-step-' + circumstance.testId"
            />
          </template>
        </div>
      </template>

      <!-- only MIV-Code -->
      <template v-else-if="circumstance.miv">
        <component-checkbox
          color="primary"
          large
          :model-value="mivSelected(circumstance.miv)"
          :test-id="testId + '-circumstances-step-' + circumstance.testId"
          @change="(event) => mivCheckboxChanged(circumstance.miv, event)"
        >
          {{ circumstance.label }}
        </component-checkbox>
      </template>

      <!-- Patient Attribute without special config -->
      <template v-else>
        <component-checkbox
          v-model="form.circumstances.attributes[circumstance.key]"
          color="primary"
          large
          :test-id="testId + '-circumstances-step-' + circumstance.testId"
        >
          {{ circumstance.label }}
        </component-checkbox>
      </template>
    </template>
  </patientinput-component-step>
</template>

<script>
  import {computed} from "vue";

  import {circumstancesPatient, circumstancesCarer} from "@pages/Patientinput/Enums.js";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";

  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepCircumstances",

    components: {
      ComponentCheckbox,
      ComponentInput,
      ComponentSelect,
      ComponentTextarea,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const circumstances = computed(() => {
        const currentCircumstances = props.form.meta.is_patient ? circumstancesPatient : circumstancesCarer;

        return currentCircumstances.filter((circumstance) => showCircumstanceForCurrentGender(circumstance));
      });

      const showCircumstanceForCurrentGender = (circumstance) => {
        if (Object.hasOwn(circumstance, "gender")) {
          if (typeof circumstance.gender === "string") {
            return circumstance.gender === props.form.masterdata.patient.gender;
          } else if (typeof circumstance.gender === "object") {
            return circumstance.gender.includes(props.form.masterdata.patient.gender);
          }
        }

        return true;
      };

      const attributeCircumstancesChanged = (attribute, selected) => {
        if (Object.hasOwn(attribute, "attributeOptions") && !selected) {
          attribute.attributeOptions.forEach((select) => {
            props.form.circumstances.attributes[attribute.key].payload[select.attribute] = null;
          });
        }
      };

      const mivSelected = (miv) => {
        return props.form.circumstances.mivs.filter((iMiv) => iMiv === miv).length === 1;
      };

      const mivCheckboxChanged = (miv, selected) => {
        props.form.circumstances.mivs = props.form.circumstances.mivs.filter((iMiv) => iMiv !== miv);

        if (selected) {
          props.form.circumstances.mivs.push(miv);
        }
      };

      return {
        /** computed */
        circumstances,

        /** function */
        attributeCircumstancesChanged,
        mivSelected,
        mivCheckboxChanged,
      };
    },
  };
</script>
