<template>
  <component-dialog ref="refRecordEditFinishedDialog" dialog-width="medium">
    <template #title />

    <template #content>
      <div class="flex space-x-2 w-full justify-center text-mcgreen">
        <span class="text-xl">Eingabe abgeschlossen</span>

        <component-icon class="text-xl mt-1">check</component-icon>
      </div>

      <hr class="w-full h-[1px] p-0 my-4" />

      <div>
        <h4 class="font-semibold mb-2">Alle Angaben korrekt?</h4>

        <h4 class="font-normal">Das wird analysiert:</h4>

        <ul class="mt-4 space-y-3">
          <li class="w-full flex justify-between items-start">
            <div class="flex items-center space-x-4">
              <component-icon class="text-mcgreen text-2xl">check</component-icon>

              <span>Medikationsanalyse</span>
            </div>

            <button class="group relative cursor-help">
              <div
                class="hidden group-hover:block absolute w-96 bg-white p-3 rounded-xs shadow-lg translate-x-[-100%] translate-y-[-70%] text-left text-sm"
              >
                <p>
                  Strukturierte Prüfung auf arzneimittelbezogene Probleme (ABP), deren mögliche Ursachen sowie
                  Lösungsvorschläge für aktuelle Beschwerden des Patienten.
                </p>
              </div>

              <component-icon class="text-blue-600 text-2xl">info</component-icon>
            </button>
          </li>

          <li class="w-full flex justify-between items-start">
            <div class="flex items-center space-x-4">
              <component-icon class="text-mcgreen text-2xl">check</component-icon>

              <span>Adhärenz</span>
            </div>

            <button class="group relative cursor-help">
              <div
                class="hidden group-hover:block absolute w-96 bg-white p-3 rounded-xs shadow-lg translate-x-[-100%] translate-y-[-70%] text-left text-sm"
              >
                <p>
                  Es wird auf Therapietreue (Non-Adhärenz oder Non-Compliance) geprüft. Empfehlungen zu Maßnahmen, die
                  die Therapietreue (Adhärenz) steigern können, werden angezeigt.
                </p>
              </div>

              <component-icon class="text-blue-600 text-2xl">info</component-icon>
            </button>
          </li>

          <li class="w-full flex justify-between items-start">
            <div class="flex items-center space-x-4">
              <component-icon class="text-mcgreen text-2xl">check</component-icon>

              <span>Prävention</span>
            </div>

            <button class="group relative cursor-help">
              <div
                class="hidden group-hover:block absolute w-96 bg-white p-3 rounded-xs shadow-lg translate-x-[-100%] translate-y-[-70%] text-left text-sm"
              >
                <p>
                  Empfehlungen zu Präventionsmaßnahmen sind in kompakter Form verfügbar, wenn Sie Erkrankungen /
                  Indikationen in der Eingabemaske angegeben haben.
                </p>
              </div>

              <component-icon class="text-blue-600 text-2xl">info</component-icon>
            </button>
          </li>
        </ul>
      </div>
    </template>

    <template #actions>
      <component-button
        :disabled="analysisButtonDisabled"
        class="p4umc-primary"
        label="Analysieren"
        @click="$emit('closeAndEmitAnalysisStart')"
      />

      <component-button label="Abbrechen" @click="close" />

      <div v-if="$page.props.can.is_nident_token" class="flex-grow" />

      <div @mouseenter="showUpgradeLabel = true" @mouseleave="showUpgradeLabel = false">
        <component-button
          v-if="$page.props.can.is_nident_token"
          :label="upgradeLabel"
          :uppercase="false"
          :min-width="false"
          class="!mr-auto ml-0 text-mcred"
          :icon-button="true"
          @click="openUpgradePage"
        >
          <component-icon class="text-2xl">stars</component-icon>
        </component-button>
      </div>
    </template>
  </component-dialog>
</template>

<script>
  import {ref, watch} from "vue";

  import Window from "@utils/Helpers/Window.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "RecordComponentBeforeAnalyseDialog",

    components: {ComponentButton, ComponentDialog, ComponentIcon},

    props: {
      analysisButtonDisabled: {
        type: Boolean,
        required: true,
      },
    },

    emits: ["closeAndEmitAnalysisStart"],

    setup() {
      const refRecordEditFinishedDialog = ref(null);

      const showUpgradeLabel = ref(false);
      const upgradeLabel = ref("");

      watch(showUpgradeLabel, (newValue) => {
        if (newValue) {
          upgradeLabel.value = "Upgrade Info";
        } else {
          upgradeLabel.value = "";
        }
      });

      const open = () => {
        refRecordEditFinishedDialog.value.open();
      };

      const close = () => {
        if (refRecordEditFinishedDialog.value) refRecordEditFinishedDialog.value.close();
      };

      const openUpgradePage = () => {
        Window.open(import.meta.env.MC_PHARMA4U_UPGRADE_URL + "#checkumfang", "_blank").catch(() => {
          console.error("Upgrade page open failed!");
        });
      };

      return {
        /** ref */
        refRecordEditFinishedDialog,

        /** const */
        showUpgradeLabel,
        upgradeLabel,

        /** function */
        open,
        close,
        openUpgradePage,
      };
    },
  };
</script>
