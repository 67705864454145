<template>
  <patientinput-component-step title="Probleme/Symptome" :test-id="testId + '-symptoms'">
    <div class="flex flex-col gap-8">
      <section>
        <p>Bitte kreuzen Sie die Symptome an, die in den letzten vier Wochen verstärkt aufgetreten sind.</p>

        <component-accordion>
          <component-accordion-item
            v-for="(category, categoryIndex) in symptoms"
            :key="categoryIndex"
            :title="category.title"
            :test-id="testId + '-symptoms-step-' + categoryIndex"
          >
            <template v-if="categorySelected(category)" #title>
              <sup>
                <component-category-badge
                  color="info"
                  :label="categorySelected(category).toString()"
                  :test-id="testId + '-symptoms-step-' + categoryIndex"
                />
              </sup>
            </template>

            <div class="flex flex-col gap-3">
              <component-checkbox
                v-for="(item, itemIndex) in category.items"
                :key="itemIndex"
                color="primary"
                large
                :model-value="mivSelected(item.miv)"
                :test-id="testId + '-symptoms-step-' + categoryIndex + '-' + itemIndex"
                @change="(event) => mivCheckboxChanged(item.miv, event)"
              >
                <span v-if="form.meta.is_patient" v-text="item.labelPatient" />
                <span v-else v-text="item.labelCarer" />
              </component-checkbox>
            </div>
          </component-accordion-item>
        </component-accordion>
      </section>

      <section>
        <h2 class="text-2xl sr-only">Weitere Probleme/Symptome</h2>

        <p v-if="form.meta.is_patient">
          Sie haben weitere Probleme/Symptome, die nicht in der Liste stehen? Dann können Sie danach suchen.
        </p>
        <p v-else>
          Ihr Patient hat weitere Probleme/Symptome, die nicht in der Liste stehen? Dann können Sie danach suchen.
        </p>

        <patientinput-additional-symptoms :form="form" :test-id="testId + '-symptoms-step'" />
      </section>
    </div>
  </patientinput-component-step>
</template>

<script>
  import {symptoms} from "@pages/Patientinput/Enums.js";

  import ComponentAccordion from "@components/Accordion/Accordion.vue";
  import ComponentAccordionItem from "@components/Accordion/AccordionItem.vue";
  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  import PatientinputAdditionalSymptoms from "@pages/Patientinput/Components/AdditionalSymptoms.vue";
  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepSymptoms",

    components: {
      ComponentAccordion,
      ComponentAccordionItem,
      ComponentCategoryBadge,
      ComponentCheckbox,
      PatientinputAdditionalSymptoms,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const categorySelected = (category) => {
        let count = 0;

        category.items.forEach((item) => {
          if (mivSelected(item.miv)) {
            count++;
          }
        });

        return count;
      };

      const mivSelected = (miv) => {
        return props.form.symptoms.mivs.some((iMiv) => {
          return Array.isArray(miv) ? miv.includes(iMiv) : iMiv === miv;
        });
      };

      const mivCheckboxChanged = (miv, selected) => {
        props.form.symptoms.mivs = props.form.symptoms.mivs.filter((iMiv) => {
          return Array.isArray(miv) ? !miv.includes(iMiv) : iMiv !== miv;
        });

        if (selected) {
          if (Array.isArray(miv)) {
            miv.forEach((m) => props.form.symptoms.mivs.push(m));
          } else {
            props.form.symptoms.mivs.push(miv);
          }
        }
      };

      return {
        /** enum */
        symptoms,

        /** function */
        categorySelected,
        mivSelected,
        mivCheckboxChanged,
      };
    },
  };
</script>

<style scoped lang="postcss">
  section {
    @apply flex flex-col gap-3;
  }
</style>
