<template>
  <component-checkbox v-model="checked" color="primary" @change="toggleSetting()">
    <slot>Nicht mehr anzeigen</slot>
  </component-checkbox>
</template>

<script>
  import {inject, ref} from "vue";
  import {router} from "@inertiajs/vue3";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "ComponentDoNotShowAgain",

    components: {ComponentCheckbox},

    props: {
      settingsName: {
        type: String,
        required: true,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");

      const checked = ref(false);

      const toggleSetting = () => {
        router.post(
          route("settings.store", {user: privacy.getCurrentUser()?.id}),
          {
            [props.settingsName]: !checked.value,
          },
          {
            preserveScroll: true,
          },
        );
      };

      return {checked, toggleSetting};
    },
  };
</script>
