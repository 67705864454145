<template>
  <div v-if="filteredMessages.length > 0" class="print:hidden">
    <div v-for="(message, index) in filteredMessages" :key="index">
      <component-alert :type="message.type || 'warning'" :test-id="testId + '-message-' + index">
        <template v-if="message.title">
          <strong class="inline-block mr-2" v-text="message.title" />
        </template>

        <span v-html="message.text || message" />
      </component-alert>
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentAlert from "@components/Alerts/Alert.vue";

  export default {
    name: "ComponentMessage",

    components: {
      ComponentAlert,
    },

    props: {
      displayMode: {
        type: String,
        default: "",
      },
    },

    setup(props) {
      const page = usePage();

      const filteredMessages = computed(() => {
        if (typeof page.props.messages !== "object") {
          return [];
        }

        return page.props.messages.filter((message) => {
          return message.display_mode === props.displayMode;
        });
      });

      return {
        /** computed  */
        filteredMessages,
      };
    },
  };
</script>
