<template>
  <section class="flex flex-col sm:flex-row w-full sm:space-x-8 print:hidden">
    <component-tabs class="w-full" :active-tab="activeTab" @click="tabChanged">
      <component-tab title="Arztadresse">
        <div class="flex items-baseline justify-between">
          <h4 class="px-2 py-1 mt-1.5 cursor-default">An:</h4>
        </div>

        <component-editor
          v-model="form.doctoraddress"
          :encrypted="true"
          :hide-toolbar="true"
          :enable-resize="false"
          editor-class="min-h-40 h-full"
          :disabled="form.processing && lastUpdatedField === 'doctoraddress'"
          @blur="save('doctoraddress')"
        />
      </component-tab>

      <component-tab title="Patientenadresse">
        <div class="flex items-baseline justify-between">
          <h4 class="px-2 py-1 mt-1.5 cursor-default">An:</h4>
        </div>
        <component-editor
          v-model="form.patientaddress"
          :encrypted="true"
          :hide-toolbar="true"
          :enable-resize="false"
          editor-class="min-h-40 h-full"
          :disabled="form.processing && lastUpdatedField === 'patientaddress'"
          @blur="save('patientaddress')"
        />
      </component-tab>
    </component-tabs>

    <div class="w-full mt-14">
      <div class="flex items-baseline justify-between">
        <h4 class="px-2 py-1 mt-1.5 cursor-default">Von:</h4>
      </div>

      <component-editor
        v-model="form.useraddress"
        :encrypted="true"
        :hide-toolbar="true"
        :enable-resize="false"
        editor-class="min-h-40 h-full"
        :disabled="form.processing && lastUpdatedField === 'useraddress'"
        @blur="save('useraddress')"
      />
    </div>
  </section>

  <!-- print -->
  <section class="w-full !mt-0 pb-8 hidden space-x-8 print:flex print:flex-row">
    <div class="min-h-[4cm] bg-gray-200 p-3 w-full border-gray-300 border-b-2 border-t-2">
      An:
      <div class="hidden pt-1 [.print-doc_&]:print:block" data-format="html" :data-encrypted="form.doctoraddress" />

      <div
        class="hidden pt-1 [.print-patient_&]:print:block"
        data-format="html"
        :data-encrypted="form.patientaddress"
      />
    </div>

    <div class="min-h-[4cm] bg-gray-200 p-3 w-full border-gray-300 border-b-2 border-t-2">
      Von:
      <div class="pt-1" data-format="html" :data-encrypted="form.useraddress" />
    </div>
  </section>
</template>

<script>
  import {ref} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";

  import ComponentTabs from "@components/Tab/Tabs.vue";
  import ComponentTab from "@components/Tab/Tab.vue";
  import ComponentEditor from "@components/Editor/Editor.vue";

  export default {
    name: "PagesRecordResultAddresses",

    components: {ComponentEditor, ComponentTab, ComponentTabs},

    props: {
      /** @var Object patient is an already decrypted (!) patient */
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const activeTab = ref("Arztadresse");
      const lastUpdatedField = ref(null);

      const form = useForm({
        useraddress: initUserAddress(),
        doctoraddress: initDoctorAddress(),
        patientaddress: initPatientAddress(),
      });

      const tabChanged = (newActiveTab) => {
        activeTab.value = newActiveTab;
      };

      function save(field) {
        if (form.isDirty) {
          lastUpdatedField.value = field;
          form
            .transform((data) => {
              switch (field) {
                case "useraddress":
                  return {useraddress: data.useraddress};
                case "doctoraddress":
                  return {doctoraddress: data.doctoraddress};
                case "patientaddress":
                  return {patientaddress: data.patientaddress};
              }
            })
            .patch(route("records.update", {patient: props.patient.id, record: props.record.id}), {});
        }
      }
      function initUserAddress() {
        // use already stored value
        if (props.record.useraddress) {
          return props.record.useraddress;
        } else if (props.patient.is_fake || props.record.shared_with_me) {
          return null;
        } else if (
          props.record?.transfer?.data?.pharmacy &&
          (props.record?.transfer?.data?.pharmacy?.first_name ||
            props.record?.transfer?.data?.pharmacy?.last_name ||
            props.record?.transfer?.data?.pharmacy?.company_name)
        ) {
          // get from wawi via transfer.data.pharmacy
          let rows = [];

          // NAME
          if (props.record?.transfer?.data?.pharmacy?.first_name || props.record?.transfer?.data?.pharmacy?.last_name) {
            rows.push(
              (
                (props.record?.transfer?.data?.pharmacy?.first_name ?? "") +
                " " +
                (props.record?.transfer?.data?.pharmacy?.last_name ?? "")
              ).trim(),
            );
          }

          // COMPANY
          if (props.record?.transfer?.data?.pharmacy?.company_name) {
            rows.push(props.record?.transfer?.data?.pharmacy?.company_name);
          }

          // STREET
          if (props.record?.transfer?.data?.pharmacy?.street_address) {
            rows.push(props.record?.transfer?.data?.pharmacy?.street_address);
          }

          // ZIPCODE CITY
          if (props.record?.transfer?.data?.pharmacy?.postal_code || props.record?.transfer?.data?.pharmacy?.city) {
            rows.push(
              (
                (props.record?.transfer?.data?.pharmacy?.postal_code ?? "") +
                " " +
                (props.record?.transfer?.data?.pharmacy?.city ?? "")
              ).trim(),
            );
          }

          // PHONE
          if (props.record?.transfer?.data?.pharmacy?.telephone) {
            rows.push("Telefon: " + props.record?.transfer?.data?.pharmacy?.telephone);
          }

          // EMAIL
          if (props.record?.transfer?.data?.pharmacy?.email) {
            rows.push("E-Mail: " + props.record?.transfer?.data?.pharmacy?.email);
          }

          return rows.length ? "<p>" + rows.join("<br>") + "</p>" : null;
        } else {
          // get from p4uApi via $page.props.client
          let rows = [];
          const client = usePage().props.client;

          // NAME, COMPANY: use user from record or customer from authentication
          let name = props.record.username ?? (client?.first_name + " " + client?.last_name).trim();
          name += client?.company ? ", " + client?.company : "";
          rows.push(name);

          // STREET
          if (client?.address) {
            rows.push(client?.address);
          }

          // ZIP CITY
          if (client?.zip || client?.city) {
            rows.push((client?.zip + " " + client?.city).trim());
          }

          // PHONE
          if (client?.telephone) {
            rows.push("Telefon: " + client?.telephone);
          }

          // FAX
          if (client?.fax) {
            rows.push("Fax: " + client?.fax);
          }

          // EMAIL
          if (client?.email) {
            rows.push("E-Mail: " + client?.email);
          }
          return rows.length ? "<p>" + rows.join("<br>") + "</p>" : null;
        }
      }
      function initDoctorAddress() {
        // use already stored value
        if (props.record.doctoraddress) {
          return props.record.doctoraddress;
        } else if (props.patient.is_fake || props.record.shared_with_me) {
          return null;
        } else if (
          props.record?.transfer?.data?.doctor &&
          (props.record?.transfer?.data?.doctor?.first_name ||
            props.record?.transfer?.data?.doctor?.last_name ||
            props.record?.transfer?.data?.doctor?.company_name)
        ) {
          // get from wawi via transfer.data.doctor
          let rows = [];

          // NAME, COMPANY
          let name = (
            (props.record?.transfer?.data?.doctor?.first_name ?? "") +
            " " +
            (props.record?.transfer?.data?.doctor?.last_name ?? "")
          ).trim();
          name += props.record?.transfer?.data?.doctor?.company_name
            ? ", " + props.record?.transfer?.data?.doctor?.company_name
            : "";
          rows.push(name);

          // STREET
          if (props.record?.transfer?.data?.doctor?.street_address) {
            rows.push(props.record?.transfer?.data?.doctor?.street_address);
          }

          // ZIPCODE CITY
          if (props.record?.transfer?.data?.doctor?.postal_code || props.record?.transfer?.data?.doctor?.city) {
            rows.push(
              (
                (props.record?.transfer?.data?.doctor?.postal_code ?? "") +
                " " +
                (props.record?.transfer?.data?.doctor?.city ?? "")
              ).trim(),
            );
          }

          // PHONE
          if (props.record?.transfer?.data?.doctor?.telephone) {
            rows.push("Telefon: " + props.record?.transfer?.data?.doctor?.telephone);
          }

          // EMAIL
          if (props.record?.transfer?.data?.doctor?.email) {
            rows.push("E-Mail: " + props.record?.transfer?.data?.doctor?.email);
          }
          return rows.length ? "<p>" + rows.join("<br>") + "</p>" : null;
        }
      }

      function initPatientAddress() {
        // use already stored value
        if (props.record.patientaddress) {
          return props.record.patientaddress;
        } else if (props.patient.is_fake || props.record.shared_with_me) {
          return null;
        } else if (props.patient?.firstname || props.patient?.lastname) {
          // at least use name of patient
          return "<p>" + props.patient.firstname + " " + props.patient.lastname + "</p>";
        }
      }

      return {activeTab, tabChanged, form, save, lastUpdatedField};
    },
  };
</script>
