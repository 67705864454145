<template>
  <div :class="{'flex flex-wrap space-x-1.5': inline}">
    <h6 class="text-gray-600" :class="{'font-semibold': inline, 'text-xs uppercase': !inline}">
      Pflegegrad{{ inline ? ":" : "" }}
    </h6>
    <div class="flex items-start space-x-1.5" :class="{'mt-0.5 text-sm': !inline}">
      <strong :class="{'text-3xl font-normal': !inline, 'font-semibold': inline}">
        {{ attribute.payload?.level }}
      </strong>
      <span v-text="helperText" />
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";
  import {degreeOfCareLevel} from "@pages/Patients/Components/PatientAttributes/Enums.js";

  export default {
    name: "ComponentPatientSidebarDegreeOfCareAttribute",
    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const helperText = computed(() => {
        return typeof degreeOfCareLevel[parseInt(props?.attribute?.payload?.level)] !== "undefined"
          ? degreeOfCareLevel[props.attribute.payload.level]
          : null;
      });

      return {helperText};
    },
  };
</script>
