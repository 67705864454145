<template>
  <div class="flex ml-8">
    <component-input
      ref="refHourField"
      v-model="hour"
      time-select="hourField"
      type="text"
      class="!w-9"
      autocomplete="off"
      maxlength="2"
      :show-maxlength-count="false"
      input-alignment="text-center"
      hide-clear
      @input="handleChange"
    />

    <span class="pt-1 px-1">:</span>

    <component-input
      ref="refMinuteField"
      v-model="minute"
      time-select="minuteField"
      type="text"
      maxlength="2"
      :show-maxlength-count="false"
      class="!w-9"
      autocomplete="off"
      input-alignment="text-center"
      hide-clear
      @input="handleChange"
    />

    <span class="pt-2 text-sm">Uhr</span>
  </div>
</template>

<script>
  import {onBeforeMount, ref} from "vue";

  import ComponentInput from "@components/Inputs/Input.vue";

  export default {
    name: "ComponentTimeSelect",

    components: {ComponentInput},

    props: {
      value: {
        type: [String, ref],
        default: "",
      },
    },

    emits: ["selectedTime"],

    setup(props, {emit}) {
      const refHourField = ref(null);
      const refMinuteField = ref(null);

      const selectedTime = ref(null);
      const hour = ref(null);
      const minute = ref(null);

      onBeforeMount(() => {
        if (props.value !== null) {
          const time = props.value.split(":");
          hour.value = time[0];
          minute.value = time[1];
        }
      });

      const handleChange = () => {
        if (hour.value && hour.value.length === 2) {
          refMinuteField.value.focus();
        }

        if (minute.value === null) {
          minute.value = "00";
        }

        selectedTime.value = hour.value + ":" + minute.value;
        emit("selectedTime", {
          time: selectedTime.value,
        });
      };

      return {refHourField, refMinuteField, hour, minute, handleChange};
    },
  };
</script>
