<template>
  <component-dialog ref="bmpDialog" dialog-title="BMP-Manager" @dialog-closed="close">
    <template #content>
      <template v-if="loading">
        <div class="text-center py-10"><component-spinner class="w-10" /></div>
      </template>

      <template v-else-if="error">
        <component-alert v-if="error === 'popup'" type="error">
          <p>Ihr Browser scheint Popups zu blockieren, weshalb der BMP-Manager nicht geöffnet werden konnte.</p>
          <p>Bitte prüfen Sie Ihre "Pop-up"-Browser-Einstellungen unter "Datenschutz und Sicherheit".</p>
        </component-alert>
        <component-alert v-else type="error">
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
        </component-alert>
      </template>

      <template v-else>
        <p class="text-sm">
          Sie öffnen den BMP im BMP-Manager. Dort können Sie beliebige inhaltliche Änderungen vornehmen, bspw. Rezeptur-
          oder Freitextzeilen hinzufügen oder Indikationen für Ihren Patienten als Laienbegriff eintragen.
        </p>

        <p class="text-sm mt-2">
          Bitte beachten Sie, dass Änderungen im BMP-Manager bislang nicht in den MediCheck zurück übertragen werden.
          Sie sollten einen angepassten BMP also unbedingt als PDF speichern.
        </p>

        <component-do-not-show-again settings-name="bmpManagerInfo" class="mt-4" />
      </template>
    </template>

    <template #actions>
      <component-button
        class="p4umc-primary"
        label="BMP-Manager öffnen"
        :disabled="loading"
        @click="pushToBmpManager"
      />

      <component-button label="Abbrechen" @click="close" />
    </template>
  </component-dialog>
</template>

<script>
  import {inject, onBeforeUnmount, ref} from "vue";

  import Window from "@utils/Helpers/Window.js";
  import {pdfRouter} from "@utils/pdfRouter/pdfRouter.js";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentDoNotShowAgain from "@components/Checkboxes/DoNotShowAgain.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "ComponentBmpExportDialog",

    components: {ComponentAlert, ComponentButton, ComponentDialog, ComponentDoNotShowAgain, ComponentSpinner},

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");

      const /** @type {import("vue").Ref} */ bmpDialog = ref(null);

      const /** @type {import("vue").Ref<AbortController>} */ abortController = ref(null);

      const /** @type {import("vue").Ref<Boolean>} */ loading = ref(false);
      const /** @type {import("vue").Ref<String|Boolean>} */ error = ref(false);

      const open = () => {
        abortController.value = null;
        loading.value = false;
        error.value = null;

        if (privacy.getConfiguration("bmpManagerInfo")?.amount === false) {
          pushToBmpManager();
        }

        bmpDialog.value.open();
      };

      const pushToBmpManager = async () => {
        if (abortController.value) {
          abortController.value.abort();
        }

        abortController.value = new AbortController();

        loading.value = true;
        error.value = false;

        const patient = await privacy.decryptPatient(props.patient);
        const record = await privacy.decryptRecord(props.record);

        if (record.checks) {
          delete record.checks;
        }

        if (record.metadata) {
          delete record.metadata;
        }

        if (record.patient) {
          delete record.patient;
        }

        pdfRouter.initializeBmpManager(
          {
            client: privacy.getClient(),
            patient: patient,
            record: record,
          },
          {
            onSuccess: (response) => {
              Window.open(response.data.url, "_blank")
                .then(() => close())
                .catch(() => {
                  showError("popup");
                });
            },
            onError: () => {
              showError("default");
            },
          },
          {
            signal: abortController.value.signal,
          },
        );
      };

      const showError = (errorType) => {
        error.value = errorType;
        loading.value = false;
      };

      const close = () => {
        if (abortController.value) {
          abortController.value.abort();
        }

        bmpDialog.value.close();
      };

      onBeforeUnmount(() => {
        close();
      });

      return {bmpDialog, loading, error, pushToBmpManager, open, close};
    },
  };
</script>

<style scoped></style>
