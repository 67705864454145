<template>
  <div class="flex items-center px-2 pb-4 space-x-2">
    <!-- Headline is editable -->
    <template v-if="isEditable">
      <div class="flex items-center space-x-1">
        <h5 class="text-2xl">Medikation</h5>
        <component-info-icon placement="top">
          <template #content>
            <p>
              - Welche AM wenden Sie (davon) aktuell an?
              <br />
              - Wie wenden Sie die AM an?
              <br />
              - Wofür oder wogegen wenden Sie die AM an?
              <br />
              - Wie sinnvoll/hilfreich finden Sie das/die AM?
              <br />
              - Haben Sie die Dosis (gelegentlich) geändert/ausgelassen?
              <br />
              - Welche weiteren AM wurden verordnet oder als Selbstmedikation angewendet?
              <br />
              - Wie häufig haben Sie im letzten Monat vergessen, Ihre AM einzunehmen?
              <br />
              - Haben Sie Probleme/Symptome, die Sie mit dem AM in Zusammenhang bringen?
              <br />
              - Wo/wie lagern Sie Ihre AM?
            </p>
          </template>
        </component-info-icon>
      </div>
    </template>

    <!-- Headline is not editable -->
    <template v-else>
      <h5 class="text-2xl">Medikation</h5>
      <component-button class="p4umc-primary" label="Bearbeiten" @click="changeIsEditable" />
    </template>
  </div>

  <!-- Alert for not loadable medications -->
  <component-alert
    v-if="
      (unloadableMedicationsUnsolved && unloadableMedicationsUnsolved.length > 0) ||
      (unloadableMedicationsSolved && unloadableMedicationsSolved.length > 0)
    "
    type="warning"
  >
    <h4 class="font-bold">
      In diesem Bereich befinden sich Arzneimittel bzw. Medizinprodukte, die in der aktualisierten Datenbank ersetzt
      (oder geschlechtsspezifisch zugeordnet) wurden.
    </h4>

    <div v-if="unloadableMedicationsUnsolved.length > 0">
      <p class="mt-2">
        Bitte tauschen Sie folgende Arzneimittel bzw. Medizinprodukte durch geeignete neue aus, damit sie im Check
        erkannt und berücksichtigt werden:
      </p>
      <ul class="mt-2 space-y-1">
        <li v-for="(item, index) in unloadableMedicationsUnsolved" :key="index">
          <em>{{ item.fam }}</em>
        </li>
      </ul>
    </div>

    <div v-if="unloadableMedicationsSolved.length > 0">
      <p class="mt-2">
        Für folgende Arzneimittel bzw. Medizinprodukte konnten wir eine mögliche Alternative finden. Bitte prüfen und
        aktualisieren Sie diese.
      </p>
      <ul class="mt-2 space-y-1">
        <li v-for="(item, index) in unloadableMedicationsSolved" :key="index">
          <em>{{ item.old.fam }}</em>
          wurde ersetzt durch
          <em>{{ item.new.Name }}</em>
          .
        </li>
      </ul>
    </div>
  </component-alert>

  <!-- Table -->
  <template v-if="recordData.medications.length > 0">
    <medication-table :record-data="recordData" :is-editable="isEditable" :is-loading="isLoading" />
  </template>

  <!-- Search -->
  <medication-component-pac-search v-if="isEditable" />

  <!-- Buttons -->
  <div v-if="hasThirdParty('p4u')" class="flex justify-between py-2">
    <medication-component-athina-import-export :hide-import="!isEditable" :disable-export="isEditable" />

    <template v-if="isEditable && hasServices">
      <medication-component-pharmaceutical-services />
    </template>

    <medication-component-plan-print />
  </div>

  <!-- Dialogs -->
  <medication-component-dosage-dialog ref="refDosageDialog" />
  <component-core-info-dialog ref="refCoreInfoDialog" />
  <medication-component-detail-dialog ref="refEditDetailDialog" :is-editable="isEditable" />
  <medication-component-indication-dialog ref="refIndicationDialog" />
</template>

<script>
  import {computed, inject, provide, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCoreInfoDialog from "@components/Dialogs/CoreInfoDialog/CoreInfoDialog.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  import MedicationComponentAthinaImportExport from "@pages/Records/Components/Sections/MedicationBar/Components/AthinaImportExport.vue";
  import MedicationComponentDetailDialog from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/DetailDialog.vue";
  import MedicationComponentDosageDialog from "@pages/Records/Components/Sections/MedicationBar/Components/DosageDialog.vue";
  import MedicationComponentIndicationDialog from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationDialog.vue";
  import MedicationComponentPacSearch from "@pages/Records/Components/Sections/MedicationBar/Components/PacSearch.vue";
  import MedicationComponentPharmaceuticalServices from "@pages/Records/Components/Sections/MedicationBar/Components/PharmaceuticalServices.vue";
  import MedicationComponentPlanPrint from "@pages/Records/Components/Sections/MedicationBar/Components/PlanPrint.vue";
  import MedicationTable from "@pages/Records/Components/Sections/MedicationBar/Components/Table.vue";

  export default {
    name: "RecordComponentSectionMedicationBar",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentCoreInfoDialog,
      ComponentInfoIcon,
      MedicationComponentAthinaImportExport,
      MedicationComponentDetailDialog,
      MedicationComponentDosageDialog,
      MedicationComponentIndicationDialog,
      MedicationComponentPacSearch,
      MedicationComponentPharmaceuticalServices,
      MedicationComponentPlanPrint,
      MedicationTable,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      changeIsEditable: {
        type: Function,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      unloadableMedicationsUnsolved: {
        type: Object,
        required: true,
      },
      unloadableMedicationsSolved: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup() {
      const page = usePage();

      const hasThirdParty = inject("$hasThirdParty");

      const refCoreInfoDialog = ref(null);
      const refDosageDialog = ref(null);
      const refEditDetailDialog = ref(null);
      const refIndicationDialog = ref(null);

      const hasServices = computed(() => page.props.services.bloodPressure.has || page.props.services.inhaler.has);

      const openDialog = (dialogName, data) => {
        switch (dialogName) {
          case "refDosageDialog":
            refDosageDialog.value.open(data);
            break;
          case "refCoreInfoDialog":
            refCoreInfoDialog.value.open(data);
            break;
          case "refEditDetailDialog":
            refEditDetailDialog.value.open(data);
            break;
          case "refIndicationDialog":
            refIndicationDialog.value.open(data);
            break;
        }
      };

      provide("medicationOpenDialog", openDialog);

      return {
        /** inject */
        hasThirdParty,

        /** ref */
        refCoreInfoDialog,
        refDosageDialog,
        refEditDetailDialog,
        refIndicationDialog,

        /** computed **/
        hasServices,
      };
    },
  };
</script>
