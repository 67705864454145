<template>
  <div v-if="haveSaveDate && !secondsToHideDate" class="text-sm">
    <span>Gespeichert: {{ formattedDate }}</span>
  </div>

  <div v-else-if="show && secondsToHideDate" class="flex justify-end items-center space-x-1 pr-1 text-sm">
    <component-icon class="text-mcgreen">done</component-icon>
    <div>Gespeichert: {{ formattedDate }}</div>
  </div>
</template>

<script>
  /** @deprecated ? */
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentProcessSaved",

    components: {
      ComponentIcon,
    },

    props: {
      secondsToHideDate: {
        type: Number,
        required: true,
      },
    },

    setup() {
      /** TODO miss the logic */
      const formattedDate = "12:30";
      const haveSaveDate = true;
      const show = false;

      return {
        formattedDate,
        haveSaveDate,
        show,
      };
    },
  };
</script>
