<template>
  <tr
    class="hover:bg-gray-100 border-b-2 border-gray-200 leading-tight cursor-pointer"
    :data-test="testId + '-contact-row'"
    @click="(event) => clickRow(event, $route('contacts.edit', {contact: contact.id}))"
  >
    <td class="px-0.5 py-2">
      <component-tooltip v-if="doctorProfession[contact.profession]" tooltip="Fachrichtung" :force-inline="true">
        <span class="text-sm">{{ doctorProfession[contact.profession] }}</span>
      </component-tooltip>

      <div>
        <inertia-link
          :href="$route('contacts.edit', {contact: contact.id})"
          class="text-gray-700 hover:text-gray-900 font-semibold text-lg hover:underline cursor-pointer"
          :data-test="testId + '-contact-row-name-contact-edit-inertia-link'"
        >
          <component-tooltip v-if="contact.title" tooltip="Titel" :force-inline="true">
            {{ contact.title }}
          </component-tooltip>

          <component-tooltip tooltip="Nachname, Vorname" :force-inline="true">
            {{ contact.lastname ? contact.lastname + "," : "" }} {{ contact.firstname }}
          </component-tooltip>
        </inertia-link>
      </div>
    </td>

    <td class="px-0.5 py-2">
      <inertia-link
        :href="$route('contacts.edit', {contact: contact.id})"
        class="text-gray-700 hover:text-gray-900 hover:underline cursor-pointer"
        :data-test="testId + '-contact-row-company-contact-edit-inertia-link'"
      >
        <component-tooltip v-if="contact.company" tooltip="Firma" :force-inline="true">
          {{ contact.company }}
        </component-tooltip>
      </inertia-link>
    </td>

    <td class="px-0.5 py-2">
      <div class="flex space-x-2 items-center">
        <!-- related patients -->
        <component-tooltip
          tooltip="Zugeordnete Patienten anzeigen"
          :force-hidden="contact.patients_count === 0"
          force-inline
        >
          <component-category-badge class="min-h-5" :color="contact.patients_count > 0 ? 'info' : null">
            <component
              :is="contact.patients_count === 0 ? 'span' : 'inertia-link'"
              :href="filteredPatientsUrl"
              class="text-gray-900"
              :data-test="testId + '-contact-row-filtered-patients-url'"
            >
              {{ contact.patients_count }} {{ contact.patients_count === 1 ? "Patient" : "Patienten" }} zugeordnet
            </component>
          </component-category-badge>
        </component-tooltip>

        <!-- contacts.note -->
        <component-category-badge v-if="contact.note" color="gray" class="min-h-5">
          <component-info-icon icon-class="text-base text-mcgreen" placement="top-end">
            <template #content>
              <strong>Hinterlegte zusätzliche Informationen:</strong>

              <p>{{ contact.note }}</p>
            </template>
          </component-info-icon>
        </component-category-badge>
      </div>
    </td>

    <td class="hidden 2xl:table-cell px-0.5 py-2">
      <component-tooltip v-if="contact.email" tooltip="E-Mail" :force-inline="true">
        {{ contact.email }}
      </component-tooltip>
    </td>

    <td class="hidden 2xl:table-cell px-0.5 py-2">
      <component-tooltip v-if="contact.telephone" tooltip="Tel.-Nr." :force-inline="true">
        {{ contact.telephone }}
      </component-tooltip>
    </td>

    <td class="hidden lg:table-cell px-0.5 py-2">
      <div v-if="contact.street">
        <component-tooltip tooltip="Straße, Hausnr." :force-inline="true">
          {{ contact.street }}
        </component-tooltip>
      </div>

      <div v-if="contact.zip || contact.city">
        <component-tooltip tooltip="Ort" :force-inline="true">{{ contact.zip }} {{ contact.city }}</component-tooltip>
      </div>
    </td>

    <!-- actions -->
    <td class="px-0.5 py-2">
      <div class="flex flex-row-reverse space-x-reverse space-x-4 justify-start">
        <component-tooltip tooltip="Löschen">
          <component-icon-button icon="delete" :test-id="testId + '-delete-contact'" @click="deleteContact" />
        </component-tooltip>

        <component-icon-button
          class="p4umc-primary"
          icon="edit"
          :href="$route('contacts.edit', {contact: contact.id})"
          title="Bearbeiten"
          :test-id="testId + '-edit-contact'"
        />
      </div>
    </td>
  </tr>
</template>

<script>
  import {computed} from "vue";
  import {Link as InertiaLink, router} from "@inertiajs/vue3";

  import {doctorProfession} from "@pages/Contact/Enums/Enums.js";

  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "ContactsContactRow",

    components: {
      ComponentCategoryBadge,
      ComponentIcon,
      ComponentIconButton,
      ComponentInfoIcon,
      ComponentTooltip,
      InertiaLink,
    },

    props: {
      contact: {
        type: Object,
        required: true,
      },
    },

    emits: ["open:deleteContact"],

    setup(props, {emit}) {
      const filteredPatientsUrl = computed(() => {
        return (
          route("patients.index") +
          "?flags[]=" +
          encodeURIComponent("related-contact") +
          "&flags[]=" +
          encodeURIComponent("related-contact:" + props.contact.id)
        );
      });

      function deleteContact() {
        emit("open:deleteContact");
      }

      function clickRow(event, href) {
        let node = event.target ?? event;

        if (!node.nodeName) {
          return;
        }

        if (node.nodeName.toLowerCase() === "td") {
          // click on <td> => open link
          router.visit(href);
          return;
        }

        if (
          node.nodeName.toLowerCase() === "a" ||
          node.nodeName.toLowerCase() === "button" ||
          node.nodeName.toLowerCase() === "select" ||
          node.nodeName.toLowerCase() === "input" ||
          node.nodeName.toLowerCase() === "textarea" ||
          node.nodeName.toLowerCase() === "dialog"
        ) {
          // click on a clickable element (a or button)
          return;
        }

        if (node.parentNode) {
          // click on any other element: check if parent is <td>
          return clickRow(node.parentNode, href);
        }
      }

      return {
        /** enum */
        doctorProfession,

        /** computed */
        filteredPatientsUrl,

        /** function */
        deleteContact,
        clickRow,
      };
    },
  };
</script>
