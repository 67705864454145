<template>
  <patientinput-component-autocomplete
    placeholder="Bitte Name/PZN eingeben"
    :fetch-method="searchPac"
    :test-id="testId + '-medication-search'"
    @selected="updateRowWithSelectedItem"
  >
    <template #autocomplete-item="{item}">
      <div class="min-h-10">
        <div class="flex justify-between">
          <div>{{ item.Name }} / {{ item.Packung }}</div>
        </div>

        <p class="text-sm text-gray-500 leading-tight">{{ item.Anbieter }} / PZN: {{ item.PZN }}</p>

        <div
          v-if="form.medication.pzns.some((pzn) => parseInt(pzn) === parseInt(item.PZN))"
          class="text-sm text-mcred-500"
        >
          Artikel wurde bereits erfasst
        </div>
      </div>
    </template>
  </patientinput-component-autocomplete>
</template>

<script>
  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  import PatientinputComponentAutocomplete from "@pages/Patientinput/Components/Autocomplete.vue";

  export default {
    name: "PatientinputMedicationSearch",

    components: {PatientinputComponentAutocomplete},

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const searchPac = (searchTerm) => {
        // some scanners prepend a '#', which we have to remove
        if (searchTerm.charAt(0) === "#") {
          searchTerm = searchTerm.slice(1);
        }

        // A scanned "PZN" value starts with a '-', so if
        // we receive a searchTerm like '-12345678', we
        // strip off this '-' and search for the PZN.
        let match = searchTerm.match(/^-(\d{7,8})/i);
        if (match && match[1]) {
          // we just use the digits as PZN and do not
          // care if it is 7 or 8 digits long
          searchTerm = match[1];
        }

        return new Promise((resolve) => {
          coreRouter.searchPacForPatientinput(searchTerm, {
            onSuccess: (response) => {
              resolve(response.data);
            },
            onError: () => {
              resolve(false);
            },
          });
        });
      };

      const updateRowWithSelectedItem = (selectedFam) => {
        if (!props.form.medication.pzns.some((pzn) => parseInt(pzn) === parseInt(selectedFam.PZN))) {
          props.form.medication.pzns.push(selectedFam.PZN);
          props.form.medication.list.push(selectedFam);
        }
      };

      return {
        /** function */
        searchPac,
        updateRowWithSelectedItem,
      };
    },
  };
</script>
