<template>
  <div :id="'p4umc-medication-rowcontrol' + dataHashId" class="mr-2">
    <component-tooltip :tooltip="showBadge ? 'Weitere Angaben sind hinterlegt' : 'Weitere / abweichende Angaben'">
      <component-badge v-if="showBadge" icon="info" class="text-mcgreen">
        <component-icon-button class="p4umc-primary" icon="edit" @click="onClick" />
      </component-badge>

      <component-icon-button v-else class="p4umc-primary" icon="edit" @click="onClick" />
    </component-tooltip>
  </div>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentBadge from "@components/Badges/Badge.vue";

  export default {
    name: "MedicationComponentRowControl",

    components: {ComponentBadge, ComponentIconButton, ComponentTooltip},

    props: {
      dataHashId: {
        type: String,
        required: true,
      },
      showBadge: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["click"],

    setup(props, {emit}) {
      const onClick = () => {
        emit("click");
      };

      return {onClick};
    },
  };
</script>
