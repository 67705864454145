<template>
  <div class="flex flex-col gap-5 h-full">
    <component-alert v-if="error" class="mt-3" type="error" :test-id="$page.props.testId">
      <p>Beim Laden der Seite ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>
    </component-alert>

    <template v-else>
      <patientinput-component-stepper
        v-if="currentStep > 1 && currentStep <= steps.length"
        :steps="steps.slice(2, -1)"
        :current-step="currentStep - 1"
        :disabled="currentStep === steps.length - 1"
        :test-id="$page.props.testId"
        @step-clicked="navigate"
      />

      <component :is="steps[currentStep].component" :form="form" :test-id="$page.props.testId" @navigate="navigate" />
    </template>
  </div>
</template>

<script>
  import {computed, defineComponent, onMounted, provide, ref} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import {importSPKI, CompactEncrypt} from "jose";
  import {isArray, isBoolean, isObject} from "lodash";
  import {restartActivityTracking} from "@pharma4u/medicheck-activity-tracker";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import PatientinputLayout from "@components/Layout/PatientinputLayout.vue";

  import PatientinputComponentStepBmp from "@pages/Patientinput/Components/Steps/StepBmp.vue";
  import PatientinputComponentStepCircumstances from "@pages/Patientinput/Components/Steps/StepCircumstances.vue";
  import PatientinputComponentStepCompliance from "@pages/Patientinput/Components/Steps/StepCompliance.vue";
  import PatientinputComponentStepDiagnoses from "@pages/Patientinput/Components/Steps/StepDiagnoses.vue";
  import PatientinputComponentStepEnd from "@pages/Patientinput/Components/Steps/StepEnd.vue";
  import PatientinputComponentStepLabordata from "@pages/Patientinput/Components/Steps/StepLabordata.vue";
  import PatientinputComponentStepMasterdata from "@pages/Patientinput/Components/Steps/StepMasterdata.vue";
  import PatientinputComponentStepMedication from "@pages/Patientinput/Components/Steps/StepMedication.vue";
  import PatientinputComponentStepMobility from "@pages/Patientinput/Components/Steps/StepMobility.vue";
  import PatientinputComponentStepSuccessCarer from "@pages/Patientinput/Components/Steps/StepSuccessCarer.vue";
  import PatientinputComponentStepSuccessPatient from "@pages/Patientinput/Components/Steps/StepSuccessPatient.vue";
  import PatientinputComponentStepSymptoms from "@pages/Patientinput/Components/Steps/StepSymptoms.vue";
  import PatientinputComponentStepWelcomeCarer from "@pages/Patientinput/Components/Steps/StepWelcomeCarer.vue";
  import PatientinputComponentStepWelcomePatient from "@pages/Patientinput/Components/Steps/StepWelcomePatient.vue";
  import PatientinputComponentStepWishesAims from "@pages/Patientinput/Components/Steps/StepWishesAims.vue";
  import PatientinputComponentStepper from "@pages/Patientinput/Components/Stepper.vue";

  export default defineComponent({
    name: "PagesPatientinputIndex",

    components: {
      ComponentAlert,
      PatientinputComponentStepper,
      PatientinputLayout,
    },

    layout: PatientinputLayout,

    props: {
      publicKey: {
        type: String,
        required: true,
      },
      saveUrl: {
        type: String,
        required: true,
      },
      isCarer: {
        type: Boolean,
        required: true,
      },
      isPatient: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const form = useForm({
        meta: {
          is_carer: props.isCarer,
          is_patient: props.isPatient,
        },

        masterdata: {
          patient: {
            firstname: null,
            lastname: null,
            birthdate: null,
            gender: null,
            insurancenumber: null,
          },
          doc_name: null,
          nursing_home_carer: null,
          nursing_home_name: null,
          nursing_home_street: null,
          nursing_home_zip: null,
          nursing_home_city: null,
          nursing_home_ward: null,
          nursing_home_room: null,
        },

        medication: {
          pzns: [],
          list: [], // only internal for displaying
          bmp: [],
        },

        compliance: {
          confident: 3,
          forgotten: 3,
          affect: 3,
          pain: 0,
        },

        symptoms: {
          mivs: [],
          list: [], // only internal for displaying
        },

        circumstances: {
          mivs: [],
          attributes: {
            tubePatient: {
              selected: false,
              payload: {
                tube_position: null,
              },
            },
            insulinPump: false,
            port: false,
            palliativePatient: false,
            stoma: false,
            bitesMedicines: false,
            refusesMedications: false,
            swallowingDifficulties: false,

            // mobility
            stick: false,
            rollator: false,
            wheelchair: false,
            bedridden: false,
          },
        },

        laborvalues: {
          size: null,
          weight: null,
          bmi: null,
          bloodpressure: null,
          pulse: null,
        },

        wishes_aims: [],

        diagnoses: {
          mivs: [],
          list: [], // only internal for displaying
        },

        additional: null,
      });

      const currentStep = ref(0);

      const error = ref(null);

      let publicKey = null;

      const steps = computed(() => {
        if (form.meta.is_patient) {
          return [
            {name: "Start", component: PatientinputComponentStepWelcomePatient},
            {name: "Medikationsplan", component: PatientinputComponentStepBmp},
            {name: "Start", component: PatientinputComponentStepMasterdata},
            {name: "Medikation", component: PatientinputComponentStepMedication},
            {name: "Compliance", component: PatientinputComponentStepCompliance},
            {name: "Probleme/Symptome", component: PatientinputComponentStepSymptoms},
            {name: "Lebensumstände", component: PatientinputComponentStepCircumstances},
            {name: "Labor-/Vitalwerte", component: PatientinputComponentStepLabordata},
            {name: "Wünsche und Ziele", component: PatientinputComponentStepWishesAims},
            {name: "Abschluss", component: PatientinputComponentStepEnd},
            {name: "Erfolg", component: PatientinputComponentStepSuccessPatient},
          ];
        }

        return [
          {name: "Start", component: PatientinputComponentStepWelcomeCarer},
          {name: "Medikationsplan", component: PatientinputComponentStepBmp},
          {name: "Stammdaten", component: PatientinputComponentStepMasterdata},
          {name: "Labor-/Vitalwerte", component: PatientinputComponentStepLabordata},
          {name: "Diagnosen", component: PatientinputComponentStepDiagnoses},
          {name: "Probleme/Symptome", component: PatientinputComponentStepSymptoms},
          {name: "Mobilität", component: PatientinputComponentStepMobility},
          {name: "Weitere Angaben", component: PatientinputComponentStepCircumstances},
          {name: "Abschluss", component: PatientinputComponentStepEnd},
          {name: "Erfolg", component: PatientinputComponentStepSuccessCarer},
        ];
      });

      onMounted(() => {
        importSPKI(props.publicKey, "RSA-OAEP")
          .then((key) => {
            publicKey = key;
          })
          .catch(() => {
            error.value = true;
          });

        if (!import.meta.env.DEV) {
          // warn when reloading or closing tab
          window.addEventListener("beforeunload", (event) => {
            if (form.isDirty) {
              event.preventDefault();
              event.stopPropagation();
            }
          });
        }
      });

      const navigate = (toOrDirection) => {
        if (toOrDirection === "+") {
          currentStep.value++;
        } else if (toOrDirection === "-") {
          currentStep.value--;
        } else if (toOrDirection === "submit") {
          submit();
        } else if (Number.isInteger(toOrDirection)) {
          currentStep.value = toOrDirection;
        }

        restartActivityTracking();
        window.scrollTo(0, 0);
      };

      provide("navigate", navigate);

      const submit = async () => {
        form.processing = true;

        const formData = form.data();
        const meta = formData.meta;

        delete formData.meta; // does not need encryption
        delete formData.medication.list; // was only internal for displaying
        delete formData.symptoms.list; // was only internal for displaying
        delete formData.diagnoses.list; // was only internal for displaying

        if (meta.is_carer) {
          delete formData.compliance; // compliance is only for patient
        }

        const deleteEmptyData = (key) => {
          Object.keys(formData[key]).forEach((i) => {
            if (isArray(formData[key][i]) && formData[key][i].length === 0) {
              delete formData[key][i];
            } else if (isObject(formData[key][i]) && Object.keys(formData[key][i]).length === 0) {
              delete formData[key][i];
            } else if (isBoolean(formData[key][i]) && formData[key][i] === false) {
              delete formData[key][i];
            } else if (formData[key][i] === null) {
              delete formData[key][i];
            }
          });

          if (Object.keys(formData[key]).length === 0) {
            delete formData[key];
          }
        };

        Object.keys(formData.circumstances.attributes).forEach((key) => {
          if (isBoolean(formData.circumstances.attributes[key]) && formData.circumstances.attributes[key] === false) {
            delete formData.circumstances.attributes[key];
          }
        });

        if (formData.circumstances.attributes.tubePatient.selected === false) {
          delete formData.circumstances.attributes.tubePatient;
        }

        deleteEmptyData("masterdata");
        deleteEmptyData("medication");
        deleteEmptyData("symptoms");
        deleteEmptyData("circumstances");
        deleteEmptyData("laborvalues");
        deleteEmptyData("diagnoses");

        if (formData.additional === null) {
          delete formData.additional;
        }

        if (formData.wishes_aims.length === 0) {
          delete formData.wishes_aims;
        }

        const encryptedData = await new CompactEncrypt(new TextEncoder().encode(JSON.stringify(formData)))
          .setProtectedHeader({alg: "RSA-OAEP", enc: "A256GCM"})
          .encrypt(publicKey);

        form
          .transform(() => ({
            meta: meta,
            encrypted: encryptedData,
            firstname_clipped: formData.masterdata.patient.firstname.substr(0, 3),
            lastname_clipped: formData.masterdata.patient.lastname.substr(0, 3),
            birthdate_clipped: new Date(formData.masterdata.patient.birthdate).getFullYear(),
            insurancenumber_clipped: formData.masterdata.patient.insurancenumber?.substr(0, 3),
          }))
          .post(props.saveUrl, {
            onSuccess: () => {
              currentStep.value++;
            },
          });
      };

      return {
        /** const */
        form,
        currentStep,
        error,

        /** computed */
        steps,

        /** function */
        navigate,
      };
    },
  });
</script>
