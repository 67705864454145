<template>
  <component-breadcrumb
    class="mt-2"
    :links="[
      {
        label: 'Patienten',
        url: $route('patients.index'),
        testId: 'patient-index',
      },
      {
        label: 'Neuen Patient anlegen',
      },
    ]"
  />

  <section class="mt-6 bg-white max-w-screen-xl">
    <div class="pt-4 px-4">
      <h2 class="text-2xl font-semibold">Neuer Patient</h2>
    </div>

    <article class="p-4 flex items-start flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
      <div class="w-full lg:max-w-screen-md space-y-6">
        <patients-patient-master-data :continue-with="continueWith" :test-id="$page.props.testId" />
      </div>
    </article>
  </section>
</template>

<script>
  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import PatientsPatientMasterData from "@pages/Patients/Components/PatientMasterData.vue";

  export default {
    name: "PagesPatientShow",

    components: {
      ComponentBreadcrumb,
      PatientsPatientMasterData,
    },

    props: {
      patient: {
        type: Object,
        default: null,
      },
      continueWith: {
        type: String,
        default: null,
      },
    },
  };
</script>
